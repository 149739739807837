export const SubAttribute = 'signInUserSession.idToken.payload.sub';
export const EmailAttribute = 'signInUserSession.idToken.payload.email';
export const EmailVerifiedAttribute =
    'signInUserSession.idToken.payload.email_verified';
export const PhoneNumberVerifiedAttribute =
    'signInUserSession.idToken.payload.phone_number_verified';
export const PhoneNumberAttribute =
    'signInUserSession.idToken.payload.phone_number';
export const GivenNameAttribute =
    'signInUserSession.idToken.payload.given_name';
export const FamilyNameAttribute =
    'signInUserSession.idToken.payload.family_name';
export const CompanyIdAttribute =
    'signInUserSession.idToken.payload.custom:companyId';
export const RoleIdAttribute =
    'signInUserSession.idToken.payload.custom:roleId';
export const UserJWTTokenAttribute = 'signInUserSession.idToken.jwtToken';
export const OrganisationIdAttribute =
    'signInUserSession.idToken.payload.custom:organisationId';
export const IsOrganisationViewAttribute =
    'signInUserSession.idToken.payload.custom:isOrganisationView';
export const OrganisationRoleIdAttribute =
    'signInUserSession.idToken.payload.custom:organisationRoleId';
export const UserAccessTokenAttribute =
    'signInUserSession.accessToken.jwtToken';
