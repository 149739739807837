import { Reducer } from 'redux';
import { RolesState, RolesActionTypes } from './types';

// Type-safe initialState!
export const initialState: RolesState = {
    loading: false,
    allRoles: [],
    rolePermissions: {},
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<RolesState> = (state = initialState, action) => {
    switch (action.type) {
        case RolesActionTypes.GET_ALL_ROLES_REQUEST: {
            return { ...state, loading: true };
        }
        case RolesActionTypes.GET_ALL_ROLES_SUCCESS: {
            return { ...state, loading: false, allRoles: action.payload };
        }
        case RolesActionTypes.GET_ROLE_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                rolePermissions: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as rolesReducer };
