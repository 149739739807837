import update from 'immutability-helper';
import { concat, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import { USERS_PAGE } from '../../config/tableAndPageConstants';
import { usersSortByOptions } from '../../constants/usersSortAndFilters';
import { UsersActionTypes, UsersState } from './types';

// Type-safe initialState!
export const initialState: UsersState = {
    loading: false,
    errorMessages: [],
    changeRolesLoading: false,
    data: [],
    pageData: {
        pageSize: USERS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: { Name: '', Email: '', Role: [] },
    sortBy: usersSortByOptions[0],
    sortAscending: true,
    tableFilter: undefined,
    userPreference: {},
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<UsersState> = (state = initialState, action) => {
    switch (action.type) {
        case UsersActionTypes.GET_USERS_REQUEST: {
            return { ...state, loading: true };
        }
        case UsersActionTypes.GET_USERS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case UsersActionTypes.GET_USERS_ERROR: {
            return {
                ...state,
                loading: false,
                data: [],
                errorMessages: action.payload.errorMessages,
            };
        }
        case UsersActionTypes.CHANGE_USERS_ROLE_REQUEST: {
            return { ...state, changeRolesLoading: true };
        }
        case UsersActionTypes.CHANGE_USERS_ROLE_RESPONSE: {
            return { ...state, changeRolesLoading: false };
        }
        case UsersActionTypes.UPDATE_USERS_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case UsersActionTypes.UPDATE_USERS_SORT_BY: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
            };
        }
        case UsersActionTypes.UPDATE_USERS_TABLE_FILTER: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case UsersActionTypes.CLEAR_USERS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }
        case UsersActionTypes.CLEAR_USERS_STATE_DATA: {
            return {
                ...initialState,
            };
        }
        case UsersActionTypes.GET_USER_PREFERENCES_SUCCESS: {
            return {
                ...state,
                userPreference: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as usersReducer };
