import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import { Tabs, Col, Row, Typography } from 'antd';
import { withRouter } from 'react-router-dom';

import ImportNowPage from '../payment_plan/import_now/ImportPaymentPlanPage';
import { includes, map } from 'lodash';
import { ApplicationState } from '../../../store';
import { getSelectedAccountingSystemBasedOnCompanyDetails } from '../../../utils/commonFunctions';
import { accountingSystemOptions } from '../../../constants/settings';
import ImportHistoryPage from '../../../components/history/ImportHistoryPage';
import { EntityType } from '../../../store/import/types';
import { setImportHistoryEntityTypeAction } from '../../../store/import/actions';

const { TabPane } = Tabs;
const { Title } = Typography;
interface IProps {
    location: any;
    history: any;
    match: {
        path: string;
    };
}

const routePaths = {
    IMPORT: { name: 'Import Now', component: ImportNowPage },
    IMPORT_HISTORY: { name: 'History', component: ImportHistoryPage },
};

const showImportPagePath = [accountingSystemOptions.EXCEL];
const importRoute = '/app/import/payment_plan';
const ImportPageContainer: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    useEffect( () => {
        dispatch(setImportHistoryEntityTypeAction(EntityType.PaymentPlan));
    }, []);

    const [currentTab, setCurrentTab] = useState<string>('');
    const selectedUserCompany = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const selectedAccountingSystem = getSelectedAccountingSystemBasedOnCompanyDetails(
        selectedUserCompany
    );
    const routeTabs = includes(showImportPagePath, selectedAccountingSystem)
        ? [routePaths.IMPORT, routePaths.IMPORT_HISTORY]
        : [routePaths.IMPORT_HISTORY];

    useEffect(() => {
        const currentTabPath = props.location.pathname
            .replace(importRoute, '')
            .replace('/', '');

        const selectedTab = currentTabPath || routeTabs[0].name.toLowerCase();

        setCurrentTab(selectedTab);
    }, [props.location.pathname, routeTabs]);

    const onTabChange = (tabKey: string) => {
        const tabPath =
            tabKey === routeTabs[0].name.toLowerCase() ? '' : `/${tabKey}`;

        props.history.push(`${importRoute}${tabPath}`);
    };

    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={24}>
                            <Title level={3}>Import Payment Plans</Title>
                        </Col>
                    </Row>
                    <br />
                    <Row key="tabs-container">
                        <Col span={24}>
                            <Tabs
                                className="left-aligned-tab h-100"
                                tabPosition="left"
                                activeKey={currentTab}
                                onChange={onTabChange}
                            >
                                {map(
                                    routeTabs,
                                    ({
                                        name: tabName,
                                        component: TabComponent,
                                    }: any) => {
                                        const lowerCaseTabName = tabName.toLowerCase();

                                        return (
                                            <TabPane
                                                tab={tabName}
                                                key={lowerCaseTabName}
                                            >
                                                {lowerCaseTabName ===
                                                currentTab ? (
                                                    <div
                                                        className="custom-tab-container"
                                                        style={{
                                                            height:
                                                                window.innerHeight -
                                                                150,
                                                        }}
                                                    >
                                                        <TabComponent
                                                            history={
                                                                props.history
                                                            }
                                                        />
                                                    </div>
                                                ) : null}
                                            </TabPane>
                                        );
                                    }
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        </div>
    );
};

export default withRouter(ImportPageContainer);
