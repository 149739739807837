export enum WorkflowActionTypes {
    GET_WORKFLOW_DEFINITION_REQUEST = '@@workflow/GET_WORKFLOW_DEFINITION_REQUEST',
    GET_WORKFLOW_DEFINITION_SUCCESS = '@@workflow/GET_WORKFLOW_DEFINITION_SUCCESS',
    GET_WORKFLOW_DEFINITION_ERROR = '@@workflow/GET_WORKFLOW_DEFINITION_ERROR',
    SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST = '@@workflow/SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST',
    SAVE_WORKFLOW_DEFINITION_CONFIGURATION_RESPONSE = '@@contents/SAVE_WORKFLOW_DEFINITION_CONFIGURATION_RESPONSE',
    CLEAR_WORKFLOW_DEFINITION = '@@workflow/CLEAR_WORKFLOW_DEFINITION',
    SELECT_WORKFLOW_ID = '@@workflow/SELECT_WORKFLOW_ID',
}
export interface SaveWorkflowDefinitionConfigurationPayload {
    WorkflowId: string;
    WorkflowTransitionDefinitions: WorkflowTransitionDefinition[];
}

export interface WorkflowTransitionDefinition {
    Name: string;
    Trigger: string;
    NewState: string;
    Gap: number;
}

export interface GetWorkflowDefinitionRequestPayload {
    WorkflowId: string;
}

export interface WorkflowDefinitionState {
    readonly saveLoading: boolean;
    readonly selectedWorkflowId: string | null;
    readonly workflowDefinition: {
        readonly errorMessages: string[];
        readonly loading: boolean;
        readonly workflowDefinitionConfigure: WorkflowDefinition | null;
    }
}

export interface WorkflowDefinition {
    readonly WorkflowId: string;
    readonly Name: string;
    readonly EntityType: string;
    readonly AutoClose: boolean;
    readonly AutoCloseTrigger: string;
    readonly States: [WorkflowState];
}

export interface WorkflowState {
    readonly Name: string;
    readonly Type: string;
    readonly Automation: string;
    readonly ReminderType: string;
    readonly NotDueGap: number | undefined;
    readonly IsEntryOrExit: boolean | undefined;
    readonly Transitions: WorkflowTransition[];
    readonly DeliveryMethod: number | undefined;
}

export interface WorkflowTransition {
    readonly DisplayName: string;
    readonly Trigger: string;
    readonly NewState: string;
    readonly MinimumAmount: number | undefined;
    readonly Events: WorkflowEvent[];
    readonly Timing: WorkflowTiming;
}

export interface WorkflowEvent {
    readonly FunctionName: string;
    readonly HandleCallback: boolean | undefined;
}

export interface WorkflowTiming {
    readonly Orientation: string;
    Gap: number;
}
export interface WorkflowTimingDefinition {
    Id: string;
    Name: string;
    Trigger: string;
    NewState: string;
    Gap: number;
    Orientation: string;
    DisplayName: string;
}