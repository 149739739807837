import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { BankFileCustomization, BanksActionTypes } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { API_NAME } from "../../config/config";
import { getBankFileSuccessAction, saveBankFileResponseAction } from "./actions";
import { get } from "lodash";
import { DynamicObject } from "../../utils/commonInterfaces";
import queries from '../../graphql/queries.graphql';
import { setLoadingCompanyAction } from "../companies/actions";

/**
 * Function called for saving the bank-file - talking to the API.
 */
function* handleSaveBankFileRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/bank-file-configuration/save', {
            body: payload,
        });

        yield put(saveBankFileResponseAction());

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveBankFileResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function responsible for getting the company workflow names.
 */
function* handleGetBankFileRequest() {
    try {
        yield put(setLoadingCompanyAction(true));
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_BANK_FILE_CONFIGURATION_FOR_COMPANY)
        );

        const bankFileCustomization: BankFileCustomization = get(
            res,
            'data.GetBankFileConfigurationForCompany'
        );
        yield put(getBankFileSuccessAction(bankFileCustomization));
        yield put(setLoadingCompanyAction(false));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getBankFileSuccessAction(undefined)); // Empty if error
        yield put(setLoadingCompanyAction(false));
    }
}

function* watchSaveBankFileRequest() {
    yield takeLatest(
        BanksActionTypes.SAVE_BANK_FILE_REQUEST,
        handleSaveBankFileRequest
    );
}

function* watchGetBankFileRequest() {
    yield takeLatest(
        BanksActionTypes.GET_BANK_FILE,
        handleGetBankFileRequest
    );
}

function* banksSaga() {
    yield all([
        fork(watchSaveBankFileRequest),
        fork(watchGetBankFileRequest),
    ]);
}

export default banksSaga;