/**
 * File responsible for all the UI and actions for Settings>Display page - `/app/settings/display`.
 */

import {
    Button,
    Col,
    Divider,
    Form,
    InputNumber,
    Modal,
    Row,
    Select,
    Typography,
} from 'antd';
import { get, includes, isEmpty, map, toLower } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import SelectReadonly from '../../components/FormComponents/SelectReadonly';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import { DEFAULT_NUMBER_OF_DAYS_FOR_RECENT_COMMENT } from '../../constants/common';
import { atbCalculationOptions, atbPeriodOptions, AtbViewType } from '../../constants/settings';
import { ApplicationState } from '../../store';
import {
    getUserCompaniesRequestAction,
    saveUserCompanyRequestAction,
} from '../../store/companies/actions';
import { CompaniesState } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getRolePermissions } from '../../store/roles/sagas';
import { limitOnlyNumber } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';

const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { Option } = Select;
interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const formFieldNames = {
    ATBCalculationVia: 'ATBCalculationVia',
    ATBCalculationPeriod: 'ATBCalculationPeriod',
    NumberOfDaysBeforeRecentCommentTurnedOff: 'NumberOfDaysBeforeRecentCommentTurnedOff',
    DisplayCustomerCompanyName: 'DisplayCustomerCompanyName',
};

const DisplayManagementPage: React.FC<IProps> = (props: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();
    const rolePermissions = useSelector(getRolePermissions);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const { selectedUserCompany, saveLoading } = companiesState;

    const displaySettingsData = get(selectedUserCompany, 'Company', {});

    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form;

    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, all
                    the data will be reverted to the last saved values.
                </div>
            ),
            onOk() {
                resetFields();
            },
            onCancel() {},
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (IsSuccess: boolean) => {
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: `Display settings saved successfully!`,
                onOk: () => {
                    dispatch(getUserCompaniesRequestAction());
                },
            });
        } else {
            Modal.error({
                title: 'Error',
                content: `Failed to save display settings`,
            });
        }
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const payload = {
                    IsDueDateView:
                        values[formFieldNames.ATBCalculationVia] === 'DueDate',
                    IsCalendarView:
                        values[formFieldNames.ATBCalculationPeriod] === 'CalendarMonth',
                    NumberOfDaysBeforeRecentCommentTurnedOff: values[formFieldNames.NumberOfDaysBeforeRecentCommentTurnedOff],
                    DetailType: 'AtbSetting',
                };

                dispatch(
                    saveUserCompanyRequestAction(
                        payload,
                        ({ IsSuccess }: { IsSuccess: boolean }) =>
                            handleModalSaveResponse(IsSuccess)
                    )
                );
            }
        });
    };

    /**
     * Function that populates the dropdown options for ATB calculation.
     */
    const populateATBCalculationOptions = () =>
        map(
            atbCalculationOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );

    const populateATBPeriodOptions = () =>
        map(
            atbPeriodOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );

    /**
     * Function that populates the dropdown options for ATB calculation.
     */
    // const populateDisplayCompanyInfoOptions = () =>
    //     map(
    //         companyDisplayInformationOptions,
    //         ({ label, value }: { label: string; value: string }) => (
    //             <Option key={value} value={value}>
    //                 {label}
    //             </Option>
    //         )
    //     );

    /**
     * Function for handling number of days for recent comment change
     */
    const handleNumberOfDaysForRecentCommentChange = (amount: number | undefined) => {
        if (amount) {
            amount = Math.round(amount);
            setTimeout(() => {
                setFieldsValue({
                    [formFieldNames.NumberOfDaysBeforeRecentCommentTurnedOff]: amount
                });
            });
        }
    };

    let formDisabled = true;
    const allowedRoles = rolePermissions.CUSTOMER_PAYMENTS_UPDATE;
    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        formDisabled = false;
    }

    const formHasChanges = true;

    let atbStateValue = '';
    let atbPeriodValue = '';

    if (get(displaySettingsData, AtbViewType.DueDateView) !== undefined) {
        atbStateValue =
            get(displaySettingsData, AtbViewType.DueDateView) === true
                ? 'DueDate'
                : 'CreatedDate';
    }

    if (get(displaySettingsData, AtbViewType.CalendarView) !== undefined) {
        atbPeriodValue =
            get(displaySettingsData, AtbViewType.CalendarView) === true
                ? 'CalendarMonth'
                : '306090';
    }

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 7 },
                        xl: { span: 7 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 17 },
                        xl: { span: 17 },
                        lg: { span: 18 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container" type="flex" align="middle">
                            <Col span={12}>
                                <Row>
                                    <Col>
                                        <Title level={3}>
                                            Display settings
                                        </Title>
                                    </Col>
                                    <Col>
                                        These settings are applied to all users
                                        viewing this company
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12} className="ta-right">
                                <Button
                                    className="mr-10 w-100px"
                                    type="primary"
                                    onClick={onSaveButtonClick}
                                    disabled={formDisabled || !formHasChanges}
                                    loading={saveLoading}
                                >
                                    Save
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                    disabled={formDisabled || !formHasChanges}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>Aged Trial Balance calculation</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col span={24}>
                                        Display the Aged Trial Balance of
                                        invoices and {toLower(customerLabel)}{' '}
                                        using the &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.ATBCalculationVia,
                                                {
                                                    initialValue: atbStateValue,
                                                }
                                            )(
                                                <SelectReadonly
                                                    readOnly={formDisabled}
                                                    style={{ width: 180 }}
                                                >
                                                    {populateATBCalculationOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                                className='mt-8'
                            >
                                <Row>
                                    <Col span={24}>
                                        Aged Trial Balance calculation period &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.ATBCalculationPeriod,
                                                {
                                                    initialValue: atbPeriodValue,
                                                }
                                            )(
                                                <SelectReadonly
                                                    readOnly={formDisabled}
                                                    style={{ width: 180 }}
                                                >
                                                    {populateATBPeriodOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>Recent comment indicator</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col span={24}>
                                        Number of days before the <em>recent comment indicator</em> is turned off:&nbsp;&nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.NumberOfDaysBeforeRecentCommentTurnedOff,
                                                {
                                                    initialValue: get(displaySettingsData, 'NumberOfDaysBeforeRecentCommentTurnedOff')
                                                        || DEFAULT_NUMBER_OF_DAYS_FOR_RECENT_COMMENT,
                                                }
                                            )(
                                                <InputNumber
                                                    onChange={handleNumberOfDaysForRecentCommentChange}
                                                    placeholder="Input a number"
                                                    onKeyDown={limitOnlyNumber(false)}
                                                    step={1}
                                                    min={1}
                                                    max={30}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>Display company information</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col span={24} className="mb-10">
                                        When displaying your customer's company
                                        name on the UI &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.DisplayCustomerCompanyName,
                                                {
                                                    initialValue:
                                                        get(
                                                            displaySettingsData,
                                                            'DisplayCustomerCompanyName'
                                                        ) ||
                                                        get(
                                                            companyDisplayInformationOptions,
                                                            '0.value'
                                                        ),
                                                }
                                            )(
                                                <SelectReadonly
                                                    disabled={formDisabled}
                                                    style={{ width: 130 }}
                                                >
                                                    {populateDisplayCompanyInfoOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                        &nbsp; the primary contact's name.
                                    </Col>
                                    <Col>
                                        The display of an individual's name is
                                        unchanged.
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-10">
                            <Col>
                                <Alert
                                    type="info"
                                    showIcon
                                    message="This setting does not alter the way communications are generated"
                                />
                            </Col>
                        </Row> */}
                        <Divider />
                    </QueueAnim>
                </Form>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle="Saving display data"
                        modalVisible={saveLoading}
                        displayMessage="Please wait while saving display data. . ."
                    />
                )}
            </Col>
        </div>
    );
};

const DisplayManagementPageForm = Form.create({
    name: 'display-management-form',
})(DisplayManagementPage);
export default withRouter(DisplayManagementPageForm);
