import { Reducer } from 'redux';
import { SmsCustomizationState, SmsProviderActionTypes } from './types';

// Type-safe initialState!
export const initialState: SmsCustomizationState = {
    data: {
        Items: [],
        SelectedItemId: '',
        IsCustomizeSms: false
    },
    errorMessages: [],
    loading: false,
    saveLoading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SmsCustomizationState> = (state = initialState, action) => {
    switch (action.type) {
        case SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_REQUEST: {
            return { ...state, loading: true };
        }
        case SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_SUCCESS: {
            return { ...state, loading: false, data: action.payload };
        }
        case SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_FAIL: {
            return { ...state, loading: false };
        }
        case SmsProviderActionTypes.SAVE_SMS_CUSTOM_PROVIDER_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case SmsProviderActionTypes.SAVE_SMS_CUSTOM_PROVIDER_RESPONSE: {
            return { ...state, saveLoading: false };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as smsProvidersReducer };
