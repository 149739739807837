/**
 * Component for showing an error when page is not accessible.
 */

import React from 'react';
import { Row, Col } from 'antd';
import AlertMessageWithIcon from './AlertMessageWithIcon';
import { getIfIsLg } from '../../utils/commonFunctions';
interface IProps {
    readonly errorFaIcon: string[];
    readonly errorTitle: string;
    readonly errorDisplayComponent: any;
}
const ErrorPageComponent: React.FC<IProps> = ({
    errorFaIcon,
    errorTitle,
    errorDisplayComponent,
}: IProps) => {
    const isLg = getIfIsLg();
    return (
        <Row
            type="flex"
            style={{
                padding: 20,
                height: '100%',
            }}
        >
            <Col
                span={24}
                style={{
                    height: '90%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: isLg ? '80%' : '60%',
                        height: '50%',
                        minHeight: 300,
                        // marginTop: '-5%',
                    }}
                >
                    <AlertMessageWithIcon
                        alertType="error"
                        faIcon={errorFaIcon}
                        title={errorTitle}
                        displayMessageComp={errorDisplayComponent}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default ErrorPageComponent;
