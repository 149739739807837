export const dateFormatDDMMYYYYSlash = 'DD/MM/YYYY';
export const dateFormatDMMMYYYYSpace = 'D MMM YYYY';
export const dateFormatDDMMMYYYYSpace = 'DD MMM YYYY';
export const dateFormatDoMMMYYYYSpace = 'Do MMM YYYY';
export const dateFormatDoMMMYYYYhmmASpace = 'Do MMM YYYY h:mm A';
export const dateFormatDoMMMYYYYHHmmSpace = 'Do MMM YYYY HH:mm';
export const dateFormatDoMMMYYYYHHmmssSpace = 'Do MMM YYYY HH:mm:ss';
export const dateFormatYYYYMMDDTHHmmssDash = 'YYYY-MM-DDTHH:mm:ss';
export const dateFormatYYYYMMDDTHHmmssDashSpace = 'YYYY-MM-DD HH:mm:ss';
export const dateFormatYYYYMMDDTHHmm00Dash = 'YYYY-MM-DDTHH:mm:00';
export const dateFormatYYYYMMDDTHHmmssSSSSSSDash = 'YYYY-MM-DDTHH:mm:ss.SSSSSS';
export const dateFormatYYYYMMDDTHHmmssSSSSSSUTCDash = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const dateFormatDDMMMYYYYHHmmSpace = 'DD MMM YYYY HH:mm';
export const dateFormatDDMMMMYYYYhmmASpace = 'DD MMMM YYYY h:mm A';
export const dateFormatDDMMMYYYYhmmASpace = 'DD MMM YYYY h:mm A';
export const dateFormatYYYYMMDDDash = 'YYYY-MM-DD';
export const dateFormatDDMMMYYYYhmmaSpace = 'DD MMM YYYY h:mma';
export const dateFormatTimestamp = 'x';
export const dateFormatFileDownload = 'YYYYMMDDHHmmss';
export const dateFormatYYYYMMDDT00Dash = 'YYYY-MM-DDT00:00:00';
export const dateFormatNowPlaceholder = 'DDMMYYHHmmss';
export const dateFormatMDYYYYhmmssASpaceSlash = 'M/D/YYYY h:mm:ss A';
export const dateFormatMDYYYYSlash = 'M/D/YYYY';
export const timeFormatHHmm = 'HH:mm';
export const dateFormatMMMYYYYSpace = 'MMM YYYY';
export const dateFormatMMDDYYYYhhmmssASpaceSlash = 'MM/DD/YYYY hh:mm:ss AM/PM';
