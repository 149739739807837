/**
 * Component for populating the details drawer.
 */

import { Drawer } from 'antd';
import React from 'react';
import { DynamicObject } from '../../utils/commonInterfaces';
import SmsProviderItemDetailsComponent from './SmsProviderItemDetailsComponent';

interface IProps {
    readonly visible: boolean;
    readonly closeDrawer: () => void;
    readonly record: DynamicObject;
}

const usedDrawerWidth = 500;
const SmsProviderItemDetailsDrawerComponent: React.FC<IProps> = ({
    visible,
    closeDrawer,
    record
}: IProps) => {
    return (
        <Drawer
            className="protected-drawer"
            title="Provider"
            visible={visible}
            width={usedDrawerWidth}
            onClose={closeDrawer}
            destroyOnClose
            zIndex={1300}
            bodyStyle={{
                width: usedDrawerWidth,
            }}
        >
            {visible && (
                <div id="drawer-container">
                    <SmsProviderItemDetailsComponent
                        visible={visible}
                        record={record}
                        closeDrawer={closeDrawer}
                    />
                </div>
            )}
        </Drawer>
    );
};

export default SmsProviderItemDetailsDrawerComponent;
