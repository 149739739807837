import { Col, Row, Tabs, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { useState } from 'react';
import ReportHistoryTable from '../../components/report/ReportHistoryTable';
import { get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { DEFAULT_REGION_NAME } from '../../config/config';
import { getReportDataForOrganisationRequestAction, getReportsForOrganisationRequestAction } from '../../store/report/actions';
import { Organisation } from '../../store/organisations/types';
import { ApplicationState } from '../../store';

const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps { }

const ReportHistoryOrganisationPage: React.FC<IProps> = ({ }) => {
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const regions = get(selectedUserOrganisation, 'SupportedRegions').sort();
    const [selectedRegion, setSelectedRegion] = useState(DEFAULT_REGION_NAME);

    const handleTabChange = (value: string) => {
        setSelectedRegion(value);
    };

    const populateReportContent = () => {
        if (regions && regions.length === 1) {
            return <ReportHistoryTable
                getReportsRequestAction={getReportsForOrganisationRequestAction}
                getReportDataRequestAction={(ReportJobId: string) => getReportDataForOrganisationRequestAction(ReportJobId, regions[0])}
                region={regions[0]}
            />
        }

        return <Tabs
            activeKey={selectedRegion}
            onChange={handleTabChange}
        >
            {map(regions, (region) => (
                <TabPane
                    tab={region === DEFAULT_REGION_NAME ? `${region} (Global)` : region}
                    key={region}
                >
                    {selectedRegion === region && <ReportHistoryTable
                        getReportsRequestAction={getReportsForOrganisationRequestAction}
                        getReportDataRequestAction={(ReportJobId: string) => getReportDataForOrganisationRequestAction(ReportJobId, region)}
                        region={selectedRegion}
                    />}
                </TabPane>
            )
            )}
        </Tabs>
    };

    return (<>
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>Report History</Title>
                    </Col>
                </Row>
                <div className="spacer-10" />
                <div className="spacer-10" />
                {populateReportContent()}
            </QueueAnim>
        </Col>
    </>)
};

export default ReportHistoryOrganisationPage;
