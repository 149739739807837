/**
 * File responsible for all the UI and actions for Settings>Remittance Advice Customization page container - `/app/settings/ra_customization`.
 */

import { Col, Row, Tabs, Typography } from 'antd';
import { History as IHistory } from 'history';
import { get, map } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import {
    CompaniesState,
    CompanyUserRole,
} from '../../../store/companies/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { updateLessVariables } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import '../settings.less';
import RACustomizationProcessingSettingsPage from './process-settings/RACustomizationProcessingSettingsPage';
import RACustomizationRebateMappingsPage from './rebate-mappings/RACustomizationRebateMappingsPage';

const { TabPane } = Tabs;
const { Title } = Typography;
interface IProps {
    location: Location;
    history: typeof IHistory;
    match: {
        path: string;
    };
}

const routeTabs = [
    { name: 'Process Settings', component: RACustomizationProcessingSettingsPage },
    { name: 'Rebate Mappings', component: RACustomizationRebateMappingsPage }
];
const raCustomizationRoute = '/app/settings/ra_customization';
const RACustomizationPageContainer: React.FC<IProps> = (props: IProps) => {
    const rolePermissions = useSelector(getRolePermissions);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;

    const childRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [currentTab, setCurrentTab] = useState<string>('');

    // const rolePermissions = useSelector(getRolePermissions);

    /**
     * Responsible for setting the selected tab on page load. Based on the route path.
     */
    useEffect(() => {
        const currentTabPath = props.location.pathname
            .replace(raCustomizationRoute, '')
            .replace('/', '');
        const selectedTab = currentTabPath || routeTabs[0].name.toLowerCase();

        setCurrentTab(selectedTab);
    }, [props.location.pathname]);

    /**
     * Function that is executed when a tab is selected (change - different from the previously selected tab).
     * @param tabKey - key for the tab (required to set the tab identity)
     */
    const onTabChange = (tabKey: string) => {
        const tabPath =
            tabKey === routeTabs[0].name.toLowerCase() ? '' : `/${tabKey}`;

        props.history.push(`${raCustomizationRoute}${tabPath}`);
    };

    /**
     * Function called on mount and unmount.
     * This one specifically handles the unmounting to listen for changes
     * and reset colors if needed.
     */
    const mountUnmountFunction = () => {
        return () => {
            if (childRef.current) {
                const hasChanges = childRef.current.checkForChanges();

                if (hasChanges === true) {
                    if (selectedUserCompany) {
                        const { PrimaryColorTheme, SecondaryColorTheme } =
                            selectedUserCompany.Company;

                        if (PrimaryColorTheme && SecondaryColorTheme) {
                            updateLessVariables({
                                '@custom-primary-color': PrimaryColorTheme,
                                '@custom-secondary-color': SecondaryColorTheme,
                            });
                        }
                    }
                }
            }
        };
    };

    useEffect(mountUnmountFunction, []);

    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={12}>
                            <Title level={3}>Remittance Advice Customization</Title>
                        </Col>
                    </Row>
                    <br />
                    <Row key="tabs-container">
                        <Col span={24}>
                            <Tabs
                                className="left-aligned-tab h-100"
                                tabPosition="left"
                                activeKey={currentTab}
                                onChange={onTabChange}
                            >
                                {map(
                                    routeTabs,
                                    ({
                                        name: tabName,
                                        component: TabComponent,
                                    }: {
                                        name: string;
                                        component: any;
                                    }) => {
                                        const lowerCaseTabName =
                                            tabName.toLowerCase();

                                        return (
                                            <TabPane
                                                tab={tabName}
                                                key={lowerCaseTabName}
                                            >
                                                {lowerCaseTabName ===
                                                currentTab ? (
                                                    <div
                                                        className="custom-tab-container"
                                                        style={{
                                                            height:
                                                                window.innerHeight -
                                                                150,
                                                        }}
                                                    >
                                                        <TabComponent
                                                            history={
                                                                props.history
                                                            }
                                                            selectedUserCompany={get(
                                                                selectedUserCompany,
                                                                'Company',
                                                                {}
                                                            )}
                                                        />
                                                    </div>
                                                ) : null}
                                            </TabPane>
                                        );
                                    }
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        </div>
    );
};

export default withRouter(RACustomizationPageContainer);
