export const bankFileDescriptorsSortByOptions = [
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Customer name', value: 'Company name' },
    { label: 'Descriptor', value: 'Descriptor' },
];

export const getBankFileDescriptorsFilterBarFilters = () => {
    return [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Descriptors',
            filterStateName: 'Descriptors',
            filterElement: 'input-multiple-tag',
            filterSort: 'Descriptors',
            filterLabelField: 'Descriptors'
        }
    ];
};