export enum DashboardsActionTypes {
    GET_DASHBOARDS_REQUEST = '@@dashboards/GET_DASHBOARDS_REQUEST',
    GET_DASHBOARDS_SUCCESS = '@@dashboards/GET_DASHBOARDS_SUCCESS',
    GET_DASHBOARDS_ERROR = '@@dashboards/GET_DASHBOARDS_ERROR',
    CLEAR_DASHBOARDS_STATE_DATA = '@@dashboards/CLEAR_DASHBOARDS_STATE_DATA',

    SET_DASHBOARD_SELECTED_ID_REQUEST = '@@dashboards/SET_DASHBOARD_SELECTED_ID_REQUEST',
    SET_DASHBOARD_SELECTED_ID_SUCCESS = '@@dashboards/SET_DASHBOARD_SELECTED_ID_SUCCESS',
    GET_DASHBOARD_DATA_REQUEST = '@@dashboards/GET_DASHBOARD_DATA_REQUEST',
    GET_DASHBOARD_DATA_SUCCESS = '@@dashboards/GET_DASHBOARD_DATA_SUCCESS',
    GET_DASHBOARD_DATA_ERROR = '@@dashboards/GET_DASHBOARD_DATA_ERROR',

    GET_DASHBOARD_INVOICES_REQUEST = '@@dashboards/GET_DASHBOARD_INVOICES_REQUEST',
    GET_DASHBOARD_CREDITS_REQUEST = '@@dashboards/GET_DASHBOARD_CREDITS_REQUEST',
    GET_DASHBOARD_CUSTOMERS_REQUEST = '@@dashboards/GET_DASHBOARD_CUSTOMERS_REQUEST',
    GET_DASHBOARD_RECENT_CHANGES_REQUEST = '@@dashboards/GET_DASHBOARD_RECENT_CHANGES_REQUEST',
    GET_DASHBOARD_CHANGE_STATISTICS_REQUEST = '@@dashboards/GET_DASHBOARD_CHANGE_STATISTICS_REQUEST',
    GET_DASHBOARD_CHANGE_STATISTICS_BY_TYPE_REQUEST = '@@dashboards/GET_DASHBOARD_CHANGE_STATISTICS_BY_TYPE_REQUEST',
    GET_DASHBOARD_CUSTOMERS_SETTLEMENT_REQUEST = '@@dashboards/GET_DASHBOARD_CUSTOMERS_SETTLEMENT_REQUEST',
    GET_DASHBOARD_TASKS_COUNT_REQUEST = '@@dashboards/GET_DASHBOARD_TASKS_COUNT_REQUEST',
    GET_DASHBOARD_TASKS_COUNT_FOR_USERS_REQUEST = '@@dashboards/GET_DASHBOARD_TASKS_COUNT_FOR_USERS_REQUEST',
    GET_DASHBOARD_TASKS_COUNT_FOR_USER_TYPES_REQUEST = '@@dashboards/GET_DASHBOARD_TASKS_COUNT_FOR_USER_TYPES_REQUEST',
    GET_DASHBOARD_OPEN_SUPPORT_TICKETS_REQUEST = '@@dashboards/GET_DASHBOARD_OPEN_SUPPPORT_TICKETS_REQUEST',
    GET_DASHBOARD_SALES_REQUEST = '@@dashboards/GET_DASHBOARD_SALES_REQUEST',
    GET_DASHBOARD_NOTIFICATION_SUCCESS_REQUEST = '@@dashboards/GET_DASHBOARD_NOTIFICATION_SUCCESS_REQUEST',
    GET_DASHBOARD_INVOICING_AND_SETTLEMENTS_REQUEST = '@@dashboards/GET_DASHBOARD_INVOICING_AND_SETTLEMENTS_REQUEST',
    GET_DASHBOARD_TICKETS_REQUEST = '@@dashboards/GET_DASHBOARD_TICKETS_REQUEST',
    GET_DASHBOARD_TICKETS_FOR_USERS_REQUEST = '@@dashboards/GET_DASHBOARD_TICKETS_FOR_USERS_REQUEST',
    GET_DASHBOARD_TICKETS_FOR_USER_TYPES_REQUEST = '@@dashboards/GET_DASHBOARD_TICKETS_FOR_USER_TYPES_REQUEST',
    GET_DASHBOARD_TOTAL_OWED_REQUEST = '@@dashboards/GET_DASHBOARD_TOTAL_OWED_REQUEST',
    GET_DASHBOARD_NOTIFICATIONS_REQUEST = '@@dashboards/GET_DASHBOARD_NOTIFICATIONS_REQUEST',
    GET_DASHBOARD_CURRENT_ATB_REQUEST = '@@dashboards/GET_DASHBOARD_CURRENT_ATB_REQUEST',
    GET_DASHBOARD_OPEN_INVOICES_REQUEST = '@@dashboards/GET_DASHBOARD_OPEN_INVOICES_REQUEST',
    GET_DASHBOARD_COMMUNICATION_DELIVERY_REQUEST = '@@dashboards/GET_DASHBOARD_COMMUNICATION_DELIVERY_REQUEST',
    GET_DASHBOARD_PAYMENTS_REQUEST = '@@dashboards/GET_DASHBOARD_PAYMENTS_REQUEST',
    GET_DASHBOARD_PAYMENTS_ALLOCATION_REQUEST = '@@dashboards/GET_DASHBOARD_PAYMENTS_ALLOCATION_REQUEST',
    GET_CASFHLOW_FORECAST_REQUEST = '@@dashboard/GET_CASFHLOW_FORECAST_REQUEST',
    GET_DAILY_SALES_OUT_FOR_COMPANY_REQUEST = '@@dashboards/GET_DAILY_SALES_OUT_FOR_COMPANY_REQUEST',

    GET_TASK_COUNT_ACTIONED_BY_USER_REQUEST = '@@dashboards/GET_TASK_COUNT_ACTIONED_BY_USER_REQUEST',
    GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_REQUEST = '@@dashboards/GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_REQUEST',

    SET_IS_EDITING_DASHBOARD = '@@dashboards/SET_IS_EDITING_DASHBOARD',
    GET_CUSTOMER_COUNT_PBI_FOR_COMPANY_REQUEST = '@@dashboards/GET_CUSTOMER_COUNT_PBI_FOR_COMPANY_REQUEST',
}

export interface DashboardsState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: any[];
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly isEditing: boolean;
}

export interface TaskCountStateName {
    readonly Count: number;
    readonly User: string;
}

export interface TaskCountUserStateName {
    readonly Count: number;
    readonly User: string;
    readonly ActionedByEmailAddress: string;
}

export interface TaskCountUserTypeStateName {
    readonly Count: number;
    readonly User: string;
}


export interface OpenSupportTickets {
    readonly TicketCount: number;
}

export interface GetOpenSupportTicketsRequestPayload {
    filters: {
        Status: number;
        /*Countries: string[];
        Postcodes: string[];*/
    };
}

export interface Sales {
    readonly AmountOwing: number;
    readonly AmountPaid: number;
    readonly AmountAvailable: number;
    readonly OriginalAmount: number;
    readonly DisplayPeriod: number;
    readonly Year: number;
}
