/**
 * Root component for App if user is  signed in.
 * Contains the sessiont timeout component listener as well.
 */
import React from 'react';
import PageLayout from '../PageLayout/PageLayout';
import SessionTimeout from '../Session/SessionTimeout';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { getLoadLuckysheet } from '../../store/common/sagas';

interface IProps {}
const App: React.FC<IProps> = (props: IProps) => {
    const loadLuckysheet: boolean = useSelector(getLoadLuckysheet);

    return (
        <>
            <div className="fixed-width" style={{ height: '100%' }}>
                <SessionTimeout />
                <PageLayout {...props} />
            </div>
            {loadLuckysheet && <Helmet>
                <link rel='stylesheet' href='/plugins/luckysheet/plugins/css/pluginsCss.css' />
                <link rel='stylesheet' href='/plugins/luckysheet/plugins/plugins.css' />
                <link rel='stylesheet' href='/plugins/luckysheet/css/luckysheet.css' />
                <link rel='stylesheet' href='/plugins/luckysheet/assets/iconfont/iconfont.css' />
                <script src="/plugins/luckysheet/plugins/js/plugin.js"></script>
                <script src="/plugins/luckysheet/luckysheet.umd.js"></script>
            </Helmet>}
        </>
    );
};

export default App;
