/**
 * Common component for populating font awesome icons
 * with props and style.
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconPrefix,
    IconName,
    Transform,
} from '@fortawesome/fontawesome-svg-core';

interface IProps {
    icon: string[];
    className?: string;
    style?: {};
    transform?: string | Transform;
    size?:
        | 'xs'
        | 'lg'
        | 'sm'
        | '1x'
        | '2x'
        | '3x'
        | '4x'
        | '5x'
        | '6x'
        | '7x'
        | '8x'
        | '9x'
        | '10x'
        | undefined;
    pulse?: boolean;
}
const FontAwesome: React.FC<IProps> = ({ icon, ...rest }: IProps) => {
    return <FontAwesomeIcon icon={icon as [IconPrefix, IconName]} {...rest} />;
};

export default FontAwesome;
