export enum BanksActionTypes {
    SAVE_BANK_FILE_REQUEST = '@@banks/SAVE_BANK_FILE_REQUEST',
    SAVE_BANK_FILE_RESPONSE = '@@banks/SAVE_BANK_FILE_RESPONSE',
    GET_BANK_FILE = '@@banks/GET_BANK_FILE',
    GET_BANK_FILE_SUCCESS = '@@banks/GET_BANK_FILE_SUCCESS',
}

export interface BanksState {
    readonly loading: boolean;
    readonly bankFileCustomization: BankFileCustomization | undefined;
    readonly saveLoading: boolean;
}

export interface BankFileCustomization {
    BankFileIgnoreStrings?: BankFileIgnoreString[];
    BankFileMappings: BankFileMapping[];
    BankFileFormats?: BankFileFormat[];
    PaymentObjectHeading?: PaymentObjectHeading;
}

export interface BankFileIgnoreString {
    Format: string;
    IgnoreStrings: string[];
}

export interface BankFileIgnoreStringVM {
    Format: string;
    IgnoreStrings: IgnoreString[];
}

export interface IgnoreString {
    Id: string;
    IgnoreString: string;
}

export interface BankFileMapping {
    Format: string;
    FieldMappings: FieldMapping[];
}

export interface FieldMapping {
    BankFileHeading: string;
    PaymentHeading: string;
    Index: number;
}

export interface BankFileFormat {
    BankName: string;
    Description: string;
    Headings: BankFileHeading[]
}

export interface BankFileHeading {
    Name: string;
    Description: string;
    Index: number;
}

export interface PaymentObjectHeading {
    ObjectHeadings: ObjectHeading[]
}

export interface ObjectHeading {
    Name: string;
    Description: string;
    IsRequired: boolean;
}