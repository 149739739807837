/**
 * Component drawer for Configuring widget panel.
 */

import {
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    Modal,
    Row,
    Select,
} from 'antd';
import { clone, difference, get, isEmpty, keys, range } from 'lodash';
import moment from 'moment-timezone/moment-timezone';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import {
    notQueryRelatedKeys,
    WIDGET_TYPE_COMPONENTS,
} from '../../constants/dashboards';
import {
    getPopoverContainer,
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import SelectReadonly from '../FormComponents/SelectReadonly';

const { Item: FormItem } = Form;
const { Option } = Select;
const { confirm } = Modal;

interface IProps {
    visible: boolean;
    onSave: (containerRef: any) => void;
    onClose: (resetChanges?: boolean) => void;
    widgetDetails: DynamicObject;
    widgetLayout: DynamicObject;
    readonly form?: any;
    onWidgetConfigureUpdate: Function;
    hasFormChanges: boolean;
    customFieldsFilterList: DynamicObject[];
    isOrgView?: boolean;
    readonly organisationCurrenciesAll?: [];
}

const ConfigureWidgetPanel: React.FC<IProps> = ({
    visible,
    onSave,
    onClose,
    widgetDetails,
    widgetLayout,
    form,
    hasFormChanges,
    customFieldsFilterList,
    isOrgView,
    organisationCurrenciesAll,
    onWidgetConfigureUpdate,
}: IProps) => {
    const drawerRef: any = useRef(null);
    const [hideModalBody, setHideModalBody] = useState<boolean>(false);
    // const [lastWidgetDetails, setLastWidgetDetails] = useState<any>(null);

    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setFieldsValue,
    } = form;

    /**
     * Function called when the `Save` button is clicked.
     */
    const onSaveButtonClick = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const startDate = get(values, 'customDateRangeStart');
                const endDate = get(values, 'customDateRangeEnd');
                if (
                    startDate &&
                    endDate &&
                    moment(endDate).isSameOrBefore(moment(startDate))
                ) {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <div>
                                <b>Start date</b> should not be greater than{' '}
                                <b>End date</b>!
                            </div>
                        ),
                        getContainer: populatePopoverContainer(drawerRef),
                    });
                } else {
                    onWidgetConfigureUpdate({
                        ...widgetDetails,
                        ...values,
                        refetchQuery: false,
                    });
                    onSave(drawerRef);
                    resetFields();
                }
            }
        });
    };

    /**
     * Function called when the `Close` button is clicked.
     */
    const onCancelButtonClick = () => {
        if (hasFormChanges) {
            confirm({
                className: 'modal-swapped-buttons',
                title: 'Do you want to discard your changes?',
                content: (
                    <div>
                        When you click the <b>{confirmModalOkText}</b> button,
                        all the changes you made will be gone.
                    </div>
                ),
                onOk: () => {
                    resetFields();
                    onClose(true);
                },
                okText: confirmModalOkText,
                cancelText: confirmModalCancelText,
                getContainer: () => getPopoverContainer(drawerRef),
            });
        } else {
            onClose();
        }
    };

    /**
     * Function to populate the range for height and width of widgets.
     * @param minimumValue
     * @param maximumValue
     */
    const populateSelectRangeOptions = (
        minimumValue: number,
        maximumValue: number
    ) => {
        const forReturn: any[] = [];
        range(minimumValue, maximumValue + 1).forEach((current) => {
            forReturn.push(
                <Option key={current} value={current}>
                    {current}
                </Option>
            );
        });

        return forReturn;
    };

    const formDisabled = false;
    const saveLoading = false;
    /**
     * Function called for focusnig on the active configured widget.
     */
    const listenForConfiguredWidget = () => {
        if (visible) {
            const configuredElement = document.getElementById(
                get(widgetDetails, 'i')
            );
            const usedWidgetDetails = clone(widgetDetails);
            // const usedWidgetLayout = !isEmpty(widgetLayout)
            //     ? clone(widgetLayout)
            //     : usedWidgetDetails;
            if (configuredElement) {
                const scrollableDashboardContainer = document.getElementById(
                    'dashboard-container-scrolling'
                );
                if (scrollableDashboardContainer) {
                    // const scrollWidth = get(
                    //     scrollableDashboardContainer,
                    //     'scrollWidth'
                    // );

                    // const columnWidth = scrollWidth / 12;
                    const activeWidget = document.getElementById(
                        usedWidgetDetails.i
                    );
                    let xPosition = 0;
                    let yPosition = 0;
                    if (activeWidget) {
                        const transformValue = activeWidget.style.transform;
                        if (transformValue) {
                            const splitTransform = transformValue.split(', ');

                            if (splitTransform) {
                                const transformX = get(splitTransform, 0, '')
                                    .replace('translate(', '')
                                    .replace('px', '');

                                xPosition = Number(transformX);

                                const transformY = get(splitTransform, 1, '')
                                    .replace(')', '')
                                    .replace('px', '');

                                yPosition = Number(transformY);
                            }
                        }
                    }

                    // const dashboardWidth =
                    //     scrollableDashboardContainer.clientWidth;

                    // if (activeWidget) {
                    //     const originalWidgetWidth = activeWidget.clientWidth;
                    //     const originalWidgetHeight = activeWidget.clientHeight;

                    //     const dashboardHeight =
                    //         scrollableDashboardContainer.clientHeight;

                    //     if (
                    //         originalWidgetWidth > dashboardWidth ||
                    //         originalWidgetHeight > dashboardHeight
                    //     ) {
                    //         const widgetComponentContainer = get(
                    //             activeWidget.getElementsByClassName(
                    //                 'widget-component'
                    //             ),
                    //             0
                    //         ) as HTMLElement;

                    //         activeWidget.style.overflow = 'auto';

                    //         if (widgetComponentContainer) {
                    //             if (originalWidgetWidth > dashboardWidth) {
                    //                 usedWidgetDetails.widthUpdate = true;
                    //                 activeWidget.style.width = `${dashboardWidth}px`;
                    //                 widgetComponentContainer.style.width = `${originalWidgetWidth}px`;
                    //             }
                    //             if (originalWidgetHeight > dashboardHeight) {
                    //                 usedWidgetDetails.heightUpdate = true;
                    //                 activeWidget.style.height = `${
                    //                     dashboardHeight - 50
                    //                 }px`;
                    //                 widgetComponentContainer.style.height = `${originalWidgetHeight}px`;
                    //             }
                    //         }
                    //     }
                    // }
                    scrollableDashboardContainer.scrollTo(xPosition, yPosition);
                }
            }
            // setLastWidgetDetails(usedWidgetDetails);
        } else {
            // if (!isEmpty(lastWidgetDetails)) {
            //     const activeWidget = document.getElementById(
            //         lastWidgetDetails.i
            //     );
            //     if (activeWidget) {
            //         const widgetComponentContainer = get(
            //             activeWidget.getElementsByClassName('widget-component'),
            //             0
            //         ) as HTMLElement;
            //         const originalWidgetWidth =
            //             widgetComponentContainer.clientWidth;
            //         const originalWidgetHeight =
            //             widgetComponentContainer.clientHeight;
            //         if (widgetComponentContainer) {
            //             if (lastWidgetDetails.widthUpdate) {
            //                 activeWidget.style.width = `${originalWidgetWidth}px`;
            //             }
            //             if (lastWidgetDetails.heightUpdate) {
            //                 activeWidget.style.height = `${
            //                     originalWidgetHeight + 50
            //                 }px`;
            //             }
            //             activeWidget.style.overflow = 'hidden';
            //         }
            //     }
            // setLastWidgetDetails(null);
            // }
        }
    };

    const toggleModalBodyVisibility = () => {
        setHideModalBody((prevState) => !prevState);
    };

    useLayoutEffect(listenForConfiguredWidget, [visible, widgetLayout]);

    const WidgetFieldsComponent = get(
        WIDGET_TYPE_COMPONENTS,
        `${get(widgetDetails, 'type')}.fields`
    );

    return (
        <Drawer
            className={`configure-widget-drawer ${
                hideModalBody && 'modal-body-hidden'
            }`}
            visible={visible}
            width={500}
            zIndex={1300}
            title={
                <div className="center-flex-sb">
                    <div>Configure</div>
                    <div>
                        <Button onClick={toggleModalBodyVisibility} type="link">
                            {hideModalBody
                                ? 'Show modal body'
                                : 'Hide modal body'}
                        </Button>
                    </div>
                </div>
            }
            maskClosable={false}
            closable={false}
        >
            {visible && (
                <>
                    <div
                        className="widget-drawer-container"
                        ref={drawerRef}
                        style={{
                            height: window.innerHeight - 165,
                        }}
                    >
                        <Form
                            className="form-inline-mb-0"
                            labelCol={{ span: 24 }}
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem label="Title">
                                        {getFieldDecorator('title', {
                                            initialValue: get(
                                                widgetDetails,
                                                'title'
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Title required!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Title"
                                                readOnly={formDisabled}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <FormItem label="Width">
                                        {getFieldDecorator('w', {
                                            initialValue: get(
                                                widgetDetails,
                                                'w'
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Width required!',
                                                },
                                            ],
                                        })(
                                            <SelectReadonly
                                                readOnly={formDisabled}
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {populateSelectRangeOptions(
                                                    get(
                                                        widgetDetails,
                                                        'minW',
                                                        1
                                                    ),
                                                    get(
                                                        widgetDetails,
                                                        'maxW',
                                                        12
                                                    )
                                                )}
                                            </SelectReadonly>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem label="Height">
                                        {getFieldDecorator('h', {
                                            initialValue: get(
                                                widgetDetails,
                                                'h'
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Height required!',
                                                },
                                            ],
                                        })(
                                            <SelectReadonly
                                                readOnly={formDisabled}
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {populateSelectRangeOptions(
                                                    get(
                                                        widgetDetails,
                                                        'minH',
                                                        1
                                                    ),
                                                    get(
                                                        widgetDetails,
                                                        'maxH',
                                                        12
                                                    )
                                                )}
                                            </SelectReadonly>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Divider />
                            {WidgetFieldsComponent && (
                                <WidgetFieldsComponent
                                    drawerRef={drawerRef}
                                    widgetDetails={widgetDetails}
                                    formDisabled={formDisabled}
                                    getFieldDecorator={getFieldDecorator}
                                    customFieldsFilterList={
                                        customFieldsFilterList
                                    }
                                    getFieldValue={getFieldValue}
                                    setFieldsValue={setFieldsValue}
                                    isOrgView={isOrgView}
                                    organisationCurrenciesAll={
                                        organisationCurrenciesAll
                                    }
                                />
                            )}
                        </Form>
                    </div>
                    <div className="plr-24 pt-10">
                        <Row>
                            <Col className="ta-right">
                                <Button
                                    className="mr-10 w-100px"
                                    type="primary"
                                    onClick={onSaveButtonClick}
                                    disabled={formDisabled || !hasFormChanges}
                                    loading={saveLoading}
                                >
                                    Save
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                    disabled={formDisabled}
                                >
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </Drawer>
    );
};

let timeoutHandle: any = null;

const ConfigureWidgetPanelForm = Form.create({
    name: 'configure-widget-panel-form',
    onValuesChange: (props: any, changedFields: any, allFields: any) => {
        const newWidgetData = { ...props.widgetDetails, ...allFields };

        if (timeoutHandle) clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(() => {
            const keysFieldsChanged = keys(changedFields);
            const filteredKeys = difference(
                keysFieldsChanged,
                notQueryRelatedKeys
            );

            newWidgetData.refetchQuery = !isEmpty(filteredKeys);
            props.onWidgetConfigureUpdate(newWidgetData);

            timeoutHandle = undefined;
        }, 500);
    },
})(ConfigureWidgetPanel);

export default withRouter(ConfigureWidgetPanelForm);
