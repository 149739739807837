import API, { graphqlOperation } from '@aws-amplify/api';
import { filter, flatten, forEach, get, includes, map, orderBy, some } from 'lodash';
import {
    all,
    call,
    cancelled,
    delay,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch, getRegionConfigFromList } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { CompanyCustomFieldConfigure, CompanyUserRole } from '../companies/types';
import { getDashboardsSuccessAction } from '../dashboards/actions';
import {
    getDemoOptionsConfigAction,
    getPresignedUrlDownloadReportReadyAction,
    updateDemoOptionsConfigAction,
} from './actions';
import { CommonFiltersActionTypes } from './types';
import { ReportType } from '../../constants/reportSortAndFilters';
import { Auth } from 'aws-amplify';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';
import { Organisation } from '../organisations/types';

export const getDefaultPageViewValue = (state: ApplicationState) =>
    state.app.defaultPageViewValue;

export const getIsAttachmentEditingActive = (state: ApplicationState) =>
    state.app.attachmentEditing;

/**
 * Function used in useSelector for getting the demo options config.
 */
export const getDemoOptionsConfig = (state: ApplicationState) =>
    state.app.demoOptionsConfig;

export const getLocale = (state: ApplicationState) => state.app.locale;

export const getLoadLuckysheet = (state: ApplicationState) => state.app.loadLuckysheet;

/**
 * Function called for saving the applied filters view to db.
 */
function* handleSaveAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

/**
 * Function called for deleting the applied filters view from db.
 */
function* handleDeleteAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

/**
 * Function called for fetching the Page Views list from the api.
 */
function* handleFetchPageAppliedFiltersViewListRequestAction({
    payload: sagaPayload,
}: any) {
    const { pageName, isDashboard, notSameCompany, callback } = sagaPayload;
    const abortController = new AbortController();
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAGE_VIEWS, {
                PageName: pageName,
            })
        );

        const { Views: PageViews } = get(res.data, 'GetPageViews');
        if (PageViews) {
            if (callback) callback(PageViews);
        }

        if (isDashboard) {
            yield put(
                getDashboardsSuccessAction({ data: PageViews, notSameCompany })
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        if (callback) callback([]);
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled) {
            abortController.abort();
        }
    }
}

/**
 * Function called for starting the data extraction for the items needed in excel file.
 */
function* handleDownloadToExcel({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    payload.Type = payload.Type || ReportType.Page;
    const ApiUrl = get(payload, 'ApiUrl');

    try {
        if (ApiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            yield fetch(
                `${ApiUrl}/report/excel`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );
        } else {
            yield call([API, 'post'], API_NAME, '/report/excel', {
                body: payload,
            });
        }

        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.', err);
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}

        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.', err);
        //}
    }
}

/**
 * Function called for getting the presigned URL which then is used
 * for downloading the excel report stored in S3.
 */
function* handleGetPresignedUrlForReportReady({ payload: sagaPayload }: any) {
    const { payload, callback, refetchCount } = sagaPayload;
    const Region = get(payload, 'Region');

    try {
        let apiUrl: string | undefined = undefined;
        if (Region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                Region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/report/excel?ReportJobId=${get(payload, 'EntityKey')}`,
                {
                    method: 'GET',
                    headers: { Authorization: accessToken }
                }
            );
            res = yield response.json();
        } else {
            res = yield call(
                [API, 'get'],
                API_NAME,
                `/report/excel?ReportJobId=${get(payload, 'EntityKey')}`,
                {}
            );
        }

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            yield delay(refetchAPIDelay);
            yield put(getPresignedUrlDownloadReportReadyAction(payload, callback, refetchCount + 1));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }

            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occurred.', err);
            }
            //if (callback) {
            //    const returnData = get(err.response, 'data')
            //        ? err.response.data
            //        : { Messages: [err.message] };
            //    returnData.IsSuccess = false;
            //    callback(returnData);
            //}

            //if (err instanceof Error) {
            //    console.log('Error', err);
            //} else {
            //    console.error('An unknown error occured.', err);
            //}
        }
    }
}

/**
 * Function called for getting the custom fields filter list for a specific page.
 */
function* handleGetCustomFieldsFilterListByTypes({
    payload: sagaPayload,
}: any) {
    const { customFieldTypes, callback } = sagaPayload;

    try {
        const selectedUserCompany: DynamicObject = yield select(
            (state: ApplicationState) => state.companies.selectedUserCompany
        );

        const companyCustomFields = get(
            selectedUserCompany,
            'Company.CustomFieldsConfigure'
        );
        
        let customFieldsByType: CompanyCustomFieldConfigure[] = [];
        forEach(
            companyCustomFields,
            (customField: CompanyCustomFieldConfigure) => {
                if (includes(customFieldTypes, customField.Type)) {
                    customFieldsByType.push(customField);
                }
            }
        );

        const customFieldsByNumberSorted = orderBy(
            customFieldsByType,
            ['Number'],
            ['asc']
        );

        var sortedCustomFieldsByType = orderBy(
            customFieldsByNumberSorted,
            function (item) {
                return customFieldTypes.indexOf(item.Type);
            }
        );

        const res: DynamicObject = {
            CustomFields: sortedCustomFieldsByType,
        };

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.', err);
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}

        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.', err);
        //}
    }
}

function* handleGetCustomFieldsFilterListByTypesForOrganisation({
    payload: sagaPayload,
}: any) {
    const { customFieldTypes, callback } = sagaPayload;

    try {
        const selectedUserOrganisation: Organisation = yield select(
            (state: ApplicationState) => state.organisations.selectedUserOrganisation
        );
        const userCompanies: CompanyUserRole[] = yield select(
            (state: ApplicationState) => state.companies.userCompanies
        );
        const orgUserCompanies = filter(userCompanies, uc => uc.Company.OrganisationId === get(selectedUserOrganisation, 'OrganisationId'));
        const companyCustomFields = flatten(map(orgUserCompanies, uc => get(uc, 'Company.CustomFieldsConfigure')));

        let customFieldsByType: CompanyCustomFieldConfigure[] = [];
        forEach(
            companyCustomFields,
            (customField: CompanyCustomFieldConfigure) => {
                if (includes(customFieldTypes, customField.Type)
                    && !some(customFieldsByType, cf => cf.FieldName === customField.FieldName)) {
                    customFieldsByType.push(customField);
                }
            }
        );

        const customFieldsByNumberSorted = orderBy(
            customFieldsByType,
            ['Number'],
            ['asc']
        );

        var sortedCustomFieldsByType = orderBy(
            customFieldsByNumberSorted,
            function (item) {
                return customFieldTypes.indexOf(item.Type);
            }
        );

        const res: DynamicObject = {
            CustomFields: sortedCustomFieldsByType,
        };

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.', err);
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}

        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.', err);
        //}
    }
}

/**
 * Function called for getting the presigned url for uploading an attachment.
 */
function* handleGetAttachmentPresignedUrl({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

/**
 * Function called for deleting an attachment.
 */
function* handleDeleteAttachment({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

let refetchCount = 0;
/**
 * Function called for getting the demo options config from the json file.
 */
function* handleGetDemoOptionsConfig() {
    try {
        const jsonResponse: DynamicObject = yield call(
            fetch,
            `${window.location.origin}/demo-options-config.json`
        );
        const demoOptionsConfig: DynamicObject[] = yield jsonResponse.json();
        refetchCount = 0;
        yield put(updateDemoOptionsConfigAction(demoOptionsConfig));
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getDemoOptionsConfigAction());
        } else {
            console.log('Error trying to get demo options config', err);
        }
    }
}

function* watchSaveAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.SAVE_APPLIED_FILTERS_VIEW,
        handleSaveAppliedFiltersView
    );
}

function* watchDeleteAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_APPLIED_FILTERS_VIEW,
        handleDeleteAppliedFiltersView
    );
}

function* watchFetchPageAppliedFiltersViewListRequest() {
    yield takeEvery(
        CommonFiltersActionTypes.FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST,
        handleFetchPageAppliedFiltersViewListRequestAction
    );
}

function* watchDownloadToExcel() {
    yield takeLatest(
        CommonFiltersActionTypes.DOWNLOAD_TO_EXCEL,
        handleDownloadToExcel
    );
}

function* watchGetPresignedUrlForReportReady() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_PRESIGNED_URL_FOR_REPORT_READY,
        handleGetPresignedUrlForReportReady
    );
}

function* watchGetCustomFieldsFilterListByTypes() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_CUSTOM_FIELDS_FILTER_LIST,
        handleGetCustomFieldsFilterListByTypes
    );
}

function* watchGetCustomFieldsFilterListByTypesForOrganisation() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_CUSTOM_FIELDS_FILTER_LIST_FOR_ORGANISATION,
        handleGetCustomFieldsFilterListByTypesForOrganisation
    );
}

function* watchGetAttachmentPresignedUrl() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_ATTACHMENT_PRESIGNED_URL,
        handleGetAttachmentPresignedUrl
    );
}

function* watchDeleteAttachment() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_ATTACHMENT,
        handleDeleteAttachment
    );
}

function* watchGetDemoOptionsConfig() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_DEMO_OPTIONS_CONFIG,
        handleGetDemoOptionsConfig
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* commonSaga() {
    yield all([
        fork(watchSaveAppliedFiltersView),
        fork(watchDeleteAppliedFiltersView),
        fork(watchFetchPageAppliedFiltersViewListRequest),
        fork(watchDownloadToExcel),
        fork(watchGetPresignedUrlForReportReady),
        fork(watchGetCustomFieldsFilterListByTypes),
        fork(watchGetCustomFieldsFilterListByTypesForOrganisation),
        fork(watchGetAttachmentPresignedUrl),
        fork(watchDeleteAttachment),
        fork(watchGetDemoOptionsConfig),
    ]);
}

export default commonSaga;
