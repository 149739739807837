/**
 * Component for populating protected button (controlled by user role).
 */

import { Button } from 'antd';
import { find, get, includes, isEmpty } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
    IsOrganisationViewAttribute,
    OrganisationRoleIdAttribute,
} from '../../constants/authUserAttributes';
import { ApplicationState } from '../../store';
import { getCurrentUser } from '../../store/users/sagas';

interface IProps {
    buttonProps: {};
    allowedRoles?: string[];
    children: any;
}
const ProtectedButton: React.FC<IProps> = (props: IProps) => {
    const currentUser = useSelector(getCurrentUser);
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);
    let userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    if (isOrgView) {
        userRole = get(orgUserRole, 'Name');
    }

    return isEmpty(props.allowedRoles) ||
        includes(props.allowedRoles, userRole) ? (
        <Button {...props.buttonProps}>{props.children}</Button>
    ) : null;
};

export default memo(ProtectedButton);
