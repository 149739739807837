/**
 * HOC Component used for handling ticket icons.
 */

import React from 'react';
import { ticketOptionTypes } from '../../constants/customerAssistanceSortAndFilters';
import FontAwesome from './FontAwesome';
import { getIfIsLg } from '../../utils/commonFunctions';

export const withTicketIconsHandler = (WrappedComponent: any) => {
    const TicketIconsHandler: React.FC<any> = (props: any) => {
        /**
         * Function to generate ticket icons based on the given parameters.
         * @param type - type of ticket
         * @param iconColor - color of the icon
         * @param fontSizeProp - font size for the icon to be returned
         */
        const generateTicketIcon = (
            type: string,
            iconColor: string,
            fontSizeProp?: number | string | null
        ) => {
            /**
             * Request for payment plan
             */
            const fontSize = fontSizeProp || (getIfIsLg() ? 24 : undefined);
            if (type === ticketOptionTypes.REQUEST_PAYMENT_PLAN) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-2 left-3"
                            />
                            <FontAwesome
                                icon={['fas', 'money-bill-alt']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-9 left-5"
                            />
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-2 left-3"
                            />
                            <FontAwesome
                                icon={['fas', 'info']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-9"
                            />
                        </div>
                    </>
                );
            }
        };

        return (
            <WrappedComponent
                {...props}
                generateTicketIcon={generateTicketIcon}
            />
        );
    };

    return TicketIconsHandler;
};
