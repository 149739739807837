import { action } from 'typesafe-actions';
import {
    GetWorkflowDefinitionRequestPayload,
    WorkflowActionTypes,
    WorkflowDefinition,
} from './types';
import { ResponseModalObject } from '../../utils/commonInterfaces';

/**
 * Actions for fetching the workflow definition list.
 * @param payload
 */
export const getWorkflowDefinitionRequestAction = (payload: GetWorkflowDefinitionRequestPayload) =>
    action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST, payload);

export const getWorkflowDefinitionSuccessAction = (payload: {
    data: WorkflowDefinition;
}) => action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_SUCCESS, payload);

export const getWorkflowDefinitionErrorAction = (payload: string[]) =>
    action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_ERROR, payload);

/**
 * Actions called for saving workflow definition data.
 * @param payload
 * @param callback - function called after saving - useful for refetching the workflow definition
 * and showing alert modals
 */
export const saveWorkflowDefinitionConfigurationRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST, {
        payload,
        callback,
    });
export const saveWorkflowDefinitionConfigurationResponseAction = () =>
    action(WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_RESPONSE);

export const clearWorkflowDefinitionAction = () =>
    action(WorkflowActionTypes.CLEAR_WORKFLOW_DEFINITION);

export const selectWorkflowSuccessAction = (
    selectedWorkflowId: string | null
) => action(WorkflowActionTypes.SELECT_WORKFLOW_ID, selectedWorkflowId)