import { action } from 'typesafe-actions';
import {
    PageData,
} from '../common/types';
import {
    BankFileDescriptor,
    BankFileDescriptorsActionTypes,
    DeleteBankFileDescriptorRequestPayload,
    GetBankFileDescriptorsRequestPayload,
    RequestCreateOrEditBankFileDescriptorPayload} from './types';

/**
 * Actions for fetching the bankFileDescriptors list.
 * @param payload
 */
export const getBankFileDescriptorsRequestAction = (payload: GetBankFileDescriptorsRequestPayload) =>
    action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_REQUEST, payload);
export const getBankFileDescriptorsSuccessAction = (payload: {
    data: BankFileDescriptor[];
    pageData: PageData;
}) => action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_SUCCESS, payload);
export const getBankFileDescriptorsErrorAction = (payload: string[]) =>
    action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_ERROR, payload);

/**
 * Action called for updating the filters on Bank File Descriptors management page.
 * @param filters
 */
export const updateBankFileDescriptorsFiltersAction = (
    filters: GetBankFileDescriptorsRequestPayload['filters']
) => action(BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_FILTERS, filters);

/**
 * Action called for changing the sort by, sort order for Credits list.
 * @param tableSort
 */
export const updateBankFileDescriptorsSortByAction = (tableSort: {
    sortBy: GetBankFileDescriptorsRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_SORT_BY,
        tableSort
    );

/**
 * Action called when changing the bank file descriptors view filter.
 * @param creditState
 */
export const updateBankFileDescriptorsTableFilterAction = (bankFileDescriptorState: string) =>
    action(BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_TABLE_FILTER_STATE, bankFileDescriptorState);

/**
 * Action for resetting table filters and sort values.
 */
export const clearBankFileDescriptorsStateAllTableFiltersAction = () =>
    action(BankFileDescriptorsActionTypes.CLEAR_BANK_FILE_DESCRIPTORS_STATE_ALL_TABLE_FILTERS);

export const clearBankFileDescriptorsListData = () =>
    action(BankFileDescriptorsActionTypes.CLEAR_BANK_FILE_DESCRIPTORS_LIST_DATA);

// Single Record
/**
 * Actions for setting the selected descriptor Id when clicking on a bank file descriptor table row item.
 * @param descriptorId
 * @param callback
 */
export const setBankFileDescriptorSelectedIdRequestAction = (
    customerId: string | null,
    callback: () => void
) =>
    action(BankFileDescriptorsActionTypes.SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_REQUEST, {
        customerId,
        callback,
    });
export const setBankFileDescriptorSelectedIdSuccessAction = (customerId: string | null) =>
    action(BankFileDescriptorsActionTypes.SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_SUCCESS, customerId);

/**
 * Actions for getting the details for the selected single bank file descriptor.
 */
export const getBankFileDescriptorDataRequestAction = (
    customerId: string
) =>
    action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_REQUEST, {
        customerId: customerId,
    });
export const getBankFileDescriptorDataSuccessAction = (payload: { record: {} }) =>
    action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_SUCCESS, payload);
export const getBankFileDescriptorDataErrorAction = (payload: string[]) =>
    action(BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_ERROR, payload);

/**
 * Action for clearing the details of a selected bank file descriptor.
 */
export const clearBankFileDescriptorDataAction = () =>
    action(BankFileDescriptorsActionTypes.CLEAR_BANK_FILE_DESCRIPTOR_DATA_SUCCESS);

/**
 * Function for create bank file descriptors
 * @param payload
 */
export const createOrEditBankFileDescriptorRequestAction = (
    payload: RequestCreateOrEditBankFileDescriptorPayload
) => action(BankFileDescriptorsActionTypes.CREATE_OR_EDIT_BANK_FILE_DESCRIPTORS_REQUEST, payload);

/**
* Function for delete bank file descriptor
* @param payload
*/
export const deleteBankFileDescriptorRequestAction = (
    payload: DeleteBankFileDescriptorRequestPayload
) => action(BankFileDescriptorsActionTypes.DELETE_BANK_FILE_DESCRIPTOR_REQUEST, payload);
