/**
 * File responsible for all the UI and actions for Settings>RA Customization page - Rebate mappings tab item - `/app/settings/ra_customization/rebate-mappings`.
 */

import { Form, Col, Icon, Row, Spin, Typography,Button, Input, Modal,Empty, } from 'antd';
import { History as IHistory } from 'history';
import { get, isEmpty, isEqual, forEach } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { ApplicationState } from '../../../../store';
import { Company } from '../../../../store/companies/types';
import { RemittanceAdvicesState } from '../../../../store/remittanceAdvices/types';
import {
    DynamicObject
} from '../../../../utils/commonInterfaces';
import { withRouter } from 'react-router-dom';

import {
    getUserCompaniesRequestAction
} from '../../../../store/companies/actions';

import {
    saveRARebateMappingConfigurationRequestAction,
} from '../../../../store/remittanceAdvices/actions';

import {
    getRARebateMappingsRequestAction
} from '../../../../store/remittanceAdvices/actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import FontAwesome from '../../../../components/common/FontAwesome';
import ModalWithSpinner from '../../../../components/common/ModalWithSpinner';
const RACustomizationImportDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../../components/settings/RACustomizationImportDetailsDrawerComponent'
        )
);

const RACustomizationExportDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../../components/settings/RACustomizationExportDetailsDrawerComponent'
        )
);

const { Title } = Typography;
const { Item: FormItem,  } = Form;

interface IProps {
    history: typeof IHistory;
    selectedUserCompany: Company;
    form: DynamicObject;
}

const RACustomizationRebateMappingsPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    const remittanceAdvicesState: RemittanceAdvicesState = useSelector(
        (state: ApplicationState) => state.remittanceAdvices
    );

    const [importDetailsDrawerValues, setImportDetailsDrawerValues] = useState<{
        visible: boolean;
    }>({
        visible: false
    });

    const [exportDetailsDrawerValues, setExportDetailsDrawerValues] = useState<{
        visible: boolean;
    }>({
        visible: false
    });

    const [rebateMappingsData, setRebateMappingsData] = useState<{
        RebateMappings: [{
            RebateCode: string,
            RebateAlt: string
        }];
    }>({
        RebateMappings: [{
            RebateCode: '',
            RebateAlt: ''
        }]
    });

    const [rebateMappingsState, setRebateMappingsState] = useState<{
        loading: boolean;
        RebateMappings: [{
            RebateCode: string,
            RebateAlt: string
        }];
        errorMessages: string[];
    }>({
        loading: true,
        RebateMappings: [{
            RebateCode: '',
            RebateAlt: ''
        }],
        errorMessages: [],
    });

    const { errorMessages } = rebateMappingsState;

    const [keys, setKeys] = useState<number[]>([]);
    const [id, setId] = useState(1);

    const {
        getFieldDecorator,
        validateFields,
        resetFields
    } = props.form;

    const formFieldNames = {
        REBATE_CODE: "RebateCode",
        REBATE_ALT: "RebateAlt",
    };

    const requiredFields = [
        "RebateCode"
    ];
    
    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            let rebateMappings: { RebateCode: string; RebateAlt: string }[] = [];

            if (!err) {
                if(!rebateMappingsState.RebateMappings.length){
                    //Pushing the first default form
                    rebateMappings.push({
                        RebateCode: get(values, formFieldNames.REBATE_CODE+0),
                        RebateAlt: get(values, formFieldNames.REBATE_ALT+0)
                    })
                }
                
                //Pushing the added mapping value
                keys.map((key: number) => (
                    rebateMappings.push({
                        RebateCode: get(values, formFieldNames.REBATE_CODE+key),
                        RebateAlt: get(values, formFieldNames.REBATE_ALT+key)
                    })
                ));
                
                rebateMappingsState.RebateMappings.map((RebateMapping:any,index: number) => (
                    rebateMappings.push({
                        RebateCode: RebateMapping.RebateCode,
                        RebateAlt: RebateMapping.RebateAlt
                    })
                ));

                const raRebateMappingsPayload: {
                    RebateMappings: {},   
                } = {
                    RebateMappings: rebateMappings,
                }

                submitRARebateMappingsConfig(raRebateMappingsPayload)
            }
        });
    };

    
    const submitRARebateMappingsConfig = (raRebateMappingsPayload: any, isImport:boolean = false) => {
        dispatch(
            saveRARebateMappingConfigurationRequestAction(
                raRebateMappingsPayload,
                ({ IsSuccess }: { IsSuccess: boolean }) =>
                    handleModalSaveResponse(
                        IsSuccess,
                        isImport
                    )
            )
        );
    }

    /**
     * Function responsible for showing the success/error modal after saving RA Processing settings
     * @param param0 - includes a boolean and may have an error message response from API
     */
    const handleModalSaveResponse = (IsSuccess: boolean,isImport: boolean) => {
        if (IsSuccess) {
            Modal.success({
                zIndex:9999,
                title: 'Success',
                content: 'Remittance advice rebate mappings saved successfully!',
                onOk: () => {
                    fetchInitialData()
                    dispatch(getUserCompaniesRequestAction());
                    setKeys([]);
                },
            });
        } else {
            Modal.error({
                zIndex:9999,
                title: 'Error',
                content: `Failed to save remittance advice rebate mappings`,
            });
        }
    };

    /**
    * Function called when import button is clicked.
    */
    const onImportButtonClick = () => {     
        setImportDetailsDrawerValues({
            visible: true
        });
    };

    /**
    * Function called when you close the import panel.
    */
    const closeImportDetailsDrawer = () => {
        setImportDetailsDrawerValues({
            visible: false
        });
    };

    /**
    * Function called when import button is clicked.
    */
    const onExportButtonClick = () => {
        setExportDetailsDrawerValues({
            visible: true
        });
    };

    /**
    * Function called when you close the import panel.
    */
    const closeExportDetailsDrawer = () => {
        setExportDetailsDrawerValues({
            visible: false
        });
    };

    /**
    * Function that checks if there are any changes to the form done.
    * Compared to the original state saved in redux.
    */
    const checkForChanges = () => {
        var stateNotEqual = false;
        
        forEach(rebateMappingsData.RebateMappings, (rebateMapping: any, index:number) => {
            if (!isEqual(
                rebateMappingsState.RebateMappings[index],
                rebateMapping
            )) {
                stateNotEqual = true;
            }
        });

        if(keys.length > 0)
            stateNotEqual = true;

        return stateNotEqual;
    };

    const { loading } = remittanceAdvicesState;

    const fetchInitialData = () => {
        dispatch(getRARebateMappingsRequestAction(
            (response: any) => {
                const raRebateMappingsData = get(
                    response.data,
                    'GetRARebateMappings'
                );

                let hasError = !response.IsSuccess;
                let errorMessages:string[] = [];
                if (
                    isEmpty(raRebateMappingsData)
                ) {
                    hasError = true;
                    errorMessages = ['Error fetching remittance advice rebate mapping details. Please try again later.'];
                }
                setRebateMappingsData({
                    ...raRebateMappingsData,
                });

                setRebateMappingsState({
                    ...raRebateMappingsData,
                    loading: false,
                    errorMessages,
                });
            }
        ))
    }

    useEffect(fetchInitialData, [props.selectedUserCompany]);

    const clearOnCompanyChange = () => {
        resetFields();
    };

    useEffect(clearOnCompanyChange, [props.selectedUserCompany]);

    const rebateMappingsFormState: any = (
        name: string,
        index: number
    ) => {
        return (
            e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
        ) => {
            const correctValue =
                get(e, `target.value`) ||
                get(e, `target.value`);

            const newList = [...rebateMappingsState.RebateMappings];

            newList[index]= {
                ...newList[index],
                [name]: correctValue
            };

            setRebateMappingsState(prevState => ({
                ...prevState,
                RebateMappings: newList as [{ RebateCode: string; RebateAlt: string; }]
            }));

        };
    };

    const populateSavedRebateMappings = () => {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            }
        };
        var rebateMappingsFormItems:any = [];

        if(rebateMappingsState.RebateMappings){
            rebateMappingsFormItems = rebateMappingsState.RebateMappings.map((rebateMapping:any, index:number) => (
           
                <Row type="flex" align="middle" key={index}>
                     <Col span={16}>
                        <Row>
                            <Col span={12} className="flex-i">
                                Rebate Code
                            </Col>
    
                            <Col span={12} className="flex-i">
                                Alternative Code
                            </Col>
                        </Row>
                         <Row>
                             <Col span={10}>
                                 <FormItem className="f-ta-100 red" {...(formItemLayout)}
                                    required={true}
                                    key={index}>
                                     {getFieldDecorator(formFieldNames.REBATE_CODE + 'initial' + [index],
                                        {
                                            initialValue:
                                                get(
                                                    rebateMapping,
                                                    'RebateCode'
                                                ),
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Rebate code is required!",
                                                },
                                            ]
                                        }
                                    )(
                                        <Input
                                            placeholder="IODM"
                                            onChange={rebateMappingsFormState(
                                                'RebateCode',
                                                index
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={10}>
                                <FormItem className="red f-ta-100" {...(formItemLayout)}
                                    required={true}
                                    key={index}>
                                    {getFieldDecorator(formFieldNames.REBATE_ALT + 'initial' + [index],
                                        {
                                            initialValue: get(
                                                rebateMapping,
                                                'RebateAlt'
                                            )
                                        }
                                    )(
                                        <Input
                                            placeholder="IODM ALT"
                                            onChange={rebateMappingsFormState(
                                                'RebateAlt',
                                                index
                                            )}
                                        />
    
                                    )} 
                                </FormItem>
                            </Col>
                            <Col span={2}>
                                <Button type="link" onClick={() => removeInitialRebateMappings(index)} className="row-icons-btn">
                                    <div className="fs-20">
                                        <FontAwesome
                                            icon={['fa', 'minus-circle']}
                                            className="red"
                                            size="2x"
                                            transform="shrink-9 left-3"
                                        />
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                )
            );
        }

        return (
            <>
                { rebateMappingsFormItems }
            </>
        );
    };

    const populateCustomizationSection = () => {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            }
        };

        const formItems = keys.map((k:any, index:number) => (
            <Row type="flex" align="middle" key={index} className="fade-in">
                 <Col span={16}>
                    <Row>
                        <Col span={12} className="flex-i">
                            Rebate Code
                        </Col>

                        <Col span={12} className="flex-i">
                            Alternative Code
                        </Col>
                    </Row>
                     <Row>
                         <Col span={10}>
                            <FormItem className="f-ta-100 red" {...(formItemLayout)}
                                required={true}
                                key={index}>
                                 {getFieldDecorator(formFieldNames.REBATE_CODE + [k],
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Rebate code is required!",
                                            },
                                        ]
                                    }
                                )(
                                    <Input
                                        placeholder="IODM"
                                    />
                                )} 
                            </FormItem>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={10}>
                            <FormItem className="red f-ta-100" {...(formItemLayout)}
                                required={true}
                                key={k}>
                                {getFieldDecorator(formFieldNames.REBATE_ALT + [k])(
                                    <Input
                                        placeholder="IODM ALT"
                                    />
                                )} 
                            </FormItem>
                        </Col>
                        <Col span={2}>
                            <Button type="link" onClick={() => remove(k)} className="row-icons-btn">
                                <div className="fs-20">
                                    <FontAwesome
                                        icon={['fa', 'minus-circle']}
                                        className="red"
                                        size="2x"
                                        transform="shrink-9 left-3"
                                    />
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        ));

        return (
            <>
                { isEmpty(rebateMappingsState.RebateMappings) ?
                    <Row type="flex" align="middle">
                        <Col span={16}>
                            <Row>
                                <Col span={12} className="flex-i">
                                    Rebate Code
                                </Col>

                                <Col span={12} className="flex-i">
                                    Alternative Code
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    <FormItem className="f-ta-100 red" {...(formItemLayout)} required={true}>
                                        {getFieldDecorator(formFieldNames.REBATE_CODE + 0,
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Rebate code is required!",
                                                    },
                                                ]
                                            }
                                        )(
                                            <Input
                                                placeholder="IODM"
                                            />
                                        )} 
                                    </FormItem>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={10}>
                                    <FormItem className="red f-ta-100" {...(formItemLayout)} required={true}>
                                        {getFieldDecorator(formFieldNames.REBATE_ALT + 0)(
                                            <Input
                                                placeholder="IODM ALT"
                                            />
                                        )} 
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row> : 
                    ''
                }
                
                {formItems}
            </>
        );
    };

    const add = () => {
        setKeys([...keys, id]);
        setId(id + 1);
    };

    const remove = (k:any) => {
        setKeys(keys.filter(key => key !== k));
    };

    const removeInitialRebateMappings = (map_index:any) => {
        const newRebateMappingsState = rebateMappingsState.RebateMappings.filter((_, index) => index !== map_index);
        
        setRebateMappingsState(prevState => ({
            ...prevState,
            RebateMappings: newRebateMappingsState as [{ RebateCode: string; RebateAlt: string; }]
        }));

        //Reset the current field index and the one below to prevent pushing it on the next field row
        resetRebateMappingFields(newRebateMappingsState,map_index)
    };

    const resetRebateMappingFields = (newRebateMappingsState:any,map_index:any) => {
        resetFields([formFieldNames.REBATE_CODE + 'initial' + [map_index],formFieldNames.REBATE_CODE + 'initial' + [map_index+1]]);
        (newRebateMappingsState as [{ RebateCode: string; RebateAlt: string; }] ).map((rebateMapping:any, index:number) => (
            resetFields([formFieldNames.REBATE_ALT + 'initial' + [index]])
        ));
    }

    /**
     * Function responsible for showing the error modal after importing RA Processing settings
     * @param param0 - includes an array of required fields that doesn't have values and may have an error message response from API
     */
    const handleModalImportExceptionResponse = (exceptionFields: string[] = []) => {
        if(exceptionFields.length == 0){
            Modal.error({
                title: 'Error',
                content: `Failed to import remittance advice rebate mappings. Please check the correct JSON code syntax.`,
            });
        } else {   
            Modal.error({
                title: 'Error',
                content: <div dangerouslySetInnerHTML={{__html:`Failed to import remittance advice processing settings. <br/><br/>  
                Validation Error:<br/>${exceptionFields.join('<br/>')}`}}/>
            });
        }
    };

    return (
        <Col span={24}>
            <RouteLeavingGuard
                when={checkForChanges()}
                navigate={(path: string) => props.history.push(path)}
                shouldBlockNavigation={() => {
                    if (checkForChanges()) {
                        return true;
                    }
                    return false;
                }}
            />
            <QueueAnim type={['right', 'left']} leaveReverse>
            <Spin
                spinning={rebateMappingsState.loading}
                tip='Loading remittance advice rebate mappings details'
                style={{
                    height: '80%',
                }}
            >
            {
                isEmpty(errorMessages) ?
                <>
                    <Row key="title-container" type="flex" align="top">
                        <Col span={16}>
                            <Row>
                                <Col>
                                    <Title level={3}>Rebate Mappings</Title>
                                </Col>
                                <Col className="grey">
                                    This page allows you to configure alternate mappings for Rebate Codes found in Remittance Advices.
                                    <br/>
                                    <br/>
                                    An entry with just the desired rebate code will fetch that rebate instead of finding a rebate's full string.
                                    <br/>
                                    Eg.For code "IODM", IODM 12345 -&gt; IODM
                                    <br/>
                                    <br/>
                                    An entry with an alternate code and rebate code will fetch the rebate with the code.
                                    <br/>
                                    Eg. For alt "IODM ALT" with code "IODM", IODM ALT 12345 -&gt; IODM
                                    <br/>
                                    <br/>
                                    Note: If multiple entries would be found to have the same code, A new rebate will be created with the relevant rebate details.
                                    <br/>
                                    Example 1: If IODM ALT 12345 and IODM ALT 12346 found, Both will be created with the same details as IODM rebate.
                                    <br/>
                                    Example 2: If IODM ALT 12345 and IODM found, IODM ALT 12345 will be created.
                                    <br/>
                                    Example 3: If 2 instances of IODM found, IODM dd/MM/yyyy HH:mm 1 & 2 will be created.
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    Note: This page does not validate if these rebates exist in the system. Ensure rebates entered are available in the system before leaving.
                                </Col>
                            </Row>
                        </Col>
                        
                        <Col span={8} className="ta-right">
                            <Button
                                className="mr-10 w-100px"
                                type="primary"
                                onClick={onImportButtonClick}
                            >
                                Import
                            </Button>
                            <Button
                                className="w-100px"
                                type="primary"
                                onClick={onExportButtonClick}
                                disabled={checkForChanges()}
                            >
                                Export
                            </Button>
                        </Col>
                    </Row>
                    {
                        populateSavedRebateMappings()
                    }
                    
                    {
                        populateCustomizationSection()
                    }
                    
                    <Row type="flex" align="middle">
                        <Col span={16}>
                            <Row type="flex" align="middle">
                                <Col span={16}></Col>
                                <Col span={8} className="ta-left">
                                <Button className='w-100px' onClick={add} type="link" style={{ width: '100%' }}>
                                    <Icon type="plus" /> Add another mapping
                                </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row type="flex" align="bottom" style={{ marginTop: "70px" }}>
                        <Col span={16}></Col>
                        
                        <Col span={8} className="ta-left">
                            <Button
                                className=" mr-10 w-100px"
                                type="primary"
                                onClick={onSaveButtonClick}
                                disabled={!checkForChanges() || errorMessages.length > 0}
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </>     
                : 
                <Empty description={get(errorMessages, 0)} />
            }
                    
                
            </Spin>
            <br /> 
            </QueueAnim>
            {loading && (
                <ModalWithSpinner
                    zIndex={9999}
                    modalTitle="Saving remittance advice rebate mappings"
                    modalVisible={loading}
                    displayMessage="Please wait while saving remittance advice rebate mappings. . ."
                />
            )}
            <Suspense fallback={null}>
                <RACustomizationImportDetailsDrawerComponent
                    companyId={get(props.selectedUserCompany, "CompanyId")}
                    raCustomizationPage={'Rebate Mappings'}
                    visible={importDetailsDrawerValues.visible}
                    closeDrawer={closeImportDetailsDrawer}
                    requiredFields={requiredFields}
                    saveRAConfigRequestAction={saveRARebateMappingConfigurationRequestAction}
                    handleModalSaveResponse={handleModalSaveResponse}
                    handleModalImportExceptionResponse={handleModalImportExceptionResponse}
                />
            </Suspense>
            <Suspense fallback={null}>
                <RACustomizationExportDetailsDrawerComponent
                    visible={exportDetailsDrawerValues.visible}
                    closeDrawer={closeExportDetailsDrawer}
                    raCustomizationSettings={rebateMappingsData.RebateMappings}
                />
            </Suspense>
        </Col>
    );
};

const RACustomizationRebateMappingsPageForm = Form.create({
    name: 'ra-rebate-mappings-form',
})(RACustomizationRebateMappingsPage);
export default withRouter(RACustomizationRebateMappingsPageForm);
