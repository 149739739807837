import { action } from 'typesafe-actions';
import { ResponseModalObject } from '../../utils/commonInterfaces';
import {
    BankFileCustomization,
    BanksActionTypes,
} from './types';

/**
 * Actions called for saving bank data.
 */
export const saveBankFileRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(BanksActionTypes.SAVE_BANK_FILE_REQUEST, {
        payload,
        callback,
    });
export const saveBankFileResponseAction = () =>
    action(BanksActionTypes.SAVE_BANK_FILE_RESPONSE);

/**
 * Actions for getting the bank data,
 * this includes company bank data.
 */
export const getBankFileAction = () =>
    action(BanksActionTypes.GET_BANK_FILE);
export const getBankFileSuccessAction = (data: BankFileCustomization | undefined) =>
    action(BanksActionTypes.GET_BANK_FILE_SUCCESS, data);