import { Col, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { formFieldNames } from '../../constants/announcementsSortAndFilters';
import { dateFormatYYYYMMDDTHHmmssDash } from '../../constants/dateFormats';
import { parseToHTML } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import AnnouncementTag from './AnnouncementTag';

interface IProps {
    formState: DynamicObject;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const AnnouncementPreview: React.FC<IProps> = ({
    formState,
    formatDateUTCToLocal,
}: IProps) => {
    const populateAnnouncementTag = () => {
        const tagValue = get(formState, formFieldNames.TAG);

        return tagValue && <AnnouncementTag tagValue={tagValue} />;
    };

    const populateDateTimeFromNow = () => {
        const publishDateTime = get(formState, formFieldNames.PUBLISH_DATETIME);
        const localPublishDateTime = formatDateUTCToLocal(
            publishDateTime,
            null,
            dateFormatYYYYMMDDTHHmmssDash
        );

        return (
            publishDateTime && (
                <div className="grey">
                    {moment(localPublishDateTime).fromNow()}
                </div>
            )
        );
    };
    return (
        <div className="h-100">
            <Row>
                <Col>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col>{populateAnnouncementTag()}</Col>
                        <Col>{populateDateTimeFromNow()}</Col>
                    </Row>
                </Col>
                <Col>
                    <div className="spacer-10" />
                    <Row>
                        <Col className="fs-16">
                            <b>{get(formState, formFieldNames.TITLE)}</b>
                        </Col>
                    </Row>
                    <div className="spacer-3" />
                    <Row>
                        <Col className="parsed-announcement-cont">
                            {parseToHTML(get(formState, formFieldNames.BODY))}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default withDateFormatHandler(AnnouncementPreview);
