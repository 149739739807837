/**
 * Component for the page header. The one at the top that contains the Company logo, company dropdown and user dropdown.
 */

 import {
    Avatar,
    Badge,
    Button,
    Col,
    Dropdown,
    Empty,
    Icon,
    Input,
    Layout,
    Menu,
    Popover,
    Radio,
    Row,
    Spin,
    Tooltip,
} from 'antd';
import {
    debounce,
    filter,
    get,
    includes,
    isEmpty,
    map,
    orderBy,
    toLower,
    truncate,
    upperCase,
} from 'lodash';
import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Environments } from '../../AmplifyConfig';
import {
    INVALIDATE_COMPANY_LOGO_PATH,
    IODM_COMPANY_STORAGE_NAME,
    IODM_ORGANISATION_STORAGE_NAME,
    onlineHelpBaseUrl,
    onlineHelpConfigMapping,
    SYSTEM_ENDPOINT_DEV,
    SYSTEM_ENDPOINT_PRETEST,
    SYSTEM_ENDPOINT_NONPROD,
    SYSTEM_ENDPOINT_PROD,
    SYSTEM_ENDPOINT_TEST,
    SYSTEM_ENDPOINT_UAT,
    IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME,
    TRAINING_ENDPOINT_DEV,
    TRAINING_ENDPOINT_PROD,
} from '../../config/config';
import {
    EmailAttribute,
    FamilyNameAttribute,
    GivenNameAttribute,
    RoleIdAttribute,
} from '../../constants/authUserAttributes';
import { companyGlobeIconList } from '../../constants/common';
import { ApplicationState } from '../../store';
import { getRegionKeyConfig } from '../../store/auth/sagas';
import { selectUserCompanyRequestAction } from '../../store/companies/actions';
import {
    CompanyUserRole,
    SystemConfiguration,
} from '../../store/companies/types';
import { getIsEditingDashboard } from '../../store/dashboards/sagas';
import { getUnreadNotificationsCount } from '../../store/notifications/sagas';
import { selectUserOrganisationRequestAction } from '../../store/organisations/actions';
import { Organisation } from '../../store/organisations/types';
import { getCurrentUser } from '../../store/users/sagas';
import {
    getAssetsPath,
    getCurrentBuildEnvironment,
    getIfIsLg,
    getNameInitials,
    invalidateImagePathCache,
    openURLNewTab,
    stringToHex,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import FontAwesome from '../common/FontAwesome';

const { Header } = Layout;

const assetsPath = getAssetsPath();
const iodmLogo = `${assetsPath}/logo.png`;

const ImageWithLoading = lazy(() => import('../common/ImageWithLoading'));

let timeoutHandle: any = null;
let unmounted: boolean = false;
const compOrgSearchDelayMs = 300;
let cacheTimeUsed = new Date().getTime();
let showDropdownInd: boolean = false;
interface IProps {
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
    pageLayoutLoading: boolean;
    history: any;
    showWhatsNewPanel: () => void;
    isOrgView: boolean;
}
const PageHeader: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = props.isOrgView;

    const isEdittingDashboard = useSelector(getIsEditingDashboard);
    const regionKeyConfig = useSelector(getRegionKeyConfig);
    const bannerRef: any = useRef(null);

    const [bannerData, setBannerData] = useState<{
        fullEnvironmentBanner: string;
        environmentBanner: string;
        showBannerTooltip: boolean;
        bannerMarginLeft: number;
    }>({
        fullEnvironmentBanner: '',
        environmentBanner: '',
        showBannerTooltip: false,
        bannerMarginLeft: 0,
    });

    const userCompanies: CompanyUserRole[] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );

    const userOrganisations: Organisation[] = useSelector(
        (state: ApplicationState) => state.organisations.userOrganisations
    );

    const [compOrgState, setCompOrgState] = useState<{
        showDropdown: boolean;
        searchText: string;
        loading: boolean;
        type: 'comp' | 'org';
        filteredList: DynamicObject[];
    }>({
        showDropdown: false,
        searchText: '',
        loading: false,
        type: isOrgView ? 'org' : 'comp',
        filteredList: [],
    });

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) =>
            state.organisations.selectedUserOrganisation
    );

    const systemConfiguration: SystemConfiguration = useSelector(
        (state: ApplicationState) => state.companies.systemConfiguration
    );

    const announcementsFetching: boolean = useSelector(
        (state: ApplicationState) => state.notifications.announcementsFetching
    );

    const username = get(currentUser, GivenNameAttribute);

    const fullName = `${get(currentUser, GivenNameAttribute)} ${get(
        currentUser,
        FamilyNameAttribute
    )}`;

    const notificationsCount = useSelector(getUnreadNotificationsCount);

    /**
     * Common function for updating the compOrgState state.
     */
    const updateCompOrgState = (compOrgStateObj: DynamicObject) => {
        setCompOrgState({
            ...compOrgState,
            ...compOrgStateObj,
        });
    };

    // Conditional UI Updates
    const onCompOrgTypeChange = (e: any) => {
        const type = e.target.value;
        updateCompOrgState({ type, filteredList: [], loading: true });

        if (timeoutHandle) clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(() => {
            if (unmounted) return;
            onCompOrgFilter(compOrgState.searchText, type);
        }, compOrgSearchDelayMs);
    };
    /**
     * Function that populates the header section for company dropdown
     */
    const populateCompOrgDropdownHeader = () => {
        return (
            <div className="ta-center">
                <Radio.Group
                    className="btn-radio-group"
                    value={compOrgState.type}
                    onChange={onCompOrgTypeChange}
                >
                    {/* <Radio.Button value="all">
                        <div className="center-flex">
                            <FontAwesome
                                className="mr-5 fs-18"
                                icon={['fas', 'th']}
                                transform="shrink-2"
                            />
                            View all
                        </div>
                    </Radio.Button> */}
                    <Radio.Button value="comp">
                        <div className="center-flex-all">
                            <FontAwesome
                                className="mr-5 fs-18"
                                icon={['fa', 'building']}
                            />
                            Companies
                        </div>
                    </Radio.Button>
                    <Radio.Button value="org">
                        <div className="center-flex-all">
                            <FontAwesome
                                className="mr-5 fs-18"
                                icon={['fas', 'city']}
                            />
                            Organisations
                        </div>
                    </Radio.Button>
                </Radio.Group>
            </div>
        );
    };

    const checkCurrentDropdownType = () => {
        const orgViewType = isOrgView ? 'org' : 'comp';
        if (compOrgState.type !== orgViewType) {
            showDropdownInd = false;
            onCompOrgTypeChange({ target: { value: orgViewType } });
        }
    };

    useEffect(checkCurrentDropdownType, [isOrgView]);

    /**
     * Function called on input search update for companies/orgs
     */
    const onCompOrgSearchChange = (e: any) => {
        const searchText = e.target.value;
        updateCompOrgState({ searchText, loading: true });

        if (timeoutHandle) clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(() => {
            if (unmounted) return;
            onCompOrgFilter(searchText);
        }, compOrgSearchDelayMs);
    };

    /**
     * Function called when comp org list is filtered
     */
    const onCompOrgFilter = (
        searchText: string,
        type: string = compOrgState.type
    ) => {
        let usedCompOrgList: DynamicObject[] = userCompanies;
        let usedNameComparator = 'Company.Name';
        if (type === 'org') {
            usedCompOrgList = userOrganisations;
            usedNameComparator = 'Name';
        }
        const filteredList = filter(usedCompOrgList, (uc: DynamicObject) => {
            return includes(
                toLower(get(uc, usedNameComparator)),
                toLower(searchText)
            );
        });
        updateCompOrgState({
            filteredList,
            searchText,
            loading: false,
            type,
            showDropdown: showDropdownInd,
        });
    };

    /**
     * Function called on initial load.
     */
    const onInitialLoad = () => {
        unmounted = false;

        return () => {
            unmounted = true;
        };
    };

    useEffect(onInitialLoad, []);

    const invCompanyLogoPath = localStorage.getItem(
        INVALIDATE_COMPANY_LOGO_PATH
    );

    const onInvalidateLogoPathUpdate = () => {
        if (invCompanyLogoPath === 'true') {
            cacheTimeUsed = new Date().getTime();
            localStorage.removeItem(INVALIDATE_COMPANY_LOGO_PATH);
        }
    };

    useEffect(onInvalidateLogoPathUpdate, [invCompanyLogoPath]);

    /**
     * Function that sets the user companies initial value
     */
    const setUserCompOrgsInitial = () => {
        if (compOrgState.type === 'comp' && !isEmpty(userCompanies)) {
            updateCompOrgState({ filteredList: userCompanies });
        } else if (compOrgState.type === 'org' && !isEmpty(userOrganisations)) {
            updateCompOrgState({ filteredList: userOrganisations });
        }
    };

    useEffect(setUserCompOrgsInitial, [userCompanies, userOrganisations]);

    const populateEmptyDescription = () => {
        let typeUsed = 'companies or organisations';
        const { type } = compOrgState;
        if (type === 'comp') {
            typeUsed = 'companies';
        } else if (type === 'org') {
            typeUsed = 'organisations';
        }

        return `No ${typeUsed} found`;
    };

    /**
     * * Function called when company item is selected in dropdown menu
     * @param userCompOrg
     */
    const onCompanyItemClick = (userCompOrg: DynamicObject) => {
        return () => {
            if (
                isOrgView ||
                (get(userCompOrg, 'Company.CompanyId') &&
                    get(userCompOrg, 'Company.CompanyId') !==
                        get(selectedUserCompany, 'Company.CompanyId'))
            ) {
                localStorage.removeItem(IODM_ORGANISATION_STORAGE_NAME);
                localStorage.setItem(
                    IODM_COMPANY_STORAGE_NAME,
                    JSON.stringify(userCompOrg)
                );
            }

            dispatch(
                selectUserCompanyRequestAction(
                    userCompOrg as CompanyUserRole,
                    () => {
                        updateCompOrgState({
                            showDropdown: false,
                            searchText: '',
                            loading: false,
                            filteredList: userCompanies,
                        });
                    }
                )
            );
        };
    };

    /**
     * Function called when organisation item is selected in dropdown menu
     * @param userCompOrg
     */
    const onOrganisationItemClick = (userCompOrg: DynamicObject) => {
        return () => {
            if (
                !isOrgView ||
                (get(userCompOrg, 'OrganisationId') &&
                    get(userCompOrg, 'OrganisationId') !==
                        get(selectedUserOrganisation, 'OrganisationId'))
            ) {
                localStorage.removeItem(IODM_COMPANY_STORAGE_NAME);
                localStorage.removeItem(IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME);
                localStorage.setItem(
                    IODM_ORGANISATION_STORAGE_NAME,
                    JSON.stringify(userCompOrg)
                );
            }

            dispatch(
                selectUserOrganisationRequestAction(
                    userCompOrg as Organisation,
                    () => {
                        updateCompOrgState({
                            showDropdown: false,
                            searchText: '',
                            loading: false,
                            filteredList: userOrganisations,
                        });
                    }
                )
            );
        };
    };

    /**
     * Function used for populating company dropdown.
     */
    const populateCompOrgDropdownMenuOverlay = () => {
        const listType = compOrgState.type;
        let logoPathStrUsed = 'Company.LogoPath';
        let nameStrUsed = 'Company.Name';
        let keyStrUsed = 'CompanyUserRoleId';
        const selectedObjectVal: DynamicObject = isOrgView
            ? selectedUserOrganisation
            : selectedUserCompany;

        if (listType === 'org') {
            logoPathStrUsed = 'LogoPath';
            nameStrUsed = 'Name';
            keyStrUsed = 'OrganisationId';
        }
        const sortedCompOrgList = orderBy(
            compOrgState.filteredList,
            [nameStrUsed],
            ['asc']
        );

        return (
            <div>
                <div className="pa-10">
                    <Input
                        prefix={
                            compOrgState.loading ? (
                                <Spin
                                    indicator={
                                        <Icon
                                            type="loading"
                                            style={{ fontSize: 12 }}
                                            spin
                                        />
                                    }
                                />
                            ) : (
                                <Icon type="search" />
                            )
                        }
                        placeholder="Search"
                        allowClear
                        onChange={onCompOrgSearchChange}
                        value={compOrgState.searchText}
                    />
                </div>
                <Spin spinning={compOrgState.loading}>
                    {isEmpty(sortedCompOrgList) ? (
                        <div className="pb-10">
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={populateEmptyDescription()}
                            />
                        </div>
                    ) : (
                        <Menu
                            selectedKeys={[get(selectedObjectVal, keyStrUsed)]}
                            style={{
                                maxHeight: window.innerHeight * 0.6,
                                overflowY: 'auto',
                            }}
                            onSelect={() => {
                                updateCompOrgState({
                                    showDropdown: false,
                                    loading: false,
                                });
                            }}
                        >
                            {map(
                                sortedCompOrgList,
                                (userCompOrg: DynamicObject) => {
                                    const companyLogoPath =
                                        invalidateImagePathCache(
                                            get(userCompOrg, logoPathStrUsed),
                                            cacheTimeUsed
                                        );

                                    let onMenuItemClick =
                                        onCompanyItemClick(userCompOrg);

                                    if (listType === 'org') {
                                        onMenuItemClick =
                                            onOrganisationItemClick(
                                                userCompOrg
                                            );
                                    }

                                    return (
                                        <Menu.Item
                                            key={get(userCompOrg, keyStrUsed)}
                                            onClick={onMenuItemClick}
                                        >
                                            <div>
                                                <Row
                                                    type="flex"
                                                    align="middle"
                                                    gutter={10}
                                                >
                                                    <Col span={8}>
                                                        <div className="company-logo-container">
                                                            <ImageWithLoading
                                                                src={
                                                                    companyLogoPath
                                                                }
                                                                className="company-logo-dd"
                                                                alt={get(
                                                                    userCompOrg,
                                                                    nameStrUsed
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col span={16}>
                                                        <div className="ws-nm">
                                                            {get(
                                                                userCompOrg,
                                                                nameStrUsed
                                                            )}
                                                        </div>
                                                        {populateSelectedRegionAndIcon(
                                                            userCompOrg,
                                                            !isEmpty(
                                                                get(
                                                                    userCompOrg,
                                                                    'Company'
                                                                )
                                                            )
                                                                ? false
                                                                : true
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Menu.Item>
                                    );
                                }
                            )}
                        </Menu>
                    )}
                </Spin>
            </div>
        );
    };

    /**
     * Function that updates the companies dropdown visibility
     */
    const handleCompaniesDropdownVisibleChange = (visible: boolean) => {
        showDropdownInd = visible;
        updateCompOrgState({
            showDropdown: visible,
        });
    };

    /**
     * Function called when notification badge is clicked.
     */
    const handleNotificationsClick = () => {
        props.openNotificationsMenu(notificationsCount);
    };

    /**
     * Function called when clicking on Help button. Will go to the online help section depending on the current page when this button is clicked.
     */
    const goToOnlineHelp = () => {
        const pathName = get(props.history, 'location.pathname');
        openURLNewTab(
            get(onlineHelpConfigMapping, pathName, onlineHelpBaseUrl)
        );
    };

    const getRedirectUrl = (env: string) => {
        if (env === Environments.DEV) {
            if (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            ) {
                return `http://${window.location.hostname}:3000`;
            } else {
                return `https://tasks.${SYSTEM_ENDPOINT_DEV}`;
            }
        } else if (env === Environments.TEST) {
            return `https://tasks.${SYSTEM_ENDPOINT_TEST}`;
        } else if (env === Environments.PRETEST) {
            return `https://tasks.${SYSTEM_ENDPOINT_PRETEST}`;    
        } else if (env === Environments.UAT) {
            return `https://tasks.${SYSTEM_ENDPOINT_UAT}`;
        } else if (env === Environments.NONPROD) {
            return `https://tasks.${SYSTEM_ENDPOINT_NONPROD}`;
        } else if (env === Environments.PROD) {
            return `https://tasks.${SYSTEM_ENDPOINT_PROD}`;
        }
    };

    const switchToTaskPortal = () => {
        const buildEnvironment = getCurrentBuildEnvironment();
        const redirectUrl = getRedirectUrl(buildEnvironment);
        if (redirectUrl) window.location.replace(redirectUrl);
    };

    const getTrainingRedirectUrl = (env: string) => {
        if (env === Environments.PROD) {
            return TRAINING_ENDPOINT_PROD;
        } else {
            return TRAINING_ENDPOINT_DEV;
        }
    };

    // Utility function to generate a hash using the Web Crypto API
    const generateHash = async (msg: string, key: string) => {
        const msgUint8 = new TextEncoder().encode(msg + key);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };

    const handleLogin = async () => {
        const currentUserRole = get(currentUser, RoleIdAttribute);
        const supportCashAllocation = get(selectedUserCompany, 'Company.SupportCashAllocation');

        // Generate token by combining payload and secret key, then hashing
        const secretKey = 'iodm-secret-key'; // This should match the secret key in your WordPress configuration
        const payload = { currentUserRole }; // Adjust the payload as needed
        const payloadString = JSON.stringify(payload);  // Convert payload to a JSON string
        const supportCashAllocationPayload = JSON.stringify({ supportCashAllocation });
        const generatedToken = await generateHash(payloadString, secretKey);
        const generateSupportCashAllocationToken = await generateHash(supportCashAllocationPayload, secretKey);

        //env url
        const buildEnvironment = getCurrentBuildEnvironment();
        const redirectUrl = getTrainingRedirectUrl(buildEnvironment);

        var url = redirectUrl + '/wp-json/custom/v1/authenticate';
        url += '?token=' + encodeURIComponent(generatedToken);
        url += '&supportCashAllocation=' + encodeURIComponent(generateSupportCashAllocationToken);

        //redirect to training website
        window.open(url, '_blank');
    };

    /**
     * Function to populate the user dropdown overlay.
     */
    const populateUserDropdownMenuOverlay = () => (
        <Menu>
            <Menu.Item>
                <div className="center-flex-sb">
                    <div>{populateUserAvatar('large')}</div>
                    <div className="pa-10 mw-300px">
                        <div className="ws-nm">{fullName}</div>
                        <div className="ws-nm">
                            {get(currentUser, EmailAttribute)}
                        </div>
                    </div>
                </div>
            </Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item>
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-18"
                        icon={['fas', 'address-card']}
                    />
                    My Profile
                </span>
            </Menu.Item> */}
            <Menu.Item onClick={handleNotificationsClick}>
                <span className="center-flex">
                    <span className="notification-circle mr-10">
                        {notificationsCount}
                    </span>
                    Notifications
                </span>
            </Menu.Item>
            <Menu.SubMenu
                className="submenu-flex"
                title={
                    <span className="center-flex">
                        <FontAwesome
                            className="mr-10 fs-20"
                            icon={['fas', 'question-circle']}
                        />{' '}
                        Support
                    </span>
                }
            >
                <Menu.Item onClick={goToOnlineHelp}>Online Help</Menu.Item>
                <Menu.Item
                    onClick={props.showWhatsNewPanel}
                    disabled={announcementsFetching}
                >
                    <Spin
                        spinning={announcementsFetching}
                        size="small"
                        className="mr-5"
                    />
                    What's New
                </Menu.Item>
                {/* <Menu.Item>Contact Support</Menu.Item> */}
            </Menu.SubMenu>
            <Menu.Item onClick={switchToTaskPortal}>
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-20"
                        icon={['fas', 'random']}
                    />{' '}
                    Switch to task portal
                </span>
            </Menu.Item>
            <Menu.Item onClick={handleLogin}>
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-20"
                        icon={['fas', 'question-circle']}
                    />{' '}
                    Training
                </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={handleLogout}>
                <div className="mb-20">
                    <div className="center-flex">
                        <FontAwesome
                            className="mr-10 fs-20"
                            icon={['fas', 'sign-out-alt']}
                        />{' '}
                        Sign Out
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    const usedAvatarColor: any = stringToHex(fullName);

    /**
     * Function to populate the user avatar icon.
     * @param size
     */
    const populateUserAvatar = (
        size: number | 'default' | 'small' | 'large' | undefined = 'default'
    ) => (
        <Avatar size={size} style={{ backgroundColor: usedAvatarColor }}>
            {getNameInitials(fullName)}
        </Avatar>
    );

    /**
     * Function called when user clicks on logout button in user popover.
     */
    const handleLogout = () => {
        props.logoutUser();
    };

    const isLiveMode = get(systemConfiguration, 'LiveMode');

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        initializeBannerData();
    };

    /**
     * Function that will initialize the banner data (for environment) needed to adjust the banner size and location (if present).
     */
    const initializeBannerData = () => {
        if (isLiveMode) return;

        const isLg = getIfIsLg();
        const environmentMaxLength = 14;
        const fullEnvironmentBanner = upperCase(
            get(systemConfiguration, 'Environment')
        );
        const environmentBanner = isLg
            ? truncate(fullEnvironmentBanner, {
                  length: environmentMaxLength,
              })
            : fullEnvironmentBanner;

        const showBannerTooltip =
            fullEnvironmentBanner.length > environmentMaxLength;

        const bannerSpan = bannerRef.current
            ? bannerRef.current.clientWidth
            : 147;

        const bannerMarginLeft = bannerSpan + (isLg ? 15 : 33);

        setBannerData({
            fullEnvironmentBanner,
            environmentBanner,
            showBannerTooltip,
            bannerMarginLeft,
        });
    };

    /**
     * Function for populating the selected company's/organisation's region and icon.
     */
    const populateSelectedRegionAndIcon = (
        userCompOrgState: DynamicObject,
        isOrg: boolean
    ) => {
        const selectedCompanyRegion = isOrg
            ? 'Organisation'
            : get(userCompOrgState, 'Company.Region');
        let selectedGlobeIcon = get(
            filter(regionKeyConfig, ['Region', selectedCompanyRegion]),
            '0.GlobeLocation'
        );
        if (!includes(companyGlobeIconList, selectedGlobeIcon) && !isOrg)
            selectedGlobeIcon = 'asia';

        const fontAwesomeIconUsed = isOrg
            ? 'city'
            : `globe-${selectedGlobeIcon}`;
        return (
            <span className="center-flex">
                <FontAwesome
                    className="mr-5"
                    icon={['fas', fontAwesomeIconUsed]}
                />
                <i className="company-region">{selectedCompanyRegion}</i>
            </span>
        );
    };

    /**
     * Function for getting the company or organisation name and image
     */
    const getCompOrgNameAndImage = () => {
        let compOrgImageUsed = isOrgView
            ? get(selectedUserOrganisation, 'LogoPath')
            : get(selectedUserCompany, 'Company.LogoPath');
        if (compOrgImageUsed) {
            compOrgImageUsed = invalidateImagePathCache(
                compOrgImageUsed,
                cacheTimeUsed
            );
        }

        const compOrgNameUsed = isOrgView
            ? get(selectedUserOrganisation, 'Name')
            : get(selectedUserCompany, 'Company.Name');

        return {
            compOrgName: compOrgNameUsed,
            compOrgImage: compOrgImageUsed,
        };
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    useLayoutEffect(initializeBannerData, [
        bannerRef.current,
        bannerData.environmentBanner,
        isLiveMode,
    ]);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    const {
        fullEnvironmentBanner,
        environmentBanner,
        showBannerTooltip,
        bannerMarginLeft,
    } = bannerData;

    const { compOrgImage, compOrgName } = getCompOrgNameAndImage();

    const elementsCursor = isEdittingDashboard ? 'cursor-na' : 'cursor-p';

    const showSelectedCompOrg =
        !isEmpty(selectedUserOrganisation) || !isEmpty(selectedUserCompany);


    return (
        <Header className="header">
            <Row>
                <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <div className="logo">
                        <Suspense fallback={<Spin />}>
                            <ImageWithLoading
                                src={iodmLogo}
                                alt="IODM Logo"
                                style={{ width: '60px', height: 'auto' }}
                            />
                        </Suspense>
                    </div>
                </Col>
                <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    style={{ textAlign: 'center' }}
                >
                    <div>
                        {!isLiveMode && !props.pageLayoutLoading && (
                            <Tooltip
                                title={
                                    showBannerTooltip
                                        ? fullEnvironmentBanner
                                        : null
                                }
                            >
                                <span
                                    ref={bannerRef}
                                    className="test-mode-label secondary-contrast-color"
                                    style={{
                                        marginLeft: `${-bannerMarginLeft}px`,
                                    }}
                                >
                                    Running in {environmentBanner} mode
                                </span>
                            </Tooltip>
                        )}

                        <Popover
                            placement="bottom"
                            title={populateCompOrgDropdownHeader()}
                            content={populateCompOrgDropdownMenuOverlay()}
                            overlayStyle={{
                                width: 420,
                            }}
                            overlayClassName="companies-dropdown-container"
                            trigger="click"
                            visible={compOrgState.showDropdown}
                            onVisibleChange={
                                handleCompaniesDropdownVisibleChange
                            }
                        >
                            <span className={elementsCursor}>
                                {showSelectedCompOrg && (
                                    <span className="header-company-name">
                                        <Button
                                            type="link"
                                            className="pa-0 secondary-contrast-color sel-com-btn"
                                            disabled={isEdittingDashboard}
                                        >
                                            <Suspense fallback={<Spin />}>
                                                <ImageWithLoading
                                                    src={compOrgImage}
                                                    className="company-logo"
                                                    alt={compOrgName}
                                                />
                                            </Suspense>
                                            <span className="com-name-reg-cont">
                                                <span className="com-name-cont">
                                                    {compOrgName}
                                                </span>
                                                {populateSelectedRegionAndIcon(
                                                    isOrgView
                                                        ? selectedUserOrganisation
                                                        : selectedUserCompany,
                                                    isOrgView
                                                )}
                                            </span>
                                            &nbsp;&nbsp;
                                            <span style={{ fontSize: 10 }}>
                                                <Icon type="down" />
                                            </span>
                                        </Button>
                                    </span>
                                )}
                            </span>
                        </Popover>
                    </div>
                </Col>
                <Col
                    className="ta-right"
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <span className={elementsCursor}>
                        <Dropdown
                            trigger={['click']}
                            overlay={populateUserDropdownMenuOverlay()}
                            disabled={isEdittingDashboard}
                        >
                            <span>
                                <Button
                                    type="link"
                                    className="pa-0 mr-10 header-username secondary-contrast-color"
                                >
                                    {username} <Icon type="down" />
                                </Button>
                                <Badge
                                    className={elementsCursor}
                                    count={notificationsCount}
                                    offset={[3, 6]}
                                >
                                    {populateUserAvatar()}
                                </Badge>
                            </span>
                        </Dropdown>
                    </span>
                </Col>
            </Row>
        </Header>
    );
};

export default withRouter(PageHeader);
