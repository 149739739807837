export const defaultPageSizeForReport = 100;

export const usersSummaryQueryName = `GET_USERS_FOR_COMPANY`;
export const usersSummaryPageQuery = `
    query ${usersSummaryQueryName}(
        $Name: String
        $Email: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $RoleIds: [String]
        $CompanyId: ID
    ) {
        GetUsersForCompany(
            Filter: {
                Name: $Name
                Email: $Email
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                RoleIds: $RoleIds
                CompanyId: $CompanyId
            }
        ) {
            CompanyUserRoles {
                CompanyUserRoleId
                User {
                    GivenName
                    FamilyName
                    Email
                }
                Role {
                    Name
                }
            }
        }
    }
`;

export const contactFragmentString = `
    FirstName
    LastName
    AddressLine1
    AddressLine2
    City
    State
    Postcode
    Country
    Email
    MobileNumber
`;

export const attachmentFragmentString = `
    FileId
    IsPublic
    Title
`;

export const agedTrialBalanceFromCreateFragmentString = `
    Current
    Period1
    Period2
    Period3Plus
`;

export const agedTrialBalanceFromDueFragmentString = `
    Current
    Period1
    Period2
    Period3Plus
    NotDue
`;

export const customerFragmentString = `
    Id
    CompanyId
    CustomerCode @skip(if: $IsCloudImportType)
    CompanyName
    TaxNumber
    SecondaryTaxNumber
    IsVip
    NewInvoiceSendMode
    DisplayName
    Contacts {
        ${contactFragmentString}
    }
    AgedTrialBalance {
        TotalOwing
        TotalOverdue
        AgedTrialBalanceFromCreate {
            ${agedTrialBalanceFromCreateFragmentString}
        }
        AgedTrialBalanceFromDue {
            ${agedTrialBalanceFromDueFragmentString}
        }
    }
    Uri
    CustomFields {
        Name
        Value
    }
    Attachments {
        ${attachmentFragmentString}
    }
    PayerState
    CreditLimit
    TotalCreditUsed
`;

export const invoiceFragmentString = `
    Id
    CompanyId
    InvoiceCode
    Number
    CreatedDate
    LocalCreatedDate
    DueDate
    LocalDueDate
    OriginalAmount
    AmountOwing
    State
    SettledDate
    LocalSettledDate
    Type
    GlCode
    Customer {
        ${customerFragmentString}
    }
    AgedTrialBalanceFromCreate {
        ${agedTrialBalanceFromCreateFragmentString}
    }
    AgedTrialBalanceFromDue {
        ${agedTrialBalanceFromDueFragmentString}
    }
    Uri
    CustomFields {
        Name
        Value
    }
    Attachments {
        ${attachmentFragmentString}
    }
`;

export const remittanceAdviceInvoicesFragmentString = `
    Invoice {
            ${invoiceFragmentString}
        }
    Customer {
            ${customerFragmentString}
        }
    Number
    Amount
`;

export const creditsFragmentString = `
    Id
    CreditCode
    Number
    CreatedDate
    LocalCreatedDate
    AvailableDate
    LocalAvailableDate
    OriginalAmount
    AmountAvailable
    State
    ClosedDate
    Customer {
        ${customerFragmentString}
    }
    AgedTrialBalanceFromCreate {
        ${agedTrialBalanceFromCreateFragmentString}
    }
    AgedTrialBalanceFromDue {
        ${agedTrialBalanceFromDueFragmentString}
    }
    Uri
    CustomFields {
        Name
        Value
    }
    Attachments {
        ${attachmentFragmentString}
    }
`;

export const remittanceAdviceCreditsFragmentString = `
    Credit {
            ${creditsFragmentString}
        }
    Invoice {
            ${invoiceFragmentString}
        }
    Customer {
            ${customerFragmentString}
        }
    Number
    Amount
`;

export const remittanceAdviceRebatesFragmentString = `
    Rebate {
            ${creditsFragmentString}
        }
    Number
    AmountToAllocate
`;

export const customersSummaryQueryName = `GET_CUSTOMERS_FOR_COMPANY`;
export const customersSummaryPageQuery = `
    query ${customersSummaryQueryName}(
        $Customer: String
        $CustomerCountry: String
        $CustomerState: String
        $InclusiveContact: Boolean
        $Contact: Int
        $ATBType: Int
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $CustomFieldSort: String
        $IsCloudImportType: Boolean = false
        $UsingCustomerWorkflow: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $IsVip: Boolean
        $CustomerFirstLetter: [String]
    ) {
        GetCustomersForCompany(
            Filter: {
                Customer: $Customer
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                InclusiveContact: $InclusiveContact
                Contact: $Contact
                ATBType: $ATBType
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomFieldSort: $CustomFieldSort
                IsVip: $IsVip
                CustomerFirstLetter: $CustomerFirstLetter
            }
        ) {
            Customers {
                ${customerFragmentString}
                TicketCount
                ConversationLine {
                    Content
                    CreatedDateTime
                }
                LastCommunication @include(if: $UsingCustomerWorkflow) {
                    StateName
                    SentDateTime
                    WorkflowName @include(if: $IsMultipleWorkflow)
                    DeliveryDetails {
                        Type
                        Comment
                    }
                }
                CurrentTask @include(if: $UsingCustomerWorkflow) {
                    Id
                    Automation
                    ActionDate
                    Type
                    Workflow {
                        StateName
                        WorkflowName @include(if: $IsMultipleWorkflow)
                    }
                    Ticket {
                        TicketOption {
                            Reason
                            CaptureMessage
                        }
                        TicketNumber
                    }
                    State
                }
                IsVip
                WorkflowName
            }
        }
    }
`;

export const customersOrganisationSummaryQueryName = `GET_CUSTOMERS_FOR_ORGANISATION`;
export const customersOrganisationSummaryPageQuery = `
    query ${customersOrganisationSummaryQueryName}(
        $Customer: String
        $InclusiveContact: Boolean
        $Contact: Int
        $ATBType: Int
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $IsCloudImportType: Boolean = false
        $Countries: [String]
        $Regions: [String]
        $CompanyIds: [ID]
        $OrganisationCurrencies: [OrganisationCurrencyInput]
        $ConversionCurrency: Float
        $IsVip: Boolean
        $CustomerCountry: String
        $CustomerState: String
        $UserId: ID
        $OrganisationId: ID
        $UsingCustomerWorkflow: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $FetchOrganisationData: Boolean = false
    ) {
        GetCustomersForOrganisation(
            FetchOrganisationData: $FetchOrganisationData,
            Filter: {
                Customer: $Customer
                InclusiveContact: $InclusiveContact
                Contact: $Contact
                ATBType: $ATBType
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                Countries: $Countries
                Regions: $Regions
                CompanyIds: $CompanyIds
                OrganisationCurrencies: $OrganisationCurrencies
                ConversionCurrency: $ConversionCurrency
                IsVip: $IsVip
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                UserId: $UserId
                OrganisationId: $OrganisationId
            }            
        ) {
            Customers {
                ${customerFragmentString}
                TicketCount
                ConversationLine {
                    Content
                    CreatedDateTime
                }
                LastCommunication @include(if: $UsingCustomerWorkflow) {
                    StateName
                    SentDateTime
                    WorkflowName @include(if: $IsMultipleWorkflow)
                    DeliveryDetails {
                        Type
                        Comment
                    }
                }
                CurrentTask @include(if: $UsingCustomerWorkflow) {
                    Id
                    Automation
                    ActionDate
                    Type
                    Workflow {
                        StateName
                        WorkflowName @include(if: $IsMultipleWorkflow)
                    }
                    Ticket {
                        TicketOption {
                            Reason
                            CaptureMessage
                        }
                        TicketNumber
                    }
                    State
                }
                IsVip
                Company {
                    Name
                }
            }
        }
    }
`;

export const invoicesSummaryQueryName = `GET_INVOICES_FOR_COMPANY`;
export const invoicesSummaryPageQuery = `
    query ${invoicesSummaryQueryName}(
        $InvoiceState: String
        $InvoiceNumber: String
        $Customer: String
        $CustomerCode: String
        $CustomerCountry: String
        $CustomerState: String
        $CreatedDateMin: String
        $CreatedDateMax: String
        $DueDateMin: String
        $DueDateMax: String
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $InvoiceIds: [ID]
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $CustomFieldSort: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $UsingCustomerWorkflow: Boolean = false
        $IsBlocked: Boolean
        $CustomerFirstLetter : [String]
        $IsCalendarView: Boolean = false 
    ) {
        GetInvoicesForCompany(
            Filter: {
                InvoiceState: $InvoiceState
                InvoiceNumber: $InvoiceNumber
                Customer: $Customer
                CustomerCode: $CustomerCode
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                DueDateMin: $DueDateMin
                DueDateMax: $DueDateMax
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                InvoiceIds: $InvoiceIds
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomFieldSort: $CustomFieldSort
                IsBlocked: $IsBlocked
                CustomerFirstLetter : $CustomerFirstLetter
            }
                IsCalendarView: $IsCalendarView
        ) {
            Invoices {
                Number
                CreatedDate
                LocalCreatedDate
                DueDate
                LocalDueDate
                OriginalAmount
                AmountOwing
                State
                SettledDate
                LocalSettledDate
                AgedTrialBalanceFromCreate {
                    ${agedTrialBalanceFromCreateFragmentString}
                }
                AgedTrialBalanceFromDue {
                    ${agedTrialBalanceFromDueFragmentString}
                }
                Customer {
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    CurrentTask @include(if: $UsingCustomerWorkflow) {
                        Id
                        Automation
                        ActionDate
                        Type
                        Workflow {
                            StateName
                            WorkflowName @include(if: $IsMultipleWorkflow)
                        }
                        Ticket {
                            TicketOption {
                                Reason
                                CaptureMessage
                            }
                            TicketNumber
                        }
                        State
                    }
                }
                ConversationLine {
                    Content
                }
                CustomFields {
                    Name
                    Value
                }
                Attachments {
                    ${attachmentFragmentString}
                }
                LastCommunication {
                    StateName
                    SentDateTime
                    WorkflowName @include(if: $IsMultipleWorkflow)
                    DeliveryDetails {
                        Type
                        Comment
                    }
                }
                CurrentTask {
                    Id
                    Automation
                    ActionDate
                    Type
                    Workflow {
                        StateName
                        WorkflowName @include(if: $IsMultipleWorkflow)
                    }
                    Ticket {
                        TicketOption {
                            Reason
                            CaptureMessage
                            NonBlocking
                        }
                        TicketNumber
                        ConversationLine {
                            Content
                        }
                    }
                    State
                }
                IsBlocked
            }
        }
    }
`;

export const invoicesOrganisationSummaryQueryName = `GET_INVOICES_FOR_ORGANISATION`;
export const invoicesOrganisationSummaryPageQuery = `
    query ${invoicesOrganisationSummaryQueryName}(
        $InvoiceState: String
        $InvoiceNumber: String
        $Customer: String
        $CustomerCode: String
        $CreatedDateMin: String
        $CreatedDateMax: String
        $DueDateMin: String
        $DueDateMax: String
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $InvoiceIds: [ID]
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $UsingCustomerWorkflow: Boolean = false
        $IsBlocked: Boolean
        $Countries: [String]
        $Regions: [String]
        $CompanyIds: [ID]
        $OrganisationCurrencies: [OrganisationCurrencyInput]
        $ConversionCurrency: Float
        $IsBlocked: Boolean
        $CustomerCountry: String
        $CustomerState: String
        $UserId: ID
        $OrganisationId: ID
        $FetchOrganisationData: Boolean = false
    ) {
        GetInvoicesForOrganisation(
            FetchOrganisationData: $FetchOrganisationData,
            Filter: {
                InvoiceState: $InvoiceState
                InvoiceNumber: $InvoiceNumber
                Customer: $Customer
                CustomerCode: $CustomerCode
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                DueDateMin: $DueDateMin
                DueDateMax: $DueDateMax
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                InvoiceIds: $InvoiceIds
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                Countries: $Countries
                Regions: $Regions
                CompanyIds: $CompanyIds
                OrganisationCurrencies: $OrganisationCurrencies
                ConversionCurrency: $ConversionCurrency
                IsBlocked: $IsBlocked
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                UserId: $UserId
                OrganisationId: $OrganisationId
            }
        ) {
            Invoices {
                Number
                CreatedDate
                LocalCreatedDate
                DueDate
                LocalDueDate
                OriginalAmount
                AmountOwing
                State
                SettledDate
                LocalSettledDate
                Customer {
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    CurrentTask @include(if: $UsingCustomerWorkflow) {
                        Id
                        Automation
                        ActionDate
                        Type
                        Workflow {
                            StateName
                            WorkflowName @include(if: $IsMultipleWorkflow)
                        }
                        Ticket {
                            TicketOption {
                                Reason
                                CaptureMessage
                            }
                            TicketNumber
                        }
                        State
                    }
                }
                ConversationLine {
                    Content
                }
                CustomFields {
                    Name
                    Value
                }
                Attachments {
                    ${attachmentFragmentString}
                }
                LastCommunication {
                    StateName
                    SentDateTime
                    WorkflowName @include(if: $IsMultipleWorkflow)
                    DeliveryDetails {
                        Type
                        Comment
                    }
                }
                CurrentTask {
                    Id
                    Automation
                    ActionDate
                    Type
                    Workflow {
                        StateName
                        WorkflowName @include(if: $IsMultipleWorkflow)
                    }
                    Ticket {
                        TicketOption {
                            Reason
                            CaptureMessage
                            NonBlocking
                        }
                        TicketNumber
                        ConversationLine {
                            Content
                        }
                    }
                    State
                }
                IsBlocked
                Company {
                    Name
                }
            }
        }
    }
`;

export const creditsSummaryQueryName = `GET_CREDITS_FOR_COMPANY`;
export const creditsSummaryPageQuery = `
    query ${creditsSummaryQueryName}(
        $CreditNumber: String
        $Customer: String
        $CustomerCode: String
        $CreatedDateMin: String
        $CreatedDateMax: String
        $AvailableDateMin: String
        $AvailableDateMax: String
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CreditState: String
        $CreditIds: [ID]
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $CustomFieldSort: String
        $CompanyId: ID
        $CustomerFirstLetter : [String]
    ) {
        GetCreditsForCompany(
            Filter: {
                CreditNumber: $CreditNumber
                Customer: $Customer
                CustomerCode: $CustomerCode
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                AvailableDateMin: $AvailableDateMin
                AvailableDateMax: $AvailableDateMax
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CreditState: $CreditState
                CreditIds: $CreditIds
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomFieldSort: $CustomFieldSort
                CompanyId: $CompanyId
                CustomerFirstLetter : $CustomerFirstLetter 
            }
        ) {
            Credits {
                Id
                CreditCode
                Number
                CreatedDate
                LocalCreatedDate
                AvailableDate
                LocalAvailableDate
                OriginalAmount
                AmountAvailable
                State
                ClosedDate
                Customer {
                    Id
                    CustomerCode
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                }
                CustomFields {
                    Name
                    Value
                }
                Attachments {
                    ${attachmentFragmentString}
                }
            }
        }
    }
`;

export const creditsOrganisationSummaryQueryName = `GET_CREDITS_FOR_ORGANISATION`;
export const creditsOrganisationSummaryPageQuery = `
    query ${creditsOrganisationSummaryQueryName}(
        $CompanyId: ID
        $CreditNumber: String
        $CustomerId: ID
        $Customer: String
        $CustomerCode: String
        $CreatedDateMin: String
        $CreatedDateMax: String
        $AvailableDateMin: String
        $AvailableDateMax: String
        $AmountType: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CreditState: String
        $CreditIds: [ID]
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $ExcludeCredits: Boolean
        $Countries: [String]
        $Regions: [String]
        $CompanyIds: [ID]
        $OrganisationCurrencies: [OrganisationCurrencyInput]
        $ConversionCurrency: Float
        $UserId: ID
        $OrganisationId: ID
        $Type: String
        $CreatedType: String
        $Recurring: Boolean
        $FetchOrganisationData: Boolean = false
    ) {
        GetCreditsForOrganisation(
            FetchOrganisationData: $FetchOrganisationData,
            Filter: {
                CompanyId: $CompanyId
                CreditNumber: $CreditNumber
                CustomerId: $CustomerId
                Customer: $Customer
                CustomerCode: $CustomerCode
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                AvailableDateMin: $AvailableDateMin
                AvailableDateMax: $AvailableDateMax
                AmountType: $AmountType
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CreditState: $CreditState
                CreditIds: $CreditIds
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                ExcludeCredits: $ExcludeCredits
                Countries: $Countries
                Regions: $Regions
                CompanyIds: $CompanyIds
                OrganisationCurrencies: $OrganisationCurrencies
                ConversionCurrency: $ConversionCurrency
                UserId: $UserId
                OrganisationId: $OrganisationId
                Type: $Type
                CreatedType: $CreatedType
                Recurring: $Recurring
            }
        ) {
            Credits {
                Id
                CreditCode
                Number
                CreatedDate
                LocalCreatedDate
                AvailableDate
                LocalAvailableDate
                OriginalAmount
                AmountAvailable
                State
                ClosedDate
                Customer {
                    Id
                    CustomerCode
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                }
                CustomFields {
                    Name
                    Value
                }
                Attachments {
                    ${attachmentFragmentString}
                }
                IsRecurring
                Percentage
                Description
                Company {
                    Name
                }
            }
        }
    }
`;

export const taskHistorySummaryQueryName = `GET_TASK_BATCH_HISTORY_FOR_COMPANY`;
export const taskHistorySummaryPageQuery = `
    query ${taskHistorySummaryQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $InvoiceNumber: String
        $Type: Int
        $Status: Int
        $ActionDateMin: String
        $ActionDateMax: String
        $ActionedByUserId: ID
        $ActionedByEmailAddress: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
    ) {
        GetTaskBatchHistoryForCompany(
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                InvoiceNumber: $InvoiceNumber
                Type: $Type
                Status: $Status
                ActionDateMin: $ActionDateMin
                ActionDateMax: $ActionDateMax
                ActionedByUserId: $ActionedByUserId
                ActionedByEmailAddress: $ActionedByEmailAddress
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
            }
        ) {
            TaskBatches {
                BatchId
                CompanyId
                UserId
                Type
                State
                Automation
                CreatedDateTime
                BatchCount
                User {
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const historyDeliveryReportQueryName = `GET_HISTORY_DELIVERY_REPORT`;
export const historyDeliveryReportPageQuery = `
    query ${historyDeliveryReportQueryName}(
        $BatchId: ID
        $CompanyId: ID
        $DeliveryStatus: Int
        $DeliveryType: Int
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CreatedDateMin: String
        $CreatedDateMax: String
    ) {
        GetDeliveryHistoryReportForCompany(
            Filter: {
                BatchId: $BatchId
                CompanyId: $CompanyId
                DeliveryStatus: $DeliveryStatus
                DeliveryType: $DeliveryType
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
            }
        ) {
            TaskBatchGroups {
                CustomerCode
                CompanyName
                CommunicationId
                StateName
                State
                DeliveryDetails {
                    Contact
                    Type
                    Comment
                    State
                    Reason
                }
            }
        }
    }
`;

export const ticketOptionsFragmentString = `
    Reason
    CaptureMessage
    Type
`;
export const customerAssistanceSummaryQueryName = `GET_TICKETS_FOR_COMPANY`;
export const customerAssistanceSummaryPageQuery = `
    query ${customerAssistanceSummaryQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $CreatedDateMin: String
        $CreatedDateMax: String
        $ClosedDateMin: String
        $ClosedDateMax: String
        $LastChatDateMin: String
        $LastChatDateMax: String
        $Status: Int
        $Action: Int
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $TicketOptionReasons: [String]
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $IsCloudImportType: Boolean = false
        $CustomerCountry: String
        $CustomerState: String
        $AssignedUserId: ID
        $AssignedEmailAddress: String
        $CustomerFirstLetter : [String]
    ) {
        GetTicketsForCompany(
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                ClosedDateMin: $ClosedDateMin
                ClosedDateMax: $ClosedDateMax
                LastChatDateMin: $LastChatDateMin
                LastChatDateMax: $LastChatDateMax
                Status: $Status
                Action: $Action
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                TicketOptionReasons: $TicketOptionReasons
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                AssignedUserId: $AssignedUserId
                AssignedEmailAddress: $AssignedEmailAddress
                CustomerFirstLetter : $CustomerFirstLetter 
            }
        ) {
            Tickets {
                TicketNumber
                State
                Details
                ResolvedReason
                ResolvedDateTime
                CreatedDateTime
                TicketOption {
                    ${ticketOptionsFragmentString}
                }
                Customer {
                    ${customerFragmentString}
                }
                Attachments {
                    ${attachmentFragmentString}
                }
                ConversationLine {
                    Content
                }
                AssignedUser {
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const activeTasksSummaryQueryName = `GET_TASKS_FOR_COMPANY`;
export const activeTasksSummaryPageQuery = `
    query ${activeTasksSummaryQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $InvoiceNumber: String
        $InvoiceDueDateMin: AWSDateTime
        $InvoiceDueDateMax: AWSDateTime
        $AmountValue: Float
        $AmountOperator: String
        $ActionDateMin: AWSDateTime
        $ActionDateMax: AWSDateTime
        $CreatedDateMin: AWSDateTime
        $CreatedDateMax: AWSDateTime
        $Type: Int
        $Automation: Int
        $Contact: Int
        $Status: Int
        $TaskIds: [ID]
        $ExcludeTasks: Boolean
        $SortField: String
        $ThenSortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $CustomFieldSort: String
        $AssignedUserId: ID
        $AssignedEmailAddress: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $WorkflowFilters: [WorkflowFilterInput]
        $CustomerCountry: String
        $CustomerState: String
        $Action: Int
        $CustomerFirstLetter : [String]
    ) {
        GetTasksForCompany(
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                InvoiceNumber: $InvoiceNumber
                InvoiceDueDateMin: $InvoiceDueDateMin
                InvoiceDueDateMax: $InvoiceDueDateMax
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                ActionDateMin: $ActionDateMin
                ActionDateMax: $ActionDateMax
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                Type: $Type
                Automation: $Automation
                Contact: $Contact
                Status: $Status
                TaskIds: $TaskIds
                ExcludeTasks: $ExcludeTasks
                SortField: $SortField
                ThenSortField: $ThenSortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                AssignedUserId: $AssignedUserId
                AssignedEmailAddress: $AssignedEmailAddress
                WorkflowFilters: $WorkflowFilters
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                Action: $Action
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomerFirstLetter: $CustomerFirstLetter
            }
        ) {
            Tasks {
                ActionDate
                Automation
                ConversationLine {
                    Content
                }
                Customer {
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    PayerState
                }
                Invoice {
                    Number
                    AmountOwing
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                }
                Type
                Workflow {
                    WorkflowId
                    StateName
                    DeliveryMethod
                    WorkflowName @include(if: $IsMultipleWorkflow)
                }
                State
                Ticket {
                    Id
                    TicketNumber
                    TicketOption {
                        Id
                        Type
                        Reason
                    }
                    ConversationLine {
                        Content
                    }
                }
                AssignedUser {
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const activeTasksSummaryOrganisationQueryName = `GET_TASKS_FOR_ORGANISATION`;
export const activeTasksSummaryOrganisationPageQuery = `
    query ${activeTasksSummaryOrganisationQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $InvoiceNumber: String
        $InvoiceDueDateMin: AWSDateTime
        $InvoiceDueDateMax: AWSDateTime
        $AmountValue: Float
        $AmountOperator: String
        $ActionDateMin: AWSDateTime
        $ActionDateMax: AWSDateTime
        $CreatedDateMin: AWSDateTime
        $CreatedDateMax: AWSDateTime
        $Type: Int
        $Automation: Int
        $Contact: Int
        $Status: Int
        $TaskIds: [ID]
        $ExcludeTasks: Boolean
        $SortField: String
        $ThenSortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $AssignedUserId: ID
        $AssignedEmailAddress: String
        $WorkflowFilters: [WorkflowFilterInput]
        $CustomerCountry: String
        $CustomerState: String
        $Action: Int
        $CompanyIds: [ID]
        $FetchOrganisationData: Boolean = false
    ) {
        GetTasksForOrganisation(
            FetchOrganisationData: $FetchOrganisationData,
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                InvoiceNumber: $InvoiceNumber
                InvoiceDueDateMin: $InvoiceDueDateMin
                InvoiceDueDateMax: $InvoiceDueDateMax
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                ActionDateMin: $ActionDateMin
                ActionDateMax: $ActionDateMax
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                Type: $Type
                Automation: $Automation
                Contact: $Contact
                Status: $Status
                TaskIds: $TaskIds
                ExcludeTasks: $ExcludeTasks
                SortField: $SortField
                ThenSortField: $ThenSortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                AssignedUserId: $AssignedUserId
                AssignedEmailAddress: $AssignedEmailAddress
                WorkflowFilters: $WorkflowFilters
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                Action: $Action
                CompanyIds: $CompanyIds
            }
        ) {
            Tasks {
                Id
                CompanyId
                ActionDate
                Automation
                ConversationLine {
                    Content
                }
                Customer {
                    Id
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyId
                    CompanyName
                    DisplayName
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    PayerState
                }
                Invoice {
                    Number
                    AmountOwing
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                }
                Company {
                    Name
                }
                RemittanceAdvice {
                    Id
                    Amount
                    AllocatedAmount
                    Status
                    ReceivedDate
                    MultipleCustomer
                    ConversationLine {
                        Content
                    }
                }
                Type
                Workflow {
                    WorkflowId
                    StateName
                    DeliveryMethod
                    WorkflowName @include(if: $IsMultipleWorkflow)
                }
                State
                Ticket {
                    Id
                    TicketNumber
                    TicketOption {
                        Id
                        Type
                        Reason
                    }
                    ConversationLine {
                        Content
                    }
                }
                AssignedUser {
                    UserId
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const inactiveTasksSummaryQueryName = `GET_INACTIVE_TASKS_FOR_COMPANY`;
export const inactiveTasksSummaryPageQuery = `
    query ${inactiveTasksSummaryQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $InvoiceNumber: String
        $AmountValue: Float
        $AmountOperator: String
        $ActionDateMin: AWSDateTime
        $ActionDateMax: AWSDateTime
        $CreatedDateMin: AWSDateTime
        $CreatedDateMax: AWSDateTime
        $Type: Int
        $Automation: Int
        $Contact: Int
        $Status: Int
        $TaskIds: [ID]
        $ExcludeTasks: Boolean
        $SortField: String
        $ThenSortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $AssignedUserId: ID
        $AssignedEmailAddress: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $WorkflowFilters: [WorkflowFilterInput]
        $CustomerCountry: String
        $CustomerState: String
        $Action: Int
    ) {
        GetInactiveTasksForCompany(
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                InvoiceNumber: $InvoiceNumber
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                ActionDateMin: $ActionDateMin
                ActionDateMax: $ActionDateMax
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                Type: $Type
                Automation: $Automation
                Contact: $Contact
                Status: $Status
                TaskIds: $TaskIds
                ExcludeTasks: $ExcludeTasks
                SortField: $SortField
                ThenSortField: $ThenSortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldSort: $CustomFieldSort
                AssignedUserId: $AssignedUserId
                AssignedEmailAddress: $AssignedEmailAddress
                WorkflowFilters: $WorkflowFilters
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                Action: $Action
            }
        ) {
            Tasks {
                ActionDate
                Automation
                ConversationLine {
                    Content
                }
                Customer {
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    PayerState
                }
                Invoice {
                    Number
                    AmountOwing
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                }
                Type
                Workflow {
                    WorkflowId
                    StateName
                    DeliveryMethod
                    WorkflowName @include(if: $IsMultipleWorkflow)
                }
                State
                Ticket {
                    Id
                    TicketNumber
                    TicketOption {
                        Id
                        Type
                        Reason
                    }
                    ConversationLine {
                        Content
                    }
                }
                AssignedUser {
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const archiveTasksSummaryQueryName = `GET_ARCHIVE_TASKS_FOR_COMPANY`;
export const archiveTasksSummaryPageQuery = `
    query ${archiveTasksSummaryQueryName}(
        $DisplayName: String
        $CustomerCode: String
        $InvoiceNumber: String
        $AmountValue: Float
        $AmountOperator: String
        $ActionDateMin: AWSDateTime
        $ActionDateMax: AWSDateTime
        $CreatedDateMin: AWSDateTime
        $CreatedDateMax: AWSDateTime
        $Type: Int
        $Automation: Int
        $Contact: Int
        $Status: Int
        $TaskIds: [ID]
        $ExcludeTasks: Boolean
        $SortField: String
        $ThenSortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldSort: String
        $AssignedUserId: ID
        $AssignedEmailAddress: String
        $IsCloudImportType: Boolean = false
        $IsMultipleWorkflow: Boolean = false
        $WorkflowFilters: [WorkflowFilterInput]
        $CustomerCountry: String
        $CustomerState: String
        $Action: Int
    ) {
        GetTasksArchiveForCompany(
            Filter: {
                DisplayName: $DisplayName
                CustomerCode: $CustomerCode
                InvoiceNumber: $InvoiceNumber
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                ActionDateMin: $ActionDateMin
                ActionDateMax: $ActionDateMax
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                Type: $Type
                Automation: $Automation
                Contact: $Contact
                Status: $Status
                TaskIds: $TaskIds
                ExcludeTasks: $ExcludeTasks
                SortField: $SortField
                ThenSortField: $ThenSortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomFieldSort: $CustomFieldSort
                AssignedUserId: $AssignedUserId
                AssignedEmailAddress: $AssignedEmailAddress
                WorkflowFilters: $WorkflowFilters
                CustomerCountry: $CustomerCountry
                CustomerState: $CustomerState
                Action: $Action
                CustomerFirstLetter : $CustomerFirstLetter 
            }
        ) {
            Tasks {
                ActionDate
                Automation
                ConversationLine {
                    Content
                }
                Customer {
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                    PayerState
                }
                Invoice {
                    Number
                    AmountOwing
                    CustomFields {
                        Name
                        Value
                    }
                    ConversationLine {
                        Content
                    }
                }
                Type
                Workflow {
                    WorkflowId
                    StateName
                    DeliveryMethod
                    WorkflowName @include(if: $IsMultipleWorkflow)
                }
                State
                Ticket {
                    Id
                    TicketNumber
                    TicketOption {
                        Id
                        Type
                        Reason
                    }
                    ConversationLine {
                        Content
                    }
                }
                AssignedUser {
                    GivenName
                    FamilyName
                }
                AssignedEmailAddress
            }
        }
    }
`;

export const paymentsSummaryQueryName = `GET_PAYMENTS_FOR_COMPANY`;
export const paymentsSummaryPageQuery = `
    query ${paymentsSummaryQueryName}(
        $Customer: String
        $CustomerCode: String
        $Result: Int
        $PaidDateMin: String
        $PaidDateMax: String
        $AmountValue: String
        $AmountOperator: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
        $CustomFieldFilters: String
        $CustomFieldMultipleValuesFilters: String
        $CustomFieldSort: String
        $BankInstitutions: [String]
        $CustomerFirstLetter : [String]
        $ActiveState: String
    ) {
        GetPaymentsForCompany(
            Filter: {
                Customer: $Customer
                CustomerCode: $CustomerCode
                Result: $Result
                PaidDateMin: $PaidDateMin
                PaidDateMax: $PaidDateMax
                AmountValue: $AmountValue
                AmountOperator: $AmountOperator
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
                CustomFieldFilters: $CustomFieldFilters
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters
                CustomFieldSort: $CustomFieldSort
                BankInstitutions: $BankInstitutions
                CustomerFirstLetter : $CustomerFirstLetter 
                ActiveState: $ActiveState
            }
        ) {
            Payments {
                Id
                Result
                PaidDate
                LocalPaidDate
                PaidAmount
                Customer {
                    Id
                    CustomerCode
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                }
                Uri
                Allocations {
                    AmountAllocated
                    InvoiceNumber
                }
                Attachments {
                    ${attachmentFragmentString}
                }
                ConversationLine {
                    Content
                }
                SettlementDate
                TransactionFeeBuyer
                TransactionFeeSeller
                BankInstitution
            }
        }
    }
`;

export const paymentPlansSummaryQueryName = `GET_PAYMENT_PLANS_FOR_COMPANY`;
export const paymentPlansSummaryPageQuery = `
    query ${paymentPlansSummaryQueryName}(
        $Status: Int
        $CreatedDateMin: String
        $CreatedDateMax: String
        $SortField: String
        $Ascending: Boolean
        $PaymentPlanState: String
        $Skip: Int
        $PageSize: Int
        $IsCloudImportType: Boolean = false
        $PaymentPlanIds: [ID]
        $ExcludePaymentPlans: Boolean = false
        $Customer: String
        $CustomerCode: String
        $CompanyId: ID
    ) {
        GetPaymentPlansForCompany(
            Filter: {
                Status: $Status
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                SortField: $SortField
                Ascending: $Ascending
                PaymentPlanState: $PaymentPlanState
                Skip: $Skip
                PageSize: $PageSize
                PaymentPlanIds: $PaymentPlanIds
                ExcludePaymentPlans: $ExcludePaymentPlans
                Customer: $Customer
                CustomerCode: $CustomerCode
                CompanyId: $CompanyId
            }
        ) {
            PaymentPlans {
                Number
                TotalAmount
                AmountRemaining
                RequestedDateTime
                ApprovedDateTime
                ClosedDateTime
                Customer {
                    CustomerCode @skip(if: $IsCloudImportType)
                    CompanyName
                    DisplayName
                }
                State
                TotalSchedules
                PaymentPlanSchedules {
                    Number
                    DueDateTime
                    State
                    TotalAmount
                    AmountRemaining
                }
            }
        }
    }
`;

export const remittanceAdvicesSummaryQueryName = `GET_REMITTANCE_ADVICES_FOR_COMPANY`;
export const remittanceAdvicessSummaryPageQuery = `
    query ${remittanceAdvicesSummaryQueryName}(
        $CompanyId: ID,
        $CustomerId: ID,
        $RemittanceAdviceState: Int, 
        $PaidDateMin: String,
        $PaidDateMax: String,
        $PaidAmountMin: String,
        $PaidAmountMax: String,
        $AmountValue: Float,
        $AmountOperator: String,
        $AmountType: String,
        $Customer: String,
        $CustomerCode: String,
        $CustomFieldFilters: String,
        $CustomFieldMultipleValuesFilters: String,
        $CustomFieldSort: String,
        $SortField: String,
        $Ascending: Boolean,
        $Skip: Int,
        $PageSize: Int,
        $IsCloudImportType: Boolean = false,
        $ForReport: Boolean = true,
        $MultipleCustomer: Boolean,
        $CustomerFirstLetter : [String],
        $ActiveState: String 
    ) {
        GetRemittanceAdvicesForCompany(
            Filter: {
                CompanyId: $CompanyId,
                CustomerId: $CustomerId,
                RemittanceAdviceState: $RemittanceAdviceState, 
                PaidDateMin: $PaidDateMin,
                PaidDateMax: $PaidDateMax,
                PaidAmountMin: $PaidAmountMin,
                PaidAmountMax: $PaidAmountMax,
                AmountValue: $AmountValue,
                AmountOperator: $AmountOperator,
                AmountType: $AmountType,
                Customer: $Customer,
                CustomerCode: $CustomerCode,
                CustomFieldFilters: $CustomFieldFilters,
                CustomFieldMultipleValuesFilters: $CustomFieldMultipleValuesFilters,
                CustomFieldSort: $CustomFieldSort,
                SortField: $SortField,
                Ascending: $Ascending,
                Skip: $Skip,
                PageSize: $PageSize,
                ForReport: $ForReport,
                MultipleCustomer: $MultipleCustomer,
                CustomerFirstLetter : $CustomerFirstLetter,
                ActiveState: $ActiveState
            }
        ) {
            RemittanceAdvices {
                Id
                CompanyId
                Customer {
                    ${customerFragmentString}
                }
                Status
                ReceivedDate
                LocalReceivedDate
                PaidDate
                LocalPaidDate
                Amount
                MultipleCustomer
                ConversationLine {
                    Id
                    CompanyId
                    CreatedDateTime
                    Type
                    TypeClassification
                    Content
                    Context
                    DisplayName
                    User {
                        UserId
                        GivenName
                        FamilyName
                        Email
                        PhoneNumber
                    }
                    ExternalEmailAddress
                }
                Uri
                Attachments{
                    ${attachmentFragmentString}
                }
                InvoiceNumbers
                CreditNumbers
                RebateNumbers
            }
        }
    }
`;

export const transcriptReportQueryName = `GET_CHAT_FOR_COMPANY`;
export const transcriptReportQuery = `
    query ${transcriptReportQueryName}(
        $Type: Int
        $ResourceScopes: [ResourceScopeInput]
        $CreatedDateMin: String
        $CreatedDateMax: String
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
        $CompanyId: ID
    ) {
        GetConversationResourcesForCompany(
            Filter: {
                Type: $Type
                ResourceScopes: $ResourceScopes
                CreatedDateMin: $CreatedDateMin
                CreatedDateMax: $CreatedDateMax
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
                CompanyId: $CompanyId
            }
        ) {
            ConversationLines {
                Id
                CreatedDateTime
                Type
                TypeClassification
                Content
                DisplayName
                User {
                    UserId
                    GivenName
                    FamilyName
                }
                ExternalEmailAddress
                AttachmentId
                Attachment {
                    Title
                }
            }
        }
    }
`;

export const customerCountPBIQueryName = `GET_CUSTOMER_COUNT_PBI_FOR_COMPANY`;
export const customerCountPBIQuery = `
    query ${customerCountPBIQueryName}
    (
        $CompanyId: ID
    ) {
        GetCustomerCountPBIForCompany(
            Filter: {
                CompanyId: $CompanyId
            }
        ) {
            CustomerCountPaymentBehaviourInsights {
                Count
                Description
            }
        }
    }
`;

export const customerCountPBIForOrganisationQueryName = `GET_CUSTOMER_COUNT_PBI_FOR_ORGANISATION`;
export const customerCountPBIForOrganisationQuery = `
    query ${customerCountPBIForOrganisationQueryName}
    (
        $CompanyIds: [ID]
    ) {
        GetCustomerCountPBIForOrganisation(
            Filter: { 
                CompanyIds: $CompanyIds
            }
        ) {
            CustomerCountPaymentBehaviourInsights {
                Count
                Description
            }
        }
    }
`;
export const bankFileDescriptorsSummaryQueryName = `GET_BANK_FILE_DESCRIPTORS_FOR_COMPANY`;
export const bankFileDescriptorsSummaryPageQuery = `
    query ${bankFileDescriptorsSummaryQueryName}(
        $CompanyId: ID
        $Customer: String
        $Descriptors: [String]
        $SortField: String
        $Ascending: Boolean
        $Skip: Int
        $PageSize: Int
    ) {
        GetCustomerBankDescriptorsForCompany (
            Filter: {
                CompanyId: $CompanyId
                Customer: $Customer
                Descriptors: $Descriptors
                SortField: $SortField
                Ascending: $Ascending
                Skip: $Skip
                PageSize: $PageSize
            }
        ) {
            CustomerBankDescriptors {
                Customer {
                    Id
                    CustomerCode
                    CompanyId
                    CompanyName
                    DisplayName
                    Contacts {
                        ${contactFragmentString}
                    }
                    CustomFields {
                        Name
                        Value
                    }
                }
	            BankDescriptors
            }
        }
    }
`;