/**
 * Component for populating each item in management page.
 */

import { Avatar, Checkbox, Col, Row } from 'antd';
import { get, includes, isEqual } from 'lodash';
import React, { memo } from 'react';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    readonly item: DynamicObject;
    readonly hideCheckbox?: boolean;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly selectedId?: any;
}
const SmsCustomizationItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    selectedId,
}: IProps) => {
    const { 
        Id,
        IsSelected,
        Type,
        Name,
        Url
    } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col span={24} style={{ height: '100%' }}>
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col md={2} sm={24} xs={24}>
                        <div style={{ padding: '0px 20px' }}>
                            <Checkbox
                                checked={includes(selectedRowKeys, Id)}
                                onClick={handleCheckboxClick}
                                disabled={get(item, 'active') === false}
                            />
                        </div>
                    </Col>
                    <Col md={4} sm={24} xs={24}>
                        {Type}
                    </Col>
                    <Col md={4} sm={24} xs={24}>
                        {Name}
                    </Col>
                    <Col md={10} sm={24} xs={24} className="ta-center">
                        {Url}
                    </Col>
                    <Col className="center-flex-i" md={4} sm={24} xs={24}>
                        {IsSelected && (
                            <>
                                <Avatar
                                    style={{
                                        backgroundColor: 'green',
                                        verticalAlign: 'middle',
                                        marginRight: 8,
                                    }}
                                    size={15}
                                ></Avatar>
                                Selected
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    const prevItem = { ...prevProps.item };
    const nextItem = { ...nextProps.item };
    if (get(prevItem, 'avatar')) {
        delete prevItem.avatar;
    }
    if (get(nextItem, 'avatar')) {
        delete nextItem.avatar;
    }
    if (
        JSON.stringify(prevItem) === JSON.stringify(nextItem) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(SmsCustomizationItemComponent, arePropsEqual);
