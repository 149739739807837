/**
 * File responsible for the content when clicking `Allocate to invoice` in credits management pages.
 */

import { Button, Col, Form, Modal, Row, Table } from 'antd';
import { isEmpty, map, filter, first, findIndex, capitalize } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, roundNumberToDecimalDigits } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { CreditVM, RequestCreateCreditManuallyPayload } from '../../store/credits/types';
import FontAwesome from '../common/FontAwesome';
import CreateCreditItemManuallyPanelWrapper from './CreateCreditItemManuallyPanel';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { Payment } from '../../store/payments/types';
import { RemittanceAdvice } from '../../store/remittanceAdvices/types';

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));
export interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly dispatchAction: (requestCreateCreditManuallyPayload: RequestCreateCreditManuallyPayload) => void;
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly onCreateSuccess?: (creditCodes: Partial<CreditVM>[]) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
    readonly remittanceAdvice?: RemittanceAdvice;
}

const CreateCreditManuallyPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    formatCurrency,
    formatToParts,
    onCreateSuccess,
    disableCustomer,
    payment,
    remittanceAdvice
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const [isEditMode, setisEditMode] = useState<boolean>(false);
    const [creditList, setCreditList] = useState<Partial<CreditVM>[]>([]);
    const [credit, setCredit] = useState<Partial<CreditVM>>({});
    const [isCreateCreditItemManuallyPanelVisible, setIsCreateCreditItemManuallyPanelVisible] = useState<boolean>(false);

    const creditsTableColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            width: '20%'
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '27%'
        },
        {
            title: `${capitalize(customerLabel)}`,
            dataIndex: 'Customer',
            width: '20%'
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            width: '15%'
        },
        {
            title: 'Recurring',
            dataIndex: 'Recurring',
            width: '18%'
        }
    ];

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const {
        validateFields,
        resetFields,
    } = form;

    const isCreateBtnDisabled = submitLoading || creditList.length === 0;

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
    * Function for calling when the CreateCreditItemManuallyPanel is closed.
    * @param refreshList
    */
    const onCreateCreditItemManuallyPanelClose = (isCreditCreatedSuccessfully?: boolean) => {
        setIsCreateCreditItemManuallyPanelVisible(false);
        setisEditMode(false);

        if (isCreditCreatedSuccessfully) {
            if (closePanel) {
                closePanel(true);
            };
        }
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const credits: Partial<CreditVM>[] = creditList.map(credit => {
                    return {
                        Type: credit.Type,
                        CreditCode: credit.CreditCode,
                        Description: credit.Description,
                        Customer: credit.Customer,
                        UsePercentage: credit.UsePercentage,
                        Percentage: credit.Percentage,
                        OriginalAmount: credit.OriginalAmount,
                        CreatedType: credit.CreatedType,
                        IsRecurring: credit.IsRecurring
                    }
                });

                const requestCreateCreditManuallyPayload: RequestCreateCreditManuallyPayload = {
                    Credits: credits,
                    callback: createCreditResponseModal
                }

                dispatch(
                    dispatchAction(requestCreateCreditManuallyPayload)
                );
            }
        });
    };

    /**
     * Function responsible for showing the response modal after credit created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createCreditResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content:
                    'Credits created successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
            if (onCreateSuccess) {
                onCreateSuccess(creditList);
            }
        } else {
            let errorMessageContent: any = `Failed to create credits!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    const populateCreditTableDataSourceItem = (credit: CreditVM, index: number) => {
        const { currency } = handleFormatCurrency(credit.OriginalAmount);

        return {
            key: index,
            Name: credit.CreditCode,
            Description: credit.Description,
            Customer: credit.Customer.DisplayName,
            Amount: credit.UsePercentage ? `${roundNumberToDecimalDigits(credit.Percentage) + '%'}` : `${currency + ' ' + roundNumberToDecimalDigits(credit.OriginalAmount)}`,
            Recurring: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {credit.IsRecurring ? 'True' : 'False'}
                    </div>{' '}
                    <div className="fs-16 cursor-p-div mr-5" onClick={handleEditCredit(credit)}>
                        <FontAwesome icon={['fas', 'edit']} />
                    </div>{' '}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteCredit(credit)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const handleAddCredit = () => {
        setIsCreateCreditItemManuallyPanelVisible(true);
    };

    const handleAddCreditSuccessfully = (credit: CreditVM) => {
        setCreditList(list => {
            return [...list, credit];
        });
    };

    const handleEditCredit = (credit: CreditVM) => () => {
        setisEditMode(true);
        setCredit(credit);
        setIsCreateCreditItemManuallyPanelVisible(true);
    };

    const handleEditCreditSuccessfully = (credit: CreditVM) => {
        setisEditMode(false);

        const creditIdx = findIndex(creditList, cre => cre.Id === credit.Id);

        if (creditIdx !== -1) {
            const credits: Partial<CreditVM>[] = [...creditList];
            credits.splice(creditIdx, 1, credit);

            setCreditList(credits);
        }
    };

    const handleDeleteCredit = (credit: CreditVM) => () => {
        const creditIdx = findIndex(creditList, cre => cre === credit);

        if (creditIdx !== -1) {
            const credits: Partial<CreditVM>[] = [...creditList];
            credits.splice(creditIdx, 1);

            setCreditList(credits);
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span={24}>
                        <Table
                            className="app-credits-table"
                            columns={creditsTableColumns}
                            dataSource={map(creditList, populateCreditTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                        />
                    </Col>
                    <Col span={24} className="ta-right">
                        <Button
                            type="link"
                            onClick={handleAddCredit}
                            style={{ padding: 0 }}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>Add credit</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            Create
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Creating new credit"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while creating new credit . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
            <Suspense fallback={null}>
                <div ref={null}>
                    <CreateCreditItemManuallyPanelWrapper
                        visible={isCreateCreditItemManuallyPanelVisible}
                        isEditMode={isEditMode}
                        editCredit={credit}
                        closePanel={onCreateCreditItemManuallyPanelClose}
                        handleAddCredit={ handleAddCreditSuccessfully }
                        handleEditCredit={ handleEditCreditSuccessfully }
                        existingCreditList={ creditList }
                        disableCustomer={disableCustomer}
                        payment={payment}
                        remittanceAdvice={remittanceAdvice}
                    />
                </div>
            </Suspense>
        </Row>
    );
};


const CreateCreditManuallyPanelForm = Form.create({
    name: 'create-credit-manually-panel-form',
})(CreateCreditManuallyPanel);

export default withRouter(
    withNumberFormatHandler(
        withDateFormatHandler(CreateCreditManuallyPanelForm)
    )
)