import { endsWith } from "lodash";
import { defaultContentValues } from "../constants/contents";

export const decodeHtmlEntitiesInLiquidSections = (source: any) => {
    const regexStr = `{%(.*?)%}|{{(.*?)}}`;
    const regex = new RegExp(regexStr, 'gms');
    const matches = source.matchAll(regex);
    let match: IteratorResult<RegExpMatchArray>;
    const element = document.createElement('div');
    do {
        match = matches.next();
        if (!match.done) {
            const section = match.value[0];
            const content = match.value[1] || match.value[2];
            element.innerHTML = content;
            const newSection = section.replace(content, element.textContent || element.innerText);
            source = source.replace(section, newSection);
        }
    } while (!match.done);
    return source;
}

export const getAllTags = (source: string, tagName: string) => {
    const regexStr = `<${tagName}[^<]*?>(.*?)</${tagName}>`;
    const regex = new RegExp(regexStr, 'gms');
    const matches = source.match(regex) || [];
    return matches;
}

export const getTagInnerContent = (source: string) => {
    const regexStr = `<.+?>(.*)<\/.+?>`;
    const regex = new RegExp(regexStr, 'gms');
    const matches = regex.exec(source);
    return matches && matches[1].trim() || undefined;
}

export const getTagPropertyValue = (source: string, propertyName: string) => {
    const regexStr = `\\b${propertyName}="(.*?)"`;
    const regex = new RegExp(regexStr);
    const matches = regex.exec(source);
    return matches && matches[1].trim() || undefined;
}

export const replaceTag = (source: string, tagName: string, id?: string, newContent?: string) => {
    const regexStr = id
        ? `<${tagName}[^<]+?id="${id}"[^<]*?>(.*?)</${tagName}>`
        : `<${tagName}[^<]*?>(.*?)</${tagName}>`;
    const regex = new RegExp(regexStr, 'gms');
    return (source as any).replaceAll(regex, newContent || '');
}

export const replaceCustomizationTags = (source: any, wrap: boolean, wrapElement?: string, wrapElementStyle?: string) => {
    const regexStr = `<([^ ]+?)[^<]+?app-customization="true"[^<]*?>(.*?)</\\1>`;
    const regex = new RegExp(regexStr, 'gms');
    const matches = source.matchAll(regex);
    wrapElement = wrapElement || defaultContentValues.wrapElement;
    wrapElementStyle = wrapElementStyle || '';
    let match: IteratorResult<RegExpMatchArray>;
    do {
        match = matches.next();
        if (!match.done) {
            const matchTag = match.value[0];
            const group = match.value[2];
            let newContent: string;
            if (wrap) {
                newContent = `<${wrapElement} style="${wrapElementStyle}">${group}</${wrapElement}>`;
            } else {
                newContent = group;
            }
            source = source.replace(matchTag, newContent);
        }
    } while (!match.done);
    return source;
}

export const replaceAllTagsProperty = (source: string, tagName: string, propertyName: string, newContent: string) => {
    const allTags = getAllTags(source, tagName) || [];
    allTags.forEach(tag => {
        const regexStr = `\\b${propertyName}=".*?"`;
        const regex = new RegExp(regexStr, 'gms');
        const matches = source.match(regex);
        if (matches) {
            const newTag = tag.replace(matches[0], newContent);
            source = source.replace(tag, newTag);
        }
    })
    return source;
}

export const replaceTagContent = (source: any, tagName: string, id: string, newContent: string) => {
    const regexStr = `<${tagName}[^<]+?id="${id}"[^<]*?>(.*?)</${tagName}>`;
    const regex = new RegExp(regexStr, 'gms');
    const matches = source.matchAll(regex);
    let match: IteratorResult<RegExpMatchArray>;
    do {
        match = matches.next();
        if (!match.done) {
            const matchTag = match.value[0];
            const group = match.value[1];
            const newTag = matchTag.replace(group, newContent);
            source = source.replace(matchTag, newTag);
        }
    } while (!match.done);
    return source;
}

export const insertContent = (source: any, index: number, content: string) => {
    return index > 0
        ? (source.substring(0, index) + content + source.substring(index))
        : (content + source);
}

export const isHtml = (file: string) => endsWith(file, '.html');

export const getTemplateContentType = (filePath: string) => {
    return isHtml(filePath) ? 'html' : 'sms';
}

export const naiveRound = (number: number, precision: number) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(number * multiplier) / multiplier;
}