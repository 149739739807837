/**
 * Component responsible for populating the Create Token panel.
 */

import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import { get, isEmpty, map, omitBy } from 'lodash';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { createAccessTokenRequestAction } from '../../store/accessTokens/actions';
import { CompanyUserRole } from '../../store/companies/types';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import FontAwesome from '../common/FontAwesome';

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

const FormItem = Form.Item;

interface IProps {
    onCancelClick: (refreshList?: boolean) => void;
    containerRef?: any;
    form: any;
    visible: boolean;
}

/**
 * Names for form items based on backend properties.
 */
const formItemNames = {
    NAME: 'Name',
    ACCESS_LEVEL: 'RoleId',
};
const TokenCreateDrawerContent: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const tokenBoxRef: any = useRef(null);

    /**
     * Function for getting the selected user role.
     */
    const selectedUserRole: CompanyUserRole['Role'] = useSelector(
        (state: ApplicationState) =>
            get(state.companies.selectedUserCompany, 'Role')
    );

    const [formResponseState, setFormResponseState] = useState<{
        apiKey: string | undefined;
        token: string | undefined;
    }>({
        apiKey: undefined,
        token: undefined,
    });

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    /**
     * Function that updates the `formResponseState` state.
     * @param formResponseStateObj - must conform to formResponseState state
     */
    const updateFormResponseState = (formResponseStateObj: DynamicObject) => {
        setFormResponseState({
            ...formResponseState,
            ...formResponseStateObj,
        });
    };

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
        resetFields,
    } = props.form;

    /**
     * Function that resets the field values if drawer is closed.
     */
    const initializeValues = () => {
        if (props.visible === false) {
            resetFields();
        }
    };

    useEffect(initializeValues, [props.visible]);

    /**
     * Function called when the `Save` button is clicked.
     * @param e
     */
    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        setSubmitLoading(true);
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                dispatch(
                    createAccessTokenRequestAction(
                        values,
                        createAccessTokenResponseHandler
                    )
                );
            }
        });
    };

    /**
     * Callback function called after creating an access token (success/fail).
     * @param response
     */
    const createAccessTokenResponseHandler = (response: DynamicObject) => {
        setSubmitLoading(false);
        if (response.IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Personal access token created successfully!',
                onOk: () => {
                    updateFormResponseState({
                        apiKey: get(response, 'Key'),
                        token: get(response, 'Token'),
                    });
                },
                getContainer: () => getPopoverContainer(props.containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to create personal access token!`;
            if (!isEmpty(response.Messages)) {
                errorMessageContent = map(
                    response.Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(props.containerRef),
                onOk: () => {
                    updateFormResponseState({
                        apiKey: undefined,
                        token: undefined,
                    });
                },
            });
        }
    };

    /**
     * Function that handles copying the token value to clipboard.
     * @param event
     */
    const copyTokenToClipboard = (event: any) => {
        if (tokenBoxRef && tokenBoxRef.current) {
            tokenBoxRef.current.select();

            document.execCommand('copy');
            event.target.focus();

            Modal.success({
                title: 'Success',
                content: 'Token copied to the clipboard!',
            });
        }
    };

    /**
     * Function to populate the components inside the drawer.
     * Handles the display during and after token creation.
     */
    const populateDrawerContent = () => {
        const { apiKey, token } = formResponseState;
        const hasSuccessResponse = apiKey && token;

        if (hasSuccessResponse) {
            return (
                <>
                    <div>
                        You have successfully added a new personal access token.
                        Copy the token now!
                    </div>
                    <br />
                    <div>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="API Key">
                                    {getFieldDecorator('API Key', {
                                        initialValue: apiKey,
                                    })(<Input readOnly />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Token">
                                    {getFieldDecorator('Token', {
                                        initialValue: token,
                                    })(
                                        <Input
                                            ref={tokenBoxRef}
                                            readOnly
                                            addonAfter={
                                                <div
                                                    className="cursor-p-div"
                                                    onClick={
                                                        copyTokenToClipboard
                                                    }
                                                >
                                                    <FontAwesome
                                                        icon={['fa', 'copy']}
                                                        className="blueCustom"
                                                    />
                                                </div>
                                            }
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <Alert
                        message=""
                        description={
                            <div className="center-flex">
                                <div className="mr-20">
                                    <FontAwesome
                                        className="orange"
                                        icon={['fas', 'exclamation-triangle']}
                                        size="2x"
                                    />
                                </div>
                                <div>
                                    <div>
                                        Warning - Make sure you copy the above
                                        token now.
                                    </div>
                                    <div>
                                        We don't store it and you will not be
                                        able to see it again.
                                    </div>
                                </div>
                            </div>
                        }
                        type="warning"
                        showIcon={false}
                    />
                    <br />
                    <div className="mt-10 ta-right">
                        <Button
                            onClick={handleCancelClickRefresh}
                            type="primary"
                        >
                            Close
                        </Button>
                    </div>
                </>
            );
        } else {
            const allValues = omitBy(getFieldsValue(), isEmpty);
            const isSaveBtnDisabled = isEmpty(allValues);

            return (
                <>
                    <div>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Name">
                                    {getFieldDecorator(formItemNames.NAME, {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Name required!',
                                            },
                                        ],
                                    })(<Input />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Access level">
                                    {getFieldDecorator(
                                        formItemNames.ACCESS_LEVEL,
                                        {
                                            initialValue: get(
                                                selectedUserRole,
                                                'Name'
                                            ),
                                        }
                                    )(<Input readOnly />)}
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="mt-10 ta-right">
                        <Button
                            className="mr-10"
                            onClick={handleFormSave}
                            disabled={isSaveBtnDisabled}
                            type="primary"
                        >
                            Create
                        </Button>
                        <Button
                            className="buttonGrey"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            );
        }
    };

    /**
     * Common function used for calling the cancel click / close.
     */
    const handleCancelClick = () => {
        props.onCancelClick();
    };

    /**
     * Function that handles the closing of drawer, also refetching of access token list after.
     */
    const handleCancelClickRefresh = () => {
        props.onCancelClick(true);
    };

    return (
        <Form className="form-lbl-lh-24" colon={false}>
            {populateDrawerContent()}
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Creating access token"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while the access token is being created. . ."
                        containerRef={props.containerRef}
                    />
                </Suspense>
            )}
        </Form>
    );
};

const TokenCreateDrawerContentForm = Form.create({
    name: 'create-personal-token-form',
})(TokenCreateDrawerContent);

export default withRouter(TokenCreateDrawerContentForm);
