import API, { graphqlOperation } from '@aws-amplify/api';
import { get, includes, isEmpty, map } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    takeEvery,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    fetchCommunicationResourceResponseDetailsAction,
    sendCommunicationResourceResponseAction,
    publicAttachToResourceRequestAction
} from './actions';
import { ConversationLineActionTypes } from './types';

let refetchCount = 0;

/**
 * Function for getting the data needed to populate the details in the Customer Response page from API.
 * @param param0
 */
function* handleFetchCommunicationResourceResponseDetails({ payload: sagaPayload }: any) {
    const {
        conversationLineId,
        callback,
    } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMMUNICATION_RESOURCE_RESPONSE_DETAILS, {
                ConversationLineId: conversationLineId || null,
            })
        );
        res.IsSuccess = true;
        refetchCount = 0;
        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                fetchCommunicationResourceResponseDetailsAction(
                    conversationLineId,
                    callback
                )
            );
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
            //if (callback) {
            //    const returnData = get(err.response, 'data')
            //        ? err.response.data
            //        : { Messages: [err.message] };
            //    returnData.IsSuccess = false;
            //    callback(returnData);
            //}
        }
    }
}

/**
 * Function called when sending email response.
 * @param param0
 */
 function* handleSendCommunicationResourceResponseRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/communication/resource/emailresponse',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            if (isEmpty(get(res, 'Messages'))) {
                const response = {
                    ...res,
                    IsSuccess: true,
                };
                callback(response);
            } else {
                throw new Error('Failed');
            }
        }
    } catch (err) {
        yield put(publicAttachToResourceRequestAction(payload, callback));

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}
/**
 * Function called when uploading an attachment file.
 * @param param0
 */
 function* handlePublicAttachToResourceRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/document/publicattachtoresource',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            if (isEmpty(get(res, 'Messages'))) {
                const response = {
                    ...res,
                    IsSuccess: true,
                };
                callback(response);
            } else {
                throw new Error('Failed');
            }
        }
    } catch (err) {
        yield put(publicAttachToResourceRequestAction(payload, callback));

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}


// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchCommunicationResourceResponseDetails() {
    yield takeEvery(
        ConversationLineActionTypes.FETCH_COMMUNICATION_RESOURCE_RESPONSE_DETAILS,
        handleFetchCommunicationResourceResponseDetails
    );
}
function* watchSendCommunicationResourceResponseRequest() {
    yield takeEvery(
        ConversationLineActionTypes.SEND_COMMUNICATION_RESOURCE_RESPONSE_REQUEST,
        handleSendCommunicationResourceResponseRequest
    );
}
function* watchPublicAttachToResourceRequest() {
    yield takeEvery(
        ConversationLineActionTypes.PUBLIC_ATTACH_TO_RESOURCE_REQUEST,
        handlePublicAttachToResourceRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* conversationLineSaga() {
    yield all([
        fork(watchFetchCommunicationResourceResponseDetails),
        fork(watchSendCommunicationResourceResponseRequest),
        fork(watchPublicAttachToResourceRequest)
    ]);
}

export default conversationLineSaga;
