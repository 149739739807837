import { Input, Spin } from "antd";
import React, { useMemo } from "react";

interface IProps {
    previewContent?: string;
    rendering: boolean;
    isHtml: boolean;
}

const ContentPreview: React.FC<IProps> = ({ previewContent, rendering, isHtml }: IProps) => {
    const domParser = useMemo(() => new DOMParser(), []);
    const processedContent = useMemo(() => {
        let content = previewContent;
        if (content) {
            if (isHtml) {
                const document = domParser.parseFromString(content, 'text/html');
                document.querySelectorAll('a').forEach(a => {
                    a.href = 'javascript:void(0);'
                });
                content = document.documentElement.outerHTML;
            }
        }
        return content;
    }, [previewContent, isHtml, domParser]);

    if (!processedContent) {
        return <>There is nothing to preview</>;
    }

    return <Spin spinning={rendering}>
        {isHtml
            ? <iframe
                sandbox=""
                style={{
                    width: '100%', height: '80vh'
                }}
                srcDoc={processedContent}>
            </iframe>
            : <Input.TextArea style={{ width: '100%', height: '80vh' }} value={processedContent} readOnly />}
    </Spin>;
}

export default ContentPreview;