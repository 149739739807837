/**
 * Component for populating protected popover button (controlled by user role).
 */

import { Button, Popover } from 'antd';
import { get, includes, isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    readonly containerRef?: any;
    readonly allowedRoles?: string[];
    readonly popoverOverlayStyle?: DynamicObject;
    readonly popoverVisible?: boolean;
    readonly popoverOnVisibleChange?: (visible: boolean) => void;
    readonly popoverTitle?: any;
    readonly popoverPlacement:
        | 'top'
        | 'left'
        | 'right'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined;
    readonly popoverContent: any;
    readonly popoverTrigger:
        | 'hover'
        | 'focus'
        | 'click'
        | 'contextMenu'
        | undefined;
    readonly buttonType:
        | 'link'
        | 'default'
        | 'ghost'
        | 'primary'
        | 'dashed'
        | 'danger'
        | undefined;
    readonly buttonClassName?: string;
    readonly buttonContent: any;
    readonly buttonRestProps?: any;
}
const ProtectedPopoverButton: React.FC<IProps> = ({
    containerRef,
    allowedRoles,
    popoverOverlayStyle,
    popoverVisible,
    popoverOnVisibleChange,
    popoverTitle,
    popoverPlacement,
    popoverContent,
    popoverTrigger,
    buttonType,
    buttonClassName,
    buttonContent,
    buttonRestProps,
}: IProps) => {
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        if (popoverOnVisibleChange) {
            return (
                <Popover
                    visible={popoverVisible}
                    placement={popoverPlacement}
                    title={popoverTitle}
                    content={popoverContent}
                    trigger={popoverTrigger}
                    onVisibleChange={popoverOnVisibleChange}
                    getPopupContainer={populatePopoverContainer(containerRef)}
                    overlayStyle={popoverOverlayStyle}
                >
                    <Button
                        type={buttonType}
                        className={buttonClassName}
                        {...buttonRestProps}
                    >
                        {buttonContent}
                    </Button>
                </Popover>
            );
        } else {
            return (
                <Popover
                    placement={popoverPlacement}
                    title={popoverTitle}
                    content={popoverContent}
                    trigger={popoverTrigger}
                    getPopupContainer={populatePopoverContainer(containerRef)}
                    overlayStyle={popoverOverlayStyle}
                >
                    <Button
                        type={buttonType}
                        className={buttonClassName}
                        {...buttonRestProps}
                    >
                        {buttonContent}
                    </Button>
                </Popover>
            );
        }
    } else {
        return null;
    }
};

export default ProtectedPopoverButton;
