import { Tag } from 'antd';
import React from 'react';
import { announcementTagTypes } from '../../constants/announcementsSortAndFilters';

interface IProps {
    tagValue?: string;
}

const AnnouncementTag: React.FC<IProps> = ({ tagValue }: IProps) => {
    const backgroundColor =
        tagValue === announcementTagTypes.FEATURE ? '#80c076' : '#a5a7db';
    return (
        <Tag className="announcement-tag" style={{ backgroundColor }}>
            {tagValue}
        </Tag>
    );
};

export default AnnouncementTag;
