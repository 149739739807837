import { get } from 'lodash';
import { Reducer } from 'redux';
import { DEFAULT_CURRENCY_CODE, DEFAULT_LOCALE } from './../../config/config';
import { currencyCodes } from './../../constants/companyGeneralOptions';
import { AppCommonState, CommonFiltersActionTypes } from './types';

// Type-safe initialState!
export const initialState: AppCommonState = {
    loadLuckysheet: false,
    refetchPageViews: false,
    defaultPageViewValue: undefined,
    attachmentEditing: false,
    locale: DEFAULT_LOCALE,
    currencyCode: DEFAULT_CURRENCY_CODE,
    demoOptionsConfig: {},
    pageViewsSelected: {},
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AppCommonState> = (state = initialState, action) => {
    switch (action.type) {
        case CommonFiltersActionTypes.UPDATE_REFETCH_PAGE_VIEWS: {
            return {
                ...state,
                refetchPageViews: action.payload.willRefetch,
                defaultPageViewValue: action.payload.defaultPageViewValue,
            };
        }

        case CommonFiltersActionTypes.UPDATE_PAGE_VIEWS_SELECTED: {
            const pageViewsSelected = { ...state.pageViewsSelected };
            return {
                ...state,
                pageViewsSelected: {
                    ...pageViewsSelected,
                    [action.payload.pageName]: action.payload.pageView,
                },
            };
        }

        case CommonFiltersActionTypes.UPDATE_ATTACHMENT_EDITING: {
            return {
                ...state,
                attachmentEditing: action.payload,
            };
        }

        case CommonFiltersActionTypes.SET_LOCALE: {
            const usedLocale = action.payload;
            const localeArray = usedLocale.split('-');
            const country = get(localeArray, 1);
            const currencyCode = get(currencyCodes, country);
            return {
                ...state,
                locale: usedLocale,
                currencyCode,
            };
        }
        case CommonFiltersActionTypes.UPDATE_DEMO_OPTIONS_CONFIG:
            return {
                ...state,
                demoOptionsConfig: { ...action.payload },
            };
        case CommonFiltersActionTypes.LOAD_LUCKYSHEET:
            return {
                ...state,
                loadLuckysheet: true
            };
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as commonReducer };
