import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import {
    ChangeUsersRoleRequestPayload,
    GetUsersRequestPayload,
    RemoveUsersRequestPayload,
    User,
    UserPreference,
    UsersActionTypes,
} from './types';

/**
 * Actions for getting the users - request, to handle the loading indicator.
 * @param payload
 */
export const getUsersRequestAction = (payload: GetUsersRequestPayload) =>
    action(UsersActionTypes.GET_USERS_REQUEST, payload);
export const getUsersSuccessAction = (payload: {
    data: User[];
    pageData: PageData;
}) => action(UsersActionTypes.GET_USERS_SUCCESS, payload);
export const getUsersErrorAction = (payload: { errorMessages: string[] }) =>
    action(UsersActionTypes.GET_USERS_ERROR, payload);

/**
 *Actions for changing the selected users role.
 * @param payload
 */
export const changeUsersRoleRequestAction = (
    payload: ChangeUsersRoleRequestPayload
) => action(UsersActionTypes.CHANGE_USERS_ROLE_REQUEST, payload);
export const changeUsersRoleResponseAction = () =>
    action(UsersActionTypes.CHANGE_USERS_ROLE_RESPONSE);

/**
 * Action for updating the filter inside Users management page - after cliking `Apply filters`.
 * @param filters
 */
export const updateUsersFiltersAction = (
    filters: GetUsersRequestPayload['filters']
) => action(UsersActionTypes.UPDATE_USERS_FILTERS, filters);

/**
 * Action for updating the dropdown filter in the upper-left corner (for view).
 * @param tableFilter - value of the dropdown
 */
export const updateUsersTableFilterAction = (tableFilter: string) =>
    action(UsersActionTypes.UPDATE_USERS_TABLE_FILTER, tableFilter);

/**
 * Action for updating the sort options (sort by and sort order) inside Users management page.
 * @param filters
 */
export const updateUsersSortByAction = (tableSortState: {
    sortBy: GetUsersRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(UsersActionTypes.UPDATE_USERS_SORT_BY, tableSortState);

/**
 * Action for resetting table filters and sort values.
 */
export const clearUsersStateAllTableFiltersAction = () =>
    action(UsersActionTypes.CLEAR_USERS_STATE_ALL_TABLE_FILTERS);

/**
 * Function for clearing all the users redux state.
 */
export const clearUsersStateDataAction = () =>
    action(UsersActionTypes.CLEAR_USERS_STATE_DATA);

/**
 * Action for getting the User record based on the name provided.
 * @param name - name of user
 */
export const getUserByNameAction = (
    name: string,
    callback: (res: DynamicObject) => void
) => action(UsersActionTypes.GET_USER_BY_NAME, { name, callback });

/**
 * Action for getting the User record based on the email provided.
 * @param email - email of user
 */
export const getUserByEmailAction = (
    email: string,
    callback: (res: DynamicObject) => void
) => action(UsersActionTypes.GET_USER_BY_EMAIL, { email, callback });

/**
 * Actions for removing users.
 * @param payload
 */
export const removeUsersRequestAction = (payload: RemoveUsersRequestPayload) =>
    action(UsersActionTypes.REMOVE_USERS_REQUEST, payload);

/**
 * Function for getting the user's last login date.
 */
export const getUserLastLoginDateAction = (
    callback: (res: DynamicObject) => void
) => action(UsersActionTypes.GET_USER_LAST_LOGIN_DATE, callback);

/**
 * Function for getting the user's preferences.
 */
export const getUserPreferencesRequestAction = (
    callback?: (res: DynamicObject) => void
) => action(UsersActionTypes.GET_USER_PREFERENCES_REQUEST, callback);

/**
 * Function for setting the user's preferences based on response during fetch.
 */
export const getUserPreferencesSuccessAction = (userPref: UserPreference) =>
    action(UsersActionTypes.GET_USER_PREFERENCES_SUCCESS, userPref);
