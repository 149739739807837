import { paymentsSortByOptions } from '../../constants/paymentsSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { BankFileFormat } from '../banks/types';
import {
    Attachment,
    ConversationLine,
    ConversationState,
    PageData,
} from '../common/types';
import { Customer } from '../customers/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';

export enum PaymentsActionTypes {
    GET_PAYMENTS_REQUEST = '@@payments/GET_PAYMENTS_REQUEST',
    GET_PAYMENTS_SUCCESS = '@@payments/GET_PAYMENTS_SUCCESS',
    GET_PAYMENTS_ERROR = '@@payments/GET_PAYMENTS_ERROR',
    UPDATE_PAYMENTS_FILTERS = '@@payments/UPDATE_PAYMENTS_FILTERS',
    UPDATE_PAYMENTS_SORT_BY_AND_STATE = '@@payments/UPDATE_PAYMENTS_SORT_BY_AND_STATE',
    UPDATE_PAYMENTS_TABLE_FILTER_STATE = '@@payments/UPDATE_PAYMENTS_TABLE_FILTER_STATE',
    CLEAR_PAYMENTS_STATE_ALL_TABLE_FILTERS = '@@payments/CLEAR_PAYMENTS_STATE_ALL_TABLE_FILTERS',
    CLEAR_PAYMENTS_STATE_DATA = '@@payments/CLEAR_PAYMENTS_STATE_DATA',
    GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@payments/GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    SET_PAYMENT_SELECTED_ID_REQUEST = '@@payments/SET_PAYMENT_SELECTED_ID_REQUEST',
    SET_PAYMENT_SELECTED_ID_SUCCESS = '@@payments/SET_PAYMENT_SELECTED_ID_SUCCESS',
    GET_PAYMENT_DATA_REQUEST = '@@payments/GET_PAYMENT_DATA_REQUEST',
    GET_PAYMENT_DATA_SUCCESS = '@@payments/GET_PAYMENT_DATA_SUCCESS',
    GET_PAYMENT_DATA_ERROR = '@@payments/GET_PAYMENT_DATA_ERROR',
    CLEAR_PAYMENT_DATA_SUCCESS = '@@payments/CLEAR_PAYMENT_DATA_SUCCESS',
    DISREGARD_PAYMENT_REQUEST = '@@payments/DISREGARD_PAYMENT_REQUEST',
    ORGANISATION_DISREGARD_PAYMENT_REQUEST = '@@payments/ORGANISATION_DISREGARD_PAYMENT_REQUEST',

    GET_BANK_FILE_FORMATS_REQUEST = '@@payments/GET_BANK_FILE_FORMATS_REQUEST',
    GET_BANK_FILE_FORMATS_SUCCESS = '@@payments/GET_BANK_FILE_FORMATS_SUCCESS',
    GET_BANK_FILE_FORMATS_ERROR = '@@payments/GET_BANK_FILE_FORMATS_ERROR',

    GET_PAYMENT_INVOICES_REQUEST = '@@payments/GET_PAYMENT_INVOICES_REQUEST',
    GET_PAYMENT_INVOICES_SUCCESS = '@@payments/GET_PAYMENT_INVOICES_SUCCESS',
    GET_PAYMENT_INVOICES_ERROR = '@@payments/GET_PAYMENT_INVOICES_ERROR',
    UPDATE_PAYMENT_INVOICES_FILTERS = '@@payments/UPDATE_PAYMENT_INVOICES_FILTERS',
    UPDATE_PAYMENT_INVOICES_SORT_BY_AND_STATE = '@@payments/UPDATE_PAYMENT_INVOICES_SORT_BY_AND_STATE',
    UPDATE_PAYMENT_INVOICES_TABLE_FILTER_STATE = '@@payments/UPDATE_PAYMENT_INVOICES_TABLE_FILTER_STATE',

    GET_PAYMENT_CONVERSATION_REQUEST = '@@payments/GET_PAYMENT_CONVERSATION_REQUEST',
    GET_PAYMENT_CONVERSATION_SUCCESS = '@@payments/GET_PAYMENT_CONVERSATION_SUCCESS',
    GET_PAYMENT_CONVERSATION_ERROR = '@@payments/GET_PAYMENT_CONVERSATION_ERROR',
    GET_ORGANISATION_PAYMENT_CONVERSATION_REQUEST = '@@payments/GET_ORGANISATION_PAYMENT_CONVERSATION_REQUEST',
    UPDATE_PAYMENT_CONVERSATION_FILTERS = '@@payments/UPDATE_PAYMENT_CONVERSATION_FILTERS',
    UPDATE_PAYMENT_CONVERSATION_TABLE_FILTER = '@@payments/UPDATE_PAYMENT_CONVERSATION_TABLE_FILTER',
    UPDATE_PAYMENT_CONVERSATION_SORT_BY = '@@payments/UPDATE_PAYMENT_CONVERSATION_SORT_BY',

    GET_PAYMENT_PAYMENT_CHANGES_REQUEST = '@@payments/GET_PAYMENT_PAYMENT_CHANGES_REQUEST',
    GET_PAYMENT_PAYMENT_CHANGES_SUCCESS = '@@payments/GET_PAYMENT_PAYMENT_CHANGES_SUCCESS',
    GET_PAYMENT_PAYMENT_CHANGES_ERROR = '@@payments/GET_PAYMENT_PAYMENT_CHANGES_ERROR',
    GET_ORGANISATION_PAYMENT_CHANGES_REQUEST = '@@payments/GET_ORGANISATION_PAYMENT_CHANGES_REQUEST',
    UPDATE_PAYMENT_PAYMENT_CHANGES_FILTERS = '@@payments/UPDATE_PAYMENT_PAYMENT_CHANGES_FILTERS',
    UPDATE_PAYMENT_PAYMENT_CHANGES_TABLE_FILTER = '@@payments/UPDATE_PAYMENT_PAYMENT_CHANGES_TABLE_FILTER',
    UPDATE_PAYMENT_PAYMENT_CHANGES_SORT_BY = '@@payments/UPDATE_PAYMENT_PAYMENT_CHANGES_SORT_BY',

    PAYMENT_ADD_COMMENT_REQUEST = '@@payments/PAYMENT_ADD_COMMENT_REQUEST',
    ADD_PAYMENT_FOR_URL_REQUEST = '@@payments/ADD_PAYMENT_FOR_URL_REQUEST',
    ADD_PAYMENT_FOR_WESTERN_UNION_REQUEST = '@@payments/ADD_PAYMENT_FOR_WESTERN_UNION_REQUEST',
    ADD_PAYMENT_FOR_EZIDEBIT_REQUEST = '@@payments/ADD_PAYMENT_FOR_EZIDEBIT_REQUEST',
    ADD_PAYMENT_FOR_INTEGRAPAY_REQUEST = '@@payments/ADD_PAYMENT_FOR_INTEGRAPAY_REQUEST',
    ADD_PAYMENT_FOR_ADVAM_REQUEST = '@@payments/ADD_PAYMENT_FOR_ADVAM_REQUEST',
    ADD_PAYMENT_FOR_CORPAY_REQUEST = '@@payments/ADD_PAYMENT_FOR_CORPAY_REQUEST',

    GET_PAYMENT_SPOT_RATE = '@@payments/GET_PAYMENT_SPOT_RATE',
    CORPAY_BOOK_DEAL_REQUEST = '@@payments/CORPAY_BOOK_DEAL_REQUEST',
    SEND_PAYMENT_INSTRUCTION_FOR_CORPAY_REQUEST = '@@payments/SEND_PAYMENT_FOR_CORPAY_REQUEST',

    DEALLOCATE_PAYMENT_REQUEST = '@@payments/DEALLOCATE_PAYMENT_REQUEST',
    CHECK_LAST_PAYMENT_CHANGELINES_REQUEST = '@@payments/CHECK_LAST_PAYMENT_CHANGELINES_REQUEST',

    CREATE_CREDIT_AS_OVERPAYMENT_REQUEST = '@@payments/CREATE_CREDIT_AS_OVERPAYMENT_REQUEST',
    CREATE_ORGANISATION_CREDIT_AS_OVERPAYMENT_REQUEST = '@@payments/CREATE_ORGANISATION_CREDIT_AS_OVERPAYMENT_REQUEST',
}

export interface PaymentsState {
    readonly loading: boolean;
    readonly savePaymentDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: Payment[];
    readonly pageData: PageData;
    readonly filters: GetPaymentsRequestPayload['filters'];
    readonly sortBy: GetPaymentsRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly ActiveState: string;
    readonly bankFileFormats: BankFileFormat[];
    readonly activeData: {
        loading: boolean;
        record: {};
        conversation: ConversationState;
        paymentChanges: PaymentChangesState;
        selectedId: string | null;
        errorMessages: string[];
        invoices: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Invoice[];
            readonly pageData: PageData;
            readonly filters: GetInvoicesRequestPayload['filters'];
            readonly sortBy: GetInvoicesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly invoiceState: string;
        };
    };
}

export interface Payment {
    readonly Id: string;
    readonly CompanyId: string;
    readonly Result: string;
    readonly ResultDescription: string;
    readonly PaidDate: string;
    readonly LocalPaidDate: string;
    readonly PaidAmount: number;
    readonly AllocatedAmount: number;
    readonly VerificationState: string;
    readonly PaymentReference: string;
    readonly Customer: Customer;
    readonly Uri: string;
    readonly Allocations: PaymentAllocation[];
    readonly ConversationLine: ConversationLine;
    readonly Attachments: Attachment[];
    readonly Source: string;
    readonly BankReceiptNumber: string;
    readonly SettlementDate: string;
    readonly LocalSettlementDate: string;
    readonly PaymentMethod: string;
    readonly TransactionFeeBuyer: number;
    readonly TransactionFeeSeller: number;
    readonly InvoicesAllocated: number;
    readonly BankInstitution: string;
}

export interface PaymentAllocation {
    readonly AmountAllocated: number;
    readonly InvoiceResource: Invoice;
}

export interface PaymentOrganisationCurrency {
    Value: string;
    Rate: number;
}

export interface GetPaymentsRequestPayload {
    ActiveState: string;
    filters: {};
    tableFilter?: string;
    sortBy: typeof paymentsSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType: boolean;
    conversionCurrency?: number;
    organisationCurrencies?: PaymentOrganisationCurrency[]
}

export interface BulkSavePaymentUpdateRequestPayload {
    filter: {};
    paymentIds: string[] | number[];
    excludePayments: boolean;
    paymentObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface SavePaymentUpdateRequestPayload {
    paymentId: string;
    paymentObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface GetPaymentChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    paymentChangesTableFilter: string;
}

export interface PaymentChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Payment[];
    readonly pageData: PageData;
    readonly filters: GetPaymentChangesRequestPayload['filters'];
    readonly paymentChangesTableFilter: string;
    readonly sortBy: GetPaymentChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface PaymentChanges { }

export interface PaymentAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    paymentIds: string[] | number[];
    excludePayments: boolean;
}

export interface AddPaymentForUrlRequestPayload {
    Result: string;
    PaidDate: string;
    PaidAmount: string;
    InvoiceIds: string[];
    CustomerId: string;
    CompanyId: string;
    CommunicationId: string;
    ClientReference?: string;
}

export interface AddPaymentForWesternUnionRequestPayload {
    Result: string;
    PaidDate: string;
    PaidAmount: string;
    InvoiceIds: string[];
    CustomerId: string;
    CompanyId: string;
    CommunicationId: string;
    ClientReference?: string;
}

export interface AddPaymentForEziDebitRequestPayload {
    CacheKey: string | undefined;
    Result: string;
    PaidDate: string;
    PaidAmount: string;
    InvoiceIds: string[];
    CustomerId: string;
    CompanyId: string;
    CommunicationId: string;
    ClientReference: string;
    PaymentType: string;
    BankTransferConfirmed: boolean;
}

export interface AddPaymentForIntegraPayRequestPayload {
    Result: string;
    PaidDate: string;
    PaidAmount: string;
    InvoiceIds: string[];
    CustomerId: string;
    CompanyId: string;
    CommunicationId: string;
    ClientReference: string;
}

export interface AddPaymentForADVAMRequestPayload {
    Result: string;
    PaidDate: string;
    PaidAmount: string;
    InvoiceIds: string[];
    CustomerId: string;
    CompanyId: string;
    CommunicationId: string;
    ClientReference: string;
}

export interface AddPaymentForCorpayRequestPayload {
    Result: string,
    PaidDate: string,
    PaidAmount: string,
    CustomerPaidAmount: string,
    InvoiceIds: string[],
    CustomerId: string,
    CompanyId: string,
    ClientReference: string,
    CommunicationId: string,
    CacheKey: string | undefined;
    OrderId: string,
    ClientAccessToken: string,
    SelectedCurrency: string
}

export interface SendPaymentInstructionForCorpayRequestPayload {
    Email: string,
    CompanyId: string,
    PaymentId: string
}

export interface PaymentOrganisationCurrency {
    Value: string
    Rate: number
}

export interface PaymentsFetchedData {
    OnPaymentsFetchedForOrganisation: {
        Payments: Payment[];
    };
};

export interface PaymentsFetchedForOrganisationPayload {
    data: PaymentsFetchedData;
    pageSize: number;
    currentPage: number;
}