/**
 * Component for main list quick search.
 */
import { Button, Col, Row, Select } from 'antd';
import { get, isEmpty, isEqual, map } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import { DynamicObject } from '../../utils/commonInterfaces';
import { appliedFilterIndicator, customFieldIndicator } from './FilterBar';
import InputAutoCompleteWithButton from './InputAutoCompleteWithButton';

const Option = Select.Option;

interface IProps {
    readonly loading: boolean;
    readonly customFieldsFilterList: [];
    readonly applyFilters: (
        filters?: DynamicObject,
        fromFilterBar?: boolean
    ) => void;
    readonly filterOptions: DynamicObject[];
    readonly selectedUserCompany: CompanyUserRole | undefined;
}
export const qsFilterNames = {
    INVOICE: 'InvoiceNumber',
    CUSTOMER: 'Customer',
};

let lastSelectedCompanyId: string | undefined = undefined;
let lastFilterOptions: DynamicObject[] | undefined = undefined;
const QuickSearchComponent: React.FC<IProps> = ({
    loading,
    customFieldsFilterList,
    applyFilters,
    filterOptions,
    selectedUserCompany,
}: IProps) => {
    const unmountedRef = useRef<boolean>(false);
    const [formValues, setFormValues] = useState<{
        dropdown: string | undefined;
        input: '';
    }>({
        dropdown: get(filterOptions, '0.value'),
        input: '',
    });

    /**
     * Function called on initial load.
     */
    const onInitialLoad = () => {
        unmountedRef.current = false;

        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(onInitialLoad, []);

    /**
     * Common function for updating the formValues state while maintaining the other existing values.
     * @param formValuesObject
     */
    const updateFormValuesObject = (formValuesObject: DynamicObject) => {
        setFormValues({
            ...formValues,
            ...formValuesObject,
        });
    };

    /**
     * Function for populating the quick search dropdown filter.
     */
    const populateDropdownFilterComponent = () => {
        const commonFilters = map(
            filterOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
        const customFieldFilters = map(
            customFieldsFilterList,
            ({
                Type,
                Number: CFNumber,
                FieldName,
            }: CompanyCustomFieldConfigure) => (
                <Option
                    key={Type + CFNumber + FieldName}
                    value={`${customFieldIndicator}${Type}--${FieldName}`}
                >
                    {FieldName}
                </Option>
            )
        );
        return [...commonFilters, ...customFieldFilters];
    };

    /**
     * Function for populating the input component (autocomplete).
     */
    const populateInputComponent = () => {
        let queryName = '',
            filterField = '',
            sortField = '',
            responseName = '',
            labelField = '';
        switch (formValues.dropdown) {
            case qsFilterNames.INVOICE:
                queryName = 'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER';
                filterField = 'InvoiceNumber';
                sortField = 'Invoice number';
                responseName = 'GetInvoicesForCompany.Invoices';
                labelField = 'Number';
                break;
            case qsFilterNames.CUSTOMER:
                queryName = 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER';
                filterField = 'Customer';
                sortField = 'Company name';
                responseName = 'GetCustomersForCompany.Customers';
                labelField = 'DisplayName';
                break;
            default:
                if (formValues.dropdown) {
                    const selCustomFieldFilterArr = formValues.dropdown
                        .replace(customFieldIndicator, '')
                        .split('--');
                    const Type = get(selCustomFieldFilterArr, 0);
                    const FieldName = get(selCustomFieldFilterArr, 1);

                    filterField = `${customFieldIndicator}${Type}--${FieldName}`;
                    sortField = JSON.stringify({
                        Type: Type,
                        Name: FieldName,
                    });
                    labelField = customFieldIndicator;
                    if (Type === CUSTOM_FIELD_TYPES.CUSTOMER) {
                        queryName = 'GET_CUSTOMER_CUSTOM_FIELD_VALUES';
                        responseName = 'GetCustomerCustomFieldValues';
                    } else if (Type === CUSTOM_FIELD_TYPES.INVOICE) {
                        queryName = 'GET_INVOICE_CUSTOM_FIELD_VALUES';
                        responseName = 'GetInvoiceCustomFieldValues';
                    } else if (Type === CUSTOM_FIELD_TYPES.CREDIT) {
                        queryName = 'GET_CREDIT_CUSTOM_FIELD_VALUES';
                        responseName = 'GetCreditCustomFieldValues';
                    }
                }

                break;
        }

        return (
            <InputAutoCompleteWithButton
                updateField={(value: string) => {
                    updateFormValuesObject({
                        input: value,
                    });
                }}
                stateValue={formValues.input}
                queryName={queryName}
                filterField={filterField}
                queryFilterName={filterField}
                sortField={sortField}
                responseName={responseName}
                labelField={labelField}
                hasNoOkButton
                loading={loading}
                readOnly={loading}
                onPressEnter={submitForm}
                hasNoContainerRef
            />
        );
    };

    /**
     * Function called when clicking on the search button.
     */
    const submitForm = () => {
        const { dropdown: fieldName, input: inputValue } = formValues;
        if (fieldName) {
            applyFilters(
                {
                    [fieldName]: inputValue,
                    [`${fieldName}${appliedFilterIndicator}`]: inputValue,
                },
                true
            );
        }
    };

    /**
     * Function called for resetting the input/dropdown to default values.
     */
    const resetFormFields = () => {
        if (!loading) {
            const objToUpdate: DynamicObject = {
                input: '',
            };
            const currentSelectedCompanyId = get(
                selectedUserCompany,
                'Company.CompanyId'
            );
            if (
                lastSelectedCompanyId !== currentSelectedCompanyId ||
                !isEqual(lastFilterOptions, filterOptions)
            ) {
                if (lastSelectedCompanyId !== currentSelectedCompanyId)
                    lastSelectedCompanyId = currentSelectedCompanyId;
                if (!isEqual(lastFilterOptions, filterOptions))
                    lastFilterOptions = filterOptions;
                objToUpdate.dropdown = get(filterOptions, '0.value');
            }
            updateFormValuesObject(objToUpdate);
        }
    };

    useEffect(resetFormFields, [loading, selectedUserCompany]);

    /**
     * Function called when dropdown filter value changes.
     */
    const onChangeDropdownFilter = (value: string) => {
        updateFormValuesObject({
            dropdown: value,
            input: '',
        });
    };

    const submitDisabled =
        isEmpty(formValues.dropdown) || isEmpty(formValues.input);

    return (
        <div className="quick-search-comp">
            <Row type="flex" align="middle" gutter={10}>
                <Col className="qs-dd-cont">
                    <Select
                        className="w-100"
                        value={formValues.dropdown}
                        loading={loading}
                        disabled={loading}
                        onChange={onChangeDropdownFilter}
                    >
                        {populateDropdownFilterComponent()}
                    </Select>
                </Col>
                <Col className="fx-1">{populateInputComponent()}</Col>
                <Col>
                    <Button
                        icon="search"
                        // shape="circle"
                        type="primary"
                        onClick={submitForm}
                        loading={loading}
                        disabled={submitDisabled}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default memo(QuickSearchComponent);
