/**
 * HOC component for formatting numbers. Currently used for formatting currency values.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_LOCALE } from '../../config/config';
import { ApplicationState } from '../../store';
import { getLocale } from '../../store/common/sagas';
import { AppCommonState } from '../../store/common/types';

export const withNumberFormatHandler = (WrappedComponent: any) => {
    const NumberFormatHandler: React.FC<any> = (props: any) => {
        const locale = useSelector(getLocale) || DEFAULT_LOCALE;
        const appState: AppCommonState = useSelector(
            (state: ApplicationState) => state.app
        );
        const formatCurrency = (
            amount: number = 0,
            cusCurrencyCode?: string,
            cusLocale?: string
        ) => {
            const localeUsed = cusLocale || locale;
            const currencyCodeUsed = cusCurrencyCode || appState.currencyCode;
            return Intl.NumberFormat(localeUsed, {
                style: 'currency',
                currency: currencyCodeUsed,
            }).format(amount);
        };

        const formatToParts = (amount: number) => {
            return Intl.NumberFormat(locale, {
                style: 'currency',
                currency: appState.currencyCode,
            }).formatToParts(amount);
        };

        const formatNumber = (
            value: number,
            decimalScale: number = 0,
            cusLocale?: string
        ) => {
            const localeUsed = cusLocale || locale;
            return Intl.NumberFormat(localeUsed, {
                minimumFractionDigits: decimalScale,
                maximumFractionDigits: decimalScale,
            }).format(value);
        };

        return (
            <WrappedComponent
                {...props}
                formatCurrency={formatCurrency}
                formatNumber={formatNumber}
                formatToParts={formatToParts}
            />
        );
    };

    return NumberFormatHandler;
};
