import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { BANK_FILE_DESCRIPTORS_PAGE, DETAILS_TAB } from '../../config/tableAndPageConstants';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getBankFileDescriptorDataErrorAction,
    getBankFileDescriptorDataSuccessAction,
    getBankFileDescriptorsErrorAction,
    getBankFileDescriptorsRequestAction,
    getBankFileDescriptorsSuccessAction,
    setBankFileDescriptorSelectedIdSuccessAction
} from './actions';
import { BankFileDescriptor, BankFileDescriptorsActionTypes } from './types';

export const getBankFileDescriptorData = (state: ApplicationState) =>
    state.bankFileDescriptors.activeData;

export const getBankFileDescriptorSelectedId = (state: ApplicationState) =>
    state.bankFileDescriptors.activeData.selectedId;

let refetchCount = 0;

/**
 * Function that calls the API for fetching the bank file descriptor list.
 * @param param0
 */
function* handleGetBankFileDescriptorsRequest({ payload }: any) {
    const errorMessage = 'Error fetching bank file descriptors list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_BANK_FILE_DESCRIPTORS_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * BANK_FILE_DESCRIPTORS_PAGE.pageSize
            })
        );

        const { CustomerBankDescriptors } = get(res.data, 'GetCustomerBankDescriptorsForCompany');
        
        const responsePayload = {
            data: CustomerBankDescriptors ? CustomerBankDescriptors.map((bd: DynamicObject) => {
                return {
                    Id: bd.Customer.Id,
                    Customer: bd.Customer,
                    Descriptors: bd.BankDescriptors
                } as BankFileDescriptor
            }) : [],
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(CustomerBankDescriptors.length < pageSize) &&
                    !(pageSize < BANK_FILE_DESCRIPTORS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getBankFileDescriptorsSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getBankFileDescriptorsRequestAction(payload));
        } else {
            yield put(getBankFileDescriptorsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function calling the API for fetching the bank file descriptor data based on the given id.
 * @param param0
 */
function* handleGetBankFileDescriptorDataRequest({
    payload: { customerId },
}: any) {
    const errorMessage =
        'Error fetching bank file descriptor details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMER_BANK_FILE_DESCRIPTOR_DETAILS, {
                CustomerId: customerId
            })
        );

        const result = get(res.data, 'GetCustomerBankDescriptorDetails');

        if (result) {
            const responsePayload = {
                record: result,
            };

            yield put(getBankFileDescriptorDataSuccessAction(responsePayload));
        } else {
            yield put(getBankFileDescriptorDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getBankFileDescriptorDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected bank file descriptor id for reference.
 * @param param0
 */
function* handleSetBankFileDescriptorSelectedIdRequest({ payload }: any) {
    const { customerId, callback } = payload;
    yield put(setBankFileDescriptorSelectedIdSuccessAction(customerId));
    if (callback) callback();
}

/**
 * Function for creating the customer bank file descriptors.
 * @param param0
 */
function* handleCreateOrEditBankFileDescriptorsRequest({ payload: sagaPayload }: any) {
    const { BankFileDescriptors, callback } = sagaPayload;

    const payload = { BankFileDescriptors };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/payment/create-or-edit-bank-file-descriptor',
            {
                body: payload
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleDeleteBankFileDescriptorRequest({ payload: sagaPayload }: any) {
    const { filter, customerIds, excludeCustomerIds, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);
    const payload = {
        BankFileDescriptorManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            ExcludeCustomerIds: excludeCustomerIds,
        }
    };

    try {
        const apiResponse: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/payment/delete-bank-file-descriptor',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: get(apiResponse, 'Messages')
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetBankFileDescriptorsRequest() {
    yield takeLatest(
        BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_REQUEST,
        handleGetBankFileDescriptorsRequest
    );
}

function* watchGetBankFileDescriptorDataRequest() {
    yield takeLatest(
        BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_REQUEST,
        handleGetBankFileDescriptorDataRequest
    );
}

function* watchSetBankFileDescriptorSelectedIdRequest() {
    yield takeLatest(
        BankFileDescriptorsActionTypes.SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_REQUEST,
        handleSetBankFileDescriptorSelectedIdRequest
    );
}

function* watchCreateOrEditBankFileDescriptorsRequest() {
    yield takeLatest(
        BankFileDescriptorsActionTypes.CREATE_OR_EDIT_BANK_FILE_DESCRIPTORS_REQUEST,
        handleCreateOrEditBankFileDescriptorsRequest
    );
}

function* watchDeleteBankFileDescriptorRequest() {
    yield takeLatest(
        BankFileDescriptorsActionTypes.DELETE_BANK_FILE_DESCRIPTOR_REQUEST,
        handleDeleteBankFileDescriptorRequest
    );
}

function* bankFileDescriptorsSaga() {
    yield all([
        fork(watchGetBankFileDescriptorsRequest),
        fork(watchGetBankFileDescriptorDataRequest),
        fork(watchSetBankFileDescriptorSelectedIdRequest),
        fork(watchCreateOrEditBankFileDescriptorsRequest),
        fork(watchDeleteBankFileDescriptorRequest)
    ]);
}

export default bankFileDescriptorsSaga;