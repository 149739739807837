/**
 * Component for the color picker used in company branding page.
 */

import React, { Component } from 'react';
// import { ChromePicker, SketchPicker } from 'react-color';
import ChromePicker from 'react-color/lib/Chrome';
import SketchPicker from 'react-color/lib/Sketch';
import { Popover } from 'antd';

const noop = () => {};

const pickers = {
    chrome: ChromePicker,
    sketch: SketchPicker,
};

interface IProps {
    color: any;
    onChange: (colorHex: any, color: any) => void;
    onChangeComplete: (color: any) => void;
    small: any;
    type: 'chrome' | 'sketch';
    position: any;
    presetColors: string[];
    disabled: boolean;
}
interface IState {
    displayColorPicker: boolean;
    color: any;
}
export default class ColorPicker extends Component<IProps, IState> {
    static defaultProps = {
        onChange: noop,
        onChangeComplete: noop,
        position: 'bottom',
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: props.color,
        };
    }

    /**
     * React life-cycle function triggered when properties/state from this class component
     * has been changed/updated.
     * @param prevProps
     */
    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.color !== this.props.color) {
            this.setState({ color: this.props.color });
        }

        if (
            prevState.displayColorPicker !== this.state.displayColorPicker &&
            this.state.displayColorPicker === false
        ) {
            this.onPickerClose();
        }
    }

    /**
     * Function triggered when the color is clicked (triggers the visibility of the picker).
     */
    handleClick = () => {
        if (this.props.disabled) return;

        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    /**
     * Function triggered when closing the color picker.
     */
    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    /**
     * Function triggered when a color is selected from the picker.
     */
    handleChange = (color: any) => {
        this.setState({ color: color.hex });
        this.props.onChange(color.hex, color);
    };

    /**
     * Function triggered when the user has finished selecting a color from the picker.
     */
    handleChangeComplete = (color: any) => {
        this.setState({ color: color.hex });
    };

    onPickerClose = () => {
        this.props.onChangeComplete(this.state.color);
    };

    render() {
        const { type, position } = this.props;
        const { displayColorPicker } = this.state;

        const Picker = pickers[type];

        interface IStyles {
            color: {};
            swatch: {};
        }
        const styles: IStyles = {
            color: {
                width: '70px',
                height: '30px',
                borderRadius: '2px',
                background: this.state.color,
            },
            swatch: {
                background: '#fff',
                borderRadius: '2px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: this.props.disabled ? 'not-allowed' : 'pointer',
            },
        };

        const swatch = (
            <Popover
                visible={displayColorPicker}
                placement={position}
                trigger="click"
                content={
                    <Picker
                        {...this.props}
                        color={this.state.color}
                        // onChange={this.handleChange}
                        onChangeComplete={this.handleChangeComplete}
                        disableAlpha
                    />
                }
                onVisibleChange={this.handleClick}
            >
                <div style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
            </Popover>
        );

        return <div>{swatch}</div>;
    }
}
