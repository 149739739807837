/**
 * Component for populating each item in companies management page for oganisation.
 */

import { Checkbox, Col, Row } from 'antd';
import { filter, get, includes, isEqual } from 'lodash';
import React, { memo } from 'react';
import { companyGlobeIconList } from '../../constants/common';
import { companyStatuses } from '../../constants/organisationsSortAndFilters';
import { getAssetsPath } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import ImageWithLoading from '../common/ImageWithLoading';

const assetsPath = getAssetsPath();
const imgPlaceholder = `${assetsPath}/imagePlaceholder.png`;

interface IProps {
    readonly item: DynamicObject;
    readonly hideCheckbox?: boolean;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly regionKeyConfig?: DynamicObject[];
}
const CompanyItemComponent: React.FC<IProps> = ({
    item,
    hideCheckbox,
    style,
    onRowClick,
    selectedRowKeys,
    formatDateUTCToLocal,
    regionKeyConfig,
}: IProps) => {
    const selectedId = undefined;

    const { CompanyId: Id, CreatedDateTime } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    const rowDisabled = get(item, 'IsClosed');

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (rowDisabled) return;
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function for populating the company status.
     */
    const populateCompanyStatus = () => {
        const isClosed = get(item, 'IsClosed');
        return (
            <b className={isClosed ? 'red' : ''}>
                {isClosed ? companyStatuses.CLOSED : companyStatuses.OPEN}
            </b>
        );
    };

    const populateCompanyUserCount = () => {
        const userCount = get(item, 'TotalUser', 0);
        return `${userCount} user${userCount === 1 ? '' : 's'}`;
    };
    // const rowDisabled = false;
    const logoPath = get(item, 'LogoPath');
    const companyRegion = get(item, 'Region');
    let selectedGlobeIcon = get(
        filter(regionKeyConfig, ['Region', companyRegion]),
        '0.GlobeLocation'
    );
    if (!includes(companyGlobeIconList, selectedGlobeIcon))
        selectedGlobeIcon = 'asia';

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            } ${rowDisabled ? 'click-disabled' : ''} ${
                rowDisabled ? 'disabled' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleCheckboxClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 5,
                    paddingBottom: 5,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    {/* Commented xl sizes are when icon is added */}
                    {!hideCheckbox && (
                        <Col
                            // xl={3}
                            xl={1}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                        >
                            <Row className="fx-center-xl">
                                <Col
                                    // xl={12}
                                    xl={24}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                >
                                    <div className="ta-center center-flex-all">
                                        <Checkbox
                                            checked={includes(
                                                selectedRowKeys,
                                                Id
                                            )}
                                            onClick={handleCheckboxClick}
                                            disabled={rowDisabled}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col
                        // xl={21}
                        xl={!hideCheckbox ? 23 : 24}
                        lg={!hideCheckbox ? 22 : 24}
                        md={!hideCheckbox ? 22 : 24}
                        sm={!hideCheckbox ? 22 : 24}
                        xs={!hideCheckbox ? 22 : 24}
                        className="h-100"
                    >
                        <Row className="flex-i h-100">
                            <Col
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="center-flex-all"
                            >
                                {includes(logoPath, 'https://') ||
                                includes(logoPath, 'http://') ? (
                                    <ImageWithLoading
                                        src={logoPath || imgPlaceholder}
                                        className="organisation-company-logo"
                                    />
                                ) : (
                                    <img
                                        className="organisation-company-logo"
                                        alt=""
                                        src={imgPlaceholder}
                                    />
                                )}
                            </Col>
                            <Col
                                xl={6}
                                lg={6}
                                md={6}
                                sm={5}
                                xs={5}
                                className="organisation-company-item-col pl-30"
                            >
                                <Row>
                                    <Col>
                                        <b>{get(item, 'Name')}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{companyRegion}</Col>
                                </Row>
                            </Col>
                            <Col
                                xl={5}
                                lg={5}
                                md={5}
                                sm={5}
                                xs={5}
                                className="organisation-company-item-col"
                            >
                                <Row>
                                    <Col>
                                        <b>{populateCompanyUserCount()}</b>
                                    </Col>
                                    <Col>
                                        {get(item, 'LanguagePackage.Language')}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xl={5}
                                lg={5}
                                md={5}
                                sm={5}
                                xs={5}
                                className="organisation-company-item-col"
                            >
                                <Row>
                                    <Col className="center-flex">
                                        <FontAwesome
                                            className="mr-5"
                                            icon={[
                                                'fas',
                                                `globe-${selectedGlobeIcon}`,
                                            ]}
                                            transform="down-1"
                                        />
                                        <b>{companyRegion}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xl={5}
                                lg={5}
                                md={5}
                                sm={3}
                                xs={3}
                                className="ta-right organisation-company-item-col"
                            >
                                <Row>
                                    <Col>{populateCompanyStatus()}</Col>
                                    <Col>
                                        Created{' '}
                                        {CreatedDateTime &&
                                            formatDateUTCToLocal(
                                                CreatedDateTime
                                            )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* End of invoice item */}
                </Row>
            </Col>
        </Row>
    );
};

// export default CompanyItemComponent;

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(withDateFormatHandler(CompanyItemComponent), arePropsEqual);
