/**
 * File responsible for all the UI and actions for Settings>Company page - General tab item - `/app/settings/company`.
 */

import { Card, Col, Divider, Row, Select, Typography } from 'antd';
import { History as IHistory } from 'history';
import { get, isEqual, map } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import SelectReadonly from '../../../../components/FormComponents/SelectReadonly';
import { DEFAULT_LOCALE } from '../../../../config/config';
import {
    companyIndustries,
    timezoneOptions,
} from '../../../../constants/companyGeneralOptions';
import { saveUserCompanyRequestAction } from '../../../../store/companies/actions';
import { Company } from '../../../../store/companies/types';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
}
const CompanyGeneralPage: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            selectedUserCompany,
            handleModalSaveResponse,
            history,
            setFormHasChanges,
        }: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();

        const [generalFormState, setGeneralFormState] = useState<{}>({});

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            const formState: DynamicObject = { ...generalFormState };

            const constructedState = {
                ...selectedUserCompany,
                ...formState,
            };

            return !isEqual(selectedUserCompany, constructedState);
        };

        /**
         * Function responsible for syncing the data used inside this form from the redux state
         * where all the company data are saved.
         */
        const syncStateFromRedux = () => {
            const { Timezone, Industry } = selectedUserCompany;
            const formState = {
                Timezone,
                Industry,
            };

            setGeneralFormState(formState);
        };

        useEffect(syncStateFromRedux, [selectedUserCompany]);

        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            const formState: DynamicObject = { ...generalFormState };

            formState.DetailType = 'General';
            dispatch(
                saveUserCompanyRequestAction(formState, handleModalSaveResponse)
            );
        };

        /**
         * Function called when cancel is clicked.
         * Resets all the changes and set the data based on the one saved in redux (the original saved data).
         */
        const handleCancel = () => {
            syncStateFromRedux();
        };

        /**
         * Function responsible for saving the field value upon chaning.
         * @param name - form field / state field name
         */
        const updateFormState = (name: string) => {
            return (value: string) => {
                setGeneralFormState({
                    ...generalFormState,
                    [name]: value,
                });
                setFormHasChanges();
            };
        };

        /**
         * Function that populates the timezone select options from a constants file
         */
        const populateTimezoneOptions = () => {
            return map(timezoneOptions, (tz) => (
                <Option key={tz} value={tz}>
                    {tz}
                </Option>
            ));
        };

        /**
         * Function that populates the company industry select options from a constants file
         */
        const populateCompanyIndustryOptions = () => {
            return map(companyIndustries, (ci) => (
                <Option key={ci} value={ci}>
                    {ci}
                </Option>
            ));
        };

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>General</Title>
                        </Col>
                    </Row>
                    <br />
                    <Row key="timezone-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        <Title level={4}>Timezone</Title>
                                    </Col>
                                </Row>
                                <Row className="mb-10">
                                    <Col span={24}>
                                        Select the primary timezone of your
                                        company. All invoice date information is
                                        based on the selected timezone.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <SelectReadonly
                                            className="timezone-select"
                                            showSearch
                                            value={
                                                get(
                                                    generalFormState,
                                                    'Timezone'
                                                ) || undefined
                                            }
                                            onSelect={updateFormState(
                                                'Timezone'
                                            )}
                                            placeholder="Select timezone"
                                            readOnly={disabled}
                                        >
                                            {populateTimezoneOptions()}
                                        </SelectReadonly>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Divider className="divider-thick" />
                    <Row key="company-industry-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        <Title level={4}>
                                            Company Industry
                                        </Title>
                                    </Col>
                                </Row>
                                <Row className="mb-10">
                                    <Col span={24}>
                                        Industry:&emsp;
                                        <SelectReadonly
                                            className="industry-select"
                                            value={
                                                get(
                                                    generalFormState,
                                                    'Industry'
                                                ) || undefined
                                            }
                                            onSelect={updateFormState(
                                                'Industry'
                                            )}
                                            placeholder="Select industry"
                                            readOnly={disabled}
                                        >
                                            {populateCompanyIndustryOptions()}
                                        </SelectReadonly>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Divider className="divider-thick" />
                    <Row key="company-region-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        <Title level={4}>
                                            Regional Settings
                                        </Title>
                                    </Col>
                                </Row>
                                <Row className="mb-10">
                                    <Col span={24}>
                                        <Row type="flex">
                                            <Col className="ta-right pr-8">
                                                <div className="mb-5">
                                                    Country:
                                                </div>
                                                <div className="mb-5">
                                                    Hosting location:
                                                </div>
                                                <div>
                                                    Region and language options:
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-5">
                                                    {get(
                                                        selectedUserCompany,
                                                        'Country'
                                                    ) || <div>&nbsp;</div>}
                                                </div>
                                                <div className="mb-5">
                                                    {get(
                                                        selectedUserCompany,
                                                        'Region'
                                                    ) || <div>&nbsp;</div>}
                                                </div>
                                                <div>
                                                    {get(
                                                        selectedUserCompany,
                                                        'LanguagePackage.Language',
                                                        DEFAULT_LOCALE
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        );
    }
);

export default CompanyGeneralPage;
