/**
 * Index component considered to be the root file of the app.
 */

import { Spin } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import React, { lazy, Suspense, useLayoutEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import App from './components/App/App';
import PopupCheckerComponent from './components/common/PopupCheckerComponent';
import { GOOGLE_ANALYTICS_TRACKING } from './config/config';
import configureStore from './configureStore';
import './fontawesomeIcons';
import './less/main.less';
import PaymentPlanPublic from './pages/public/PaymentPlanPublic';
import ProtectedRoute from './ProtectedRoute';
import * as serviceWorker from './serviceWorker';
import { getCurrentBuildEnvironment } from './utils/commonFunctions';
import { Quill } from 'react-quill';
import { CustomBlock } from './components/common/CustomBlock';

Quill.register(CustomBlock, true);

const AppWithAuth = lazy(() => import('./components/App/AppWithAuth'));
const UsersInviteValidation = lazy(
    () => import('./pages/public/UsersInviteValidation')
);
const NotificationDetails = lazy(
    () => import('./pages/public/NotificationDetails')
);
const CustomerResponsePage = lazy(
    () => import('./pages/public/CustomerResponsePage')
);

interface IProps {}
const environment = getCurrentBuildEnvironment();
const usedGaId = get(
    GOOGLE_ANALYTICS_TRACKING,
    environment,
    GOOGLE_ANALYTICS_TRACKING.dev
);

const history = createBrowserHistory();
const initialState = window.initialReduxState;
export const store = configureStore(history, initialState);

const Root: React.FC<IProps> = () => {
    const setupGoogleAnalytics = () => {
        const gaScript = document.createElement('script');
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${usedGaId}`;
        gaScript.async = true;
        document.body.appendChild(gaScript);
        gaScript.onload = () => {
            injectGA();
        };

        history.listen((location: { pathname: string }) => {
            if (typeof window == 'undefined') {
                return;
            }
            window.gtag('event', 'page_view', {
                page_location: `${window.location.origin}${location.pathname}`,
                page_path: location.pathname,
            });
        });
    };

    const injectGA = () => {
        if (typeof window == 'undefined') {
            return;
        }
        window.gtag('js', new Date());
        window.gtag('config', usedGaId);
    };

    useLayoutEffect(setupGoogleAnalytics, []);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense
                    fallback={
                        <Spin
                            className="w-100 center-flex-all ta-center"
                            style={{ height: window.innerHeight }}
                        />
                    }
                >
                    <Switch>
                        <Route path="/" exact component={AppWithAuth} />
                        <Route
                            path="/company-invite/:inviteToken"
                            exact
                            component={UsersInviteValidation}
                        />
                        <Route
                            path="/notification-details/:notificationId"
                            exact
                            component={NotificationDetails}
                        />
                        <Route
                            path="/payment-plan-details/:notificationId"
                            exact
                            component={PaymentPlanPublic}
                        />
                        <Route
                            path="/pop-up-checker"
                            exact
                            component={PopupCheckerComponent}
                        />
                        <Route
                            // path="/customer-response/:ticketResponseId"
                            path="/ResourceReply/:conversationLineId"
                            exact
                            component={CustomerResponsePage}
                        />
                        <ProtectedRoute path="/app" component={App} />
                        <Redirect to="/" />
                    </Switch>
                </Suspense>
            </ConnectedRouter>
        </Provider>
    );
};

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
