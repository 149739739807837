import { Col, Form, Row, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { getContentTemplateAction, getManualCommunicationTemplatesAction } from '../../store/contents/actions';
import { ContentsState, SaveContentTemplatePayload, TemplateType } from '../../store/contents/types';
import { getContentSelection, getManualTemplates } from '../../store/contents/sagas';
import { find, forEach, get, map } from 'lodash';
import FormItem from 'antd/lib/form/FormItem';
import ContentsManagementForm from './ContentsManagementForm';
import { TargetContentConstant, targetContentMap } from '../../constants/contents';

const { Option } = Select;

interface IProps {
    form: WrappedFormUtils;
    valueChanges: any;
    setValueChanges: (valueChanges: any) => void;
}

const formFieldNames = {
    ManualContentName: 'ManualContentName',
    ManualTargetContent: 'ManualTargetContent'
};

const ManualContentsManagementPage: React.FC<IProps> = ({ form, ...props }) => {
    const dispatch = useDispatch();
    const { getFieldDecorator, setFieldsValue } = form;
    const {
        loading: contentLoading
    }: ContentsState['activeData'] = useSelector((state: ApplicationState) => state.contents.activeData);
    const { errorMessages, loading: templatesLoading }: ContentsState['manualContents'] = useSelector((state: ApplicationState) => state.contents.manualContents);
    const manualTemplates: ContentsState['manualContents']['templates'] = useSelector(getManualTemplates);
    const contentSelection: ContentsState['activeData']['selection'] = useSelector(getContentSelection);
    const selectedManualContentName = get(contentSelection, 'ManualContentName');
    const selectedTargetContent = get(contentSelection, 'ManualTargetContent');
    const selectedManualContent = find(manualTemplates, t => t.TemplateName === selectedManualContentName);
    const availableTargetContents = useMemo(() => {
        let contents: TargetContentConstant[] | undefined;
        if (selectedManualContent) {
            contents = [];
            forEach(selectedManualContent.Files, f => {
                Object.values(targetContentMap).forEach(targetInfo => {
                    if (targetInfo.fileType === f.Type && contents) {
                        contents.push(targetInfo);
                    }
                });
            })
        }
        return contents;
    }, [selectedManualContent]);
    const formFulfilled = !!selectedManualContentName;
    const contentSelectionFields = [
        formFieldNames.ManualContentName,
        formFieldNames.ManualTargetContent
    ];

    const generateContentsFilter = (clearData: () => void) => {
        return <Row gutter={[30, 10]} type="flex">
            <Col style={{ minWidth: 250, width: '25%' }}>
                <FormItem label="Template name">
                    {getFieldDecorator(formFieldNames.ManualContentName, {
                        initialValue: selectedManualContentName,
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            loading={templatesLoading}
                            disabled={templatesLoading || contentLoading}
                            placeholder={'Choose a template'}
                            onChange={(_) => setFieldsValue({
                                [formFieldNames.ManualTargetContent]: undefined
                            })}
                        >
                            {map(
                                manualTemplates,
                                ({ TemplateName }) => (
                                    <Option key={TemplateName} value={TemplateName}>
                                        {TemplateName}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
            <Col style={{ minWidth: 250, width: '25%' }}>
                <FormItem label="Target content">
                    {getFieldDecorator(formFieldNames.ManualTargetContent, {
                        initialValue: selectedTargetContent,
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            loading={templatesLoading}
                            disabled={!availableTargetContents || templatesLoading || contentLoading}
                            placeholder={'Choose a target content'}
                            onChange={(_) => clearData()}
                        >
                            {availableTargetContents && map(availableTargetContents,
                                ({ label, value }) => (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
        </Row>;
    };

    const generatePayload = (currentTemplate: string) => {
        const payload: SaveContentTemplatePayload & { [key: string]: any } = {
            Type: TemplateType.ManualContent,
            TemplateContentName: selectedManualContentName
        };
        if (selectedTargetContent) {
            payload[selectedTargetContent] = currentTemplate;
        }
        return payload;
    }

    const fetchInitialData = () => {
        if (!manualTemplates) {
            dispatch(getManualCommunicationTemplatesAction())
        }
    }

    useEffect(fetchInitialData, [manualTemplates]);

    const fetchManualContentTemplate = () => {
        if (selectedTargetContent && selectedManualContent && availableTargetContents) {
            const FolderLocation = get(selectedManualContent, 'FolderLocation');
            const targetContentField = find(availableTargetContents, c => c.value === selectedTargetContent);
            const fileType = targetContentField && get(targetContentField, 'fileType');
            const file = find(get(selectedManualContent, 'Files'), f => f.Type === fileType);
            dispatch(getContentTemplateAction({
                TemplateKey: `${FolderLocation}/${get(file, 'Name')}`
            }));
        }
    };

    useEffect(fetchManualContentTemplate, [selectedTargetContent, selectedManualContent, availableTargetContents]);

    return <ContentsManagementForm
        form={form}
        formTitle="Manual contents"
        contentSelectionFields={contentSelectionFields}
        formFulfilled={formFulfilled}
        generateContentsFilter={generateContentsFilter}
        generatePayload={generatePayload}
        refreshCurrentContent={fetchManualContentTemplate}
        dataErrors={errorMessages}
        {...props}
    />
};

const ManualContentsManagementPageForm: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const ContentsManagementPageForm = Form.create({
            name: 'manual-contents-management-page-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(ManualContentsManagementPage);

        return withRouter(ContentsManagementPageForm);
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default ManualContentsManagementPageForm;
