/**
 * File for the `Customers average payment timeframe` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    isNumber,
    capitalize
} from 'lodash';
import moment from 'moment-timezone';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { populatePayloadForOrganisationRegionalWidgets, widgetDisplayTypeValues, tableNumberFormatter } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardCustomersSettlementRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    getDateFilterValues,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    invoiceFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    displayRowCountOptions,
    showCustomFieldsIndicator,
} from './CustomersSettlementWidgetFields';
import {
    dateSelectOptions, customersSettlementSortByOptions
} from '../../constants/invoicesSortAndFilters';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly functionRefObj?: any;
}

const CustomersSettlementWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    isOrgView,
    organisationCurrenciesAll,
    formatNumber,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#F44336',
        '#9C27B0',
        '#FFEB3B',
        '#795548',
        '#8BC34A',
        '#263238',
    ];

    const dispatch = useDispatch();
    let widgetRef = useRef<any>(null);

    const [widgetState, setWidgetState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        tickWidth: number;
        chartMarginLeft: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        tickWidth: 30,
        chartMarginLeft: 20,
    });

    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;
    }


    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateTableStateObject = (widgetStateObject: {}) => {
        setWidgetState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    /**
     * Function for getting the length of text.
     * @param text
     */
    const measureText = (text: string) => {
        return text ? text.length : 0;
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customFieldFilters: DynamicObject = {};
        const customFieldsDisplayList: any = [];
        let dayFilters: any = {
            NumberOfDays: 0,
            AmountOperator: ''
        };
        let Customer: string = '';
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let settlementDateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let SettlementDateMin: any = undefined;
        let SettlementDateMax: any = undefined;

        settlementDateFilters.value = get(widgetDetails, "InvoiceField---SettlementDate--DateType");

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator) && !includes(wdKey, showCustomFieldsIndicator)) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
            }

            if (includes(wdKey, invoiceFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "NumberOfDays--DaysOperator")) {
                    dayFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "NumberOfDays--DaysValue")) {
                    dayFilters.NumberOfDays = wdValue;
                }
                else if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "SettlementDate")) {
                    if (settlementDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            settlementDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            settlementDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (settlementDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            settlementDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            settlementDateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(settlementDateFilters, true);
                    SettlementDateMin = minDate;
                    SettlementDateMax = maxDate;
                }
            }
        });

        const customerCustomFieldsDataIndexes: string[] = [];
        forEach(customFieldsDisplayList, (cfs: DynamicObject) => {
            const Type = get(cfs, 'type');
            const FieldName = get(cfs, 'FieldIndex');

            if (Type === CUSTOM_FIELD_TYPES.CUSTOMER) {
                customerCustomFieldsDataIndexes.push(FieldName);
            }
        });

        let payload: DynamicObject = {
            filters: {
                Customer,
                ...dayFilters,
                ...customFieldFilters,
                SettlementDateMin: !isEmpty(SettlementDateMin) ? SettlementDateMin : undefined,
                SettlementDateMax: !isEmpty(SettlementDateMax) ? SettlementDateMax : undefined,
                CustomerCountry,
                CustomerState,
                SortField: get(widgetDetails, 'SortField') ||
                    get(customersSettlementSortByOptions[1], 'value'),
                Ascending: get(widgetDetails, 'SortFieldAsc') ||
                    false,
            },
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies
            });
        }

        dispatch(
            getDashboardCustomersSettlementRequestAction(
                payload,
                isOrgView,
                (customersSettlement: any) => {
                    if (unmountedRef.current) return;

                    const dataSource = map(
                        customersSettlement,
                        (cus: any, index: number) => {

                            return {
                                ...cus,
                                key: index,
                                DisplayName: get(cus, 'Customer.DisplayName', ''),
                                'Settlement average days': get(cus, 'SettlementAverageDays', '')
                            };
                        }
                    );

                    let longestWord = '';
                    map(dataSource, (ds) => {
                        let customer = ds.DisplayName

                        if (longestWord.length < customer.length) {
                            longestWord = customer;
                        }
                    })

                    const longestWordWidth = measureText(longestWord) * 9;
                    const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                    const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                    updateTableStateObject({
                        dataSource: dataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                        tickWidth,
                        chartMarginLeft,
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatNumber(value);
        } else {
            return value;
        }
    };

    const customContent = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-widget-tooltip">
                    <p className="label">{payload[0].payload.DisplayName}</p>
                    {!isUndefined(payload[0].payload.SettlementAverageDays) && <p className="label">Settlement average days : {`${formatNumber(payload[0].payload.SettlementAverageDays)}`}</p>}
                </div>
            );
        }

        return null;
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            DisplayName,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {DisplayName}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    let blueValueKey = 'Settlement average days';

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');
        const {
            loading: stateLoading,
            dataSource: stateDataSource,
            tickWidth: stateTickWidth,
            chartMarginLeft: stateChartMarginLeft,
        } = widgetState;

        if (displayView === widgetDisplayTypeValues.TABLE) {
            const columns = [
                {
                    title: capitalize(customerLabel),
                    dataIndex: 'DisplayName',
                },
                {
                    title: 'Settlement average days',
                    dataIndex: 'SettlementAverageDays',
                },

            ];

            return (
                <Table
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableNumberFormatter(stateDataSource, formatNumber)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="key"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={stateDataSource}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="SettlementAverageDays"
                                nameKey="DisplayName"
                                isAnimationActive={false}
                            >
                                {map(stateDataSource, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} content={customContent} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <div className="tickets-widget-graph-container">
                        <ResponsiveContainer>
                            <BarChart
                                data={stateDataSource}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    left: stateChartMarginLeft,
                                    bottom: 15,
                                }}
                                layout="vertical"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis
                                    type="category"
                                    dataKey="DisplayName"
                                    tick={{
                                        fontSize: 14,
                                        width: stateTickWidth,
                                    }}
                                />
                                <XAxis
                                    type="number"
                                    allowDecimals={false}
                                    width={stateTickWidth}
                                    orientation="bottom"
                                />
                                <Tooltip formatter={tooltipFormatter} />
                                {preview && <Legend />}
                                <Bar dataKey={blueValueKey} fill="#0088fe" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div className="tickets-sticky-x-axis" />
                    </div>
                </Spin>
            );
        }
    };

    return (
        <div className="tasks-widget-container h-100" ref={widgetRef}>
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(CustomersSettlementWidget);
