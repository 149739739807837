/**
 * File responsible for the content when clicking `create descriptor` in bank file descriptor management pages.
 */

import { Button, Col, Form, Icon, Input, Modal, Row } from 'antd';
import { get, capitalize, isEmpty, map } from 'lodash';
import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import InputAutoCompleteWithButton from '../common/InputAutoCompleteWithButton';
import { DynamicObject } from '../../utils/commonInterfaces';
import { BankFileDescriptorVM, BankFileDescriptor, Descriptor, RequestCreateOrEditBankFileDescriptorPayload } from '../../store/bankFileDescriptors/types';
import { Customer } from '../../store/customers/types';
import { v4 as uuidv4 } from 'uuid';
import { createOrEditBankFileDescriptorRequestAction } from '../../store/bankFileDescriptors/actions';
import { withRouter } from 'react-router-dom';
import FontAwesome from '../common/FontAwesome';

const formFieldNames = {
    CustomerCode: 'CustomerCode',
    OldCustomerCode: 'OldCustomerCode',
    Descriptors: 'Descriptors'
};

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

const { Item: FormItem } = Form;
interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly isEditMode: boolean;
    readonly isEditViaManagementPage?: boolean;
    readonly editBankFileDescriptor?: Partial<BankFileDescriptorVM>;
    readonly existingBankFileDescriptorList?: Partial<BankFileDescriptorVM>[];
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly handleAddBankFileDescriptor?: (bankFileDescriptor: BankFileDescriptorVM) => void;
    readonly handleEditBankFileDescriptor?: (bankFileDescriptor: BankFileDescriptorVM) => void;
    readonly customer?: Customer;
}

const CreateBankFileDescriptorItemDrawerContent: React.FC<ICombinedProps> = ({
    containerRef,
    visible,
    isEditMode,
    editBankFileDescriptor,
    existingBankFileDescriptorList,
    isEditViaManagementPage,
    closePanel,
    form,
    handleAddBankFileDescriptor,
    handleEditBankFileDescriptor,
    valueChanges,
    setValueChanges,
    customer
}: ICombinedProps) => {
    const [hasError, setHasError] = useState<boolean>(true);
    const [formHasChanged, setFormHasChanged] = useState(false);
    const [customerCode, setCustomerCode] = useState<string | undefined>('');
    const [oldCustomerCode, setOldCustomerCode] = useState<string | undefined>('');
    const [customerSearch, setCustomerSearch] = useState<string>('');
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setValidationErrors,
        setFieldsValue,
        getFieldsValue
    } = form;

    const dispatch = useDispatch();

    const customerLabel = useSelector(getCustomerUILabel);

    const globalId = useMemo(()=>({id:0}),[]);
    const isCreateBtnDisabled = !formHasChanged || hasError;
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [descriptors, setDescriptors] = useState<Descriptor[]>([{ Id: `${globalId.id++}`,  Descriptor: ''}]);
    /**
     * Function called when `Cancel` button is clicked inside ? panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed or open.
     * If open, we will set the initial values for the form.
     */
    useEffect(() => {
        if (!isEditMode && visible) {
            setFieldsValue({
                [formFieldNames.CustomerCode]: undefined,
                [formFieldNames.OldCustomerCode]: undefined
            });
            setCustomerCode(undefined);
            setCustomerSearch('');
    
            setDescriptors([{ Id: `${globalId.id++}`,  Descriptor: ''}]);
        }
    }, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const descriptorStrings: string[] = getFieldValue(formFieldNames.Descriptors);
                const descriptors: Descriptor[] = descriptorStrings.filter(d => d !== null).map((item) => {
                    return {
                        Id: `${globalId.id++}`,
                        Descriptor: item
                    }
                });

                const bankFileDescriptor: BankFileDescriptorVM = {
                    Id: isEditMode && editBankFileDescriptor ? editBankFileDescriptor.Id : uuidv4(),
                    Customer: {
                        CustomerCode: (customerCode || ''),
                        DisplayName: customerSearch
                    } as Customer,
                    Descriptors: descriptors
                } as BankFileDescriptorVM;

                if (oldCustomerCode) {
                    bankFileDescriptor.OldCustomer = {
                        CustomerCode: (oldCustomerCode || '')
                    } as Customer;
                }

                if (isEditViaManagementPage) {
                    handleSaveBankFileDescriptor(bankFileDescriptor);
                }
                else
                {
                    if (isEditMode && handleEditBankFileDescriptor) {
                        handleEditBankFileDescriptor(bankFileDescriptor);
                    } else if (handleAddBankFileDescriptor) {
                        handleAddBankFileDescriptor(bankFileDescriptor);
                    }

                    handleClosePanel();
                }
            }
        });
    };

    /**
     * Function called when user submitting the form from the bank file descriptor management page.
     */
    const handleSaveBankFileDescriptor = (bankFileDescriptor: Partial<BankFileDescriptorVM>) => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const bankFileDescriptorRequest: Partial<BankFileDescriptor>[] = [
                    {
                        OldCustomer: bankFileDescriptor.OldCustomer as Customer,
                        Customer: bankFileDescriptor.Customer as Customer,
                        Descriptors: bankFileDescriptor && bankFileDescriptor.Descriptors ? bankFileDescriptor.Descriptors.map(b => b.Descriptor) : []
                    }
                ];

                const payload: RequestCreateOrEditBankFileDescriptorPayload = {
                    BankFileDescriptors: bankFileDescriptorRequest,
                    callback: createOrEditBankFileDescriptorResponseModal
                };

                dispatch(createOrEditBankFileDescriptorRequestAction(payload));
            }
        });
    };

    /**
     * Function responsible for showing the response modal after bank file descriptor created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createOrEditBankFileDescriptorResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content:
                    'Bank file descriptor edited successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to edit bank file descriptor!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    const updateCustomerCode = (value: string | undefined) => {
        const oldCode = oldCustomerCode || value;
        setFieldsValue({
            [formFieldNames.CustomerCode]: value,
            [formFieldNames.OldCustomerCode]: oldCode
        });

        setCustomerCode(value);
        setOldCustomerCode(oldCode);

        setValueChanges({
            [formFieldNames.CustomerCode]: true,
            [formFieldNames.OldCustomerCode]: true
        });
    };

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields({ suppressWarning: true }, (err: any, values: DynamicObject) => {
            if (!err) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        });
    }, [validateFields]);

    useEffect(() => {
        validateForm();
    }, [valueChanges, validateForm, visible]);

    useEffect(() => {
        if (isEditMode) {
            updateCustomerCode(get(editBankFileDescriptor, 'Customer.CustomerCode'));
            setCustomerSearch(get(editBankFileDescriptor, 'Customer.DisplayName'));

            const updatingDescriptor = get(editBankFileDescriptor, 'Descriptors', [{ Id: `${globalId.id++}`,  Descriptor: ''}]);

            if(isEditViaManagementPage) {
                const descriptors: Descriptor[] = updatingDescriptor.map((item) => {
                    return {
                        Id: `${globalId.id++}`,
                        Descriptor: item.Descriptor
                    }
                });

                setDescriptors(descriptors);
            }
            else {
                setDescriptors(updatingDescriptor);
            }
        }
    }, [isEditMode, editBankFileDescriptor]);

    // Define validation rules
    const maxLengthValidation = (_: any, value: any, callback: any) => {
        if (value && value.length > 250) {
            setHasError(true);
            callback(
                <div style={{ textAlign: 'left' }}>
                    Maximum length is 250 characters
                </div>
            );
        }
        else {
            callback();
        }
    };

    /**
     * Function called when user update the descriptor.
     */
    const isExistingBankFileDescriptor = (customerCode: string, descriptor: string) => {        
        if (existingBankFileDescriptorList) {
            let bankFileDescriptor = null;

            if (isEditMode && editBankFileDescriptor) {
                bankFileDescriptor = existingBankFileDescriptorList.find(d => d.Customer && d.Customer.CustomerCode === customerCode && d.Id !== editBankFileDescriptor.Id);
            }
            else {
                bankFileDescriptor = existingBankFileDescriptorList.find(d => d.Customer && d.Customer.CustomerCode === customerCode);
            }

            if (bankFileDescriptor && bankFileDescriptor.Descriptors) {
                const isExistingDescriptor = bankFileDescriptor.Descriptors.find(d => descriptor && d.Descriptor === descriptor);

                return isExistingDescriptor;
            }
        }

        return false;
    };

    // Custom validator function to check for duplicate values
    const validateDuplicateValues = (rule: any, value: any, callback: any) => {
        const values = getFieldsValue();
        const descriptors: string[] = values[formFieldNames.Descriptors];
        const customerCode: string = values[formFieldNames.CustomerCode];

        const valueCount = descriptors.filter(
            (fieldValue) => fieldValue && value && fieldValue.toLowerCase() === value.toLowerCase()
          ).length;

        if (valueCount > 1) {
            setHasError(true);
            callback('Duplicate value found. Please enter unique values.');
        } else if (isExistingBankFileDescriptor(customerCode, value)) {
            setHasError(true);
            callback(`The descriptor for this ${customerCode} already exists.`);
        } else {
            callback();
        }
    };

    const onDescriptorChange = (id: string, newValue: string) => {
        setFormHasChanged(true);
        setDescriptors(prevDescriptors =>
            prevDescriptors.map(item =>
              item.Id === id ? { ...item, Descriptor: newValue } : item
            )
        );
    };

    const removeField = (key: string) => {
        setFormHasChanged(true);

        setDescriptors((prevDescriptors) => {
            const updatedDescriptors = prevDescriptors.filter(d => d.Id !== key);
            if(updatedDescriptors && updatedDescriptors.length > 0) {
                return prevDescriptors.filter(d => d.Id !== key);
            }
            
            setHasError(true);
            return [{ Id: `${globalId.id++}`,  Descriptor: ''}];
        });

        // Run validation after state update
        setTimeout(() => {
            // Get all current field names related to descriptors
            const descriptorFieldNames = descriptors.map(item => `${formFieldNames.Descriptors}[${item.Id}]`);

            // Trigger validation for specific fields (forcing custom validation)
            validateFields(descriptorFieldNames, { suppressWarning: true, force: true }, (err: any, values: DynamicObject) => {
                if (!err) {
                    setHasError(false);
                } else {
                    setHasError(true);
                }
            });
        }, 0);
    };

    const addField = () => {
        setFormHasChanged(true);
        setHasError(true);

        setDescriptors([...descriptors, { Id: `${globalId.id++}`,  Descriptor: ''}]);
    };
    
    /**
     * Function that populates the descriptors.
     */
    const populateDescriptors = () => {
        return descriptors.map((item) => (
            <div key={item.Id}>
                {/* Render FormItem with Input and Button */}
                <FormItem required={false}>
                    {getFieldDecorator(`${formFieldNames.Descriptors}[${item.Id}]`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        initialValue: item.Descriptor,
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: "Descriptor is required!"
                            },
                            { validator: maxLengthValidation },
                            { validator: validateDuplicateValues },
                        ],
                    })(
                        <Input onChange={(event) => onDescriptorChange(item.Id, event.target.value)} placeholder="Descriptor" style={{ width: '95%', marginRight: 8 }} />
                    )}
                    {<Button
                        className="pa-0"
                        type="link"
                        style={{ color: 'inherit' }}
                        onClick={() => removeField(item.Id)}
                    >
                        <FontAwesome icon={['fas', 'trash']} className="mr-10" />
                    </Button>}
                </FormItem>
            </div>            
        ));
    }

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row type="flex" align="middle">
                    <Col span={24}>
                        <FormItem label={capitalize(customerLabel)}>
                            {getFieldDecorator(formFieldNames.CustomerCode, {
                                rules: [
                                    {
                                        required: true,
                                        message: `${capitalize(customerLabel)} is required!`,
                                    },
                                ],
                            })(
                                <Input hidden />
                            )}<InputAutoCompleteWithButton
                                updateField={(value: any) => {
                                    if (!value && customerCode) {
                                        updateCustomerCode(undefined);
                                    }
                                    setCustomerSearch(value);
                                }}
                                onSelect={(value: any) => {
                                    const CustomerCode = get(value, 'CustomerCode');
                                    updateCustomerCode(CustomerCode);
                                    setFormHasChanged(true);
                                }}
                                minimumLength={1}
                                stateValue={customerSearch}
                                placeholder="Search customer"
                                keyField={'Id'}
                                queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                                filterField={'Customer'}
                                queryFilterName={'Customer'}
                                sortField={'Company name'}
                                responseName={'GetCustomersForCompany.Customers'}
                                labelField={'DisplayName'}
                                hasNoOkButton
                                loading={false}
                                readOnly={false}
                            />
                        </FormItem>
                    </Col>
                    <div className="spacer-15"/>
                    <Col span={24}>
                        <div className="ant-col ant-form-item-label ant-form-item-label-left">
                            <label htmlFor="create-bank-file-descriptor-drawer-content-form_Descriptors" className="ant-form-item-required" title="Descriptors">Descriptors</label>
                        </div>
                        <div className="ant-col ant-form-item-control-wrapper">
                            {populateDescriptors()}
                            {<FormItem key={'AddFieldButton'}>
                                <Button type="dashed" onClick={() => addField()} style={{ width: '95%' }}>
                                    <Icon type="plus" /> Add new descriptor
                                </Button>
                            </FormItem>}
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            {isEditMode ? 'Save' : 'Add'}
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Editing customer bank file descriptor"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while Editing customer bank file descriptor . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const CreateBankFileDescriptorItemDrawerContentWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const CreateBankFileDescriptorItemDrawerContentForm = Form.create({
            name: 'create-bank-file-descriptor-drawer-content-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(CreateBankFileDescriptorItemDrawerContent);

        return withRouter(CreateBankFileDescriptorItemDrawerContentForm);
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default CreateBankFileDescriptorItemDrawerContentWrapper;