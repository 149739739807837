import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { DashboardsActionTypes } from './types';

export const getDashboardsRequestAction = () =>
    action(DashboardsActionTypes.GET_DASHBOARDS_REQUEST);
export const getDashboardsSuccessAction = (payload: {
    data: any[];
    notSameCompany: string | boolean;
}) => action(DashboardsActionTypes.GET_DASHBOARDS_SUCCESS, payload);
export const getDashboardsErrorAction = (payload: string[]) =>
    action(DashboardsActionTypes.GET_DASHBOARDS_ERROR, payload);

export const clearDashboardsStateDataAction = () =>
    action(DashboardsActionTypes.CLEAR_DASHBOARDS_STATE_DATA);

// Single Record
export const setDashboardSelectedIdRequestAction = (
    dashboardId: string | null,
    callback?: () => void
) =>
    action(DashboardsActionTypes.SET_DASHBOARD_SELECTED_ID_REQUEST, {
        dashboardId,
        callback,
    });
export const setDashboardSelectedIdSuccessAction = (
    dashboardId: string | null
) =>
    action(
        DashboardsActionTypes.SET_DASHBOARD_SELECTED_ID_SUCCESS,
        dashboardId
    );

export const getDashboardDataRequestAction = (dashboardId: string) =>
    action(DashboardsActionTypes.GET_DASHBOARD_DATA_REQUEST, dashboardId);
export const getDashboardDataSuccessAction = (payload: { record: {} }) =>
    action(DashboardsActionTypes.GET_DASHBOARD_DATA_SUCCESS, payload);
export const getDashboardDataErrorAction = (payload: string[]) =>
    action(DashboardsActionTypes.GET_DASHBOARD_DATA_ERROR, payload);

// TOP INVOICES
export const getDashboardTopInvoicesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_INVOICES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TOP CREDITS
export const getDashboardTopCreditsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CREDITS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });


// TOP CUSTOMERS
export const getDashboardTopCustomersRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CUSTOMERS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// RECENT CHANGES
export const getDashboardRecentChangesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_RECENT_CHANGES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

    
// CHANGE STATISTICS
export const getDashboardChangeStatisticsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CHANGE_STATISTICS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// CHANGE STATISTICS - BY TYPE
export const getDashboardChangeStatisticsByTypeRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CHANGE_STATISTICS_BY_TYPE_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// CUSTOMERS SETTLEMENT
export const getDashboardCustomersSettlementRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CUSTOMERS_SETTLEMENT_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TASKS
export const getDashboardTasksCountRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TASKS FOR USERS
export const getDashboardTasksCountForUsersRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_FOR_USERS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TASKS FOR USERS
export const getDashboardTasksCountForUserTypesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_FOR_USER_TYPES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// SALES
export const getDashboardSalesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_SALES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

export const setIsEditingDashboard = (isEditing: boolean) =>
    action(DashboardsActionTypes.SET_IS_EDITING_DASHBOARD, isEditing);

// OPEN SUPPORT TICKETS
export const getDashboardOpenSupportTicketsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_OPEN_SUPPORT_TICKETS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// NOTIFICATION SUCCESS
export const getDashboardNotificationSuccessRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_NOTIFICATION_SUCCESS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// INVOICING AND SETTLEMENTS
export const getDashboardInvoicingAndSettlementsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(
        DashboardsActionTypes.GET_DASHBOARD_INVOICING_AND_SETTLEMENTS_REQUEST,
        {
            payload,
            isOrgView,
            callback,
            payloadCallback
        }
    );

// TICKETS
export const getDashboardTicketsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TICKETS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TICKETS FOR USERS
export const getDashboardTicketsForUsersRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TICKETS_FOR_USERS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TICKETS FOR USERS - USER TYPE
export const getDashboardTicketsForUserTypesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TICKETS_FOR_USER_TYPES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TOTAL OWED
export const getDashboardTotalOwedRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_TOTAL_OWED_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// NOTIFICATIONS
export const getDashboardNotificationsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_NOTIFICATIONS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// CURRENT ATB
export const getDashboardCurrentATBRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_CURRENT_ATB_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// OPEN SUPPORT TICKETS
export const getDashboardOpenInvoicesRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_OPEN_INVOICES_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

 // OPEN SUPPORT TICKETS
export const getDashboardCommunicationDeliveryRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_COMMUNICATION_DELIVERY_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });   

// PAYMENTS
export const getDashboardPaymentsRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_PAYMENTS_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });
    
// PAYMENTS - ALLOCATION
export const getDashboardPaymentsAllocationRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DASHBOARD_PAYMENTS_ALLOCATION_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// CASHFLOW FORECAST
export const getDashboardCashflowForecastRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_CASFHLOW_FORECAST_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// DAILY SALES OUT
export const getDashboardDailySalesOutRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_DAILY_SALES_OUT_FOR_COMPANY_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });


// TASK COUNT ACTIONED BY USER
export const getDashboardTasksCountActionedByUserRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_TASK_COUNT_ACTIONED_BY_USER_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// TASK COUNT ACTIONED BY USER TYPE
export const getDashboardTasksCountActionedByUserTypeRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });

// PAYMENT BEHAVIOUR INSIGHTS BREAKDOWN
export const getDashboardCustomerCountPBIRequestAction = (
    payload: DynamicObject,
    isOrgView?: boolean,
    callback?: Function,
    payloadCallback?: (payload: any) => void
) =>
    action(DashboardsActionTypes.GET_CUSTOMER_COUNT_PBI_FOR_COMPANY_REQUEST, {
        payload,
        isOrgView,
        callback,
        payloadCallback
    });