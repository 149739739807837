import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';

import { RolesActionTypes } from './types';
import { getAllRolesSuccessAction } from './actions';

import queries from '../../graphql/queries.graphql';
import { ApplicationState } from '..';
import { DynamicObject } from '../../utils/commonInterfaces';

export const getAllRoles = (state: ApplicationState) => state.roles.allRoles;
export const getRolePermissions = (state: ApplicationState) =>
    state.roles.rolePermissions;

function* handleGetAllRolesRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ALL_ROLES)
        );

        if (res.error) {
            console.log('res.error', res.error);
        } else {
            yield put(getAllRolesSuccessAction(res.data.GetAllRoles.Roles));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetAllRolesRequest() {
    yield takeLatest(
        RolesActionTypes.GET_ALL_ROLES_REQUEST,
        handleGetAllRolesRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* rolesSaga() {
    yield all([fork(watchGetAllRolesRequest)]);
}

export default rolesSaga;
