import { DynamicObject } from '../../utils/commonInterfaces';
import { User } from '../users/types';

export interface PageData {
    pageSize: number;
    currentPage: number;
    hasNextPage: boolean;
}

export enum CommonFiltersActionTypes {
    SAVE_APPLIED_FILTERS_VIEW = '@@common/filters/SAVE_APPLIED_FILTERS_VIEW',
    DELETE_APPLIED_FILTERS_VIEW = '@@common/filters/DELETE_APPLIED_FILTERS_VIEW',
    FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST = '@@common/filters/FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST',
    UPDATE_REFETCH_PAGE_VIEWS = '@@common/filters/UPDATE_REFETCH_PAGE_VIEWS',
    UPDATE_PAGE_VIEWS_SELECTED = '@@common/filters/UPDATE_PAGE_VIEWS_SELECTED',

    DOWNLOAD_TO_EXCEL = '@@common/download/DOWNLOAD_TO_EXCEL',
    GET_PRESIGNED_URL_FOR_REPORT_READY = '@@common/download/GET_PRESIGNED_URL_FOR_REPORT_READY',
    DOWNLOAD_REPORT_READY = '@@common/download/DOWNLOAD_REPORT_READY',

    GET_CUSTOM_FIELDS_FILTER_LIST = '@@common/customFields/GET_CUSTOM_FIELDS_FILTER_LIST',
    GET_CUSTOM_FIELDS_FILTER_LIST_FOR_ORGANISATION = '@@common/customFields/GET_CUSTOM_FIELDS_FILTER_LIST_FOR_ORGANISATION',

    GET_ATTACHMENT_PRESIGNED_URL = '@@common/attachments/GET_ATTACHMENT_PRESIGNED_URL',
    DELETE_ATTACHMENT = '@@common/attachments/DELETE_ATTACHMENT',
    UPDATE_ATTACHMENT_EDITING = '@@common/attachments/UPDATE_ATTACHMENT_EDITING',

    SET_LOCALE = '@@common/locale/SET_LOCALE',

    GET_DEMO_OPTIONS_CONFIG = '@@common/demo/GET_DEMO_OPTIONS_CONFIG',
    UPDATE_DEMO_OPTIONS_CONFIG = '@@common/demo/UPDATE_DEMO_OPTIONS_CONFIG',
    
    LOAD_LUCKYSHEET = '@@common/plugins/LOAD_LUCKYSHEET'
}

export interface PageView {
    Id: string;
    CreatedUser: string;
    Name: string;
    Category: string;
    FilterState: string;
    DashboardState: string;
    IsDefaultView: boolean;
}

export enum PageViewCategoryTypes {
    DEFAULT = 'Default',
    COMPANY = 'Company',
    ORGANISATION = 'Organisation',
    USER = 'User',
    ORGANISATION_USER = 'OrganisationUser',
}

export const PageViewCategoryTypeOptions = [
    { label: 'Personal', value: PageViewCategoryTypes.USER },
    { label: 'Company', value: PageViewCategoryTypes.COMPANY },
];

export const OrganisationPageViewCategoryTypeOptions = [
    { label: 'Personal', value: PageViewCategoryTypes.ORGANISATION_USER },
    { label: 'Organisation', value: PageViewCategoryTypes.ORGANISATION },
];

export const PageViewCategoryTypeOptionsPersonal = [
    { label: 'Personal', value: PageViewCategoryTypes.USER },
];

export const OrganisationPageViewCategoryTypeOptionsPersonal = [
    { label: 'Personal', value: PageViewCategoryTypes.ORGANISATION_USER },
];

export interface AppCommonState {
    // [NOTE] Luckysheet plugin is customized, built and put into `public/plugins/luckysheet`
    loadLuckysheet: boolean;
    refetchPageViews: boolean;
    defaultPageViewValue: string | undefined;
    attachmentEditing: boolean;
    locale: string;
    currencyCode: string;
    demoOptionsConfig: {};
    pageViewsSelected: DynamicObject;
}

export interface CustomField {
    Name: string;
    Value: string;
}

export interface Attachment {
    FileId: string;
    IsPublic: boolean;
    Title: string;
    IsExternal: boolean;
    ExternalUrl: string;
}

export interface ConversationLine {
    readonly Id: string;
    readonly CompanyId: string;
    readonly CreatedDateTime: string;
    readonly Type: string;
    readonly TypeClassification: string;
    readonly Content: string;
    readonly User: User;
    readonly Context: string;
    readonly DisplayName: string;
    readonly Attachment: Attachment;
}

export interface GetConversationRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    conversationTableFilter: string;
}

export interface ConversationState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Conversation[];
    readonly pageData: PageData;
    readonly filters: GetConversationRequestPayload['filters'];
    readonly conversationTableFilter: string;
    readonly sortBy: GetConversationRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface Conversation {}

export interface SpecialisedReports {
    ReportType: string;
}

