import { Store, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import { routerMiddleware } from 'connected-react-router';
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension';
// If you use react-router, don't forget to pass in your history type.
import { History } from 'history';

// Import the state interface and our combined reducers/sagas.
import { ApplicationState, createRootReducer, rootSaga } from './store';
import { AuthActionTypes } from './store/auth/types';
import { includes } from 'lodash';

export default function configureStore(
    history: History,
    initialState: ApplicationState
): Store<ApplicationState> {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});

    // create the redux-saga middleware
    const sagaMiddleware = createSagaMiddleware();

    // We'll create our store with the combined reducers/sagas, and the initial Redux state that
    // we'll be passing from our entry point.
    const combinedReducers = createRootReducer(history);
    const rootReducer = (state: any, action: any) => {
        if (action.type === AuthActionTypes.RESET_REDUX_STATE) {
            state = undefined;
        }

        return combinedReducers(state, action);
    };
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(routerMiddleware(history), sagaMiddleware)
        )
    );

    // Don't forget to run the root saga, and return the store object.
    const rootSagaTask = sagaMiddleware.run(rootSaga);
    rootSagaTask.toPromise().catch((err: any) => {
        if (includes(err.message, 'Generator is already running')) {
            window.location.reload();
            return;
        }
    });
    return store;
}
