/**
 * Component for populating each item in organisation users management page.
 */

import { Badge, Checkbox, Col, Popover, Row } from 'antd';
import { get, includes, isEqual, map } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { SubAttribute } from '../../constants/authUserAttributes';
import { dateFormatDDMMYYYYSlash } from '../../constants/dateFormats';
import { Invoice } from '../../store/invoices/types';
import { getSelectedOrganisationUserId } from '../../store/organisations/sagas';
import { getCurrentUser } from '../../store/users/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { generateOrganisationUserIcon } from '../common/SharedExportComponents';

interface IProps {
    readonly item: Invoice;
    readonly hideCheckbox?: boolean;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const OrganisationUserItemComponent: React.FC<IProps> = ({
    item,
    hideCheckbox,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateUTCToLocal,
}: IProps) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserId: string = get(currentUser, SubAttribute);
    const selectedId = useSelector(getSelectedOrganisationUserId);

    const keyValue = get(item, 'key');

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = keyValue;

        return itemInfo;
    };
    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Indicator if the checkbox on the left side (if present) will be disabled.
     * The checkbox for the currently logged in user account is disabled.
     */
    const rowDisabled = currentUserId === keyValue;
    const isAdmin = get(item, 'IsAdminRole');
    const createdDateTime = get(item, 'User.CreatedDateTime');
    const userCompanies = get(item, 'CompanyNames', []);

    /**
     * Function for populating the company names for user
     */
    const populateUserCompaniesPopover = () => {
        return map(userCompanies, (companyName: string, idx: number) => {
            if (idx < 5) {
                return <div key={idx}>{companyName}</div>;
            }
        });
    };

    /**
     * Function for populating organisation user item.
     */
    const populateOrganisationUserItemIcon = () => {
        let itemColor = 'green';
        if (!createdDateTime) {
            itemColor = 'settled';
        }
        return generateOrganisationUserIcon(itemColor, 22, isAdmin);
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === keyValue ? 'list-item-clicked' : ''
            }`}
            key={keyValue}
            style={style}
            onClick={handleRowClick}
        >
            <Col span={24} className="h-100">
                <Row type="flex" align="middle" className="h-100">
                    <Col
                        className="h-100"
                        xxl={2}
                        xl={3}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                    >
                        <Row className="h-100" type="flex" align="middle">
                            <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                <div className={`ta-center center-flex-all`}>
                                    <Checkbox
                                        checked={includes(
                                            selectedRowKeys,
                                            keyValue
                                        )}
                                        disabled={rowDisabled}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xxl={16}
                                xl={16}
                                lg={16}
                                md={16}
                                sm={16}
                                xs={16}
                                className="ta-center"
                            >
                                <div className="ta-center center-flex-all">
                                    {populateOrganisationUserItemIcon()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={20} xl={19} lg={18} md={18} sm={18} xs={18}>
                        <Row type="flex" align="middle">
                            <Col md={6} sm={24} xs={24}>
                                {hideCheckbox ? <span>&emsp;</span> : null}
                                {get(item, 'fullName')}
                            </Col>
                            <Col md={8} sm={24} xs={24}>
                                {get(item, 'User.Email')}
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                {isAdmin ? 'Organisation Admin' : ''}
                            </Col>
                            <Col md={4}>
                                <Row type="flex" justify="end" align="middle">
                                    {createdDateTime ? (
                                        <>
                                            <div className="mr-20">
                                                <Popover
                                                    content={populateUserCompaniesPopover()}
                                                    trigger="hover"
                                                    placement="left"
                                                >
                                                    <Badge
                                                        className="building-badge"
                                                        count={
                                                            userCompanies.length
                                                        }
                                                        offset={[0, 2]}
                                                    >
                                                        <FontAwesome
                                                            icon={[
                                                                'fas',
                                                                'building',
                                                            ]}
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                        />
                                                    </Badge>
                                                </Popover>
                                            </div>
                                            <div style={{ width: 80 }}>
                                                {formatDateUTCToLocal(
                                                    createdDateTime,
                                                    undefined,
                                                    dateFormatDDMMYYYYSlash
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <Col>Invitation pending</Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2} sm={24} xs={24} className="ta-center">
                        {get(item, 'avatar')}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    const prevItem = { ...prevProps.item };
    const nextItem = { ...nextProps.item };
    if (get(prevItem, 'avatar')) {
        delete prevItem.avatar;
    }
    if (get(nextItem, 'avatar')) {
        delete nextItem.avatar;
    }
    if (
        JSON.stringify(prevItem) === JSON.stringify(nextItem) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(OrganisationUserItemComponent),
    arePropsEqual
);
