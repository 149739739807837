import { action } from 'typesafe-actions';
import { RolesActionTypes, Role } from './types';

export const getAllRolesRequestAction = () =>
    action(RolesActionTypes.GET_ALL_ROLES_REQUEST);
export const getAllRolesSuccessAction = (data: Role[]) =>
    action(RolesActionTypes.GET_ALL_ROLES_SUCCESS, data);

export const getRolePermissionsSuccessAction = (data: {}) =>
    action(RolesActionTypes.GET_ROLE_PERMISSIONS_SUCCESS, data);
