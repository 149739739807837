import { action } from 'typesafe-actions';
import { SmsCustomization, RequestSaveCompanySmsCustomizationItemPayload, SmsProviderActionTypes } from './types';

export const getSmsCustomizationProviderRequestAction = () =>
    action(SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_REQUEST);

export const getSmsCustomizationProviderSuccessAction = (data: SmsCustomization) =>
    action(SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_SUCCESS, data);

export const getSmsCustomizationProviderFailAction = () =>
    action(SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_FAIL);

export const saveSmsCustomizationProviderRequestAction = (
    payload: RequestSaveCompanySmsCustomizationItemPayload
) => action(SmsProviderActionTypes.SAVE_SMS_CUSTOM_PROVIDER_REQUEST, payload);
 
export const saveSmsCustomizationProviderResponseAction = () =>
    action(SmsProviderActionTypes.SAVE_SMS_CUSTOM_PROVIDER_RESPONSE);