/**
 * File responsible for the content when clicking `Allocate to invoice` in credits management pages.
 */

import { Drawer } from 'antd';
import React, { } from 'react';
import { CreditVM } from '../../store/credits/types';
import CreateCreditItemManuallyDrawerContentWrapper from './CreateCreditItemManuallyDrawerContent';
import { Payment } from '../../store/payments/types';
import { RemittanceAdvice } from '../../store/remittanceAdvices/types';

interface IProps {
    readonly visible: boolean;
    readonly isEditMode: boolean;
    readonly editCredit?: Partial<CreditVM>;
    readonly existingCreditList?: Partial<CreditVM>[];
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly handleAddCredit?: (creditVM: CreditVM) => void;
    readonly handleEditCredit?: (creditVM: CreditVM) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
    readonly remittanceAdvice?: RemittanceAdvice;
}

const CreateCreditItemManuallyPanel: React.FC<IProps> = ({
    visible,
    isEditMode,
    editCredit,
    existingCreditList,
    closePanel,
    form,
    handleAddCredit,
    handleEditCredit,
    disableCustomer,
    payment,
    remittanceAdvice
}: IProps) => {

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    return (
        <Drawer
            title={`${isEditMode ? 'Edit' : 'Add'} Credit`}
            width={700}
            onClose={handleClosePanel}
            visible={visible}
            className="add-credit-item-manually-panel"
            closable={false}
            maskClosable={false}
        >
            <CreateCreditItemManuallyDrawerContentWrapper
                visible={visible}
                isEditMode={isEditMode}
                closePanel={handleClosePanel}
                editCredit={editCredit}
                handleAddCredit={ handleAddCredit }
                handleEditCredit={ handleEditCredit }
                existingCreditList={existingCreditList}
                disableCustomer={disableCustomer}
                payment={payment}
                remittanceAdvice={remittanceAdvice}
            />
        </Drawer>
    );
};

export default CreateCreditItemManuallyPanel;