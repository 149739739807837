/**
 * File responsible for all the UI and actions for Settings>Company page - Branding tab item - `/app/settings/company/branding`.
 */

import {
    Button,
    Col,
    Icon,
    Modal,
    Row,
    Select,
    Spin,
    Typography,
    Upload,
} from 'antd';
import { History as IHistory } from 'history';
import {
    capitalize,
    forEach,
    get,
    isEmpty,
    isEqual,
    map,
    startsWith,
    toLower,
    upperCase,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    forwardRef,
    Ref,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageWithLoading from '../../../../components/common/ImageWithLoading';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import SelectReadonly from '../../../../components/FormComponents/SelectReadonly';
import {
    initialPrimaryColor,
    initialSecondaryColor,
    INVALIDATE_COMPANY_LOGO_PATH,
} from '../../../../config/config';
import {
    saveUserCompanyRequestAction,
    saveUserCompanyResponseAction,
    uploadCompanyLogoRequestAction,
} from '../../../../store/companies/actions';
import { Company } from '../../../../store/companies/types';
import { getCustomerUILabel } from '../../../../store/customers/sagas';
import {
    getAssetsPath,
    invalidateImagePathCache,
    updateLessVariables,
} from '../../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';
import ThemeSelection from './ThemeSelection';

const { Title } = Typography;
const { Option } = Select;

const assetsPath = getAssetsPath();

const presetThemes = [
    {
        name: 'Sunset',
        primaryColor: '#F26B13',
        secondaryColor: '#21356D',
    },
    {
        name: 'Forest',
        primaryColor: '#2C5F2D',
        secondaryColor: '#97BC62',
    },
    {
        name: 'Beach',
        primaryColor: '#13C2C2',
        secondaryColor: '#2850F8',
    },
    {
        name: 'Desert',
        primaryColor: '#EB861C',
        secondaryColor: '#74520E',
    },
    {
        name: 'Ruby',
        primaryColor: '#F5222D',
        secondaryColor: '#330426',
    },
    {
        name: 'Midnight',
        primaryColor: '#722ED1',
        secondaryColor: '#030303',
    },
    // {
    //     name: 'IODM',
    //     primaryColor: initialPrimaryColor,
    //     secondaryColor: initialSecondaryColor,
    // },
];

const imagePlaceholder = `${assetsPath}/imagePlaceholder.png`;
interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
}
let modalUpdateTheme: any = null;
const CompanyBrandingPage: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            selectedUserCompany,
            handleModalSaveResponse,
            history,
            setFormHasChanges,
        }: IProps,
        ref: Ref<any>
    ) => {
        const customerLabel = useSelector(getCustomerUILabel);
        const dispatch = useDispatch();
        const themeSelectionRef: RefObject<DynamicObject | null | undefined> =
            useRef();
        const [brandingFormState, setBrandingFormState] = useState<{}>({});

        // const [
        //     sampleNoficationState,
        //     setSampleNotificationState,
        // ] = useState<{}>({});

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Function that checks if there are any changes made to the form
         * different to the one saved in redux state.
         */
        const checkForChanges = () => {
            const formState: DynamicObject = { ...brandingFormState };
            delete formState.Theme;
            delete formState.FileToUpload;

            const constructedState = {
                ...selectedUserCompany,
                ...formState,
            };

            return !isEqual(selectedUserCompany, constructedState);
        };

        /**
         * Function called when clicking the common `Save` button
         * triggered from parent.
         * Also responsible for uploading the company logo.
         */
        const handleSave = () => {
            if (fileToUpload) {
                dispatch(
                    uploadCompanyLogoRequestAction(fileToUpload, (res: any) => {
                        if (res.IsSuccess === false) {
                            dispatch(saveUserCompanyResponseAction());

                            Modal.error({
                                title: 'Error uploading file',
                                content: get(res, 'Messages.0'),
                            });
                        } else {
                            localStorage.setItem(
                                INVALIDATE_COMPANY_LOGO_PATH,
                                'true'
                            );
                            saveCompanyData();
                        }
                    })
                );
            } else {
                saveCompanyData();
            }
        };

        /**
         * Function that saves the company data (specifically the theme colors).
         */
        const saveCompanyData = () => {
            const formState: any = { ...brandingFormState };
            delete formState.LogoPath;
            formState.DetailType = 'Branding';

            // Save grey color if color selected is not grey | (When second color picker is disabled)
            if (formState.SecondaryColorTheme !== initialSecondaryColor) {
                formState.SecondaryColorTheme = initialSecondaryColor;
            }

            dispatch(
                saveUserCompanyRequestAction(formState, handleModalSaveResponse)
            );
        };

        /**
         * Function called when the common `Cancel` button is clicked.
         */
        const handleCancel = () => {
            syncStateFromRedux();
        };

        /**
         * Common function for updating the form state for this page (will call a specific function
         * that actually updates the state).
         * Created to prevent duplicates when updating state.
         * @param brandingFormStateObject
         */
        const updateBrandingFormState = (brandingFormStateObject: {}) => {
            updateBrandingFormStateObject({
                ...brandingFormState,
                ...brandingFormStateObject,
            });
        };

        /**
         * Function for updating the state object and also triggers the form has changes value.
         * @param formStateObject
         */
        const updateBrandingFormStateObject = (formStateObject: {}) => {
            setBrandingFormState(formStateObject);
            setFormHasChanges();
        };

        /**
         * Function that sets the company data from redux to this form's state.
         */
        const syncStateFromRedux = () => {
            const { PrimaryColorTheme, SecondaryColorTheme } =
                selectedUserCompany;

            let Theme: any = undefined;
            if (PrimaryColorTheme && SecondaryColorTheme) {
                forEach(
                    presetThemes,
                    ({ primaryColor, secondaryColor }, index) => {
                        if (
                            upperCase(primaryColor) ===
                                upperCase(PrimaryColorTheme) &&
                            upperCase(secondaryColor) ===
                                upperCase(SecondaryColorTheme)
                        ) {
                            Theme = index;
                        }
                    }
                );
            }

            const formState = {
                PrimaryColorTheme,
                SecondaryColorTheme,
                Theme,
            };

            updateBrandingFormStateObject(formState);
        };

        useEffect(syncStateFromRedux, [selectedUserCompany]);

        /**
         * Function that shows the modal whenever the theme is changed (colors).
         * @param content
         */
        const showModalUpdateTheme = (
            content: string = 'Updating system theme'
        ) => {
            if (!modalUpdateTheme) {
                modalUpdateTheme = Modal.info({
                    className: 'modal-no-footer',
                    title: 'Please wait. . .',
                    content: (
                        <div className="normal-flex">
                            {content} &emsp;
                            <Spin />
                        </div>
                    ),
                });
            }
        };

        /**
         * Function that updates the less variables that will be used to style the UI.
         * Less variables are used to dynamically change styles on the go.
         * Also clear all existing modal shown.
         * @param colorVariables - object that contains the keys similar to those defined
         * in less variables
         * @param callback - a function called after setting the less variables
         */
        const changeLessVariables = (
            colorVariables: any,
            callback?: () => void
        ) => {
            updateLessVariables(colorVariables, () => {
                if (modalUpdateTheme) Modal.destroyAll();
                modalUpdateTheme = null;
                if (callback) {
                    callback();
                }
            });
        };

        /**
         * Function that is responsible for the state management of the `Sample notification` section.
         * @param name - form field name
         */
        // const updateSampleNotificationState: any = (name: string) => {
        //     return (e: any) => {
        //         const correctValue = get(e, 'target.value', e);

        //         setSampleNotificationState({
        //             ...sampleNoficationState,
        //             [name]: correctValue,
        //         });
        //     };
        // };

        /**
         * Function called when a theme is selected from the dropdown theme selection provided.
         * @param value - object that contains the colors needed
         */
        const handleSelectPresetTheme = (value: any) => {
            showModalUpdateTheme();

            setTimeout(() => {
                const additionalStateProps: any = {};
                const correctValue = value;

                const presetPrimary = get(
                    presetThemes,
                    `${correctValue}.primaryColor`
                );
                const presetSecondary = get(
                    presetThemes,
                    `${correctValue}.secondaryColor`
                );
                if (
                    presetPrimary !==
                        get(brandingFormState, 'PrimaryColorTheme') &&
                    !isEmpty(presetPrimary)
                ) {
                    additionalStateProps.PrimaryColorTheme = get(
                        presetThemes,
                        `${correctValue}.primaryColor`
                    );
                }

                if (
                    presetSecondary !==
                        get(brandingFormState, 'SecondaryColorTheme') &&
                    !isEmpty(presetSecondary)
                ) {
                    additionalStateProps.SecondaryColorTheme = get(
                        presetThemes,
                        `${correctValue}.secondaryColor`
                    );
                }

                if (
                    !isEmpty(additionalStateProps) &&
                    themeSelectionRef.current
                ) {
                    themeSelectionRef.current.skipColorVariableChange();
                }
                updateBrandingFormState({
                    Theme: correctValue,
                    ...additionalStateProps,
                });
            }, 400);
        };

        /**
         * Function that populates the theme selection options based on a static list defined above.
         */
        const populateThemePresets = () => {
            return map(
                presetThemes,
                (theme: typeof presetThemes[number], index: number) => {
                    const { name } = theme;
                    return (
                        <Option key={name} value={index}>
                            {name}
                        </Option>
                    );
                }
            );
        };

        /**
         * Function triggered when a change in color occurs inside the ThemeSelection file.
         * @param colorVariable - color string names (defined similarly to the variable names in less file)
         * @param value - color value from color picker
         */
        const handleThemeColorChange = (colorVariable: string, value: any) => {
            let colorName = '';
            switch (colorVariable) {
                case '@custom-primary-color':
                    colorName = 'PrimaryColorTheme';
                    break;
                case '@custom-secondary-color':
                    colorName = 'SecondaryColorTheme';
                    break;

                default:
                    break;
            }
            updateBrandingFormStateObject({
                ...brandingFormState,
                [colorName]: value,
            });
        };

        /**
         * Function called for getting the base64 from file - to be used in preview.
         * @param file
         */
        const getBase64 = (file: File) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        /**
         * Common function for showing modal error.
         * @param modalObject
         */
        const showModalError = (modalObject: {}) => {
            Modal.error(modalObject);
        };

        /**
         * Async function for getting a preview URL of the file selected, also validate the file.
         * @param file
         */
        const processPreviewUrl = async (file: any) => {
            const isFileTypeAllowed =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif';

            if (!isFileTypeAllowed) {
                return showModalError({
                    title: 'File type not supported',
                    content: (
                        <div>
                            You can only upload <b>JPG</b>/<b>PNG</b>/<b>GIF</b>{' '}
                            file!
                        </div>
                    ),
                });
            }

            const exceeds1Mb = file.size / 1024 / 1024 > 1;
            if (exceeds1Mb) {
                return showModalError({
                    title: 'File size limit',
                    content: <div>Image size must not exceed 1MB!</div>,
                });
            }

            if (!file.url && !file.preview) {
                file.preview = await getBase64(file);
            }

            updateBrandingFormState({
                FileToUpload: file,
                LogoPath: file.url || file.preview,
            });
        };

        const brandLabel = { paddingTop: 3, minWidth: 150 };
        // const radioStyle = {
        //     display: 'block',
        //     height: 30,
        // };
        const fileToUpload = get(brandingFormState, 'FileToUpload');
        const uploadProps = {
            accept: '.jpeg,.jpg,.png,.gif',
            onRemove: () => {
                updateBrandingFormState({
                    FileToUpload: null,
                    LogoPath: undefined,
                });
            },
            beforeUpload: (file: any) => {
                if (file) processPreviewUrl(file);
                return false;
            },
            fileList: fileToUpload ? [fileToUpload] : [],
        };

        const usedImagePlaceholder = get(
            selectedUserCompany,
            'LogoPath',
            imagePlaceholder
        );

        let imageSourcePath = get(
            brandingFormState,
            'LogoPath',
            usedImagePlaceholder
        );

        if (!startsWith(imageSourcePath, 'data:image/')) {
            imageSourcePath = invalidateImagePathCache(imageSourcePath);
        }

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => {
                        if (
                            get(brandingFormState, 'PrimaryColorTheme') ===
                                selectedUserCompany.PrimaryColorTheme &&
                            get(brandingFormState, 'SecondaryColorTheme') ===
                                selectedUserCompany.SecondaryColorTheme
                        ) {
                            history.push(path);
                        } else {
                            showModalUpdateTheme('Reverting system theme');
                            setTimeout(() => {
                                changeLessVariables(
                                    {
                                        '@custom-primary-color': get(
                                            selectedUserCompany,
                                            'PrimaryColorTheme',
                                            initialPrimaryColor
                                        ),
                                        // '@custom-secondary-color': get(selectedUserCompany, 'SecondaryColorTheme', initialSecondaryColor),
                                    },
                                    history.push(path)
                                );
                            }, 400);
                        }
                    }}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>Branding</Title>
                            <div className="grey">
                                Customise the look for your staff and{' '}
                                {toLower(customerLabel)}s
                            </div>
                        </Col>
                    </Row>
                    <div className="divider-thick-custom" />
                    <Row key="logo-and-colours-container">
                        <Col span={24}>
                            <h3>Logo and colours</h3>
                            <Row gutter={10}>
                                <Col xl={9} lg={24}>
                                    <div className="mb-10">
                                        The logo and colours you select here are
                                        used on:
                                    </div>
                                    <div>
                                        <ul>
                                            <li>This website</li>
                                            <li>
                                                Notifications sent to your{' '}
                                                {toLower(customerLabel)}s
                                            </li>
                                            <li>
                                                The {capitalize(customerLabel)}{' '}
                                                Portal
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xl={15} lg={24} className="ta-right-xl">
                                    <Row
                                        className="mb-10"
                                        type="flex"
                                        gutter={20}
                                    >
                                        <Col className="mb-10">
                                            <Row type="flex" gutter={20}>
                                                <Col
                                                    className="ta-right-xl"
                                                    style={brandLabel}
                                                >
                                                    Upload your company file:
                                                </Col>
                                                <Col className="ta-left">
                                                    <Upload {...uploadProps}>
                                                        <Button
                                                            disabled={disabled}
                                                            className="choose-file-btn"
                                                        >
                                                            <Icon type="upload" />{' '}
                                                            Choose file
                                                        </Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="ta-center"
                                            style={{
                                                minWidth: 100,
                                                maxHeight: 100,
                                                maxWidth: 300,
                                            }}
                                        >
                                            <ImageWithLoading
                                                hasPlaceholder
                                                src={imageSourcePath}
                                                style={{
                                                    height: '100%',
                                                    maxWidth: '100%',
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xxl={{
                                        span: 13,
                                        offset: 9,
                                    }}
                                    xl={{
                                        span: 20,
                                        offset: 4,
                                    }}
                                    // lg={{ span: 14, offset: 0 }}
                                >
                                    <Row>
                                        <Col span={24} className="ta-right-xl">
                                            <Row
                                                className="mb-10"
                                                type="flex"
                                                align="middle"
                                                gutter={20}
                                            >
                                                <Col>
                                                    <Row
                                                        type="flex"
                                                        align="middle"
                                                        gutter={20}
                                                    >
                                                        <Col
                                                            className="ta-right"
                                                            xl={8}
                                                            lg={10}
                                                            style={brandLabel}
                                                        >
                                                            Select your theme:
                                                        </Col>
                                                        <Col
                                                            xl={7}
                                                            lg={14}
                                                            className="mt-10-lg"
                                                        >
                                                            <SelectReadonly
                                                                className="company-select"
                                                                placeholder="Select your theme"
                                                                readOnly={
                                                                    disabled
                                                                }
                                                                value={get(
                                                                    brandingFormState,
                                                                    'Theme',
                                                                    undefined
                                                                )}
                                                                onChange={
                                                                    handleSelectPresetTheme
                                                                }
                                                            >
                                                                {populateThemePresets()}
                                                            </SelectReadonly>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col
                                                    style={{
                                                        minWidth: 100,
                                                        paddingTop: 7,
                                                    }}
                                                >
                                                    <ThemeSelection
                                                        ref={themeSelectionRef}
                                                        disabled={disabled}
                                                        initialColors={{
                                                            '@custom-primary-color':
                                                                get(
                                                                    brandingFormState,
                                                                    'PrimaryColorTheme'
                                                                ) ||
                                                                initialPrimaryColor,
                                                            '@custom-secondary-color':
                                                                get(
                                                                    brandingFormState,
                                                                    'SecondaryColorTheme'
                                                                ) ||
                                                                initialSecondaryColor,
                                                        }}
                                                        colorPickers={[
                                                            {
                                                                name: 'Primary colour',
                                                                propertyName:
                                                                    '@custom-primary-color',
                                                                styles: {
                                                                    name: {
                                                                        minWidth: 140,
                                                                        textAlign:
                                                                            'right',
                                                                    },
                                                                },
                                                            },
                                                            // {
                                                            //     name:
                                                            //         'Secondary colour',
                                                            //     propertyName:
                                                            //         '@custom-secondary-color',
                                                            // },
                                                        ]}
                                                        onColorChange={
                                                            handleThemeColorChange
                                                        }
                                                        changeLessVariables={
                                                            changeLessVariables
                                                        }
                                                        showModalUpdateTheme={
                                                            showModalUpdateTheme
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xl={15}>
                                            <Row
                                                type="flex"
                                                align="middle"
                                                gutter={20}
                                            >
                                                <Col
                                                    className="ta-right"
                                                    xl={8}
                                                    lg={10}
                                                    style={brandLabel}
                                                >
                                                    Select your theme:
                                                </Col>
                                                <Col
                                                    xl={7}
                                                    lg={14}
                                                    className="mt-10-lg"
                                                >
                                                    <SelectReadonly
                                                        className="company-select w-100"
                                                        placeholder="Select your theme"
                                                        readOnly={disabled}
                                                        value={get(
                                                            brandingFormState,
                                                            'Theme',
                                                            undefined
                                                        )}
                                                        onChange={
                                                            handleSelectPresetTheme
                                                        }
                                                    >
                                                        {populateThemePresets()}
                                                    </SelectReadonly>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            xl={9}
                                            lg={24}
                                            className="mt-10-lg ta-left"
                                        >
                                            <ThemeSelection
                                                ref={themeSelectionRef}
                                                disabled={disabled}
                                                initialColors={{
                                                    '@custom-primary-color':
                                                        get(
                                                            brandingFormState,
                                                            'PrimaryColorTheme'
                                                        ) ||
                                                        initialPrimaryColor,
                                                    '@custom-secondary-color':
                                                        get(
                                                            brandingFormState,
                                                            'SecondaryColorTheme'
                                                        ) ||
                                                        initialSecondaryColor,
                                                }}
                                                colorPickers={[
                                                    {
                                                        name: 'Primary colour',
                                                        propertyName:
                                                            '@custom-primary-color',
                                                    },
                                                    // {
                                                    //     name:
                                                    //         'Secondary colour',
                                                    //     propertyName:
                                                    //         '@custom-secondary-color',
                                                    // },
                                                ]}
                                                onColorChange={
                                                    handleThemeColorChange
                                                }
                                                changeLessVariables={
                                                    changeLessVariables
                                                }
                                                showModalUpdateTheme={
                                                    showModalUpdateTheme
                                                }
                                            />
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <div className="divider-thick-custom" />
                    <Row key="sample-notification-container">
                        <Col span={24}>
                            <h3>Sample notification</h3>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    To see what your letters and emails look
                                    like:
                                </Col>
                            </Row>
                            <Row className="mb-10 mt-10">
                                <Col span={24}>
                                    1. Enter in your email address &emsp;
                                    <Input
                                        style={{ width: 300 }}
                                        readOnly={disabled}
                                        value={get(
                                            sampleNoficationState,
                                            'TestNotificationEmailAddress',
                                            undefined
                                        )}
                                        onChange={updateSampleNotificationState(
                                            'TestNotificationEmailAddress'
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-10">
                                <Col span={24}>
                                    2. Select the type of letter you would like
                                    to see.
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Radio.Group
                                        value={get(
                                            sampleNoficationState,
                                            'TestNotificationLetterType',
                                            undefined
                                        )}
                                        onChange={updateSampleNotificationState(
                                            'TestNotificationLetterType'
                                        )}
                                    >
                                        <Radio
                                            value="single"
                                            style={radioStyle}
                                            disabled={disabled}
                                        >
                                            Single invoice
                                        </Radio>
                                        <Radio
                                            value="multiple"
                                            style={radioStyle}
                                            disabled={disabled}
                                        >
                                            Multiple invoices
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <Button type="primary" disabled={disabled}>
                                        Send a sample
                                    </Button>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <Alert
                                        message="Please note that sample information is used to generate the notifications. Links in the samples are for display purposes only and do not work."
                                        description=" "
                                        type="info"
                                        showIcon
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                </QueueAnim>
            </Col>
        );
    }
);

export default CompanyBrandingPage;
