/**
 * File responsible for all the UI and actions for ImportHistory page - `/app/import/history`.
 */

import { Col } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ImportHistoryPage from '../../components/history/ImportHistoryPage';
import { EntityType } from '../../store/import/types';
import { setImportHistoryEntityTypeAction } from '../../store/import/actions';

const ImportBankFileHistoryPage: React.FC = () => {
    const dispatch = useDispatch();
    useEffect( () => {
        dispatch(setImportHistoryEntityTypeAction(EntityType.BankFile));
    }, [])
    return (
        <div className="h-100">
            <Col span={24}>
                <ImportHistoryPage/>
            </Col>
        </div>
    );
};

export default ImportBankFileHistoryPage;
