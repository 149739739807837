import { Reducer } from 'redux';
import { AccessTokensActionTypes, AccessTokensState } from './types';

// Type-safe initialState!
export const initialState: AccessTokensState = {
    loading: false,
    errorMessages: [],
    revokeAccessTokensLoading: false,
    data: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AccessTokensState> = (state = initialState, action) => {
    switch (action.type) {
        case AccessTokensActionTypes.GET_ACCESS_TOKENS_REQUEST: {
            return { ...state, loading: true };
        }
        case AccessTokensActionTypes.GET_ACCESS_TOKENS_SUCCESS: {
            let newDataState = action.payload.data;

            return {
                ...state,
                loading: false,
                data: newDataState,
                errorMessages: initialState.errorMessages,
            };
        }
        case AccessTokensActionTypes.GET_ACCESS_TOKENS_ERROR: {
            return {
                ...state,
                loading: false,
                data: [],
                errorMessages: action.payload.errorMessages,
            };
        }
        case AccessTokensActionTypes.REVOKE_ACCESS_TOKENS_REQUEST: {
            return { ...state, revokeAccessTokensLoading: true };
        }
        case AccessTokensActionTypes.REVOKE_ACCESS_TOKENS_RESPONSE: {
            return { ...state, revokeAccessTokensLoading: false };
        }
        case AccessTokensActionTypes.CLEAR_ACCESS_TOKENS_STATE_DATA: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as accessTokensReducer };
