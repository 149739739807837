import update from 'immutability-helper';
import { concat, get, isEqual, keyBy, merge, uniqWith, values } from 'lodash';
import { Reducer } from 'redux';
import {
    CUSTOMER_ASSISTANCE_PAGE,
    DETAILS_TAB,
} from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    ticketsSortByOptions,
    ticketsStatusFilterOptions,
} from '../../constants/customerAssistanceSortAndFilters';
import {
    invoicesStateFilterOptions,
    commonInvoiceListInvoicesSortOptions,
} from '../../constants/invoicesSortAndFilters';
import { TicketsActionTypes, TicketsState } from './types';

// Type-safe initialState!
export const initialState: TicketsState = {
    loading: false,
    saveTicketDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: CUSTOMER_ASSISTANCE_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {
        DisplayName: '',
        CustomerCode: '',
        CreatedDateMin: '',
        CreatedDateMax: '',
        ClosedDateMin: '',
        ClosedDateMax: '',
        LastChatDateMin: '',
        LastChatDateMax: '',
        Status: -1,
        TicketOptionReasons: [],
    },
    ticketStatus: get(ticketsStatusFilterOptions, `${0}.value`),
    ticketTableFilter: undefined,
    sortBy: ticketsSortByOptions[4].value,
    sortAscending: false,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
        invoices: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.INVOICE_LIST.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {
                InvoiceNumber: '',
                Customer: '',
                CustomerCode: '',
                CreatedDateMin: '',
                CreatedDateMax: '',
                DueDateMin: '',
                DueDateMax: '',
                AmountType: '',
                AmountValue: '',
                AmountOperator: '',
            },
            sortBy: commonInvoiceListInvoicesSortOptions[0].value, //tasksSortByOptions[0].value,
            sortAscending: true,
            invoiceState: get(invoicesStateFilterOptions, `${1}.value`),
        },
        tasks: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: CUSTOMER_ASSISTANCE_PAGE.dataTasks.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            sortBy: '', //tasksSortByOptions[0].value,
            sortAscending: true,
        },
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        discussion: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            }
        }
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<TicketsState> = (state = initialState, action) => {
    switch (action.type) {
        case TicketsActionTypes.GET_TICKETS_REQUEST: {
            const newTicketsState = update(state, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.GET_TICKETS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            const newTicketsState = update(state, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.errorMessages,
                },
            });
            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.GET_TICKETS_ERROR: {
            const newTicketsState = update(state, {
                $merge: {
                    loading: false,
                    data: initialState.data,
                    errorMessages: action.payload,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.UPDATE_TICKETS_FILTERS: {
            const newTicketsState = update(state, {
                $merge: {
                    filters: action.payload,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.UPDATE_TICKETS_SORT_BY_AND_STATUS: {
            const newTicketsState = update(state, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    ticketStatus: action.payload.ticketStatus,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.UPDATE_TICKETS_TABLE_FILTER_STATUS: {
            const newTicketsState = update(state, {
                $merge: {
                    ticketTableFilter: action.payload,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.CLEAR_TICKETS_STATE_ALL_TABLE_FILTERS: {
            const newTicketsState = update(state, {
                $merge: {
                    filters: initialState.filters,
                    sortBy: initialState.sortBy,
                    sortAscending: initialState.sortAscending,
                    ticketStatus: initialState.ticketStatus,
                    ticketTableFilter: initialState.ticketTableFilter,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.CLEAR_TICKETS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case TicketsActionTypes.SET_TICKET_SELECTED_ID_REQUEST: {
            const newTicketsState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.SET_TICKET_SELECTED_ID_SUCCESS: {
            const newTicketsState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_REQUEST: {
            const newTicketsState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_SUCCESS: {
            const newTicketsState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_ERROR: {
            const newTicketsState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.CLEAR_TICKET_DATA_SUCCESS: {
            const {
                record,
                loading,
                selectedId,
                errorMessages,
                invoices,
                tasks,
            } = initialState.activeData;
            const newTicketsState = update(state, {
                activeData: {
                    record: { $set: record },
                    loading: { $set: loading },
                    selectedId: { $set: selectedId },
                    errorMessages: { $set: errorMessages },
                    invoices: { $set: invoices },
                    tasks: { $set: tasks },
                },
            });

            return {
                ...newTicketsState,
            };
        }

        // For invoices list reducers
        case TicketsActionTypes.GET_TICKET_DATA_INVOICES_REQUEST: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    invoices: newInvoicesState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.invoices.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.invoices.data, action.payload.data),
                    isEqual
                );
            }

            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    invoices: newInvoicesState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_INVOICES_ERROR: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.invoices.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    invoices: newInvoicesState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.UPDATE_TICKET_DATA_INVOICES_FILTERS: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    invoices: newInvoicesState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.UPDATE_TICKET_DATA_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    invoiceState: action.payload.invoiceState,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    invoices: newInvoicesState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        // For tasks list reducers
        case TicketsActionTypes.GET_TICKET_DATA_TASKS_REQUEST: {
            const newTasksState = update(state.activeData.tasks, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    tasks: newTasksState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_TASKS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.tasks.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.tasks.data, action.payload.data),
                    isEqual
                );
            }

            const newTasksState = update(state.activeData.tasks, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.activeData.tasks.errorMessages,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    tasks: newTasksState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.GET_TICKET_DATA_TASKS_ERROR: {
            const newTasksState = update(state.activeData.tasks, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.tasks.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    tasks: newTasksState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        case TicketsActionTypes.UPDATE_TICKET_DATA_TASKS_FILTERS: {
            const newTasksState = update(state.activeData.tasks, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    tasks: newTasksState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }
        case TicketsActionTypes.UPDATE_TICKET_DATA_TASKS_SORT_BY: {
            const newTasksState = update(state.activeData.tasks, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveDataState = update(state.activeData, {
                $merge: {
                    tasks: newTasksState,
                },
            });
            const newTicketsState = update(state, {
                activeData: {
                    $set: newActiveDataState,
                },
            });

            return {
                ...newTicketsState,
            };
        }

        // For conversation list reducers
        case TicketsActionTypes.GET_TICKET_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.GET_TICKET_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.GET_TICKET_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // Ticket discussion
        case TicketsActionTypes.GET_TICKET_DISCUSSION_REQUEST: {
            const newDiscussionState = update(state.activeData.discussion, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                discussion: { $set: newDiscussionState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case TicketsActionTypes.GET_TICKET_DISCUSSION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.discussion.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.discussion.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newDiscussionState = update(state.activeData.discussion, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.discussion.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                discussion: { $set: newDiscussionState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.GET_TICKET_DISCUSSION_ERROR: {
            const newDiscussionState = update(state.activeData.discussion, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.discussion.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                discussion: { $set: newDiscussionState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.CLEAR_TICKET_DISCUSSION: {
            const newDiscussionState = update(state.activeData.discussion, {
                $merge: {
                    ...initialState.activeData.discussion
                },
            });

            const newActiveData = update(state.activeData, {
                discussion: { $set: newDiscussionState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.UPDATE_TICKET_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.UPDATE_TICKET_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.UPDATE_TICKET_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case TicketsActionTypes.TICKET_ASSIGN_USER_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                activeTasks: newActiveData,

            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as ticketsReducer };
