import { DynamicObject } from '../utils/commonInterfaces';
import paymentProviderLogos from '../assets/payment-providers.jpg';
import { CompanyNotificationCustomization } from '../store/companies/types';
import { customizationPlaceholders } from './settings';

export const cardPaymentSystems = {
    NOT_USING: 'Not using',
    SELF_MANAGED: 'Self-managed',
    PAYMENT_URL: 'Own payment URL',
    EZIDEBIT: 'EziDebit',
    INTEGRAPAY: 'IntegraPay',
    ADVAM: 'ADVAM',
};
export const creditcardOptions = {
    MASTERCARD: 'Mastercard',
    VISA: 'Visa',
    AMEX: 'Amex',
};

export const supportedWUPaymentProviders = [
    {
        logo: paymentProviderLogos, alt: 'Payment Providers Logos'
    }
];

export const creditCardOptionsList = [
    {
        label: 'Mastercard',
        value: creditcardOptions.MASTERCARD,
        icon: 'cc-mastercard',
    },
    {
        label: 'Visa',
        value: creditcardOptions.VISA,
        icon: 'cc-visa',
    },
    {
        label: 'American Express',
        value: creditcardOptions.AMEX,
        icon: 'cc-amex',
    },
];
export const defaultCreditCardsAvailable = [
    creditcardOptions.MASTERCARD,
    creditcardOptions.VISA,
    creditcardOptions.AMEX,
];

export const globalPaymentSystems = {
    WESTERN_UNION: 'Western Union',
    CORPAY:'Corpay'
};
export const cardPaymentSystemOptions = [
    {
        label: cardPaymentSystems.NOT_USING,
        value: '',
    },
    {
        label: cardPaymentSystems.SELF_MANAGED,
        value: cardPaymentSystems.SELF_MANAGED,
    },
    {
        label: cardPaymentSystems.PAYMENT_URL,
        value: cardPaymentSystems.PAYMENT_URL,
    },
    {
        label: cardPaymentSystems.EZIDEBIT,
        value: cardPaymentSystems.EZIDEBIT,
    },
    {
        label: 'Payrix',
        value: cardPaymentSystems.INTEGRAPAY,
    },
    {
        label: cardPaymentSystems.ADVAM,
        value: cardPaymentSystems.ADVAM,
    },
];

export const overseasCardPaymentSystemOptions = [
    {
        label: cardPaymentSystems.NOT_USING,
        value: '',
    },
    {
        label: cardPaymentSystems.SELF_MANAGED,
        value: cardPaymentSystems.SELF_MANAGED,
    },
    {
        label: cardPaymentSystems.PAYMENT_URL,
        value: cardPaymentSystems.PAYMENT_URL,
    },
];

/**
 * Data mapping of payment options from API response
 */
export const paymentOptionsDataMapping: DynamicObject = {
    CardPaymentSelectedCreditCards: 'CardPaymentOption.SelectedCreditCards',
    CardPaymentClientReference: 'CardPaymentOption.ClientReference',
    CardPaymentSystem: 'CardPaymentOption.Name',
    CardPaymentSelfManagedDescription:
        'CardPaymentOption.SelfManagedPaymentOption.Description',
    CardPaymentPaymentURL:
        'CardPaymentOption.CreditCardPaymentOption.CreditCardPaymentUrl',
    CardPaymentEziDebitPaymentURL:
        'CardPaymentOption.EziDebitPaymentOption.PaymentUrl',
    CardPaymentEziDebitEDDRURL:
        'CardPaymentOption.EziDebitPaymentOption.EDDRUrl',
    CardPaymentEziDebitEDDRURLExist:
        'CardPaymentOption.EziDebitPaymentOption.EDDRUrlExist',
    CardPaymentEziDebitDigitalKey:
        'CardPaymentOption.EziDebitPaymentOption.MaskedDigitalKey',
    CardPaymentEziDebitPublicKey:
        'CardPaymentOption.EziDebitPaymentOption.MaskedPublicKey',
    CardPaymentIntegraPayUsername:
        'CardPaymentOption.IntegraPayPaymentOption.Username',
    CardPaymentIntegraPayPassword:
        'CardPaymentOption.IntegraPayPaymentOption.MaskedPassword',
    CardPaymentADVAMAccountNumber:
        'CardPaymentOption.AdvamPaymentOption.AccountNumber',
    CardPaymentADVAMUsername: 'CardPaymentOption.AdvamPaymentOption.Username',
    CardPaymentADVAMPassword: 'CardPaymentOption.AdvamPaymentOption.Password',
    OverseasCardPaymentSystem: 'OverseasCardPaymentOption.Name',
    OverseasCardPaymentSelfManagedDescription:
        'OverseasCardPaymentOption.SelfManagedPaymentOption.Description',
    OverseasCardPaymentPaymentURL:
        'OverseasCardPaymentOption.CreditCardPaymentOption.CreditCardPaymentUrl',
    WesternUnionPaymentOptionEnabled: 'WesternUnionPaymentOption.IsEnabled',
    WesternUnionClientId: 'WesternUnionPaymentOption.ClientId',
    WesternUnionServiceId: 'WesternUnionPaymentOption.ServiceId',
    WesternUnionClientReference: 'WesternUnionPaymentOption.ClientReference',
    CorpayPaymentOptionEnabled: 'CorpayPaymentOption.IsEnabled',
    CorpayPaymentOptionStatus: 'CorpayPaymentOption.Status',
    BankPaymentOptionEnabled: 'BankPaymentOption.IsEnabled',
    BankPaymentReferenceInstructions: 'BankPaymentOption.ReferenceNumber',
    BankPaymentAccountName: 'BankPaymentOption.AccountName',
    BankPaymentBSBNumber: 'BankPaymentOption.AccountBSBNumber',
    BankPaymentAccountNumber: 'BankPaymentOption.AccountNumber',
    BankPaymentSwiftCode: 'BankPaymentOption.SwiftCode',
    MailingChequePaymentEnabled: 'MailingChequePaymentOption.IsEnabled',
    EnablePartialPayment: 'EnablePartialPayment'
};

export const notificationsQueryString = {
    OPEN: 'o',
    PAY_NOW: 'p',
    INVOICE_TYPE: 'i',
    REGION: 'region',
    CACHE_KEY: 'cacheKey',
    TICKET_OPTIONS: 'ticketOptions',
};

export const paymentGatewaysWithRedirect = [
    cardPaymentSystems.PAYMENT_URL,
    cardPaymentSystems.EZIDEBIT,
    cardPaymentSystems.INTEGRAPAY,
    cardPaymentSystems.ADVAM,
    globalPaymentSystems.WESTERN_UNION,
    globalPaymentSystems.CORPAY,
];

export const eziDebitPaymentTypes = {
    CREDIT_CARD: 'CreditCard',
    BANK_ACCOUNT: 'BankAccount',
};

export const auOnlyPaymentOptions = [
    cardPaymentSystems.EZIDEBIT,
    cardPaymentSystems.INTEGRAPAY,
    cardPaymentSystems.ADVAM,
];

export const locationValues = {
    topLeft: "topleft",
    topCentre: "topcentre",
    topRight: "topright",
    bottomLeft: "bottomleft",
    bottomCentre: "bottomcentre",
    bottomRight: "bottomright"
}

export const locationOptions: { label: string, value: string }[] = [
    { label: 'Top left', value: locationValues.topLeft },
    { label: 'Top centre', value: locationValues.topCentre },
    { label: 'Top right', value: locationValues.topRight },
    { label: 'Bottom left', value: locationValues.bottomLeft },
    { label: 'Bottom centre', value: locationValues.bottomCentre },
    { label: 'Bottom right', value: locationValues.bottomRight }
]

export const defaultDisplay: CompanyNotificationCustomization = {
    BrandingLocation: locationValues.topLeft,
    CreditUsageWording: 'Your account contains credits that can be applied to the above invoices.',
    IncludeLinkInLetterBlock: true,
    InformUsInstruction: `If there are existing grounds as to why any of these invoices remain unpaid, or you want to let us know when you intend to pay them, please select the appropriate invoices above then click the ${customizationPlaceholders.INFORM_US_LABEL} button.`,
    InformUsTextLabel: 'Inform Us',
    OpeningWording: 'The below invoices remain unpaid by the following amounts',
    CardPaymentCustomization: {
        Heading: '',
        Description: ''
    },
    MailingChequePaymentCustomization: {
        Heading: '',
        Description: ''
    },
    BankPaymentCustomization: {
        Heading: '',
        Description: ''
    }
};