import AutoComplete from 'antd/lib/auto-complete';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal/Modal';
import Select, { SelectValue } from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { API } from 'aws-amplify';
import { clone, forEach, get, includes, isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import queries from '../../graphql/queries.graphql';
import { getUserByEmailAction } from '../../store/users/actions';
import {
    checkIfEmailIsValid,
    checkIsValidJsonString,
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const { Option } = Select;

const FILTER_SEARCH_DEBOUNCE_TIME = 2000; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: any) => void;
    stateValue: string;
    appliedValue: string;
    queryName: string;
    filterField: string | undefined;
    queryFilterName: string;
    sortField: string;
    responseName: string;
    labelField: string | string[];
    updateFiltersFunction: (defaultValue?: any) => void;
    queryFilterNameSub?: string;
    filterSubChecking?: (inputVal: string | undefined | null) => boolean;
    filterMappingUsed?: DynamicObject;
    customError?: string;
}
let timeoutHandle: any = null;

const InputSelectUserSearchWithButton: React.FC<IProps> = ({
    updateField,
    stateValue,
    appliedValue,
    queryName,
    filterField,
    queryFilterName,
    sortField,
    responseName,
    labelField,
    updateFiltersFunction,
    queryFilterNameSub,
    filterSubChecking,
    filterMappingUsed,
    customError,
}: IProps) => {
    const dispatch = useDispatch();
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        fetching: boolean;
        data: [];
        value: string | undefined;
    }>({
        fetching: false,
        data: [],
        value: stateValue || undefined,
    });

    const [showCheckUserModal, setShowCheckUserModal] =
        useState<boolean>(false);

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for getting the filter name in query used for autocomplete
     * @returns string
     */
    const getFilterNameInQueryUsed = () => {
        let filterNameInQueryUsed = queryFilterName;
        if (filterSubChecking && queryFilterNameSub) {
            if (filterSubChecking(value))
                filterNameInQueryUsed = queryFilterNameSub;
        }

        return filterNameInQueryUsed;
    };

    const fetchOptions = (value: string) => {
        // updateField(value);
        if (value.length >= 3) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            let skipCheck = false;

            if (filterSubChecking && !queryFilterNameSub) {
                skipCheck = true;
            }

            if (!skipCheck) {
                timeoutHandle = setTimeout(() => {
                    updateInputState({ value, data: [], fetching: true });

                    getDataOptions(value);
                }, FILTER_SEARCH_DEBOUNCE_TIME);
            }
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({ value, data: [], fetching: false });
        }
    };

    const getDataOptions = (value: string) => {
        const usedFilterNameInQuery = getFilterNameInQueryUsed();
        const usedQueryFilter = usedFilterNameInQuery || filterField;
        if (!usedQueryFilter) return;
        const queryVariables: DynamicObject = customError
            ? {}
            : {
                  PageSize: 10,
                  Skip: 0,
                  Ascending: true,
              };

        queryVariables[usedQueryFilter] = value;
        queryVariables.SortField = sortField;

        const apiQuery = API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        }) as Promise<any>;

        apiQuery
            .then((res: any) => {
                if (unmounted) return;
                const data: DynamicObject[] = [];
                forEach(
                    get(res, `data.${responseName}`),
                    (opt: DynamicObject) => {
                        if (!includes(data, opt)) {
                            data.push(opt);
                        }
                    }
                );

                updateInputState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;
                updateInputState({ value, data: [], fetching: false });
            });
    };

    const setValueFromStateVal = () => {
        if (stateValue !== value) {
            updateInputState({ value: stateValue });
        }
    };

    useEffect(setValueFromStateVal, [stateValue]);

    const handleChange = (value: SelectValue) => {
        updateInputState({
            value: value as string,
            // data: [],
            fetching: false,
        });
    };

    const handleSelect = (value: SelectValue) => {
        updateField(value);
    };

    const onOkClick = () => {
        if (timeoutHandle) clearTimeout(timeoutHandle);
        const isValidEmail = checkIfEmailIsValid(value);

        if (
            value &&
            (checkIsValidJsonString(value) ||
                checkIsValidJsonString(appliedValue) ||
                isValidEmail)
        ) {
            updateInputState({
                fetching: false,
                data: [],
                value: value || undefined,
            });
            let valueUsed = clone(value);
            if (isValidEmail && stateValue !== value) {
                setShowCheckUserModal(true);
                return dispatch(
                    getUserByEmailAction(valueUsed, (res: DynamicObject) => {
                        setShowCheckUserModal(false);
                        if (get(res, 'IsSuccess') === true) {
                            const userId = get(res, 'User.UserId');
                            if (userId) {
                                return updateFiltersFunction(
                                    JSON.stringify(res)
                                );
                            }
                        }
                        return updateFiltersFunction(valueUsed);
                    })
                );
            }

            if (stateValue === value) {
                return updateFiltersFunction(appliedValue);
            } else {
                if (checkIsValidJsonString(value)) {
                    return updateFiltersFunction(value);
                }
            }
        }

        if (!isEmpty(value)) {
            const contentError =
                customError ||
                'Please enter a valid email or select a user from the list!';

            return Modal.error({
                title: 'Error',
                content: contentError,
                getContainer: populatePopoverContainer(containerRef),
                onOk: () => {
                    if (value) getDataOptions(value);
                },
            });
        } else {
            return updateFiltersFunction(null);
        }
    };

    const { fetching, data, value } = inputState;
    const getSelectOptions: () => any = () => {
        if (!filterField) return null;
        return map(data, (d: DynamicObject) => {
            let label = '';
            forEach(labelField, (lf: string) => {
                if (label !== '') label += ' ';
                label += get(d, lf) || '';
            });
            const keyUssed =
                get(d, filterField) ||
                get(d, get(filterMappingUsed, filterField, '--'));
            return (
                <Option key={keyUssed} value={JSON.stringify(d)}>
                    {label}
                </Option>
            );
        });
    };

    return (
        <div className="pop-action-content" ref={containerRef}>
            <div>
                <AutoComplete
                    className="autocomplete-input"
                    value={value}
                    dataSource={getSelectOptions()}
                    notFoundContent={null}
                    filterOption={false}
                    onSearch={fetchOptions}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    dropdownClassName="autocomplete-input-dropdown"
                    getPopupContainer={populatePopoverContainer(containerRef)}
                >
                    <Input
                        prefix={
                            fetching ? (
                                <Spin
                                    indicator={
                                        <Icon
                                            type="loading"
                                            style={{ fontSize: 12 }}
                                            spin
                                        />
                                    }
                                />
                            ) : (
                                <Icon type="search" />
                            )
                        }
                        placeholder="Search"
                        allowClear
                        onPressEnter={onOkClick}
                        value={value}
                    />
                </AutoComplete>
            </div>
            <div className="ok-button-container">
                <Button type="primary" onClick={onOkClick}>
                    Ok
                </Button>
            </div>
            {showCheckUserModal && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={`Checking user records`}
                        modalVisible={showCheckUserModal}
                        displayMessage={`Please wait while checking if the email entered is an existing user. . .`}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </div>
    );
};

export default InputSelectUserSearchWithButton;
