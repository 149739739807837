import { action } from 'typesafe-actions';
import {
    InviteActionTypes,
    CompanyInviteCreatePayload,
    CompanyInviteCreateResponse,
    CompanyInviteValidateResponse,
    CompanyInviteLinkResponse,
} from './types';

export const companyInviteCreateAction = (
    payload: CompanyInviteCreatePayload,
    callback?: (response: CompanyInviteCreateResponse) => void
) =>
    action(InviteActionTypes.COMPANY_INVITE_CREATE, {
        payload,
        callback,
    });

export const companyInviteValidateAction = (
    inviteToken: string,
    callback?: (response: CompanyInviteValidateResponse) => void
) =>
    action(InviteActionTypes.COMPANY_INVITE_VALIDATE, {
        inviteToken,
        callback,
    });

export const companyInviteLinkAction = (
    inviteToken: string,
    callback?: (response: CompanyInviteLinkResponse) => void
) =>
    action(InviteActionTypes.COMPANY_INVITE_LINK, {
        inviteToken,
        callback,
    });

export const companyInviteLinkingInProgressUpdateAction = (
    inProgress: boolean
) =>
    action(InviteActionTypes.COMPANY_INVITE_LINKING_IN_PROGRESS_UPDATE, {
        inProgress,
    });
