import { Drawer, Empty, List, Modal } from 'antd';
import Cookies from 'js-cookie';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_ANNOUNCEMENTS_ON_LOGIN_TOKEN_NAME } from '../../config/config';
import { IsOrganisationViewAttribute } from '../../constants/authUserAttributes';
import {
    dateFormatYYYYMMDDT00Dash,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import '../../less/announcements.less';
import { ApplicationState } from '../../store';
import {
    getProductAnnouncementsRequestAction,
    setLoginProductAnnouncementFetching,
} from '../../store/notifications/actions';
import { Organisation } from '../../store/organisations/types';
import { getUserLastLoginDateAction } from '../../store/users/actions';
import { getCurrentUser } from '../../store/users/sagas';
import {
    getLoadingSkeletons,
    setShouldFetchAnnouncementsOnLogin,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import AnnouncementPreview from '../announcements/AnnouncementPreview';
import { withDateFormatHandler } from './DateFormatHandler';

export const withAnnouncementsHandler = (WrappedComponent: any) => {
    interface IProps {
        formatDateToDateObjectUTC: (
            date: any,
            fromFormat?: string | null,
            isLocalTime?: boolean
        ) => any;
    }
    const drawerHeight = window.innerHeight - 60;
    const drawerListPadding = 24;
    const drawerStyle: {
        height: number;
        overflowY: 'auto';
        overflowX: 'hidden';
        paddingLeft: number;
        paddingRight: number;
    } = {
        height: drawerHeight,
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: drawerListPadding,
        paddingRight: drawerListPadding,
    };
    const listItemStyle = {
        marginLeft: -drawerListPadding,
        marginRight: -drawerListPadding,
    };
    const listItemAdjustment = 12;
    const divContainerStyle = {
        marginTop: -listItemAdjustment,
        marginBottom: -listItemAdjustment,
        padding: ` 12px ${drawerListPadding}px`,
    };

    const AnnouncementsHandler: React.FC<IProps> = (props: IProps) => {
        const dispatch = useDispatch();
        const unmountedRef = useRef<any>(null);
        const selectedUserCompany = useSelector(
            (state: ApplicationState) => state.companies.selectedUserCompany
        );
        const selectedUserOrganisation: Organisation = useSelector(
            (state: ApplicationState) =>
                state.organisations.selectedUserOrganisation
        );
        const currentUser = useSelector(getCurrentUser);
        const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';

        const [announcementsState, setAnnouncementsState] = useState<{
            loading: boolean;
            showDrawer: boolean;
            data: [];
            isTwoMonths: boolean;
        }>({
            loading: false,
            showDrawer: false,
            data: [],
            isTwoMonths: true,
        });

        /**
         * Common function for updating the announcements state
         */
        const updateAnnouncementsState = (
            announcementsStateObject: DynamicObject
        ) => {
            setAnnouncementsState({
                ...announcementsState,
                ...announcementsStateObject,
            });
        };

        /**
         * Initial function called upon load.
         */
        const initializeDataFetching = () => {
            if (
                (!isOrgView && !selectedUserCompany) ||
                (isOrgView && !selectedUserOrganisation)
            )
                return;

            if (
                !announcementsState.isTwoMonths &&
                announcementsState.showDrawer
            ) {
                const minDate = moment()
                    .subtract(4, 'months')
                    .format(dateFormatYYYYMMDDTHHmmssDash);
                const usedMinDate = props
                    .formatDateToDateObjectUTC(
                        minDate,
                        dateFormatYYYYMMDDTHHmmssDash,
                        true
                    )
                    .format(dateFormatYYYYMMDDT00Dash);
                getProductAnnouncements(usedMinDate);
            } else if (announcementsState.isTwoMonths) {
                const willFetch =
                    Cookies.get(FETCH_ANNOUNCEMENTS_ON_LOGIN_TOKEN_NAME) !==
                    'false';

                if (willFetch) {
                    setShouldFetchAnnouncementsOnLogin('false');
                    dispatch(setLoginProductAnnouncementFetching(true));
                    getMinDateToUse();
                }
            }
        };

        useEffect(initializeDataFetching, [
            isOrgView,
            selectedUserCompany,
            selectedUserOrganisation,
            announcementsState.isTwoMonths,
            announcementsState.showDrawer,
        ]);

        useEffect(() => {
            unmountedRef.current = false;
            return () => {
                unmountedRef.current = true;
            };
        }, []);

        /**
         * Function for getting the min date to use (either the 2months ago date from now or the last login datetime. Whichever is latest).
         * Will only be called when announcementState.isTwoMonths is true.
         */
        const getMinDateToUse = () => {
            updateAnnouncementsState({ loading: true, data: [] });

            if (announcementsState.isTwoMonths) {
                dispatch(
                    getUserLastLoginDateAction((res: DynamicObject) => {
                        if (res.IsSuccess) {
                            let usedMinDate = moment()
                                .subtract(2, 'months')
                                .format(dateFormatYYYYMMDDT00Dash);

                            const lastLoginDatetime = get(
                                res,
                                'LastLoggedInDate'
                            );
                            if (
                                lastLoginDatetime &&
                                moment(lastLoginDatetime).isAfter(
                                    moment(usedMinDate)
                                )
                            ) {
                                usedMinDate = moment(lastLoginDatetime).format(
                                    dateFormatYYYYMMDDTHHmmssDash
                                );
                            }

                            getProductAnnouncements(usedMinDate);
                        } else {
                            if (announcementsState.isTwoMonths) {
                                dispatch(
                                    setLoginProductAnnouncementFetching(false)
                                );
                            }
                            Modal.error({
                                title: 'Error',
                                content:
                                    get(res, 'Messages.0') ||
                                    'Error encountered while fetching last login date!',
                            });
                        }
                    })
                );
            }
        };
        /**
         * Function for getting the product announcement list.
         * @param minDate - in utc
         */
        const getProductAnnouncements = (minDate: string) => {
            const usedMinDate = props
                .formatDateToDateObjectUTC(
                    minDate,
                    dateFormatYYYYMMDDTHHmmssDash,
                    true
                )
                .format(dateFormatYYYYMMDDTHHmmssDash);
            const usedMaxDate = props
                .formatDateToDateObjectUTC(moment(), undefined, true)
                .format(dateFormatYYYYMMDDTHHmmssDash);
            dispatch(
                getProductAnnouncementsRequestAction(
                    usedMinDate,
                    usedMaxDate,
                    (res: DynamicObject) => {
                        if (unmountedRef.current) return;
                        dispatch(setLoginProductAnnouncementFetching(false));
                        if (res.IsSuccess) {
                            const data = get(res, 'data') || [];

                            updateAnnouncementsState({
                                loading: false,
                                data,
                                showDrawer: !(
                                    announcementsState.isTwoMonths &&
                                    isEmpty(data)
                                ),
                            });
                        } else {
                            Modal.error({
                                title: 'Error',
                                content:
                                    get(res, 'Messages.0') ||
                                    'Error encountered while fetching product announcements!',
                            });
                        }
                    }
                )
            );
        };

        /**
         * Function for updating the visibility of the announcements drawer
         */
        const handleAnnouncementVisibility = (visible: boolean) => {
            updateAnnouncementsState({
                showDrawer: visible,
            });
        };

        /**
         * Function for rendering the announcement list item.
         */
        const renderAnnouncementListItem = (item: DynamicObject) => {
            const { ProductAnnouncementId } = item;
            return (
                <List.Item
                    key={ProductAnnouncementId}
                    className="announcement-item"
                    style={listItemStyle}
                >
                    <div style={divContainerStyle}>
                        <AnnouncementPreview formState={item} />
                    </div>
                </List.Item>
            );
        };

        /**
         * Function for showing the panel when `What's new` menu item is clicked.
         */
        const showWhatsNewPanel = () => {
            updateAnnouncementsState({
                showDrawer: true,
                isTwoMonths: false,
                loading: true,
                data: [],
            });
        };

        return (
            <>
                <Drawer
                    title={
                        <div className="center-flex fs-20">Product Updates</div>
                    }
                    width={500}
                    onClose={() => handleAnnouncementVisibility(false)}
                    visible={announcementsState.showDrawer}
                    className="announcements-menu"
                >
                    <div style={drawerStyle}>
                        {announcementsState.loading ? (
                            getLoadingSkeletons(3, true)
                        ) : (
                            <List
                                className="with-border"
                                dataSource={announcementsState.data}
                                itemLayout="vertical"
                                renderItem={renderAnnouncementListItem}
                                locale={{
                                    emptyText: (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="No announcements found"
                                        />
                                    ),
                                }}
                            />
                        )}
                    </div>
                </Drawer>
                <WrappedComponent
                    {...props}
                    showWhatsNewPanel={showWhatsNewPanel}
                />
            </>
        );
    };

    return withDateFormatHandler(AnnouncementsHandler);
};
