/**
 * Component for populating the TaskActionManuallyAllocatePaymentPanel panel when clicking on `Action` button for Manual Allocate Payment task items.
 */

import {
    Button,
    Col,
    Form,
    Row,
    Skeleton,
    Select,
    Modal
} from 'antd';
import { get, map, times,filter,forEach, includes, isEmpty, first } from 'lodash';
import React, { useEffect, useState,lazy,Suspense} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import {
    getTriggersForWorkflowRequestAction
} from '../../../store/tasks/actions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { ApplicationState } from '../../../store';
import { Task,WorkflowTransition } from '../../../store/tasks/types';
import {
    manualAllocatePaymentActionOptions,
} from '../../../constants/tasksSortAndFilters';
import { CompanyUserRole } from '../../../store/companies/types';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';
import {paymentDisregardTasksRequest, createCreditAsOverpaymentTasksRequest, organisationPaymentDisregardTasksRequest, createOrganisationCreditAsOverpaymentTasksRequest } from '../../../store/payments/actions';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import { withNumberFormatHandler } from '../../common/NumberFormatHandler';
import { getCurrentUser } from '../../../store/users/sagas';
import { IsOrganisationViewAttribute } from '../../../constants/authUserAttributes';

const { confirm } = Modal;

const TaskActionRequestRemittanceAdviceContent = lazy(
    () => import('./TaskActionRequestRemittanceAdviceContent')
);
const TaskActionPaymentLinkRemittanceContent = lazy(
    () => import('./TaskActionPaymentLinkRemittanceContent')
);
const TaskActionManualAllocatePaymentDrawer = lazy(
    () => import('./TaskActionManualAllocatePaymentDrawer')
);

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly formatCurrency: (amount: number) => JSX.Element;
    readonly form?: any;
    readonly selectedTaskId: string;
    readonly customerLabel: string;
    readonly getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        sortBy: string,
        sortAscending: boolean,
        recordLimit?: number,
    };
    readonly hasMultipleCustomer: boolean;
    readonly paymentVerificationStatus: any;
    readonly selectedTaskIds: string[];
}
const { Item: FormItem } = Form;
const { Option } = Select;

const formFields = {
    SELECT_ACTION: 'Trigger',
    COMMENT: 'Comment',
    INCLUDE_COMMENT: 'IncludeComment',
};

const TaskActionManuallyAllocatePaymentPanelOptionsContent: React.FC<IProps> = ({
    visible,
    closePanel,
    formatCurrency,
    form,
    selectedTaskId,
    customerLabel,
    getSelectedTasksValues,
    paymentVerificationStatus,
    selectedTaskIds,
    hasMultipleCustomer
}: IProps) => {
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const [actionOptions, setActionOptions] = useState<{
        options: string[];
        workflowTransitionList: DynamicObject[];
        loading: boolean;
    }>({
        options: [],
        workflowTransitionList: [],
        loading: false,
    });

    const [nextStepInfo, setNextStepInfo] = useState<{
        workflowName: string;
        requestRemittanceAdviceVisible: boolean;
        manuallyAllocateRemittanceAdviceVisible: boolean;
        manuallyAllocatePaymentVisible: boolean;
    }>({
        workflowName: '',
        requestRemittanceAdviceVisible: false,
        manuallyAllocateRemittanceAdviceVisible: false,
        manuallyAllocatePaymentVisible: false
    });

    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields } = form;
    const [submitDisregardLoading, setSubmitDisregardLoading] = useState<boolean>(false);
    const [submitOverpaymentLoading, setSubmitOverpaymentLoading] = useState<boolean>(false); 
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const { keysToUse, allExcept, filterObject, sortBy, sortAscending, recordLimit } = getSelectedTasksValues();

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

     /**
     * Function for updating the `showConditions` state.
     * @param selectionObject - must conform to `showConditions` state
     */
     const updateNextStepInfo = (newValue: {}) => {
        setNextStepInfo(current => ({
            ...current,
            ...newValue,
        }));
    };

    /**
         * Function for resetting the action drawer values (state).
         * @param refreshList
         */
    const resetActionDrawerValues = () => {
        updateNextStepInfo({
            workflowName: '',
            requestRemittanceAdviceVisible: false,
            manuallyAllocatePaymentVisible: false,
            manuallyAllocateRemittanceAdviceVisible: false
        });
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } else {
            getActionOptions();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when clicking the `Next` button
     */
    const handleNextButton = () => {
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                updateNextStepInfo({
                    workflowName: values.Trigger,
                    requestRemittanceAdviceVisible: values.Trigger === manualAllocatePaymentActionOptions.RequestRemittanceAdvice,
                    manuallyAllocatePaymentVisible: values.Trigger === manualAllocatePaymentActionOptions.ManuallyAssociatePayment,
                    manuallyAllocateRemittanceAdviceVisible: values.Trigger === manualAllocatePaymentActionOptions.ManuallyAssociateRemmitanceAdvice
                });

                if(values.Trigger === manualAllocatePaymentActionOptions.CreateCreditAsOverpayment){
                    handleCreateCreditAsOverpaymentForm();
                }

                if(values.Trigger === manualAllocatePaymentActionOptions.DisregardPayment){
                    handleDisregardTasksForm();
                }
            }
        });
    };

    
     /**
     * Function called when clicking the `Action item` button
     * Create credit as overpayment
     */
     const handleCreateCreditAsOverpaymentForm = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Warning',
            content: (
                <div>
                   {`This action will create a credit in the IODM System${selectedTaskIds.length > 1 ? '.' : ` for the remaining amount of ${getAmountToAllocate()}.`}`}
                    <div className="mt-8">
                        Are you sure that you wish to create a credit?
                    </div>
                </div>
            ),
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
            onOk() {
                CreateCreditAsOverpayment();
            }
        });
    };

     /**
     * Function called when clicking the `Action item` button
     * Disregard Payment
     */
     const handleDisregardTasksForm = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Warning',
            content: (
                <div>
                    Disregarding the payment will close the task.
                    <div className="mt-8">
                        Would you like to continue?
                    </div>
                </div>
            ),
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
            onOk() {
                disregardPayment();
            }
        });
    };
    const disregardPayment = () => {
        setSubmitDisregardLoading(true);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        const payload = {
            filter: filterObject,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            sortBy, 
            sortAscending, 
            recordLimit,
            CompanyId: selectedCompanyId
        };
        const taskAction = isOrgView ? organisationPaymentDisregardTasksRequest : paymentDisregardTasksRequest;
        dispatch(
            taskAction({
                ...payload,
                callback: disregardPaymentModal,
            })
        )
    };

    const disregardPaymentModal = ({
        IsSuccess,
        Messages,
        RefetchList = true,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        RefetchList: boolean;
    }) => {
        setSubmitDisregardLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Payment has been disregarded successfully!',
                onOk: () => {
                    if (closePanel) closePanel(RefetchList);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to disregard payment!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }   
    }


    const CreateCreditAsOverpayment = () => {
        setSubmitOverpaymentLoading(true);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        const payload = {
            filter: filterObject,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            sortBy, 
            sortAscending, 
            recordLimit,
            CompanyId: selectedCompanyId
        };
        const taskAction = isOrgView ? createOrganisationCreditAsOverpaymentTasksRequest : createCreditAsOverpaymentTasksRequest;
        
        dispatch(
            taskAction({
                ...payload,
                callback: createCreditAsOverpaymentModal,
            })
        )
    };

    const createCreditAsOverpaymentModal = ({
        IsSuccess,
        Messages,
        RefetchList = true,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        RefetchList: boolean;
    }) => {
        setSubmitOverpaymentLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Credit has been created successfully!',
                onOk: () => {
                    if (closePanel) closePanel(RefetchList);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to create credit!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }   
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    /**
     * Function for updating `actionOptions` state.
     * @param actionOptionsObject - must conform to `actionOptions` state
     */
    const updateActionOptions = (actionOptionsObject: {}) => {
        setActionOptions({
            ...actionOptions,
            ...actionOptionsObject,
        });
    };

    const checkVerificationStatus = includes(paymentVerificationStatus, 'PartiallyAllocated');

    /**
     * Function for fetching the action options to be used in this panel based on the workflowId and workflow statename.
     */
    const getActionOptions = () => {
        if (selectedTaskIds.length === 1) {
            updateActionOptions({
                loading: true,
            });
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );

            const customer = get(record, 'Customer');
            const companyId = isOrgView ? get(record, 'CompanyId') : get(selectedUserCompany, 'Company.CompanyId');

            dispatch(
                getTriggersForWorkflowRequestAction(
                    get(record, 'Workflow.WorkflowId'),
                    get(record, 'Workflow.StateName'),
                    companyId,
                    (res: WorkflowTransition[]) => {
                        const options: string[] = [];
                        const workflowTransitionList: DynamicObject = {};
                        forEach(res, (r: WorkflowTransition) => {
                            const trigger = get(r, 'Trigger');
                            if (!includes(options, trigger)) {
                                options.push(trigger);
                            }
                            workflowTransitionList[trigger] = r;
                        });
                        if (!checkVerificationStatus) 
                            options.push("Disregard payment");
                        if(customer)
                            options.push("Create credit as overpayment");
                        updateActionOptions({
                            options,
                            workflowTransitionList,
                            loading: false,
                        });
                    }
                )
            );
        } else {
            const options: string[] = [];
            const workflowTransitionList: string[] = [];
            
            if(!checkVerificationStatus)
            {
                options.push("Disregard payment");
                workflowTransitionList.push("Disregard payment");
            }
                
            if(!hasMultipleCustomer)
            {
                options.push("Create credit as overpayment");
                workflowTransitionList.push("Create credit as overpayment");
            }

            updateActionOptions({
                options,
                workflowTransitionList,
                loading: false,
            });
        }
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : amount;
    };

    const getAmountToAllocate = () => {
        const record = get(
            filter(tasksList, ['Id', selectedTaskId]),
            0
        );

        const PaidAmount = get(record, 'Payment.PaidAmount') - get(record,'Payment.AllocatedAmount');

        return handleFormatCurrency(PaidAmount);
    }

    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (actionOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {         
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );

            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-16" type="flex" align="middle">
                                <Col span={24}>
                                    Choose which action to complete then click Next.
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-10" type="flex" >
                                <Col span={24}>
                                <FormItem
                                        {...formItemLayout}
                                        label="Action"
                                    >
                                        {getFieldDecorator(
                                            formFields.SELECT_ACTION,
                                            {
                                                initialValue: get(
                                                    actionOptions.options,
                                                    '0'
                                                ),
                                            }
                                        )(
                                            <Select
                                                style={{ width: '100%' }}
                                                loading={actionOptions.loading}
                                            >
                                                {map(
                                                    actionOptions.options,
                                                    (option) => (
                                                        <Option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    <Suspense fallback={null}>
                        <div ref={null}>
                            <TaskActionRequestRemittanceAdviceContent
                                visible={nextStepInfo.requestRemittanceAdviceVisible}
                                record={record}
                                selectedTaskId={selectedTaskId}
                                panelTitle={nextStepInfo.workflowName}
                                customerLabel={customerLabel}
                                closePanel={closePanel}
                                closePaymentRemittancePanel={resetActionDrawerValues}
                            />
                            <TaskActionPaymentLinkRemittanceContent
                                visible={nextStepInfo.manuallyAllocateRemittanceAdviceVisible}
                                record={record}
                                selectedTaskId={selectedTaskId}
                                panelTitle={nextStepInfo.workflowName}
                                customerLabel={customerLabel}
                                closePanel={closePanel}
                                closePaymentRemittancePanel={resetActionDrawerValues}
                            />
                            <TaskActionManualAllocatePaymentDrawer
                                visible={nextStepInfo.manuallyAllocatePaymentVisible}
                                selectedTaskId={selectedTaskId}
                                closePanel={closePanel}
                                goBack={resetActionDrawerValues}
                                panelTitle={nextStepInfo.workflowName}
                            />
                        </div>
                        <ModalWithSpinner
                                modalTitle="Creating credit from overpayment"
                                modalVisible={submitOverpaymentLoading}
                                displayMessage="Please wait while creating credit . . ."
                        />
                            <ModalWithSpinner
                                modalTitle="Disregarding Payment(s)"
                                modalVisible={submitDisregardLoading}
                                displayMessage="Please wait while disregarding payment(s) . . ."
                            />
                    </Suspense>
                </Form>
                
            );
        }
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    {checkVerificationStatus &&
                        <span style={{ color: "gray" }}>
                            Note: To disregard this payment, you must first unallocate any amounts made to invoices.
                        </span>
                    }
                </Row>
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleNextButton}
                        >
                            Next
                        </Button>
                        <Button className="buttonGrey" onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskActionManuallyAllocatePaymentPanelOptionsContent);

export default withRouter(
    withNumberFormatHandler(
        withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
    )
);
