export const reportHistorySortValues = {
    CREATED_DATE: 'Report created date'
};

export const reportSortByOptions = [
    { label: 'Report created date', value: reportHistorySortValues.CREATED_DATE },
];

export const preConfiguredFilters = {
    ALL_REPORTS: 'All reports',
};

export const reportsTableFilterOptions = [
    { label: 'All reports', value: preConfiguredFilters.ALL_REPORTS },
];

export const dateSelectOptions = {
    WITHIN_THIS_WEEK: 'Within this week',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    TODAY: 'Today'
};

const {
    TODAY,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    LAST_WEEK_UP_TO_NOW,
    WITHIN_THIS_WEEK
} = dateSelectOptions;

export const reportsCreatedInOptions = [
    TODAY,
    WITHIN_THIS_WEEK,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE
];

export enum ReportStatus {
    InProgress = 'InProgress',
    Complete = 'Complete',
    Failed = 'Failed',
};

export const reportStateLabels: { [key: string]: string } = {
    [ReportStatus.InProgress]: 'In progress',
    [ReportStatus.Complete]: 'Complete',
    [ReportStatus.Failed]: 'Failed',
};

export const reportStateOptions = [
    {
        label: reportStateLabels.InProgress,
        value: 4,
    },
    {
        label: reportStateLabels.Failed,
        value: 2,
    },
    {
        label: reportStateLabels.Complete,
        value: 1,
    },
];

export enum ReportType {
    Page = 1,
    Dashboard = 2,
    CompleteReport = 4
};

export const ReportTypeString = {
    Page: 'Page',
    Dashboard: 'Dashboard',
    CompleteReport: 'CompleteReport'
}