/**
 * Component for handling accounting system selections.
 */

import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { companyImportTypes } from '../../constants/settings';
import { ApplicationState } from '../../store';

export const withAccountingSystemHandler = (WrappedComponent: any) => {
    const AccountingSystemHandler: React.FC<any> = (props: any) => {
        const importType = useSelector((state: ApplicationState) =>
            get(state.companies.selectedUserCompany, 'Company.ImportType')
        );

        const isUsingCloudImportType = importType === companyImportTypes.CLOUD;

        return (
            <WrappedComponent
                {...props}
                isUsingCloudImportType={isUsingCloudImportType}
            />
        );
    };

    return AccountingSystemHandler;
};
