/**
 * Component for creating an alert section with spinner and message.
 */

import React from 'react';
import { Alert, Spin } from 'antd';

interface IProps {
    readonly displayMessage?: string;
    readonly spinnerSize?: 'small' | 'default' | 'large';
    readonly alertType?: 'success' | 'info' | 'warning' | 'error' | undefined;
    readonly displayMessageStyle?: object;
    readonly noAlert?: boolean;
}
const LoadingSpinner: React.FC<IProps> = ({
    displayMessage,
    spinnerSize,
    alertType,
    displayMessageStyle,
    noAlert,
}: IProps) => {
    return (
        <div className="ta-center h-100">
            {noAlert ? (
                <div className="alert-content-100" style={{ display: 'flex' }}>
                    <Spin spinning={true} size={spinnerSize || 'large'} />
                    <p style={{ ...displayMessageStyle }}>{displayMessage}</p>
                </div>
            ) : (
                <Alert
                    className="h-100"
                    style={{ maxWidth: window.innerWidth - 40 }}
                    message={
                        <div
                            className="alert-content-100"
                            style={{ display: 'flex' }}
                        >
                            <Spin
                                spinning={true}
                                size={spinnerSize || 'large'}
                            />
                            <p style={{ ...displayMessageStyle }}>
                                {displayMessage}
                            </p>
                        </div>
                    }
                    type={alertType || 'info'}
                />
            )}
        </div>
    );
};

export default LoadingSpinner;
