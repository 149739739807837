import Cookies from 'js-cookie';
import { forEach } from 'lodash';
const MEMORY_KEY_PREFIX = '@IODMStorage:';
let dataMemory: any = {};

class SessionStorage {
    static syncPromise: any = null;
    /**
     * This is used to set a specific item in storage.
     */
    static setItem(key: any, value: any) {
        Cookies.set(MEMORY_KEY_PREFIX + key, value);

        dataMemory[key] = value;
        return dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage.
     */
    static getItem(key: any) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key)
            ? dataMemory[key]
            : undefined;
    }

    /**
     * This is used to remove an item from storage.
     */
    static removeItem(key: any) {
        Cookies.remove(MEMORY_KEY_PREFIX + key);
        return delete dataMemory[key];
    }

    /**
     * This is used to clear the storage.
     */
    static clear() {
        dataMemory = {};
        return dataMemory;
    }

    /**
     * Will sync the SessionStorage data from AsyncStorage to storageWindow SessionStorage.
     */
    static sync() {
        if (!SessionStorage.syncPromise) {
            SessionStorage.syncPromise = new Promise((res, _rej) => {
                const allCookies = Cookies.get();
                forEach(allCookies, (value: any, key: any) => {
                    const memoryKey = key.replace(MEMORY_KEY_PREFIX, '');
                    dataMemory[memoryKey] = value;
                });
                res();
            });
        }
        return SessionStorage.syncPromise;
    }
}

export default SessionStorage;
