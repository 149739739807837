import React, { memo } from 'react';
import ReactQuill from 'react-quill';

type ReactQuillProps = React.ComponentProps<typeof ReactQuill> & {
    rerenderTrigger?: any
}

const ReactQuillMemoized = React.forwardRef<ReactQuill, ReactQuillProps>((props, ref) => {
    return <ReactQuill {...props} ref={ref} />;
});

const arePropsEqual = ({ rerenderTrigger: prevProps }: ReactQuillProps, { rerenderTrigger: newProps }: ReactQuillProps) => {
    return prevProps === newProps;
};

export default memo(ReactQuillMemoized, arePropsEqual)