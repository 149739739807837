/**
 * File for the `Top customers` widget form.
 */

import { Col, Divider, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { filter, get, includes, isUndefined, map, toLower, isObject, values, capitalize } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { amountTypeOptions } from '../../constants/customersSortAndFilters';
import { AtbViewType, hiddenCloudImportFields } from '../../constants/settings';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    populatePopoverContainer,
    replaceInstancesOfCustomerString,
    useCompanyFlagValue,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { customFieldIndicator, customerFieldIndicator } from '../common/FilterBar';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import {
    customersATBCalculationOptions,
    getCustomersWidgetFilters
} from '../../constants/customersSortAndFilters';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import conversationLineSaga from '../../store/conversationLine/sagas';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
    organisationCurrenciesAll?: DynamicObject[];
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayColumnOptions = {
    CustomerCode: {
        label: 'Customer code',
        value: 'CustomerCode',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    CompanyName: {
        label: 'Company name',
        value: 'CompanyName',
        defaultChecked: false,
        OrgViewOnly: true,
    },
    IsVip: {
        label: 'VIP',
        value: 'IsVip',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    CustomerName: {
        label: 'Customer name',
        value: 'CustomerName',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    FirstName: {
        label: 'First name',
        value: 'FirstName',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    LastName: {
        label: 'Last name',
        value: 'LastName',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    AddressLine1: {
        label: 'Address Line 1',
        value: 'AddressLine1',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    AddressLine2: {
        label: 'Address Line 2',
        value: 'AddressLine2',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    Suburb: {
        label: 'Suburb',
        value: 'Suburb',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    Postcode: {
        label: 'Postcode',
        value: 'Postcode',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    Country: {
        label: 'Country',
        value: 'Country',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    Email: {
        label: 'Email',
        value: 'Email',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    MobileNumber: {
        label: 'Mobile Number',
        value: 'MobileNumber',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    TotalOwing: {
        label: 'Amount owed',
        value: 'TotalOwing',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    TotalOverdue: {
        label: 'Amount total',
        value: 'TotalOverdue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
};

export const displayColumnOptions1 = {
    TotalTicket: {
        label: 'Total Ticket',
        value: 'TotalTicket',
        defaultChecked: false,
        OrgViewOnly: false,
    },
   CustomerComment: {
        label: 'Customer Comment',
        value: 'CustomerComment',
        defaultChecked: false,
        OrgViewOnly: false,
   }, 
   Attachments: {
        label: 'Attachments',
        value: 'Attachments',
        defaultChecked: false,
        OrgViewOnly: false,
   },
   WorkflowName: {
        label: 'Workflow Name',
        value: 'WorkflowName',
        defaultChecked: false,
        OrgViewOnly: false,
   },
    CompanyName: {
        label: 'Company name',
        value: 'CompanyName',
        defaultChecked: true,
        OrgViewOnly: true,
    },
    PaymentBehaviourInsights: {
        label: 'Payment Behaviour Insights',
        value: 'PaymentBehaviourInsights',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    [`Customer${showCustomFieldsIndicator}`]: {
        label: 'Customer custom fields',
        value: `Customer${showCustomFieldsIndicator}`,
        defaultChecked: false,
        customFieldType: CUSTOM_FIELD_TYPES.CUSTOMER,
        OrgViewOnly: false,
    },
};

export const calendarViewColumnOptions = {
    CurrentFromCreate: {
        label: 'Current From Create',
        value: 'CurrentFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    FirstMonthFromCreate: {
        label: 'First Month From Create',
        value: 'FirstMonthFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    SecondMonthFromCreate: {
        label: 'Second Month From Create',
        value: 'SecondMonthFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    ThirdMonthFromCreate: {
        label: 'Third Month From Create',
        value: 'ThirdMonthFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    CurrentFromDue: {
        label: 'Current From Due',
        value: 'CurrentFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    FirstMonthFromDue: {
        label: 'First Month From Due',
        value: 'FirstMonthFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    SecondMonthFromDue: {
        label: 'Second Month From Due',
        value: 'SecondMonthFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    ThirdMonthFromDue: {
        label: 'Third Month From Due',
        value: 'ThirdMonthFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    NotDue: {
        label: 'Not Due',
        value: 'NotDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
};

export const defaultATBColumnOptions = {
    CurrentFromCreate: {
        label: 'Current From Create',
        value: 'CurrentFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    ThirtyDaysFromCreate: {
        label: 'Thirty Days From Create',
        value: 'ThirtyDaysFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    SixtyDaysFromCreate: {
        label: 'Sixty Days From Create',
        value: 'SixtyDaysFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    NinetyPlusDaysFromCreate: {
        label: 'Ninety Plus Days From Create',
        value: 'NinetyPlusDaysFromCreate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    CurrentFromDue: {
        label: 'Current From Due',
        value: 'CurrentFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    ThirtyDaysFromDue: {
        label: 'Thirty Days From Due',
        value: 'ThirtyDaysFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    SixtyDaysFromDue: {
        label: 'Sixty Days From Due',
        value: 'SixtyDaysFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    NinetyPlusDaysFromDue: {
        label: 'Ninety Plus Days From Due',
        value: 'NinetyPlusDaysFromDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    NotDue: {
        label: 'Not Due',
        value: 'NotDue',
        defaultChecked: false,
        OrgViewOnly: false,
    },
}

export const hiddenTableColumns = [
    displayColumnOptions1[`Customer${showCustomFieldsIndicator}`].value,
];

export const displayColumnOptionsWithCalendarView = {
    ...displayColumnOptions,
    ...calendarViewColumnOptions,
    ...displayColumnOptions1
}; 

export const displayColumnOptionsWithDefaultView = {
    ...displayColumnOptions,
    ...defaultATBColumnOptions ,
    ...displayColumnOptions1
}; 
export const displayRowCountOptions = [5, 10, 15, 20];

const TopCustomersWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    isUsingCloudImportType,
    isOrgView,
    getFieldValue,
    setFieldsValue,
    organisationCurrenciesAll,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);

    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);
    const companyIsDueDateView = useCompanyFlagValue(AtbViewType.DueDateView);

    const currencyObj = Intl.NumberFormat(appState.locale, {
        style: 'currency',
        currency: appState.currencyCode,
    }).formatToParts(1111);

    const currencySign = get(
        filter(currencyObj, ['type', 'currency']),
        `0.value`
    );

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    /**
     * Function for populating the state select options.
     */
    const populateATBStateSelectOptions = () =>
        map(amountTypeOptions, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ));

    /**
     * Function for populating the customer fields filters.
     * @param customFieldType
     */
    const populateCustomerFilters = () => {
        const atbType = companyIsDueDateView ? customersATBCalculationOptions[1].value : customersATBCalculationOptions[0].value

        return getCustomersWidgetFilters(atbType, isCalendarView).map(
            (filter: DynamicObject) => {
                const fieldKey = `${customerFieldIndicator}${filter.filterStateName}`;

                if (filter.filterElement === 'select-multiple-and-input-amount') {
                    const amountValueFieldKey = `${fieldKey}${"--AmountValue"}`
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filter.filterName}>
                                {
                                    map(
                                        filter.filterOptions,
                                        (optionSelect: any, index: number) => {
                                            const valuesArray = get(
                                                values(optionSelect),
                                                0
                                            );
                                            const optionFieldKey = `${fieldKey}${"--"}${filter.filterOptions[index].filterOptionName}`
                                            return (
                                                <div key={index}>
                                                    <FormItem>
                                                        {getFieldDecorator(optionFieldKey, {
                                                            initialValue: get(
                                                                widgetDetails,
                                                                optionFieldKey
                                                            ),
                                                        })(
                                                            <SelectReadonly
                                                                allowClear={true}
                                                                placeholder={filter.filterOptions[index].filterPlaceholder}
                                                                readOnly={formDisabled}
                                                                style={{ width: '100%' }}
                                                                getPopupContainer={populatePopoverContainer(
                                                                    drawerRef
                                                                )}
                                                            >
                                                                {map(
                                                                    valuesArray,
                                                                    (option: any) => {
                                                                        let value, label;
                                                                        if (
                                                                            isObject(option)
                                                                        ) {
                                                                            value = get(
                                                                                option,
                                                                                'value'
                                                                            );
                                                                            label = get(
                                                                                option,
                                                                                'label'
                                                                            );
                                                                        } else {
                                                                            value = option;
                                                                            label = option;
                                                                        }

                                                                        return (
                                                                            <Option
                                                                                key={label}
                                                                                value={
                                                                                    value
                                                                                }
                                                                            >
                                                                                {label}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectReadonly>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                <FormItem>
                                    {`${currencySign} `}
                                    {getFieldDecorator(amountValueFieldKey, {
                                        initialValue: get(
                                            widgetDetails,
                                            amountValueFieldKey
                                        ) != null ? get(widgetDetails, amountValueFieldKey) : 0,
                                    })(
                                        <InputNumber
                                            placeholder="Amount"
                                            readOnly={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'radio-group') {
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filter.filterName}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        placeholder={filter.filterPlaceholder}
                                        readOnly={formDisabled}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={label} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'input-autocomplete') {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    const stateName = filter.filterStateName || filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filterNameUsed}>
                                <InputAutoCompleteWithButtonDashboard
                                    readOnly={formDisabled}
                                    hasNoOkButton={true}
                                    updateField={(value: string) => {
                                        changeSearchFilter(stateName, value);
                                    }}
                                    stateValue={searchFilters[stateName]}
                                    queryName={filter.filterQuery}
                                    queryFilterName={filter.filterNameQuery}
                                    filterField={filter.filterStateName}
                                    sortField={filter.filterSort}
                                    responseName={filter.filterResponse}
                                    labelField={filter.filterLabelField}
                                    placeholder={filter.filterPlaceholder}
                                    filterSubChecking={filter.filterSubChecking}
                                    queryFilterNameSub={filter.filterNameQuerySub}
                                    getFieldDecorator={getFieldDecorator}
                                    widgetDetails={widgetDetails}
                                    filterFieldKey={fieldKey}
                                />
                            </FormItem>
                        </Col>
                    );
                }
                else {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filterNameUsed}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <Input
                                        placeholder={filterNameUsed}
                                        readOnly={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    );
                }
            }
        );
    };

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={FieldName}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={FieldName}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the columns to display switches.
     */
    const populateColumnsToDisplayOptions = () => {
        return map(
            isCalendarView ? displayColumnOptionsWithCalendarView : displayColumnOptionsWithDefaultView,
            ({ label, value, defaultChecked, OrgViewOnly }: DynamicObject) => {
                if (
                    isUsingCloudImportType &&
                    includes(hiddenCloudImportFields, value)
                ) {
                    return;
                }
                 if (
                    label !== "Customer custom fields"
                    ) {
                            if (!isOrgView && OrgViewOnly) {
                                return null;
                            }
                            else {

                                const displayColumnKey = `${displayColumnIndicator}${value}`;
                                const switchValue = !isUndefined(
                                    get(widgetDetails, displayColumnKey)
                                )
                                    ? get(widgetDetails, displayColumnKey)
                                    : defaultChecked;

                                const labelUsed = replaceInstancesOfCustomerString(
                                    label,
                                    customerLabel,
                                    isOrgView
                                );
                                return (
                                    <Col
                                        key={value}
                                        span={12}
                                        className="switch-fields-container"
                                    >
                                        <FormItem label={labelUsed}>
                                            {getFieldDecorator(displayColumnKey, {
                                                valuePropName: 'checked',
                                                initialValue: switchValue,
                                            })(<Switch disabled={formDisabled} />)}
                                        </FormItem>
                                    </Col>
                                );
                            }
                        }
                    }
        );
    };

    /**
     * Function that populates the custom Customer columns to display switches.
     */
    const populateCustomCustomerFilterColumnsToDisplayOption = () => {
        return map(customFieldsFilterList, 
            ({ Type, FieldName }: DynamicObject) => {
                if (Type == "Customer") {
                    if (isOrgView) {
                        return null;
                    }
                    else {
                        const customFieldKey = `${showCustomFieldsIndicator}${customFieldIndicator}${Type}--${FieldName}`;
                        const switchValue = !isUndefined(
                            get(widgetDetails, customFieldKey)
                        )
                            ? get(widgetDetails, customFieldKey)
                            : false;

                        const labelUsed = replaceInstancesOfCustomerString(
                            FieldName,
                            customerLabel,
                            isOrgView
                        );

                        return ( 
                                <Col
                                    key={customFieldKey}
                                    span={12}
                                    className="switch-fields-container"
                                >
                                    <FormItem label={labelUsed}>
                                        {getFieldDecorator(customFieldKey, {
                                            valuePropName: 'checked',
                                            initialValue: switchValue,
                                        })(<Switch disabled={formDisabled} />)}
                                    </FormItem>
                                </Col>
                                );
                        }
                }

            });
    }

    /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
        ));

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label="Sort by Amount Type">
                            {getFieldDecorator('atbState', {
                                initialValue:
                                    get(widgetDetails, 'atbState') ||
                                    get(amountTypeOptions, '0.value'),
                                rules: [
                                    {
                                        required: true,
                                        message: 'ATB state required!',
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateATBStateSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    Filter by{' '}
                                    {isOrgView
                                        ? 'customer'
                                        : toLower(customerLabel)}{' '}
                                    fields
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomerFilters()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    Filter by{' '}
                                    {isOrgView
                                        ? 'customer'
                                        : toLower(customerLabel)}{' '}
                                    custom fields
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomFieldsFilters(
                                CUSTOM_FIELD_TYPES.CUSTOMER
                            )}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                        organisationCurrenciesAll={organisationCurrenciesAll}
                    />
                )}
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>Columns to display</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateColumnsToDisplayOptions()}
                            {populateCustomCustomerFilterColumnsToDisplayOption()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <FormItem label="Number of rows (Amount)">
                            {getFieldDecorator('rowCount', {
                                initialValue:
                                    get(widgetDetails, 'rowCount') ||
                                    get(displayRowCountOptions, '0'),
                                rules: [
                                    {
                                        required: true,
                                        message: 'Row count is required!',
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: 80 }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateRowCountOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(TopCustomersWidgetFields);
