export const itemDrawerTabItemCardClassName = 'card-custom-details-thick';
export const fileTypesSignature = {
    png: ['89504E47'],
    gif: ['47494638'],
    jpg: ['FFD8FFDB', 'FFD8FFE0', 'FFD8FFE1', 'FFD8FFEE'],
    // txt: [
    //     '46545854',
    //     '4F70656E',
    //     '436F7079',
    //     '7374646F',
    //     '446F776E',
    //     '53656520',
    //     'A6C6962',
    //     '31313A31',
    //     '30393A35',
    //     '31303A34',
    //     '464F524D',
    // ],
    doc: ['D0CF11E0A1B11AE1'],
    docx: ['504B0304'],
    pdf: ['25504446'],
    xls: ['D0CF11E0A1B11AE1'],
    xlsx: ['504B0304', '504B34'],
};

export const companyGlobeIconList = ['asia', 'europe', 'americas', 'africa'];

export const dateTypeFilterStateNames = [
    'CreatedDate',
    'DueDate',
    'ActionDate',
    'ClosedDate',
    'AvailableDate',
    'PaidDate',
    'LastChatDate',
    'InvoiceDueDate',
];

export const companyLocaleDependentFilterNames = {
    AMOUNT: 'Amount',
};

export const companyLocaleDependentFilters = [
    companyLocaleDependentFilterNames.AMOUNT,
];

export const conversationTypes = {
    Comment: 'Comment',
    DeliveryTracking: 'DeliveryTracking',
    EntityStateChange: 'EntityStateChange',
    ResourceDiscussion: 'ResourceDiscussion'
};

export const conversationTypeClassifications = {
    Comment: 'Comment',
    AutomatedSMS: 'AutomatedSMS',
    AutomatedEmail: 'AutomatedEmail',
    EmailOpened: 'EmailOpened',
    SMSOpened: 'SMSOpened',
    NewTicket: 'NewTicket',
    ResolvedTicket: 'ResolvedTicket',
    FailedEmailDelivery: 'FailedEmailDelivery',
    FailedSMSDelivery: 'FailedSMSDelivery',
    PaymentPlan: 'PaymentPlan',
    ResourceRequest: 'ResourceRequest',
    ResourceReply: 'ResourceReply'
};

export const selectUserTypeFilterStateNames = ['AssignedUserId'];
export const selectActionedByUserIdFilterStateNames = ['ActionedByUserId'];
export const selectCompanyIdFilterStateNames = ['CompanyId'];

export const selectUserFilterMapping: any = {
    AssignedUserId: 'User.UserId',
};

export const selectCompanyIdFilterMapping: any = {
    CompanyId: 'CompanyId',
};

export const selectActionedByUserIdFilterMapping: any = {
    ActionedByUserId: 'User.UserId',
};

export const compactViewPages = {
    ACTIVE_TASKS: 'ActiveTasks',
    INVOICES: 'Invoices',
};

/**
 * For conversation
 */
export const conversationFilters = {
    ALL_CONVERSATIONS: 'All conversations',
    ALL_COMMENTS: 'All comments',
    ALL_SUPPORT_TICKETS: 'All support tickets',
    ALL_COMMUNICATIONS: 'All communications',
    ALL_PAYMENT_PLANS: 'All payment plans',
};
export const conversationTableFilterOptions = [
    {
        label: 'All conversations',
        value: conversationFilters.ALL_CONVERSATIONS,
    },
    {
        label: 'All comments',
        value: conversationFilters.ALL_COMMENTS,
    },
    {
        label: 'All support tickets',
        value: conversationFilters.ALL_SUPPORT_TICKETS,
    },
    {
        label: 'All communications',
        value: conversationFilters.ALL_COMMUNICATIONS,
    },
    {
        label: 'All payment plans',
        value: conversationFilters.ALL_PAYMENT_PLANS,
    },
];

export const newInvoiceSendModes = {
    SendGrouped: {
        display: 'Send Grouped',
        value: 'SendGrouped'
    },
    SendIndividual: {
        display: 'Send Individual',
        value: 'SendIndividual'
    },
    DoNotSend: {
        display: `Don't Send`,
        value: 'DoNotSend'
    }
};

export const DEFAULT_NUMBER_OF_DAYS_FOR_RECENT_COMMENT = 7;

export const SpecialisedReports = [
    {
        ReportType: 'Missing Attachments'
    }
];

export const communicationResourceReplyType = {
    Communication: 'Communication',
    Ticket: 'Ticket'
};

export const defaultGuidValue = '00000000-0000-0000-0000-000000000000';
export const MAX_INT_VALUE = 2147483647;

export const DANGER_COLOR = '#f5222d';