import { DynamicObject } from '../utils/commonInterfaces';

export const companiesSortByOptions = [
    { label: 'Company name', value: 'CompanyName' },
    { label: 'Region', value: 'Region' },
    { label: 'Created date', value: 'CreatedDate' },
];

export const companyStatuses = {
    OPEN: 'Open',
    CLOSED: 'Closed',
};

export const companiesStatusOptions = [
    { label: companyStatuses.OPEN, value: companyStatuses.OPEN },
    { label: companyStatuses.CLOSED, value: companyStatuses.CLOSED },
];

export const organisationUsersSortByOptions = [
    { label: 'Name', value: 'Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Role', value: 'Role' },
];

export const defaultOpenCompaniesFilterParams = {
    filters: { Status: [companyStatuses.OPEN] },
};

export const organisationUsersRoleAllowed = ['Administrator'];
export const organistaionUsersRoleMapping: DynamicObject = {
    Administrator: 'Organisation Admin',
};
