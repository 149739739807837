import { action } from 'typesafe-actions';
import { NotificationsActionTypes, Notification, GetCorpayCurrenciesForCompanyRequestPayload } from './types';
import {
    ResponseModalObject,
    DynamicObject,
} from '../../utils/commonInterfaces';

/**
 * Actions for getting the list data for the 1st drawer of notifications.
 * @param refetchNewNotif
 * @param callback
 * @param noState
 */
export const getCategorizedNotificationsRequestAction = (
    refetchNewNotif?: boolean,
    callback?: (notifications: any) => void,
    noState?: boolean
) =>
    action(NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_REQUEST, {
        refetchNewNotif,
        callback,
        noState,
    });
export const getCategorizedNotificationsSuccessAction = (payload: {
    data: Notification[];
    refetchNewNotif?: boolean;
}) =>
    action(
        NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_SUCCESS,
        payload
    );
export const getCategorizedNotificationsErrorAction = (payload: string[]) =>
    action(
        NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_ERROR,
        payload
    );

/**
 * Actions for getting the list data for the 2nd drawer of notifications.
 * @param categoryId
 */
export const getNotificationsByCategoryRequestAction = (
    categoryId: string | null
) =>
    action(
        NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_REQUEST,
        categoryId
    );
export const getNotificationsByCategorySuccessAction = (payload: {
    data: Notification[];
}) =>
    action(
        NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_SUCCESS,
        payload
    );
export const getNotificationsByCategoryErrorAction = (payload: string[]) =>
    action(
        NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_ERROR,
        payload
    );

/**
 * Action that clears the first drawer data list of notifications.
 */
export const clearCategorizedNotificationsStateDataAction = () =>
    action(NotificationsActionTypes.CLEAR_CATEGORIZED_NOTIFICATIONS_STATE_DATA);

/**
 * Action that clears the 2nd drawer data list of notifications.
 */
export const clearNotificationsByCategoryStateDataAction = () =>
    action(NotificationsActionTypes.CLEAR_NOTIFICATIONS_BY_CATEGORY_STATE_DATA);

/**
 * Action that sets the `refetchNewNotifications` boolean value.
 * @param payload
 */
export const triggerRefetchNotificationsBooleanAction = (payload: boolean) =>
    action(NotificationsActionTypes.REFETCH_NEW_NOTIFICATIONS_TRIGGER, payload);

/**
 * Action that fetches the list of new notifications (based on datetime).
 * @param latestNotificationDateTime
 * @param callback
 */
export const fetchNewNotificationsAction = (
    latestNotificationDateTime: string,
    callback: (response: DynamicObject) => void
) =>
    action(NotificationsActionTypes.FETCH_NEW_NOTIFICATIONS, {
        latestNotificationDateTime,
        callback,
    });

/**
 * Action that fetches the notification details page (public) data.
 * @param notificationId
 * @param callback
 */
export const fetchNotificationDetailsPublicAction = (
    notificationId: string,
    applyDelay: boolean,
    isPaymentPlanEnabled: boolean,
    invoicesAndCreditsOnly: boolean,
    callback: (response: DynamicObject) => void
) =>
    action(NotificationsActionTypes.FETCH_NOTIFICATIONS_DETAILS_PUBLIC, {
        notificationId,
        applyDelay,
        isPaymentPlanEnabled,
        invoicesAndCreditsOnly,
        callback,
    });

/**
 * Action that gets the list of unread notifications.
 */
export const getUnreadNotificationsForUserRequestAction = () =>
    action(NotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_FOR_USER_REQUEST);
export const getUnreadNotificationsForUserSuccessAction = (
    notificationCategories: string[]
) =>
    action(
        NotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_FOR_USER_SUCCESS,
        notificationCategories
    );

/**
 * Action that gets the presigned url for `View Letter` in notification details public page.
 * @param fileId
 * @param callback
 */
export const getNotificationLetterPresignedUrlAction = (
    fileId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(NotificationsActionTypes.GET_NOTIFICATION_LETTER_PRESIGNED_URL, {
        fileId,
        callback,
    });

/**
 * Action that gets the options for Step 1 in Inform Us panel.
 * @param companyId
 * @param callback
 */
export const getTicketOptionsRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject[]) => void
) =>
    action(NotificationsActionTypes.GET_TICKET_OPTIONS_REQUEST, {
        companyId,
        callback,
    });

/**
 * Action called when submitting the query (using Inform Us panel).
 * @param payload
 */
export const raiseQueryRequestAction = (payload: {}) =>
    action(NotificationsActionTypes.RAISE_QUERY_REQUEST, payload);

/**
 * Action that marks the notifications as opened/read.
 * @param categoryId
 * @param notificationId
 * @param callback
 */
export const markNotificationAsReadAction = (
    categoryId: string,
    notificationId?: string,
    callback?: (response: ResponseModalObject) => void
) =>
    action(NotificationsActionTypes.MARK_NOTIFICATION_AS_READ, {
        categoryId,
        notificationId,
        callback,
    });

/**
 * Action called when clicking `Pay using credits` button..
 * @param payload
 */
export const payUsingCreditsRequestAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) => action(NotificationsActionTypes.PAY_USING_CREDITS, { payload, callback });

/**
 * Action for getting payment details publicly
 * @param paymentId
 */
export const getPaymentDataPublicRequestAction = (
    paymentId: string,
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(NotificationsActionTypes.GET_PAYMENT_DATA_PUBLIC_REQUEST, {
        paymentId,
        companyId,
        callback,
    });

/**
 * Action for getting the product announcements.
 * @param minDate - in UTC
 * @param maxDate - in UTC
 */
export const getProductAnnouncementsRequestAction = (
    minDate: string,
    maxDate: string,
    callback: (response: DynamicObject) => void
) =>
    action(NotificationsActionTypes.GET_PRODUCT_ANNOUNCEMENTS_REQUEST, {
        minDate,
        maxDate,
        callback,
    });

export const setLoginProductAnnouncementFetching = (fetching: boolean) =>
    action(
        NotificationsActionTypes.SET_LOGIN_PRODUCT_ANNOUNCEMENT_LOADING,
        fetching
    );

    /**
 * Action for getting the corpay currencies.
 * @param payload
 */
export const getCorpayCurrenciesForCompanyRequestAction = (
    payload: GetCorpayCurrenciesForCompanyRequestPayload
) => action(NotificationsActionTypes.GET_CORPAY_CURRENCIES_FOR_COMPANY_REQUEST, payload);


