import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import {
    GetInvoiceChangesRequestPayload,
    GetInvoicesRequestPayload,
    InvoiceAddCommentRequestPayload,
    InvoiceChanges,
    InvoicePauseResumeRequestPayload,
    InvoicesActionTypes,
    InvoicesFetchedForOrganisationPayload,
} from './types';
import { ChangeLine, ChangeLinesFetchedForOrganisationPayload } from '../changes/types';
import { ConversationLinesFetchedForOrganisationPayload } from '../conversationLine/types';

export const getInvoicesRequestAction = (payload: GetInvoicesRequestPayload) =>
    action(InvoicesActionTypes.GET_INVOICES_REQUEST, payload);
export const getInvoicesForOrganisationRequestAction = (payload: GetInvoicesRequestPayload) =>
    action(InvoicesActionTypes.GET_INVOICES_FOR_ORGANISATION_REQUEST, payload);
export const getInvoicesSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(InvoicesActionTypes.GET_INVOICES_SUCCESS, payload);
export const getInvoicesErrorAction = (payload: string[]) =>
    action(InvoicesActionTypes.GET_INVOICES_ERROR, payload);

export const updateInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) => action(InvoicesActionTypes.UPDATE_INVOICES_FILTERS, filters);
export const updateInvoicesSortByAndStateAction = (tableSortAndState: {
    sortBy: GetInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        InvoicesActionTypes.UPDATE_INVOICES_SORT_BY_AND_STATE,
        tableSortAndState
    );

export const updateInvoicesTableFilterAction = (tableFilter: string) =>
    action(InvoicesActionTypes.UPDATE_INVOICES_TABLE_FILTER_STATE, tableFilter);

/**
 * Action for resetting table filters and sort values.
 */
export const clearInvoicesStateAllTableFiltersAction = () =>
    action(InvoicesActionTypes.CLEAR_INVOICES_STATE_ALL_TABLE_FILTERS);

export const clearInvoicesStateDataAction = () =>
    action(InvoicesActionTypes.CLEAR_INVOICES_STATE_DATA);

export const clearInvoicesListData = () =>
    action(InvoicesActionTypes.CLEAR_INVOICES_LIST_DATA);

// Single Record
export const setSelectedInvoiceIdRequestAction = (
    invoiceId: string | null,
    callback: () => void
) =>
    action(InvoicesActionTypes.SET_INVOICE_SELECTED_ID_REQUEST, {
        invoiceId,
        callback,
    });
export const setSelectedInvoiceIdSuccessAction = (invoiceId: string | null) =>
    action(InvoicesActionTypes.SET_INVOICE_SELECTED_ID_SUCCESS, invoiceId);

/**
 * Actions for getting invoice data.
 */
export const getInvoiceDataRequestAction = (
    invoiceId: string,
    isUsingCloudImportType: boolean
) =>
    action(InvoicesActionTypes.GET_INVOICE_DATA_REQUEST, {
        invoiceId,
        isUsingCloudImportType,
    });
/**
 * Actions for getting invoice data.
 */
export const getOrganisationInvoiceDataRequestAction = (
    invoiceId: string,
    region: string,
    isUsingCloudImportType: boolean,
    companyId: string,
    conversionCurrency: number,
    currencies: DynamicObject[]
) =>
    action(InvoicesActionTypes.GET_ORGANISATION_INVOICE_DATA_REQUEST, {
        invoiceId,
        region,
        isUsingCloudImportType,
        companyId,
        conversionCurrency,
        currencies
    });

export const getInvoiceDataSuccessAction = (payload: { record: {} }) =>
    action(InvoicesActionTypes.GET_INVOICE_DATA_SUCCESS, payload);
export const getInvoiceDataErrorAction = (payload: string[]) =>
    action(InvoicesActionTypes.GET_INVOICE_DATA_ERROR, payload);

export const clearInvoiceDataAction = () =>
    action(InvoicesActionTypes.CLEAR_INVOICE_DATA_SUCCESS);

export const checkInvoicesIfSingleCustomerAction = (payload: any) =>
    action(
        InvoicesActionTypes.CHECK_INVOICES_IF_SINGLE_CUSTOMER_REQUEST,
        payload
    );

export const raiseQueryInvoiceBulkRequestAction = (payload: {}) =>
    action(InvoicesActionTypes.RAISE_QUERY_INVOICE_BULK_REQUEST, payload);

// Conversation actions
export const getInvoiceConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(InvoicesActionTypes.GET_INVOICE_CONVERSATION_REQUEST, payload);
export const getOrganisationInvoiceConversationRequestAction = (
    payload: GetConversationRequestPayload,
    region: string,
    companyIds: string[]
) => action(InvoicesActionTypes.GET_ORGANISATION_INVOICE_CONVERSATION_REQUEST, {
    payload,
    region,
    companyIds
});
export const getInvoiceConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(InvoicesActionTypes.GET_INVOICE_CONVERSATION_SUCCESS, payload);
export const getInvoiceConversationErrorAction = (payload: string[]) =>
    action(InvoicesActionTypes.GET_INVOICE_CONVERSATION_ERROR, payload);

export const updateInvoiceConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_FILTERS, filters);

export const updateInvoiceConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateInvoiceConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_SORT_BY, tableSort);

export const invoiceAddCommentRequestAction = (
    payload: InvoiceAddCommentRequestPayload
) => action(InvoicesActionTypes.INVOICE_ADD_COMMENT_REQUEST, payload);
export const organisationInvoiceAddCommentRequestAction = (
    payload: InvoiceAddCommentRequestPayload,
    region: string,
    companyIds: string[]
) => action(InvoicesActionTypes.ORGANISATION_INVOICE_ADD_COMMENT_REQUEST, {
    payload,
    region,
    companyIds
});
/**
 * Action for getting the invoices pause resume state of selected items.
 * @param payload
 */
export const checkInvoicesPauseResumeStateAction = (
    payload: GetInvoicesRequestPayload
) => action(InvoicesActionTypes.CHECK_INVOICES_PAUSE_RESUME_STATE, payload);

/**
 * Action for pause/resume invoices
 */
export const invoicePauseResumeRequestAction = (
    payload: InvoicePauseResumeRequestPayload
) => action(InvoicesActionTypes.INVOICE_PAUSE_RESUME_REQUEST, payload);

// InvoiceChanges actions
/**
 * Action for getting the invoice changes list.
 * @param payload
 */
export const getInvoiceChangesRequestAction = (
    payload: GetInvoiceChangesRequestPayload
) => action(InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_REQUEST, payload);
export const getOrganisationInvoiceChangesRequestAction = (
    payload: GetInvoiceChangesRequestPayload,
    region: string,
    companyIds: string[]
) => action(InvoicesActionTypes.GET_ORGANISATION_INVOICE_CHANGES_REQUEST, {
    payload,
    region,
    companyIds
});
export const getInvoiceChangesSuccessAction = (payload: {
    data: InvoiceChanges[];
    pageData: PageData;
}) => action(InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_SUCCESS, payload);
export const getInvoiceChangesErrorAction = (payload: string[]) =>
    action(InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_ERROR, payload);

export const updateInvoiceChangesFiltersAction = (
    filters: GetInvoiceChangesRequestPayload['filters']
) =>
    action(InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_FILTERS, filters);

export const updateInvoiceChangesTableFilterAction = (
    invoiceChangesTableFilter: string
) =>
    action(
        InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_TABLE_FILTER,
        invoiceChangesTableFilter
    );
export const updateInvoiceChangesSortByAction = (tableSort: {
    sortBy: GetInvoiceChangesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_SORT_BY,
        tableSort
    );

export const setTableCompactViewAction = (isCompact: boolean) =>
    action(InvoicesActionTypes.INVOICE_SET_TABLE_COMPACT_VIEW, isCompact);

    /**
* Function for getting the Task Custom Fields for a company.
* @param payload
*/
export const getInvoiceCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(InvoicesActionTypes.GET_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });
export const getInvoicesForPaymentPlanRequestAction = (
    payload: GetInvoicesRequestPayload,
    callback: (response: DynamicObject) => void) =>
    action(InvoicesActionTypes.GET_INVOICES_FOR_PAYMENT_PLAN_REQUEST, { payload, callback });

/**
* Function for getting the Invoices for organisation.
* @param payload
*/
export const invoicesFetchedForOrganisationAction = (
    payload: InvoicesFetchedForOrganisationPayload
) => action(InvoicesActionTypes.INVOICES_FETCHED_FOR_ORGANISATION_RECEIVED, payload);

/**
* Function for getting the Conversations for organisation.
* @param payload
*/
export const invoiceConversationLinesFetchedForOrganisationAction = (
    payload: ConversationLinesFetchedForOrganisationPayload
) => action(InvoicesActionTypes.INVOICE_CONVERSATION_LINES_FETCHED_FOR_ORGANISATION_RECEIVED, payload);

/**
* Function for getting the ChangeLines for organisation.
* @param payload
*/
export const invoiceChangeLinesFetchedForOrganisationAction = (
    payload: ChangeLinesFetchedForOrganisationPayload
) => action(InvoicesActionTypes.INVOICE_CHANGE_LINES_FETCHED_FOR_ORGANISATION_RECEIVED, payload);

export const getInvoiceChangeLinesSuccessAction = (payload: {
    data: ChangeLine[];
    pageData: PageData;
}) => action(InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_SUCCESS, payload);