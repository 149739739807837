/**
 * Component for populating the contact method icons.
 */

import React from 'react';
import { Button, Popover } from 'antd';
import FontAwesome from './FontAwesome';
import { ContactFilterOptions } from '../../constants/tasksSortAndFilters';
import { forEach, get } from 'lodash';
import { DeliveryDetails } from '../../store/invoices/types';

interface IProps {
    readonly contactMethods: number;
    readonly postalIcon?: any;
    readonly emailIcon?: any;
    readonly mobileIcon?: any;
    readonly iconsSize?:
    | '2x'
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x'
    | undefined;
    readonly deliveryDetails?: DeliveryDetails[],
    readonly showOnlyComment?: boolean
}

const contactFilterToMethodMap: { [key: string]: string } = {
    Email: 'Email',
    Mobile: 'SMS'
};

const ContactMethodIcons: React.FC<IProps> = ({
    contactMethods,
    postalIcon,
    emailIcon,
    mobileIcon,
    iconsSize,
    deliveryDetails,
    showOnlyComment
}: IProps) => {
    /**
     * Function for getting the whole section of the container
     * for the contact methods used.
     */
    const getContactMethodSection = () => {
        const contactMethod = contactMethods;
        let contactMethodIcons: any = [];
        forEach(ContactFilterOptions, (value: number, index: string) => {
            if ((contactMethod & ContactFilterOptions[index]) !== 0) {
                const deliveryType = contactFilterToMethodMap[index];
                const deliveryDetail = deliveryDetails
                    && deliveryDetails.find(value => value.Type === deliveryType);
                contactMethodIcons.push(
                    deliveryDetail ? (                        
                        <Popover
                            key={index}
                            overlayClassName="comment-popover"
                            content={(
                                <div className="center-flex" onClick={(e) => e.stopPropagation()}>
                                    <div className="multiline multiline-limit">
                                        {
                                            showOnlyComment
                                                ? deliveryDetail.Comment
                                                : (`${deliveryDetail.Comment || ''}`) + (deliveryDetail.Reason ? (` ${deliveryDetail.Reason}`) : '')
                                        }
                                    </div>
                                </div>
                            )}
                            placement={'topRight'}
                        >
                            <span key={value}>
                                {getContactMethodIcon(value, deliveryDetail)}
                            </span>
                        </Popover>
                    ) : (
                        <span key={value}>
                            {getContactMethodIcon(value)}
                        </span>
                    )
                );
            }
        });

        return <div style={{ display: 'inline-block' }}>{contactMethodIcons}</div>;
    };

    /**
     * Function for getting the contact method icon based on the type.
     * @param contactMethodType - number based on API
     */
    const getContactMethodIcon = (contactMethodType: number, deliveryDetail?: DeliveryDetails) => {
        const iconsSizeUsed = iconsSize || '1x';
        const emailIconSelected = emailIcon || ['fas', 'envelope'];
        const mobileIconSelected = mobileIcon || ['fas', 'mobile-alt'];
        const postalIconSelected = postalIcon || ['fas', 'home'];

        let emailSMSIconColour = '';
        if (get(deliveryDetail, 'State') == "Open") {
            emailSMSIconColour = 'green';
        }
        else if (get(deliveryDetail, 'State') == "Delivered") {
            emailSMSIconColour = 'orange';
        }
        else if (get(deliveryDetail, 'State') == "Failed") {
            emailSMSIconColour = 'red';
        }

        if (contactMethodType === ContactFilterOptions.Email) {
            return (
                <Button type="link" className="pa-0 mr-8 cursor-i">
                    <FontAwesome
                        icon={emailIconSelected}
                        size={iconsSizeUsed}
                        className={emailSMSIconColour}
                    />
                </Button>
            );
        } else if (contactMethodType === ContactFilterOptions.Mobile) {
            return (
                <Button type="link" className="pa-0 mr-8 cursor-i">
                    <FontAwesome
                        icon={mobileIconSelected}
                        size={iconsSizeUsed}
                        className={emailSMSIconColour}
                    />
                </Button>
            );
        } else if (contactMethodType === ContactFilterOptions.PostalAddress) {
            return (
                <Button type="link" className="pa-0 mr-8 cursor-i">
                    <FontAwesome
                        icon={postalIconSelected}
                        size={iconsSizeUsed}
                    />
                </Button>
            );
        }
    };

    return getContactMethodSection();
};

export default ContactMethodIcons;
