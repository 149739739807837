import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';
import {
    AddPaymentForADVAMRequestPayload,
    AddPaymentForEziDebitRequestPayload,
    AddPaymentForIntegraPayRequestPayload,
    AddPaymentForUrlRequestPayload,
    AddPaymentForWesternUnionRequestPayload,
    AddPaymentForCorpayRequestPayload,
    GetPaymentChangesRequestPayload,
    GetPaymentsRequestPayload,
    PaymentAddCommentRequestPayload,
    PaymentChanges,
    PaymentsActionTypes,
    SendPaymentInstructionForCorpayRequestPayload

} from './types';

/**
 * Actions for fetching the payments list.
 * @param payload
 */
export const getPaymentsRequestAction = (payload: GetPaymentsRequestPayload) =>
    action(PaymentsActionTypes.GET_PAYMENTS_REQUEST, payload);
export const getPaymentsSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(PaymentsActionTypes.GET_PAYMENTS_SUCCESS, payload);
export const getPaymentsErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_PAYMENTS_ERROR, payload);

/**
 * Action called for updating the filters on Payments management page.
 * @param filters
 */
export const updatePaymentsFiltersAction = (
    filters: GetPaymentsRequestPayload['filters']
) => action(PaymentsActionTypes.UPDATE_PAYMENTS_FILTERS, filters);

/**
 * Action called for changing the sort by, sort order, and state for Payments list.
 * @param tableSortAndState
 */
export const updatePaymentsSortByAndStateAction = (tableSortAndState: {
    sortBy: GetPaymentsRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENTS_SORT_BY_AND_STATE,
        tableSortAndState
    );

/**
 * Action called when changing the payments view filter.
 * @param paymentViewFilter
 */
export const updatePaymentsTableFilterAction = (paymentViewFilter: string) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENTS_TABLE_FILTER_STATE,
        paymentViewFilter
    );

/**
 * Action called for clearing the Payments state.
 */
export const clearPaymentsStateDataAction = () =>
    action(PaymentsActionTypes.CLEAR_PAYMENTS_STATE_DATA);

// Single Record
/**
 * Action sfor setting the selected payment Id when clicking on a payment table row item.
 * @param paymentId
 * @param callback
 */
export const setPaymentSelectedIdRequestAction = (
    paymentId: string | null,
    callback: () => void
) =>
    action(PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_REQUEST, {
        paymentId,
        callback,
    });
export const setPaymentSelectedIdSuccessAction = (paymentId: string | null) =>
    action(PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_SUCCESS, paymentId);

/**
 * Actions for getting the details for the selected single payment.
 * @param paymentId
 * @param isUsingCloudImportType
 */
export const getPaymentDataRequestAction = (
    paymentId: string,
    isUsingCloudImportType: boolean,
    companyId: string | null = null,
) =>
    action(PaymentsActionTypes.GET_PAYMENT_DATA_REQUEST, {
        paymentId,
        isUsingCloudImportType,
        companyId,
    });
export const getPaymentDataSuccessAction = (payload: { record: {} }) =>
    action(PaymentsActionTypes.GET_PAYMENT_DATA_SUCCESS, payload);
export const getPaymentDataErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_PAYMENT_DATA_ERROR, payload);

/**
 * Action for resetting table filters and sort values.
 */
export const clearPaymentsStateAllTableFiltersAction = () =>
    action(PaymentsActionTypes.CLEAR_PAYMENTS_STATE_ALL_TABLE_FILTERS);

/**
 * Action for clearing the details of a selected payment.
 */
export const clearPaymentDataAction = () =>
    action(PaymentsActionTypes.CLEAR_PAYMENT_DATA_SUCCESS);

// Invoice List Actions
export const getPaymentInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload
) => action(PaymentsActionTypes.GET_PAYMENT_INVOICES_REQUEST, payload);
export const getPaymentInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(PaymentsActionTypes.GET_PAYMENT_INVOICES_SUCCESS, payload);
export const getPaymentInvoicesErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_PAYMENT_INVOICES_ERROR, payload);

export const updatePaymentInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) => action(PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_FILTERS, filters);
export const updatePaymentInvoicesSortByAndStateAction = (tableSortAndState: {
    sortBy: GetInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_SORT_BY_AND_STATE,
        tableSortAndState
    );
export const updatePaymentInvoicesTableFilterAction = (invoiceState: string) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_TABLE_FILTER_STATE,
        invoiceState
    );

// Conversation actions
export const getOrganisationPaymentConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(
        PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CONVERSATION_REQUEST, 
        payload
    );

export const getPaymentConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(PaymentsActionTypes.GET_PAYMENT_CONVERSATION_REQUEST, payload);
export const getPaymentConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(PaymentsActionTypes.GET_PAYMENT_CONVERSATION_SUCCESS, payload);
export const getPaymentConversationErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_PAYMENT_CONVERSATION_ERROR, payload);

export const updatePaymentConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_FILTERS, filters);

export const updatePaymentConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updatePaymentConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_SORT_BY, tableSort);

export const paymentAddCommentRequestAction = (
    payload: PaymentAddCommentRequestPayload
) => action(PaymentsActionTypes.PAYMENT_ADD_COMMENT_REQUEST, payload);

// PaymentChanges actions
/**
 * Action for getting the payment changes list.
 * @param payload
 */
export const getOrganisationPaymentChangesRequestAction = (
    payload: GetPaymentChangesRequestPayload
) => action(PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CHANGES_REQUEST, payload);
/**
 * Action for getting the payment changes list.
 * @param payload
 */
export const getPaymentChangesRequestAction = (
    payload: GetPaymentChangesRequestPayload
) => action(PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_REQUEST, payload);
export const getPaymentChangesSuccessAction = (payload: {
    data: PaymentChanges[];
    pageData: PageData;
}) => action(PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_SUCCESS, payload);
export const getPaymentChangesErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_ERROR, payload);

export const updatePaymentChangesFiltersAction = (
    filters: GetPaymentChangesRequestPayload['filters']
) =>
    action(PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_FILTERS, filters);

export const updatePaymentChangesTableFilterAction = (
    paymentChangesTableFilter: string
) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_TABLE_FILTER,
        paymentChangesTableFilter
    );
export const updatePaymentChangesSortByAction = (tableSort: {
    sortBy: GetPaymentChangesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_SORT_BY,
        tableSort
    );

/**
 * Actions for getting the bank file formats for company.
 * @param companyId
 */
export const getBankFileFormatsRequestAction = () =>
    action(PaymentsActionTypes.GET_BANK_FILE_FORMATS_REQUEST);
export const getBankFileFormatsSuccessAction = (payload: { bankFileFormats: [] }) =>
    action(PaymentsActionTypes.GET_BANK_FILE_FORMATS_SUCCESS, payload);
export const getBankFileFormatsErrorAction = (payload: string[]) =>
    action(PaymentsActionTypes.GET_BANK_FILE_FORMATS_ERROR, payload);

/**
 * Action called when adding payment for url.
 * @param payload
 * @param callback
 */
export const addPaymentForUrlRequestAction = (
    payload: AddPaymentForUrlRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_URL_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called when adding payment for western union.
 * @param payload
 * @param callback
 */
export const addPaymentForWesternUnionRequestAction = (
    payload: AddPaymentForWesternUnionRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_WESTERN_UNION_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called when adding payment for EziDebit.
 * @param payload
 * @param callback
 */
export const addPaymentForEziDebitRequestAction = (
    payload: AddPaymentForEziDebitRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_EZIDEBIT_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called when adding payment for IntegraPay.
 * @param payload
 * @param callback
 */
export const addPaymentForIntegraPayRequestAction = (
    payload: AddPaymentForIntegraPayRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_INTEGRAPAY_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called when adding payment for ADVAM.
 * @param payload
 * @param callback
 */
export const addPaymentForADVAMRequestAction = (
    payload: AddPaymentForADVAMRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_ADVAM_REQUEST, {
        payload,
        callback,
    });

/**
* Action called when adding payment for corpay.
* @param payload
* @param callback
*/
export const addPaymentForCorpayRequestAction = (
    payload: AddPaymentForCorpayRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.ADD_PAYMENT_FOR_CORPAY_REQUEST, {
        payload,
        callback,
    });
/**
 * Action called when getting the spot rate payment.
 * @param payload
 * @param callback
 */
export const getPaymentSpotRateAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) => action(PaymentsActionTypes.GET_PAYMENT_SPOT_RATE, { payload, callback });

/**
 * Action called when getting the spot rate payment.
 * @param payload
 * @param callback
 */
export const bookDealRequestAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) => action(PaymentsActionTypes.CORPAY_BOOK_DEAL_REQUEST, { payload, callback });
    /**
* Action called when sending instruction payment for corpay.
* @param payload
* @param callback
*/
export const sendInstructionPaymentForCorpayRequestAction = (
    payload: SendPaymentInstructionForCorpayRequestPayload,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.SEND_PAYMENT_INSTRUCTION_FOR_CORPAY_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called when deallocating a payment.
 * @param payload
 * @param callback
 */
export const deallocatePaymentRequestAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.DEALLOCATE_PAYMENT_REQUEST, {
        payload,
        callback,
    });

export const checkLastPaymentChangeLineRequestAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.CHECK_LAST_PAYMENT_CHANGELINES_REQUEST, {
        payload,
        callback,
    });

/**
* Function for getting the Payment Custom Fields for a company.
* @param payload
*/
export const getPaymentsCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(PaymentsActionTypes.GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });

  /**
 * Actions for creating credit as overpayment
 */
  export const createCreditAsOverpaymentTasksRequest = (payload: {}) =>
    action(PaymentsActionTypes.CREATE_CREDIT_AS_OVERPAYMENT_REQUEST, payload);

  export const createOrganisationCreditAsOverpaymentTasksRequest = (payload: {}) =>
    action(PaymentsActionTypes.CREATE_ORGANISATION_CREDIT_AS_OVERPAYMENT_REQUEST, payload);

     /**
 * Actions for disregarding payment tasks.
 */
export const paymentDisregardTasksRequest = (payload: {}) =>
    action(PaymentsActionTypes.DISREGARD_PAYMENT_REQUEST, payload);
export const organisationPaymentDisregardTasksRequest = (payload: {}) =>
    action(PaymentsActionTypes.ORGANISATION_DISREGARD_PAYMENT_REQUEST, payload);
