/**
 * File responsible for all the UI and actions for Settings>Multiple currencies page (organisation) - `/app/settings/multiple-currencies`.
 */

import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Modal,
    Row,
    Select,
    Typography,
} from 'antd';
import { find, forEach, get, includes, isEmpty, map, unionBy } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../../components/common/ModalWithSpinner';
import SelectReadonly from '../../../components/FormComponents/SelectReadonly';
import {
    confirmModalCancelText,
    confirmModalOkText,
    DEFAULT_LOCALE,
} from '../../../config/config';
import { OrganisationRoleIdAttribute } from '../../../constants/authUserAttributes';
import { currencyCodes } from '../../../constants/companyGeneralOptions';
import { defaultOpenCompaniesFilterParams } from '../../../constants/organisationsSortAndFilters';
import { ApplicationState } from '../../../store';
import { Company } from '../../../store/companies/types';
import {
    getOrganisationCompaniesRequestAction,
    getUserOrganisationsRequestAction,
    saveUserOrganisationRequestAction,
} from '../../../store/organisations/actions';
import {
    Currency,
    OrganisationsState,
} from '../../../store/organisations/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { getCurrentUser } from '../../../store/users/sagas';
import { getLoadingSkeletons } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';

const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { Option } = Select;
interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}
let unmounted = false;
const MultipleCurrenciesManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const rolePermissions = useSelector(getRolePermissions);
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );

    const [defaultCurrencyRates, setDefaultCurrencyRates] = useState<{
        currencyConversionList: DynamicObject[];
        currencyTargetList: DynamicObject[];
    }>({
        currencyConversionList: [],
        currencyTargetList: [],
    });

    const organisationCompanies: Company[] = useSelector(
        (state: ApplicationState) => state.organisations.companies.data
    );

    const organisationCompaniesLoading: boolean = useSelector(
        (state: ApplicationState) => state.organisations.companies.loading
    );

    const currentUser = useSelector(getCurrentUser);
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);

    // const [fileToLoad, setFileToLoad] = useState<any>();

    const organisationsState: OrganisationsState = useSelector(
        (state: ApplicationState) => state.organisations
    );

    const { selectedUserOrganisation, saveLoading } = organisationsState;

    const { getFieldDecorator, validateFields, resetFields, getFieldValue } =
        props.form;

    /**
     * Function for getting the organisation companies.
     */
    const getOrganisationCompanies = () => {
        if (!isEmpty(selectedUserOrganisation))
            dispatch(
                getOrganisationCompaniesRequestAction(
                    defaultOpenCompaniesFilterParams
                )
            );
    };

    useEffect(getOrganisationCompanies, [selectedUserOrganisation]);

    const unmountSetting = () => {
        unmounted = false;
        return () => {
            unmounted = true;
        };
    };

    useEffect(unmountSetting, []);

    /**
     * Common function for getting currency code by given locale
     * @param locale
     */
    const getCurrencyCodeByLocale = (locale: string) => {
        const localeArray = locale.split('-');
        const country = get(localeArray, 1);
        return get(currencyCodes, country);
    };

    /**
     * Function that populates the defaultCurrencyRates state
     */
    const getDefaultCurrencyRatesList = () => {
        if (
            isEmpty(selectedUserOrganisation) ||
            isEmpty(organisationCompanies) ||
            organisationCompaniesLoading
        )
            return;

        const companyCurrencyList: DynamicObject[] = [];
        const companyTargetList: DynamicObject[] = [];
        forEach(organisationCompanies, (oc: Company) => {
            const localeUsed = get(oc, 'LanguagePackage.Language', '');
            if (!isEmpty(localeUsed)) {
                const currencyCode = getCurrencyCodeByLocale(localeUsed);

                if (
                    isEmpty(find(companyCurrencyList, ['Value', currencyCode]))
                ) {
                    companyCurrencyList.push({
                        Value: currencyCode,
                        Rate: 1,
                    });
                }

                const regionName = get(oc, 'Region');
                if (
                    isEmpty(find(companyTargetList, ['Name', regionName])) &&
                    isEmpty(find(companyTargetList, ['Value', localeUsed]))
                ) {
                    companyTargetList.push({
                        Name: regionName,
                        Value: localeUsed,
                    });
                }
            }
        });
        const savedCurrencyList = get(selectedUserOrganisation, 'Currencies');
        const allCurrencyList = unionBy(
            savedCurrencyList,
            companyCurrencyList,
            'Value'
        );

        setDefaultCurrencyRates({
            currencyConversionList: allCurrencyList,
            currencyTargetList: companyTargetList,
        });
    };

    useEffect(getDefaultCurrencyRatesList, [
        selectedUserOrganisation,
        organisationCompanies,
        organisationCompaniesLoading,
    ]);

    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, all
                    the data will be reverted to the last saved values.
                </div>
            ),
            onOk() {
                resetFields();
            },
            onCancel() {},
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (IsSuccess: boolean) => {
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: `Multiple currency settings saved successfully!`,
                onOk: () => {
                    dispatch(getUserOrganisationsRequestAction());
                },
            });
        } else {
            Modal.error({
                title: 'Error',
                content: `Failed to save multiple currency settings`,
            });
        }
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const organisationCurrencies = [
                    ...defaultCurrencyRates.currencyConversionList,
                ];

                const newOrganisationCurrenciesValue = map(
                    organisationCurrencies,
                    (curr: Currency) => {
                        return {
                            ...curr,
                            Rate: get(values, `conversion-${curr.Value}`),
                        };
                    }
                );

                const defaultCurrency = find(
                    defaultCurrencyRates.currencyTargetList,
                    ['Value', get(values, 'DefaultCurrencyRegion')]
                );

                const payload = {
                    OrganisationId: get(
                        selectedUserOrganisation,
                        'OrganisationId'
                    ),
                    Currencies: newOrganisationCurrenciesValue,
                    DefaultCurrency: defaultCurrency,
                };

                dispatch(
                    saveUserOrganisationRequestAction(
                        payload,
                        ({ IsSuccess }: { IsSuccess: boolean }) => {
                            if (unmounted) return;
                            handleModalSaveResponse(IsSuccess);
                        }
                    )
                );
            }
        });
    };

    /**
     * Function that populates the dropdown options for ATB calculation.
     */
    const populateTargetCurrencyOptions = () => {
        const usedCurrenciesList = [...defaultCurrencyRates.currencyTargetList];
        return map(usedCurrenciesList, ({ Name, Value }: Currency) => (
            <Option key={Value} value={Value}>
                {Name}
            </Option>
        ));
    };

    /**
     * Function that populates the currency conversion section
     */
    const populateCurrencyConversionSection = () => {
        if (
            organisationCompaniesLoading ||
            isEmpty(defaultCurrencyRates.currencyConversionList)
        ) {
            return getLoadingSkeletons(1, organisationCompaniesLoading, false, {
                rows: 3,
            });
        } else {
            const usedCurrenciesList = [
                ...defaultCurrencyRates.currencyConversionList,
            ];
            const targetCountry = getFieldValue('DefaultCurrencyRegion');
            const targetCurrencyCode = getCurrencyCodeByLocale(targetCountry);

            return map(usedCurrenciesList, ({ Value, Rate }: Currency) => {
                const isDefaultRate = targetCurrencyCode === Value;
                return (
                    <Row key={Value} className="mb-10">
                        <Col className="flex-i">
                            <div>{Value}</div>
                            <div className="ml-10">
                                <FormItem className="fx-1 inline-form-item">
                                    {getFieldDecorator(`conversion-${Value}`, {
                                        initialValue: isDefaultRate ? 1 : Rate,
                                    })(
                                        <InputNumber
                                            precision={2}
                                            step={0.01}
                                            min={0}
                                            readOnly={formDisabled}
                                            disabled={isDefaultRate}
                                        />
                                    )}
                                </FormItem>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    };

    let formDisabled = true;
    const allowedRoles = rolePermissions.ORGANISATION_MULTIPLE_CURRENCIES_SAVE;

    if (
        isEmpty(allowedRoles) ||
        includes(allowedRoles, get(orgUserRole, 'Name'))
    ) {
        formDisabled = false;
    }

    const formHasChanges = true;

    /**
     * Upload properties required, based on Ant Design Upload.
     */
    // const uploadProps = {
    //     accept: '.xlsx,.xlsm',
    //     name: fileToLoad ? '' : 'No file selected',
    //     onRemove: () => {
    //         setFileToLoad(null);
    //     },
    //     beforeUpload: (file: any) => {
    //         setFileToLoad(file);
    //         return false;
    //     },
    //     fileList: fileToLoad ? [fileToLoad] : [],
    // };

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 7 },
                        xl: { span: 7 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 17 },
                        xl: { span: 17 },
                        lg: { span: 18 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row
                            className="mb-20"
                            key="title-container"
                            type="flex"
                            align="middle"
                        >
                            <Col span={12}>
                                <Row>
                                    <Col>
                                        <Title level={3}>
                                            Multiple currencies
                                        </Title>
                                    </Col>
                                </Row>
                            </Col>

                            {!formDisabled && (
                                <Col span={12} className="ta-right">
                                    <Button
                                        className="mr-10 w-100px"
                                        type="primary"
                                        onClick={onSaveButtonClick}
                                        disabled={
                                            formDisabled || !formHasChanges
                                        }
                                        loading={saveLoading}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="buttonGrey w-100px"
                                        onClick={onCancelButtonClick}
                                        disabled={
                                            formDisabled || !formHasChanges
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <Row key="target-currency-cont" className="mb-20">
                            <Col
                                xxl={18}
                                xl={20}
                                lg={22}
                                md={22}
                                sm={24}
                                xs={24}
                            >
                                <Card>
                                    <Row>
                                        <Col span={24}>
                                            <h4>Target country</h4>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col span={24}>
                                                    Pick the target country. All
                                                    other currencies are
                                                    converted to this one for
                                                    display on the
                                                    organisation's dashboard.
                                                    <div className="mt-10">
                                                        {organisationCompaniesLoading ||
                                                        isEmpty(
                                                            defaultCurrencyRates.currencyTargetList
                                                        ) ? (
                                                            getLoadingSkeletons(
                                                                1,
                                                                organisationCompaniesLoading,
                                                                false,
                                                                { rows: 1 }
                                                            )
                                                        ) : (
                                                            <FormItem className="fx-1 inline-form-item">
                                                                {getFieldDecorator(
                                                                    'DefaultCurrencyRegion',
                                                                    {
                                                                        initialValue:
                                                                            get(
                                                                                selectedUserOrganisation,
                                                                                'DefaultCurrency.Value',
                                                                                DEFAULT_LOCALE
                                                                            ),
                                                                    }
                                                                )(
                                                                    <SelectReadonly
                                                                        readOnly={
                                                                            formDisabled
                                                                        }
                                                                        style={{
                                                                            width: 180,
                                                                        }}
                                                                        loading={
                                                                            organisationCompaniesLoading
                                                                        }
                                                                    >
                                                                        {populateTargetCurrencyOptions()}
                                                                    </SelectReadonly>
                                                                )}
                                                            </FormItem>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row
                            key="currency-conv-cont"
                            type="flex"
                            align="middle"
                        >
                            <Col
                                xxl={18}
                                xl={20}
                                lg={22}
                                md={22}
                                sm={24}
                                xs={24}
                            >
                                <Card>
                                    <Row>
                                        <Col
                                            span={24}
                                            className="center-flex mb-10"
                                        >
                                            <h4 className="mr-10">
                                                Currency conversion
                                            </h4>
                                            {/* <span>
                                                <Upload {...uploadProps}>
                                                    <Button
                                                        type="primary"
                                                        className="ml-10"
                                                        disabled={formDisabled}
                                                    >
                                                        Load
                                                    </Button>
                                                </Upload>
                                            </span> */}
                                        </Col>
                                        <Col span={24}>
                                            {populateCurrencyConversionSection()}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </QueueAnim>
                </Form>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle="Saving multiple currencies data"
                        modalVisible={saveLoading}
                        displayMessage="Please wait while saving the data. . ."
                    />
                )}
            </Col>
        </div>
    );
};

const MultipleCurrenciesManagementPageForm = Form.create({
    name: 'multiple-currencies-management-form',
})(MultipleCurrenciesManagementPage);
export default withRouter(MultipleCurrenciesManagementPageForm);
