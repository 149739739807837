import { action } from 'typesafe-actions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { Company } from './../companies/types';
import {
    GetOrganisationCompaniesRequestPayload,
    GetOrganisationUsersRequestPayload,
    Organisation,
    OrganisationActionTab,
    OrganisationsActionTypes,
    OrganisationUser,
} from './types';

/**
 * Actions for getting the user organisations,
 * this includes organisation user roles.
 */
export const getUserOrganisationsRequestAction = () =>
    action(OrganisationsActionTypes.GET_USER_ORGANISATIONS_REQUEST);
export const getUserOrganisationsSuccessAction = (data: Organisation[]) =>
    action(OrganisationsActionTypes.GET_USER_ORGANISATIONS_SUCCESS, data);

/**
 * Actions triggered to determine which organisation the user has selected - for UI manipilation
 * since all API calls depend on the Organisation saved at cognito and not here.
 * @param organisationObject
 */
export const selectUserOrganisationRequestAction = (
    organisationObject: Organisation,
    callback?: () => void
) =>
    action(OrganisationsActionTypes.SELECT_USER_ORGANISATION_REQUEST, {
        organisationObject,
        callback,
    });
export const selectUserOrganisationSuccessAction = (
    organisationObject: Organisation
) =>
    action(
        OrganisationsActionTypes.SELECT_USER_ORGANISATION_SUCCESS,
        organisationObject
    );

/**
 * Action for resetting table filters and sort values.
 */
export const clearOrganisationCompaniesStateAllTableFiltersAction = () =>
    action(
        OrganisationsActionTypes.CLEAR_ORGANISATION_COMPANIES_STATE_ALL_TABLE_FILTERS
    );

/**
 * Actions for getting the organisation's companies - request, to handle the loading indicator.
 * @param payload
 */
export const getOrganisationCompaniesRequestAction = (
    payload?: DynamicObject
) =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_REQUEST,
        payload
    );
export const getOrganisationCompaniesSuccessAction = (payload: {
    data: Company[];
    pageData?: PageData;
}) =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_SUCCESS,
        payload
    );
export const getOrganisationCompaniesErrorAction = (payload: string[]) =>
    action(OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_ERROR, payload);

/**
 * Actions for getting the organisation's companies for user - request, to handle the loading indicator.
 * @param payload
 */
export const getOrganisationCompaniesForUserRequestAction = () =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_REQUEST
    );
export const getOrganisationCompaniesForUserSuccessAction = (payload: {
    data: Company[];
}) =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_SUCCESS,
        payload
    );
export const getOrganisationCompaniesForUserErrorAction = () =>
    action(OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_ERROR);

/**
 * Action for updating the filter inside OrganisationCompanies management page - after cliking `Apply filters`.
 * @param filters
 */
export const updateOrganisationCompaniesFiltersAction = (
    filters: GetOrganisationCompaniesRequestPayload['filters']
) =>
    action(
        OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_FILTERS,
        filters
    );

/**
 * Action for updating the dropdown filter in the upper-left corner (for view).
 * @param tableFilter - value of the dropdown
 */
export const updateOrganisationCompaniesTableFilterAction = (
    tableFilter: string
) =>
    action(
        OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_TABLE_FILTER,
        tableFilter
    );

/**
 * Action for updating the sort options (sort by and sort order) inside OrganisationCompanies management page.
 * @param filters
 */
export const updateOrganisationCompaniesSortByAction = (tableSortState: {
    sortBy: GetOrganisationCompaniesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_SORT_BY,
        tableSortState
    );

/**
 * Actions called for saving organisation data.
 * @param payload
 * @param callback - function called after saving - useful for refetching the organisation details
 * and showing alert modals
 */
export const saveUserOrganisationRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(OrganisationsActionTypes.SAVE_USER_ORGANISATION_REQUEST, {
        payload,
        callback,
    });
export const saveUserOrganisationResponseAction = () =>
    action(OrganisationsActionTypes.SAVE_USER_ORGANISATION_RESPONSE);

/**
 * Action called for loading currencies data.
 * @param fileToUpload
 * @param callback - function called after saving and used for showing modal response
 */
export const loadCurrenciesDataRequestAction = (
    fileToUpload: any,
    callback: (response: ResponseModalObject) => void
) =>
    action(OrganisationsActionTypes.LOAD_CURRENCIES_DATA_REQUEST, {
        fileToUpload,
        callback,
    });

/**
 * Function that gets the organisation custom fields filter list of a specific page.
 * @param customFieldType - type of custom fields filter list to get
 * @param callback - callback function called after a success/error
 */
export const getOrganisationCustomFieldsFilterListByTypes = (
    customFieldTypes: string[],
    callback?: (response: DynamicObject) => void
) =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_CUSTOM_FIELDS_FILTER_LIST,
        {
            customFieldTypes,
            callback,
        }
    );

/**
 * Actions for closing company.
 * @param payload
 */
export const closeCompanyRequestAction = (payload: DynamicObject) =>
    action(OrganisationsActionTypes.CLOSE_ORGANISATION_COMPANY, payload);

/**
 * Actions for getting the organisation's users - request, to handle the loading indicator.
 * @param payload
 */
export const getOrganisationUsersRequestAction = (
    payload?: GetOrganisationUsersRequestPayload
) => action(OrganisationsActionTypes.GET_ORGANISATION_USERS_REQUEST, payload);
export const getOrganisationUsersSuccessAction = (payload: {
    data: OrganisationUser[];
    pageData?: PageData;
}) => action(OrganisationsActionTypes.GET_ORGANISATION_USERS_SUCCESS, payload);
export const getOrganisationUsersErrorAction = (payload: string[]) =>
    action(OrganisationsActionTypes.GET_ORGANISATION_USERS_ERROR, payload);

/**
 * Action for updating the filter inside OrganisationUsers management page - after cliking `Apply filters`.
 * @param filters
 */
export const updateOrganisationUsersFiltersAction = (
    filters: GetOrganisationUsersRequestPayload['filters']
) =>
    action(OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_FILTERS, filters);

/**
 * Action for updating the dropdown filter in the upper-left corner (for view).
 * @param tableFilter - value of the dropdown
 */
export const updateOrganisationUsersTableFilterAction = (tableFilter: string) =>
    action(
        OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_TABLE_FILTER,
        tableFilter
    );

/**
 * Action for updating the sort options (sort by and sort order) inside OrganisationUsers management page.
 * @param filters
 */
export const updateOrganisationUsersSortByAction = (tableSortState: {
    sortBy: GetOrganisationUsersRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_SORT_BY,
        tableSortState
    );

/**
 * Actions for removing organisation users.
 * @param payload
 */
export const removeOrganisationUsersRequestAction = (payload: DynamicObject) =>
    action(OrganisationsActionTypes.REMOVE_ORGANISATION_USERS_REQUEST, payload);

// Single Record
export const setSelectedOrganisationUserIdRequestAction = (
    organisationUserId: string | null,
    callback: () => void
) =>
    action(OrganisationsActionTypes.SET_ORGANISATION_USER_SELECTED_ID_REQUEST, {
        organisationUserId,
        callback,
    });
export const setSelectedOrganisationUserIdSuccessAction = (
    organisationUserId: string | null
) =>
    action(
        OrganisationsActionTypes.SET_ORGANISATION_USER_SELECTED_ID_SUCCESS,
        organisationUserId
    );
/**
 * Actions for getting organisation user data.
 */
export const getOrganisationUserDataRequestAction = (
    organisationUserId: string
) =>
    action(OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_REQUEST, {
        organisationUserId,
    });
export const getOrganisationUserDataSuccessAction = (payload: { record: {} }) =>
    action(
        OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_SUCCESS,
        payload
    );
export const getOrganisationUserDataErrorAction = (payload: string[]) =>
    action(OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_ERROR, payload);
export const clearOrganisationUserDataAction = () =>
    action(OrganisationsActionTypes.CLEAR_ORGANISATION_USER_DATA_SUCCESS);

export const setSelectedTabAction = (
    payload: OrganisationActionTab
) =>
    action(
        OrganisationsActionTypes.SET_SELECTED_TAB,
        payload
    );

/**
 * Organisation Tasks History section
 */