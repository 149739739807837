import { Col, Form, Row, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContentTemplateAction, getWorkflowContentConfigurationAction } from '../../store/contents/actions';
import { CompaniesState } from '../../store/companies/types';
import { ApplicationState } from '../../store';
import { ContentsState, SaveContentTemplatePayload, TemplateType } from '../../store/contents/types';
import { getFullWorkflowOptions } from '../../store/companies/sagas';
import { getContentSelection, getWorkflowStateConfigs } from '../../store/contents/sagas';
import { filter, find, get, map, some } from 'lodash';
import { TargetContentConstant, targetContentMap } from '../../constants/contents';
import FormItem from 'antd/lib/form/FormItem';
import ContentsManagementForm from './ContentsManagementForm';

const { Option } = Select;

interface IProps {
    form: WrappedFormUtils;
    valueChanges: any;
    setValueChanges: (valueChanges: any) => void;
}

const formFieldNames = {
    WorkflowId: 'WorkflowId',
    StateName: 'StateName',
    TargetContent: 'TargetContent'
};

const WorkflowContentsManagementPage: React.FC<IProps> = ({ form, ...props }) => {
    const dispatch = useDispatch();
    const { getFieldDecorator, setFieldsValue } = form;
    const {
        loading: contentLoading
    }: ContentsState['activeData'] = useSelector((state: ApplicationState) => state.contents.activeData);
    const { errorMessages, loading: configsLoading }: ContentsState['workflowContents'] = useSelector((state: ApplicationState) => state.contents.workflowContents);
    const availableWorkflowOptions: CompaniesState['fullWorkflowOptions'] = useSelector(getFullWorkflowOptions);
    const contentConfigs: ContentsState['workflowContents']['contentConfigs'] = useSelector(getWorkflowStateConfigs);
    const workflowOptions = filter(
        availableWorkflowOptions,
        opt => some(contentConfigs,
            config => config.WorkflowId === opt.WorkflowId && config.States && config.States.length > 0));
    const contentSelection: ContentsState['activeData']['selection'] = useSelector(getContentSelection);
    const selectedWorkflowId = get(contentSelection, 'WorkflowId');
    const selectedStateName = get(contentSelection, 'StateName');
    const selectedTargetContent = get(contentSelection, 'TargetContent');
    const selectedWorkflowStateConfig = contentSelection && contentSelection.WorkflowId
        ? find(contentConfigs, c => c.WorkflowId === contentSelection.WorkflowId)
        : undefined;
    const selectedStateConfig = selectedWorkflowStateConfig && selectedStateName
        ? find(selectedWorkflowStateConfig.States, s => s.StateName === selectedStateName)
        : undefined;
    const availableTargetContents = useMemo(() => {
        let contents: TargetContentConstant[] | undefined;
        if (selectedStateConfig) {
            contents = [];
            if (selectedStateConfig.EmailBodyTemplateKey) contents.push(targetContentMap.EmailBodyContent);
            if (selectedStateConfig.EmailSubjectTemplateKey) contents.push(targetContentMap.EmailSubjectContent);
            if (selectedStateConfig.LetterTemplateKey) contents.push(targetContentMap.LetterContent);
            if (selectedStateConfig.SMSTemplateKey) contents.push(targetContentMap.SMSContent);
        }
        return contents;
    }, [selectedStateConfig]);
    const formFulfilled = !!selectedTargetContent;
    const contentSelectionFields = [
        formFieldNames.WorkflowId,
        formFieldNames.StateName,
        formFieldNames.TargetContent
    ];

    const generateContentsFilter = (clearData: () => void) => {
        return <Row gutter={[30, 10]} type="flex">
            <Col style={{ minWidth: 250, width: '20%' }}>
                <FormItem label="Workflow">
                    {getFieldDecorator(formFieldNames.WorkflowId, {
                        initialValue: selectedWorkflowId,
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            loading={configsLoading}
                            disabled={configsLoading || contentLoading}
                            placeholder={'Choose a workflow'}
                            onChange={(_) => setFieldsValue({
                                [formFieldNames.StateName]: undefined,
                                [formFieldNames.TargetContent]: undefined
                            })}
                        >
                            {map(
                                workflowOptions,
                                ({ WorkflowId, WorkflowName }) => (
                                    <Option key={WorkflowId} value={WorkflowId}>
                                        {WorkflowName}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
            <Col style={{ minWidth: 250, width: '25%' }}>
                <FormItem label="State name">
                    {getFieldDecorator(formFieldNames.StateName, {
                        initialValue: selectedStateName,
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            loading={configsLoading}
                            disabled={!selectedWorkflowStateConfig || configsLoading || contentLoading}
                            placeholder={'Choose a state name'}
                            onChange={(_) => setFieldsValue({
                                [formFieldNames.TargetContent]: undefined
                            })}
                        >
                            {selectedWorkflowStateConfig && map(
                                selectedWorkflowStateConfig.States,
                                ({ StateName }) => (
                                    <Option key={StateName} value={StateName}>
                                        {StateName}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
            <Col style={{ minWidth: 250, width: '25%' }}>
                <FormItem label="Target content">
                    {getFieldDecorator(formFieldNames.TargetContent, {
                        initialValue: selectedTargetContent,
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            loading={configsLoading}
                            disabled={!availableTargetContents || configsLoading || contentLoading}
                            placeholder={'Choose a target content'}
                            onChange={(_) => clearData()}
                        >
                            {availableTargetContents && map(availableTargetContents,
                                ({ label, value }) => (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
        </Row>;
    };

    const generatePayload = (currentTemplate: string) => {
        const payload: SaveContentTemplatePayload & { [key: string]: any } = {
            Type: TemplateType.WorkflowContent,
            WorkflowId: selectedWorkflowId,
            StateName: selectedStateName,
        };
        if (selectedTargetContent) {
            payload[selectedTargetContent] = currentTemplate;
        }
        return payload;
    }

    const fetchInitialData = () => {
        if (!contentConfigs) {
            dispatch(getWorkflowContentConfigurationAction())
        }
    }

    useEffect(fetchInitialData, [contentConfigs]);

    const fetchWorkflowContentTemplate = () => {
        if (selectedTargetContent && selectedStateConfig && availableTargetContents) {
            const targetContentField = find(availableTargetContents, c => c.value === selectedTargetContent);
            const TemplateKey = targetContentField && get(selectedStateConfig, get(targetContentField, 'templateKeyName'));
            if (TemplateKey) {
                dispatch(getContentTemplateAction({
                    TemplateKey
                }));
            }
        }
    };

    useEffect(fetchWorkflowContentTemplate, [selectedTargetContent, selectedStateConfig, availableTargetContents]);

    return <ContentsManagementForm
        form={form}
        formTitle="Workflow contents"
        contentSelectionFields={contentSelectionFields}
        formFulfilled={formFulfilled}
        generateContentsFilter={generateContentsFilter}
        generatePayload={generatePayload}
        refreshCurrentContent={fetchWorkflowContentTemplate}
        dataErrors={errorMessages}
        {...props}
    />
};

const WorkflowContentsManagementPageForm: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const ContentsManagementPageForm = Form.create({
            name: 'workflow-contents-management-page-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(WorkflowContentsManagementPage);

        return withRouter(ContentsManagementPageForm);
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default WorkflowContentsManagementPageForm;
