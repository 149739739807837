/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Col, Form, Modal, Row } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

interface IProps {
    readonly containerRef?: any;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly filterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
}

const ResendPaymentPlanApprovalPanel: React.FC<IProps> = ({
    containerRef,
    closePanel,
    filterPayload,
    dispatchAction,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const customerLabel = useSelector(getCustomerUILabel);

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        setSubmitLoading(true);

        if (dispatchAction) {
            dispatch(
                dispatchAction({
                    ...filterPayload,
                    callback: resendPaymentPlanApprovalResponseModal,
                })
            );
        } else {
            if (closePanel) closePanel(true);
        }
    };

    const resendPaymentPlanApprovalResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Requested resending payment plan approval content successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to resend payment plan approval content!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <div>
                            You are about to resend approval content to the {customerLabel}(s) associated with the selected payment plans.
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Processing"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while processing the request . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const ResendPaymentPlanApprovalPanelForm = Form.create({
    name: 'resend-payment-plan-approval-content-form',
})(ResendPaymentPlanApprovalPanel);

export default withRouter(ResendPaymentPlanApprovalPanelForm);
