/**
 * Function for populating the avatar initials component.
 */

import React, { memo } from 'react';
import { Avatar, Tooltip } from 'antd';

import {
    getNameInitials,
    populatePopoverContainer,
    stringToHex,
} from '../../utils/commonFunctions';

interface IProps {
    readonly fullName: string | undefined;
    readonly size?: number | 'small' | 'large' | 'default' | undefined;
    readonly placement?:
        | 'top'
        | 'left'
        | 'right'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined;
    readonly showTooltip?: boolean;
    readonly isDefault?: boolean;
    readonly containerRef?: any;
    readonly fontSize?: any;
}
const AvatarNameInitialsComponent: React.FC<IProps> = ({
    fullName,
    size,
    placement,
    showTooltip,
    isDefault,
    containerRef,
    fontSize = undefined,
}: IProps) => {
    if (!fullName) return null;

    const stringForColor = isDefault ? undefined : fullName;
    const colorUsed: any = stringToHex(stringForColor);
    const avatarComponent = isDefault ? (
        <Avatar
            size={size}
            style={{ backgroundColor: colorUsed, fontSize }}
            icon="user"
        />
    ) : (
        <Avatar size={size} style={{ backgroundColor: colorUsed, fontSize }}>
            {getNameInitials(fullName)}
        </Avatar>
    );

    if (showTooltip) {
        return (
            <Tooltip
                title={fullName}
                placement={placement || 'topRight'}
                getPopupContainer={populatePopoverContainer(containerRef)}
            >
                {avatarComponent}
            </Tooltip>
        );
    } else {
        return avatarComponent;
    }
};

export default memo(AvatarNameInitialsComponent);
