import { Quill } from "react-quill";
import { CONTENT_CUSTOMIZATION_EDITOR_FORMAT } from "../../constants/contents";
import { get } from "lodash";
const Block = Quill.import('blots/block');

export class CustomBlock extends Block {
    insertBefore(blot: any, ref: any) {
        super.insertBefore(blot, ref);
        const whitelist = get(this, 'parent.whitelist');
        if (get(whitelist, CONTENT_CUSTOMIZATION_EDITOR_FORMAT)) {
            this.domNode.setAttribute('app-customization', 'true');
        }
    }
}