/**
 * File for the `Current ATB` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    find,
    forEach,
    get,
    includes,
    isNumber,
    isUndefined,
    map,
} from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { widgetDisplayTypeValues, populatePayloadForOrganisationWidgets, tableCurrencyFormatter, populatePayloadForOrganisationRegionalWidgets } from '../../constants/dashboards';
import { getDashboardCurrentATBRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    useCompanyFlagValue,
    roundNumberToDecimalDigits,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    currentATBGroupingOptions,
    defaultViewOptionsValue,
} from './CurrentATBWidgetFields';
import { AtbViewType } from '../../constants/settings';
import { ApplicationState } from '../../store';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';

interface IProps {
    widgetDetails: DynamicObject;
    preview?: boolean;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element;
    readonly isOrgView?: boolean;
    readonly functionRefObj?: any;
    readonly organisationCurrenciesAll?: DynamicObject[];
}

const countIndicator = 'Count';
const CurrentATBWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    formatCurrency,
    isOrgView,
    functionRefObj,
    organisationCurrenciesAll
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);
    const atbGroupingOptions = useMemo(() => currentATBGroupingOptions(isCalendarView), [isCalendarView]);

    const [hoveredTooltip, setHoveredTooltip] = useState<string | undefined>(
        undefined
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );
    
    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
    });

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        // const region = widgetDetails.Region;
        // const currencySelectedParsed = region
        //     ? find(organisationCurrenciesAll, ['Name', region])
        //     : undefined;

        const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
        
        const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for formatting currencies
     */
    const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    /**
     * Function that populates the chart array
     * @param currentATB
     */
    const populateChartsArray = (currentATB: DynamicObject) => {
        const IsCreatedDateView = getIfViewingOptionIsCreatedView();
        let forReturn: DynamicObject = {
            name: `Current ATB - ${
                IsCreatedDateView ? 'Created date' : 'Due date'
            }`,
        };

        forEach(atbGroupingOptions, (cago: DynamicObject) => {
            if (
                IsCreatedDateView &&
                cago.value === atbGroupingOptions.NOT_DUE.value
            ) {
                return; //terminate current iteration, only display Not due in Due date atb.
            }

            forReturn[`${get(cago, 'label')}${countIndicator}`] = get(
                currentATB,
                cago.value
            );
            const percentageValue = get(currentATB, `${cago.value}Percentage`);
            forReturn[get(cago, 'label')] =
                percentageValue < 0 ? 0 : percentageValue;
        });


        return [forReturn];
    };

    /**
     * Function that returns a boolean to identify whether the viewing option is created date or not (due date).
     * @returns
     */
    const getIfViewingOptionIsCreatedView = () => {
        return !isUndefined(get(widgetDetails, 'viewOptions'))
            ? get(widgetDetails, 'viewOptions')
            : defaultViewOptionsValue;
    };
    
    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [CUSTOM_FIELD_TYPES.CUSTOMER];

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        const IsCreatedDateView = getIfViewingOptionIsCreatedView();

        let payload: DynamicObject = {
            filters: {
                ...customFieldFilters,
            },
            IsCreatedDateView,
            Customer: customerFieldValue
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed
            });
        }

        dispatch(
            getDashboardCurrentATBRequestAction(
                payload,
                isOrgView,
                (currentATB: DynamicObject) => {
                    if (unmountedRef.current) return;
                    const usedDataSource = populateChartsArray(currentATB);

                    updateWidgetStateObject({
                        dataSource : usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }
    
    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });
        
        dispatchAction(undefined);
    };

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    const showBarTooltip = (tooltipId: string | undefined) => {
        if (hoveredTooltip !== tooltipId) setHoveredTooltip(tooltipId);
    };

    /**
     * Function for populating the bar items for chart.
     */
    const populateBarItemsForChart = () => {
        const IsCreatedDateView = getIfViewingOptionIsCreatedView();
        const forReturn = map(
            atbGroupingOptions,
            (atbGroup: DynamicObject) => {
                const value = get(atbGroup, 'value');
                const label = get(atbGroup, 'label');
                if (
                    IsCreatedDateView &&
                    value === atbGroupingOptions.NOT_DUE.value
                ) {
                    return;
                }
                return (
                    <Bar
                        key={value}
                        dataKey={label}
                        id={label}
                        fill={get(atbGroup, 'color')}
                        stackId="a"
                        maxBarSize={80}
                        onMouseOver={() => showBarTooltip(label)}
                        onMouseOut={() => showBarTooltip(undefined)}
                    />
                );
            }
        );

        return forReturn;
    };

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatPercentage(value);
        } else {
            return value;
        }
    };

    /**
     * Function for formatting the currency tooltip.
     * @param value
     */
    const tooltipCurrencyFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return handleFormatCurrency(value);
        } else {
            return value;
        }
    };

    /**
     * Function for generating custom tooltip
     * @returns
     */
    const CustomTooltip = ({ active, payload }: any) => {
        if (hoveredTooltip && active && payload && payload.length) {
            const filteredValue = get(
                filter(payload, ['dataKey', hoveredTooltip]),
                0
            );
            const payloadValue = get(filteredValue, `payload`);
            const percentageValue = get(payloadValue, hoveredTooltip, 0);
            const color = get(filteredValue, 'color');

            return (
                <div className="custom-widget-tooltip">
                    <b style={{ color }}>{hoveredTooltip}</b>
                    <div>
                        Value:{' '}
                        <span style={{ color }}>
                            {handleFormatCurrency(
                                get(
                                    payloadValue,
                                    `${hoveredTooltip}${countIndicator}`,
                                    0
                                )
                            )}
                        </span>
                    </div>
                    <div>
                        Percentage:{' '}
                        <span style={{ color }}>
                            {formatPercentage(percentageValue)}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };

    /**
     * Formatting percentage number to text with percent sign.
     * @param percentageNumber
     */
    const formatPercentage = (percentageNumber: number) =>
        `${roundNumberToDecimalDigits(percentageNumber)}%`;

    /**
     * Function that converts a number to percentage text.
     * @param value
     */
    const tickFormatter = (value: number) => {
        return `${value}%`;
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            name,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
            return (
                <g>
                    <text
                        x={x}
                        y={y}
                        textAnchor={textAnchor}
                        fill="#333"
                        dominantBaseline="central"
                    >
                        {name}
                    </text>
                    <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                        {`${(percent * 100).toFixed(2)}%`}
                    </text>
                </g>
            );
  

    };

    const { loading: stateLoading, dataSource: stateDataSource } = widgetState;

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');

        //update the table
        if(displayView === widgetDisplayTypeValues.TABLE){
            const columns = [
                {
                    title: 'Current',
                    dataIndex: 'CurrentCount',
                },
                {
                    title: 'Not Due',
                    dataIndex: 'Not dueCount',
                }
            ];
            
            if (isCalendarView) {
                columns.push(
                    {
                        title: '1 month',
                        dataIndex: '1 monthCount',
                    },
                    {
                        title: '2 months',
                        dataIndex: '2 monthsCount',
                    },
                    {
                        title: '3 months',
                        dataIndex: '3+ monthsCount',
                    });
            } else {
                columns.push(
                    {
                        title: '30 Days',
                        dataIndex: '30 daysCount',
                    },
                    {
                        title: '60 Days',
                        dataIndex: '60 daysCount',
                    },
                    {
                        title: '90 Days',
                        dataIndex: '90+ daysCount',
                    });
            }

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'StateName') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableCurrencyFormatter(stateDataSource, handleFormatCurrency)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="name"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            const IsCreatedDateView = getIfViewingOptionIsCreatedView();
            const dataSource = filter(
                map(atbGroupingOptions, (atbGroup: DynamicObject) => {
                  const value = get(atbGroup, 'value');
                  const label = get(atbGroup, 'label');
                  const color = get(atbGroup, 'color');
                  if (IsCreatedDateView && value === atbGroupingOptions.NOT_DUE.value) {
                    return null;
                  }
                  const count = get(stateDataSource[0], label.concat('Count'));
              
                  return {
                    name: label,
                    value: count,
                    color: color,
                  };
                }),(dataPoint) => dataPoint !== null
              ) as ReadonlyArray<object>;

              const filteredData = dataSource.filter((dataPoint: any) => dataPoint.value > 0);

              const colors: string[] = filteredData.map((dataPoint: any) => dataPoint.color);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="value"
                                nameKey="name"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={colors[index % colors.length]}
                                    />
                                ))}

                            </Pie>
                           
                            <Tooltip formatter={tooltipCurrencyFormatter} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
            } else {
                return (
                    <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                        <ResponsiveContainer width="99%" height="99%">
                            <BarChart
                                data={stateDataSource}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    left: 20,
                                    bottom: 15,
                                }}
                                layout="vertical"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    type="number"
                                    ticks={[0, 20, 40, 60, 80, 100]}
                                    tickCount={6}
                                    tickFormatter={tickFormatter}
                                />
                                <YAxis dataKey="name" type="category" hide />
                                {!hoveredTooltip && (
                                    <Tooltip formatter={tooltipFormatter} />
                                )}
                                {hoveredTooltip && (
                                    <Tooltip content={<CustomTooltip />} />
                                )}
                                {preview && <Legend />}
                                {populateBarItemsForChart()}
                            </BarChart>
                        </ResponsiveContainer>
                    </Spin>
            );
        }
    };

    return (
        <div className="current-atb-widget-container h-100">
           {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(CurrentATBWidget);
