/**
 * File responsible for all the UI and actions for Sales>Credits page - `/app/sales/credits`.
 */

import { Col, Row, Tabs, Typography } from 'antd';
import {
    filter,
    find, get, groupBy, map,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CUSTOM_FIELD_TYPES,
} from '../../config/tableAndPageConstants';
import { ApplicationState } from '../../store';
import {
    getCustomFieldsFilterListByTypes,
} from '../../store/common/actions';
import OrganisationCreditsContent from '../../components/sales/OrganisationCreditsContent';
import { Organisation } from '../../store/organisations/types';
import { DEFAULT_REGION_NAME } from '../../config/config';
import { OrganisationIdAttribute } from '../../constants/authUserAttributes';
import { getCurrentUser } from '../../store/users/sagas';
import { CompaniesState } from '../../store/companies/types';
import { AppCommonState } from '../../store/common/types';

const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps {
}
const OrganisationCreditsManagementPage: React.FC<IProps> = ({}: IProps) => {
    const dispatch = useDispatch();
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const userCompanies: CompaniesState['userCompanies'] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );
    const currentUser = useSelector(getCurrentUser);
    const regions = get(selectedUserOrganisation, 'SupportedRegions').sort();
    const [selectedRegion, setSelectedRegion] = useState(DEFAULT_REGION_NAME);
    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );
    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>([]);

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserOrganisation) return;
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrganisationId = get(selectedUserOrganisation, 'OrganisationId');

        if (organisationIdCognito === selectedOrganisationId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CREDIT, CUSTOM_FIELD_TYPES.CUSTOMER], // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    const currencyObjSel = find(get(selectedUserOrganisation, 'Currencies'), [
        'Value',
        appState.currencyCode,
    ]);

    useEffect(getCustomFieldsFilterList, [selectedUserOrganisation]);

    const handleTabChange = (value: string) => {
        setSelectedRegion(value);
    };

    /**
     * Function that fetches the companies by region
     */
    const getCompaniesByRegion = (region: string) => {
        if (!selectedUserOrganisation) return;
        const selectedOrganisationId = get(selectedUserOrganisation, 'OrganisationId');
        
        const companies =  filter(userCompanies, uc => uc.Company.OrganisationId === selectedOrganisationId && 
                                                       uc.Company.Region === region);

        return companies.map(c => {
            return {
                CompanyId:  c.Company.CompanyId, 
                Name: c.Company.Name
            }
        });                                         
    };

    const populateContent = () => {
        if (regions && regions.length === 1) {
            return <OrganisationCreditsContent
                customFieldsFilterList={customFieldsFilterList}
                region={regions[0]}
                companies={getCompaniesByRegion(regions[0])}
                conversationRate={get(currencyObjSel, 'Rate')}
                currencies={get(selectedUserOrganisation, 'Currencies')}
            />
        }

        return <Tabs
            activeKey={selectedRegion}
            onChange={handleTabChange}
        >
            {
                map(regions, (region) => (
                    <TabPane
                        tab={region}
                        key={region}
                    >
                        {selectedRegion === region && <OrganisationCreditsContent
                            customFieldsFilterList={customFieldsFilterList}
                            region={selectedRegion}
                            companies={getCompaniesByRegion(region)}
                        />}
                    </TabPane>
                ))
            }
        </Tabs>
    };

    return (
        <QueueAnim type={['right', 'left']} leaveReverse>
            <Row key="title-container" type="flex" justify="space-between">
                <Col>
                    <Title level={3}>Credits</Title>
                </Col>
            </Row>
            <div className="spacer-5" />
            {populateContent()}
        </QueueAnim>
    );
};

export default OrganisationCreditsManagementPage;