import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    ChangesActionTypes,
} from './types';

/**
 * Action called when deallocating a change line.
 * @param payload
 * @param callback
 */
export const deallocateRequestAction = (
    payload: {},
    callback?: (response: DynamicObject) => void
) =>
    action(ChangesActionTypes.DEALLOCATE_REQUEST, {
        payload,
        callback,
    });