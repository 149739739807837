import { Auth } from '@aws-amplify/auth';
import { Modal, Skeleton } from 'antd';
import { print } from 'graphql/language/printer';
import { Parser } from 'html-to-react';
import invert from 'invert-color';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import {
    capitalize,
    clone,
    debounce,
    difference,
    filter,
    find,
    findIndex,
    first,
    forEach,
    get,
    groupBy,
    has,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    last,
    map,
    omitBy,
    orderBy,
    reduce,
    some,
    sum,
    times,
    toLower,
    toString,
    upperCase,
} from 'lodash';
import moment from 'moment-timezone';
import React, { KeyboardEvent } from 'react';
import { findDOMNode } from 'react-dom';
import config, {
    AMPLIFY_PUBLIC_CONFIG_DEV,
    AMPLIFY_PUBLIC_CONFIG_NONPROD,
    AMPLIFY_PUBLIC_CONFIG_PROD,
    AMPLIFY_PUBLIC_CONFIG_TEST,
    AMPLIFY_PUBLIC_CONFIG_UAT,
    Environments,
} from '../AmplifyConfig';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    getMinMaxIndicator,
    treeParentChildSeparator,
} from '../components/common/FilterBar';
import {
    ASSETS_LINK_DEV,
    ASSETS_LINK_NONPROD,
    ASSETS_LINK_PROD,
    ASSETS_LINK_TEST,
    ASSETS_LINK_UAT,
    COOKIE_LAST_ACTIVE_NAME,
    FETCH_ANNOUNCEMENTS_ON_LOGIN_TOKEN_NAME,
    initialSecondaryColor,
    TIMEOUT_MILLISECOND,
    TIME_ACTIVE_COOKIE_SETTING,
    xlMin,
    API_NAME,
} from '../config/config';
import { USERS_PAGE } from '../config/tableAndPageConstants';
import { conversationTypeClassifications, conversationTypes, fileTypesSignature } from '../constants/common';
import { notQueryRelatedKeys } from '../constants/dashboards';
import {
    dateFormatFileDownload,
    dateFormatTimestamp,
    dateFormatYYYYMMDDDash,
    dateFormatYYYYMMDDTHHmmssDash,
    dateFormatYYYYMMDDTHHmmssSSSSSSUTCDash,
} from '../constants/dateFormats';
import { dateSelectOptions } from '../constants/invoicesSortAndFilters';
import {
    accountingSystemOptions,
    AtbViewType,
    companyApiImportTypes,
    companyCloudImportTypes,
    companyImportTypes,
    hiddenCloudImportFields,
} from '../constants/settings';
import { CustomField } from '../store/common/types';
import { Company, CompanyUserRole } from '../store/companies/types';
import {
    Contact as ContactType,
    Customer as CustomerType,
} from '../store/customers/types';
import { User } from '../store/users/types';
import { DynamicObject } from './commonInterfaces';

import { store } from '../index';
import { companyStatuses } from '../constants/organisationsSortAndFilters';
import { DeliveryDetails } from '../store/invoices/types';
import { ContactFilterOptions } from '../constants/tasksSortAndFilters';
import QueryString from 'query-string';
import { paymentPlanActions } from '../constants/paymentPlansSortAndFilters';
import { ReportTypeFilter } from '../store/report/types';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { Currency, Organisation, OrganisationActionTab } from '../store/organisations/types';
import { getCurrentUser } from '../store/users/sagas';
import { IsOrganisationViewAttribute } from '../constants/authUserAttributes';
import { ApplicationState } from '../store';
import { API, graphqlOperation } from 'aws-amplify';
import { CreditOrganisationCurrency } from '../store/credits/types';

/**
 * Function that returns a hex color based on the string given.
 * Used for populating the Avatar icon color for name.
 * @param name - name of user
 */
export const stringToHex = (name: string | undefined) => {
    if (name === undefined) {
        return '#b3b3b3';
    }
    var hash = 0;
    if (name.length === 0) return hash;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = '#';
    for (let x = 0; x < 3; x++) {
        var value = (hash >> (x * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color.toString();
};

/**
 * Function that returns the initial of a given name.
 * @param name - name of the user
 */
export const getNameInitials = (name: string) => {
    const parts = name.split(' ');
    let initials = '';
    const first = parts[0];
    const last = parts[parts.length - 1];
    initials += first.charAt(0).toUpperCase();
    initials += last.charAt(0).toUpperCase();
    return initials;
};

/**
 * Function that adds number of days to date.
 * @param date - date to add the number of days to
 * @param days - number of days to be added to date
 */
export const addDaysToDate = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 * Function that computes the table scroll x and y based on required value by Ant Design table.
 * @param tableHeightByWindow - table height estimate
 * @param pageSize - number of items in page
 * @param rowHeight - height of each table row
 * @param tableWidthByWindow - table width estimate
 */
export const computeTableScroll = (
    tableHeightByWindow: number,
    pageSize: number,
    rowHeight: number = USERS_PAGE.rowHeight,
    tableWidthByWindow?: number
) => {
    const idealHeight = rowHeight * pageSize;
    let yValue;
    if (tableHeightByWindow <= idealHeight) {
        yValue = tableHeightByWindow;
    } else {
        yValue = pageSize * (rowHeight - 2);
    }

    const returnValue: any = {
        y: yValue,
    };

    if (tableWidthByWindow) {
        returnValue.x = tableWidthByWindow;
    }
    return returnValue;
};

/**
 * Function that refreshes the cognito session.
 * @param callback - function called after refreshing the session irregardless if there's
 * an error or not
 */
export const refreshCognitoAttributes = async (
    callback?: (err: any, session: any) => void
) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (callback) {
            callback(err, session);
        }
    });
};

/**
 * Function that updates the less variables in color.less file used for styling the UI
 * on the go.
 * @param colorVariables - color variables, must conform to the less variables defined
 * @param callback - callback function after variables have been updated
 */
export const updateLessVariables = (
    colorVariables: any,
    callback?: () => void
) => {
    const receivedColorVariables: any = { ...colorVariables };

    const primaryColor = receivedColorVariables['@custom-primary-color'];
    if (primaryColor) {
        receivedColorVariables['@contrast-custom-primary'] =
            getContrastColor(primaryColor);
    }
    receivedColorVariables['@custom-secondary-color'] = initialSecondaryColor; // overwrite secondary passed with the original grey one that we have
    const secondaryColor = receivedColorVariables['@custom-secondary-color'];

    if (secondaryColor) {
        receivedColorVariables['@contrast-custom-secondary'] =
            getContrastColor(secondaryColor);
    }

    window.less
        .modifyVars(receivedColorVariables)
        .then(() => {
            if (callback) {
                callback();
            }
        })
        .catch((error: any) => {
            console.log('failed to update theme', error);
        });
};

/**
 * Function that fetches the inverse of the color. Usually used for getting the font color
 * of a section with background.
 * @param color - color to get the inverse of
 */
export const getContrastColor = (color: any) => {
    const blackWhiteParams = {
        black: '#000000',
        white: '#ffffff',
        threshold: 0.4,
    };

    if (color) {
        return invert(color, blackWhiteParams);
    } else {
        return blackWhiteParams.black;
    }
};

/**
 * Function that gets the popover container based on the ref given.
 * If there's no ref given, or the given ref is invalid, should return the html body.
 * @param ref - ref for the component to be used as the wrapper
 */
export const getPopoverContainer = (ref: any) => {
    if (ref && ref.current) {
        return findDOMNode(ref.current) as HTMLElement;
    } else {
        return document.body;
    }
};

/**
 * Function that returns the correct Company name based on a given logic.
 * @param Customer - customer object from API
 */
export const getCompanyName = (Customer: CustomerType) => {
    if (!isEmpty(Customer)) {
        const customerDisplayName = get(Customer, 'DisplayName');
        if (customerDisplayName) {
            return customerDisplayName;
        } else {
            const { CompanyName, Contacts } = Customer;
            const Contact: ContactType = get(Contacts, 0);
            const FirstName = get(Contact, 'FirstName');
            const LastName = get(Contact, 'LastName');
            if (!isEmpty(CompanyName)) {
                if (!isEmpty(FirstName)) {
                    return `${CompanyName}, ${FirstName}`;
                } else {
                    return CompanyName;
                }
            } else if (!isEmpty(FirstName) && !isEmpty(LastName)) {
                return `${FirstName} ${LastName}`;
            } else if (!isEmpty(FirstName) && isEmpty(LastName)) {
                return FirstName;
            } else if (isEmpty(FirstName) && !isEmpty(LastName)) {
                return LastName;
            } else {
                return '';
            }
        }
    } else {
        return '';
    }
};

/**
 * Function that gets the full address based on a given contact object.
 * @param Contact - contact object from api
 */
export const getContactAddress = (Contact: ContactType) => {
    let fullAddress = '';

    const addressNeededArray = [
        'AddressLine1',
        'AddressLine2',
        'City',
        'State',
        'Postcode',
    ];

    forEach(addressNeededArray, (addressNeeded: string) => {
        const addressOption = get(Contact, addressNeeded);

        if (!isEmpty(addressOption)) {
            if (!isEmpty(fullAddress)) {
                fullAddress += ', ';
            }
            fullAddress += addressOption;
        }
    });

    return fullAddress;
};

/**
 * Function that return the proper color for each contact method/type.
 * @param Contact - contact object from API
 * @param fullAddress - full address for post address color
 */
export const getContactIconColors = (
    Contact: ContactType,
    fullAddress: string
) => {
    let addressColor = isEmpty(fullAddress) ? 'red' : 'green';
    let mobileColor = '';
    let emailColor = '';

    if (isEmpty(mobileColor)) {
        mobileColor = !isEmpty(Contact.MobileNumber) ? 'green' : 'red';
    }

    if (isEmpty(emailColor)) {
        emailColor = !isEmpty(Contact.Email) ? 'green' : 'red';
    }

    return {
        addressColor,
        mobileColor,
        emailColor,
    };
};

/**
 * Getting the correct indefinete variable (`a` or `an`) based on the succeeding word/phrase.
 * @param text - the succeeding text
 */
export const getIndefiniteArticleBySucceedingWordOrPhrase = (text: string) => {
    // Getting the first word
    var match = /\w+/.exec(text);
    if (match) var word = match[0];
    else return 'an';

    var l_word = word.toLowerCase();
    // Specific start of words that should be preceeded by 'an'
    var alt_cases = ['honest', 'hour', 'hono'];
    for (var i in alt_cases) {
        if (l_word.indexOf(alt_cases[i]) === 0) return 'an';
    }

    // Single letter word which should be preceeded by 'an'
    if (l_word.length === 1) {
        if ('aedhilmnorsx'.indexOf(l_word) >= 0) return 'an';
        else return 'a';
    }

    // Capital words which should likely be preceeded by 'an'
    if (
        word.match(
            /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/
        )
    ) {
        return 'an';
    }

    // Special cases where a word that begins with a vowel should be preceeded by 'a'
    const regexes = [
        /^e[uw]/,
        /^onc?e\b/,
        /^uni([^nmd]|mo)/,
        /^u[bcfhjkqrst][aeiou]/,
    ];
    for (var r in regexes) {
        if (l_word.match(regexes[r])) return 'a';
    }

    // Special capital words (UK, UN)
    if (word.match(/^U[NK][AIEO]/)) {
        return 'a';
    } else if (word === word.toUpperCase()) {
        if ('aedhilmnorsx'.indexOf(l_word[0]) >= 0) return 'an';
        else return 'a';
    }

    // Basic method of words that begin with a vowel being preceeded by 'an'
    if ('aeiou'.indexOf(l_word[0]) >= 0) return 'an';

    // Instances where y follwed by specific letters is preceeded by 'an'
    if (l_word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/)) return 'an';

    return 'a';
};

/**
 * Function that prevents the image from being cached by the browser by appending a timestamp to the path.
 * @param imagePath - current image path that is/will be cached by the browser
 */
export const invalidateImagePathCache = (imagePath: string, time?: number) => {
    const timeUsed = time || new Date().getTime();
    return imagePath + `?removeCachingTime=${timeUsed}`;
};

/**
 * Function that returns the correct assets path based on the build environment.
 */
export const getAssetsPath = () => {
    let assetsPath = ASSETS_LINK_DEV;

    const buildEnvironment = getCurrentBuildEnvironment();
    if (buildEnvironment === Environments.DEV) {
        assetsPath = ASSETS_LINK_DEV;
    } else if (buildEnvironment === Environments.PROD) {
        assetsPath = ASSETS_LINK_PROD;
    } else if (buildEnvironment === Environments.TEST) {
        assetsPath = ASSETS_LINK_TEST;
    } else if (buildEnvironment === Environments.UAT) {
        assetsPath = ASSETS_LINK_UAT;
    } else if (buildEnvironment === Environments.NONPROD) {
        assetsPath = ASSETS_LINK_NONPROD;
    }

    return assetsPath;
};

/**
 * Function that returns the correct amplify config based on the build environment.
 */
export const getPublicAmplifyConfig = () => {
    let publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_DEV;
    const buildEnvironment = getCurrentBuildEnvironment();
    if (buildEnvironment === Environments.DEV) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_DEV;
    } else if (buildEnvironment === Environments.PROD) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_PROD;
    } else if (buildEnvironment === Environments.TEST) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_TEST;
    } else if (buildEnvironment === Environments.UAT) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_UAT;
    } else if (buildEnvironment === Environments.NONPROD) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_NONPROD;
    }

    return publicAmplifyConfig;
};

/**
 * Function that check's if the view/device is for mobile/ipad view.
 */
export const getIsMobile = () => {
    return window.innerWidth < 758; //For size md
};

/**
 * Function that gets the current build environment.
 */
export const getCurrentBuildEnvironment = () => config.environment;

/**
 * Function that removes the indicator filters used for populating the filter bar section which the API doesn't really need.
 * @param filters - filters to be passed API as payload
 * @param processFilters - indicator if filter payload is to be processed or not
 * @param taskFilterProcessor - manages processor to be used for manipulating the filter payload is the one used in tasks page
 */
export const removeAppliedFiltersForApiRequest = (
    filters: {},
    processFilters: boolean = false,
    taskFilterProcessor: string = 'customer',
    useLocalTime: boolean = true
) => {
    const cleanFilterList: any = {};
    let filtersUsed = filters;
    const customFieldsFilters: DynamicObject[] = [];
    const CustomFieldMultipleValuesFilters: DynamicObject[] = [];
    if (processFilters) {
        if (
            taskFilterProcessor === 'customer' ||
            taskFilterProcessor === 'invoice' ||
            taskFilterProcessor === 'credit'
        ) {
            filtersUsed =
                populateFilterCalculationsForPayloadCustomerInvoices(filters);
        } else if (taskFilterProcessor === 'task') {
            filtersUsed = populateFilterCalculationsForPayloadTask(filters);
        } else if (taskFilterProcessor === 'ticket') {
            filtersUsed = populateFilterCalculationsForPayloadTicket(filters);
        } else if (taskFilterProcessor === 'organisation') {
            filtersUsed =
                populateFilterCalculationsForPayloadOrganisation(filters);
        } else if (taskFilterProcessor === 'paymentPlans') {
            filtersUsed =
                populateFilterCalculationsForPayloadPaymentPlans(filters);
        } else if (taskFilterProcessor === 'changelines') {
            filtersUsed = populateFilterCalculationsForPayloadChangeLines(filters);
        }
        else if (taskFilterProcessor === 'remittanceAdvices') {
            filtersUsed =
                populateFilterCalculationsForPayloadRemittanceAdvices(filters);
        }
        else if (taskFilterProcessor === 'report') {
            filtersUsed = populateFilterCalculationsForPayloadReports(filters);
        }
    }

    forEach(filtersUsed, (filter: any, keyName: string) => {
        if (includes(keyName, customFieldIndicator)) {
            if (includes(keyName, appliedFilterIndicator) && filter) {
                const usedKeyValue = keyName
                    .replace(customFieldIndicator, '')
                    .replace(appliedFilterIndicator, '');
                const customFieldKeyValArray = usedKeyValue.split('--');
                if(Array.isArray(filter)) {
                    CustomFieldMultipleValuesFilters.push({
                        Type: get(customFieldKeyValArray, 0),
                        Name: get(customFieldKeyValArray, 1),
                        Values: filter,
                    });
                } else {
                    customFieldsFilters.push({
                        Type: get(customFieldKeyValArray, 0),
                        Name: get(customFieldKeyValArray, 1),
                        Value: filter,
                    });
                }
            }

            delete cleanFilterList[keyName];
        } else if (includes(keyName, getMinMaxIndicator)) {
            const dateFilterName = keyName.replace(getMinMaxIndicator, '');
            const { minDate, maxDate } = getDateFilterValues(
                get(filters, dateFilterName),
                useLocalTime
            );
            cleanFilterList[`${dateFilterName}Min`] = minDate;
            cleanFilterList[`${dateFilterName}Max`] = maxDate;
        } else if (
            !includes(keyName, appliedFilterIndicator) &&
            !(includes(keyName, 'Date') && get(filter, 'value')) // For date objects
        ) {
            cleanFilterList[keyName] = filter;
        }
    });

    if (!isEmpty(CustomFieldMultipleValuesFilters)) {
        cleanFilterList.CustomFieldMultipleValuesFilters =
            JSON.stringify(CustomFieldMultipleValuesFilters);
    } else if (!isEmpty(customFieldsFilters)) {
        cleanFilterList.CustomFieldFilters =
            JSON.stringify(customFieldsFilters);
    }

    return cleanFilterList;
};

/*
* Function that returns the numerical value for contact type
* @param deliveryType - deliverystatus Type
* @param smsDeliveryType - smsDeliveryStatus Type
*/
export const getContactTypeValue = (deliveryDetails: DeliveryDetails[]) => {
    let contactTypes = 0;

    forEach(deliveryDetails, (value: DeliveryDetails) => {
        if (get(value, 'Type') == "Email") {
            contactTypes = contactTypes + ContactFilterOptions['Email'];
        }
        else if (get(value, 'Type') == "SMS") {
            contactTypes = contactTypes + ContactFilterOptions['Mobile'];
        }
    });

    return contactTypes;
};

export const getTimelineIconAndColor = (
    type: string,
    typeClassification: string,
    isRecentComment: boolean | undefined = undefined,
    context: string | undefined = undefined) => {
    const defaultSetting = {
        icon: [''],
        color: undefined
    };

    switch(type) {
        case conversationTypes.Comment: 
            {
                switch(typeClassification) {
                    case conversationTypeClassifications.Comment:
                        return {
                            icon: ['fas', 'comment'],
                            color: isRecentComment ? 'blue' : 'grey',
                        };
                    default:
                            return defaultSetting;
                }
            }
        case conversationTypes.DeliveryTracking:
            {
                switch(typeClassification) {
                    case conversationTypeClassifications.AutomatedEmail:
                        {
                            return {
                                icon: ['fas', 'envelope'],
                                color: 'orange',
                            };
                        }
                    case conversationTypeClassifications.EmailOpened: 
                        {
                            return {
                                icon: ['fas', 'envelope-open'],
                                color: 'green',
                            };
                        }
                    case conversationTypeClassifications.FailedEmailDelivery: 
                        {
                            return {
                                icon: ['fas', 'envelope'],
                                color: 'red',
                            };
                        }
                    case conversationTypeClassifications.AutomatedSMS: 
                        {
                            return {
                                icon: ['fas', 'mobile-alt'],
                                color: 'orange',
                            };
                        }
                    case conversationTypeClassifications.SMSOpened: 
                        {
                            return {
                                icon: ['fas', 'mobile-alt'],
                                color: 'green',
                            };
                        }
                    case conversationTypeClassifications.FailedSMSDelivery: 
                        {
                            return {
                                icon: ['fas', 'mobile-alt'],
                                color: 'red',
                            };
                        }
                    default:
                            return defaultSetting;
                }
            }
        case conversationTypes.EntityStateChange:
            {
                switch(typeClassification) {
                    case conversationTypeClassifications.NewTicket:
                    case conversationTypeClassifications.ResolvedTicket:
                        return {
                            icon: ['far', 'question-circle'],
                            color: 'orange',
                        };
                    case conversationTypeClassifications.PaymentPlan:
                        const action = context && first(
                            context.split(';')
                                .filter(prop => prop.startsWith('action='))
                                .map(prop => prop.split('=')[1])
                        );

                        let color: string = '';
                        switch (action) {
                            case paymentPlanActions.Requested: color = 'orange'; break;
                            case paymentPlanActions.Approved: color = 'green'; break;
                            case paymentPlanActions.Completed: color = 'blue'; break;
                            case paymentPlanActions.Rejected:
                            case paymentPlanActions.Cancelled: color = 'red'; break;
                        }

                        return {
                            icon: ['fas', 'money-bill-alt'],
                            color: color,
                        };
                    default:
                        return defaultSetting;
                }
            }
        case conversationTypes.ResourceDiscussion:
            {
                switch (typeClassification) {
                    case conversationTypeClassifications.ResourceRequest:
                        return {
                            icon: ['fas', 'share'],
                            color: 'green',
                        };
                    case conversationTypeClassifications.ResourceReply:
                        return {
                            icon: ['fas', 'reply'],
                            color: 'purple',
                        };
                    default:
                        return defaultSetting;
                }
            }
        default:
            return defaultSetting;
    }
};


/**
 * Function for processing the payload for invoices and customers filter payload
 * @param filters
 */
export const populateFilterCalculationsForPayloadCustomerInvoices = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };
    const ContactData = filterParams.Contact;
    if (ContactData) {
        filterParams.Contact = sum(get(ContactData, 'Contact'));
        filterParams.InclusiveContact = get(ContactData, 'InclusiveContact');
    }
    const UserInputData = filterParams.UserInputData;
    if (UserInputData) {
        filterParams.User = get(UserInputData, 'Name');
        filterParams.FromCustomer = !isEmpty(
            get(UserInputData, 'FromCustomer')
        );

        delete filterParams.UserInputData;
    }

    const ResultData = filterParams.Result;
    if (ResultData) {
        filterParams.Result = sum(ResultData);
    }

    const Type = filterParams.Type;
    if (Type) {
        filterParams.Type = sum(Type);
    }

    if (!isUndefined(filterParams.IsBlocked)) {
        let isBlockedValue: any = filterParams.IsBlocked;
        if (filterParams.IsBlocked === 'true') {
            isBlockedValue = true;
        } else if (filterParams.IsBlocked === 'false') {
            isBlockedValue = false;
        }
        filterParams.IsBlocked = isBlockedValue;
    }

    if (!isUndefined(filterParams.IsVip)) {
        let isVipValue: any = filterParams.IsVip;
        if (filterParams.IsVip === 'true') {
            isVipValue = true;
        } else if (filterParams.IsVip === 'false') {
            isVipValue = false;
        }
        filterParams.IsVip = isVipValue;
    }

    return filterParams;
};

/**
 * Function for processing the payload for all task related page
 */
const populateFilterCalculationsForPayloadTask = (filters: DynamicObject) => {
    const filterParams = { ...filters };

    if (filterParams.Type) {
        filterParams.Type = sum(filterParams.Type);
    }
    if (filterParams.DeliveryStatus) {
        filterParams.DeliveryStatus = sum(filterParams.DeliveryStatus);
    }
    if (filterParams.DeliveryType) {
        filterParams.DeliveryType = sum(filterParams.DeliveryType);
    }
    if (filterParams.ContactType) {
        filterParams.Contact = sum(filterParams.ContactType);

        delete filterParams.ContactType;
    }
    if (filterParams.Customer) {
        filterParams.DisplayName = filterParams.Customer;
        filterParams.CustomerCode = filterParams.Customer;

        delete filterParams.Customer;
    }
    if (filterParams.StatusType) {
        filterParams.Status = sum(filterParams.StatusType);

        delete filterParams.StatusType;
    }

    if (filterParams.WorkflowNameStep) {
        const workflowFilters: DynamicObject[] = [];
        forEach(filterParams.WorkflowNameStep, (value: string) => {
            if (includes(value, treeParentChildSeparator)) {
                const childArr = value.split(treeParentChildSeparator);
                const parent = get(childArr, 0);
                const child = get(childArr, 1);
                const workflowFilterIdx = findIndex(workflowFilters, [
                    'WorkflowId',
                    parent,
                ]);

                if (workflowFilterIdx < 0) {
                    workflowFilters.push({
                        WorkflowId: parent,
                        States: [child],
                    });
                } else {
                    const workflowObj = clone(
                        get(workflowFilters, workflowFilterIdx)
                    );
                    const workflowStates = clone(workflowObj.States);
                    if (!includes(workflowStates, child))
                        workflowStates.push(child);

                    workflowFilters[workflowFilterIdx] = {
                        ...workflowObj,
                        States: workflowStates,
                    };
                }
            }
        });

        filterParams.WorkflowFilters = workflowFilters;

        delete filterParams.WorkflowNameStep;
    }

    if (filterParams.Action) {
        filterParams.Action = sum(filterParams.Action);
    }

    return filterParams;
};

/**
 * Function for processing the payload for all ticket related page
 */
const populateFilterCalculationsForPayloadTicket = (filters: DynamicObject) => {
    const filterParams = { ...filters };

    if (filterParams.Type) {
        filterParams.TicketOptionReasons = filterParams.Type;

        delete filterParams.Type;
    }
    if (filterParams.Customer) {
        filterParams.DisplayName = filterParams.Customer;
        filterParams.CustomerCode = filterParams.Customer;

        delete filterParams.Customer;
    }

    if (filterParams.Action) {
        filterParams.Action = sum(filterParams.Action);
    }

    return filterParams;
};

/**
 * Function for processing the payload for all organisations related page
 */
const populateFilterCalculationsForPayloadOrganisation = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };

    const statusParam = filterParams.Status;
    if (statusParam && !isEmpty(statusParam)) {
        if (statusParam.length === 1) {
            filterParams.IsClosed = includes(
                statusParam,
                companyStatuses.CLOSED
            )
                ? true
                : false;
        }
    }

    if (statusParam) delete filterParams.Status;

    return filterParams;
};

/**
 * Function for processing the payload for all payment plans related page
 */
const populateFilterCalculationsForPayloadPaymentPlans = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };

    const Type = filterParams.Type;
    if (Type) {
        filterParams.Type = sum(Type);
    }

    if (filterParams.StatusType) {
        filterParams.Status = sum(filterParams.StatusType);

        delete filterParams.StatusType;
    }

    return filterParams;
};

const populateFilterCalculationsForPayloadRemittanceAdvices = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };

    if (filterParams.RemittanceAdviceState) {
        filterParams.RemittanceAdviceState = sum(filterParams.RemittanceAdviceState);
    }

    if (!isUndefined(filterParams.MultipleCustomer)) {
        let multipleCustomerValue: any = filterParams.MultipleCustomer;
        if (filterParams.MultipleCustomer === 'true') {
            multipleCustomerValue = true;
        } else if (filterParams.MultipleCustomer === 'false') {
            multipleCustomerValue = false;
        }
        filterParams.MultipleCustomer = multipleCustomerValue;
    }

    return filterParams;
};

const populateFilterCalculationsForPayloadReports = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };

    if (filterParams.ReportTypes) {
        const reportTypeFilters: ReportTypeFilter[] = [];
        forEach(filterParams.ReportTypes, (value: string) => {
            if (includes(value, treeParentChildSeparator)) {
                const childArr = value.split(treeParentChildSeparator);
                let description: string = get(childArr, 1);
                let type: number = parseInt(get(childArr, 0));
                let currentFilter: ReportTypeFilter | undefined = find(reportTypeFilters, f => f.Type === type);
                if (currentFilter) {
                    currentFilter.Descriptions.push(description);
                } else {
                    currentFilter = {
                        Descriptions: [description],
                        Type: type
                    };
                    reportTypeFilters.push(currentFilter);
                }
            } else {
                let type: number = parseInt(value);
                reportTypeFilters.push({
                    Descriptions: [],
                    Type: type
                });
            }
        });

        if (!isEmpty(reportTypeFilters)) {
            filterParams.ReportTypeFilters = reportTypeFilters;
        }

        delete filterParams.ReportTypes;
    }

    if (filterParams.Status) {
        filterParams.Status = sum(filterParams.Status);
    }

    const userValue = filterParams.ActionedByUserId;
    if (userValue) {
        if (checkIfEmailIsValid(userValue)) {
            filterParams.UserEmailAddress = userValue;
        } else {
            filterParams.UserId = userValue;
        }
        delete filterParams.ActionedByUserId;
    }

    return filterParams;
};

/**
 * Function for processing the payload for all change lines
 */
const populateFilterCalculationsForPayloadChangeLines = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };

    if (filterParams.Type) {
        filterParams.Type = sum(filterParams.Type);
    }

    return filterParams;
};

/**
 * Function that checks if the size of device used is Lg or below. Based on Ant design variables for Grid.
 */
export const getIfIsLg = () => {
    return window.innerWidth < xlMin;
};

/**
 * Function that returns the badge component display.
 * Used for correcting the bug found when scrolling in iOS safari if the badge is inside a virtualized list.
 * @param count - number to be shown inside the badge.
 * @param maxCount - the max number to be displayed for the badge. Ex. 99, if the count is > 99, then the badge will show 99+
 */
export const getBadgeDisplay = (count: number, maxCount?: number) => {
    if (count > 0) {
        let usedCount: string | number = count;
        const usedMaxCount = maxCount || 99;
        if (count > usedMaxCount) {
            usedCount = `${usedMaxCount}+`;
        }

        return <div className="ant-badge-count">{usedCount}</div>;
    }
};

/**
 * Function that gets the users complete name.
 * @param user - user object from API
 */
export const getUserFullName = (user: User) => {
    return !isEmpty(user)
        ? `${get(user, 'GivenName')} ${get(user, 'FamilyName')}`
        : '- -';
};

/**
 * Function that populates loading skeletons.
 * @param iteration - number of loading skeletons to show
 * @param loading - loading indicator
 */
export const getLoadingSkeletons = (
    iteration: number,
    loading: boolean,
    titleProp?: any,
    paragraphProp?: any
) => {
    const skeletons = times(iteration, (key: number) => (
        <Skeleton
            key={key}
            active
            loading={loading}
            title={titleProp}
            paragraph={paragraphProp}
        />
    ));

    return <>{skeletons}</>;
};

/**
 * Function that inserts a specific array of elements at a given index.
 * @param arr - array to insert the items array
 * @param index - index where the array elements be inserted (starting)
 * @param itemArray - the array of items to be inserted
 */
export const insertAt = (arr: any[], index: number, itemArray: any[]) => [
    ...arr.slice(0, index),
    ...itemArray,
    ...arr.slice(index),
];

/**
 * Function that remove whitespaces from string.
 * @param str - string to remove the whitespaces from
 */
export const removeSpaces = (str: string) => {
    return str.replace(/\s/g, '');
};

/**
 * Function that calls a callback (empty the predefined filter) when
 * there are applied filter changes and dropdown filter state is undefined.
 * @param filters - filters object to compare to previously applied filter
 * @param tableFilters - filters object previously applied
 * @param dropdownStateValue - value of the dropdown state
 * @param fromFilterBar - boolean indicator if from FilterBar `Apply filters` button
 * @param callbackEmptyFilter - function to call when conditions are met
 */
export const emptyPredefinedFilterOnAppliedFilters = (
    filters: DynamicObject | undefined,
    tableFilters: DynamicObject | undefined,
    dropdownStateValue: any,
    fromFilterBar: boolean,
    callbackEmptyFilter: () => void
) => {
    const compactFilters = omitBy(filters, isEmpty);
    const compactTableFilters = omitBy(tableFilters, isEmpty);

    if (
        fromFilterBar &&
        !isEqual(compactFilters, compactTableFilters) &&
        !isEmpty(dropdownStateValue)
    ) {
        callbackEmptyFilter();
    }
};

/**
 * Common function that sets popover container based on the given containerRef.
 * @param containerRef - ref where any of the popover element will appear
 */
export const populatePopoverContainer = (containerRef?: any) => {
    return containerRef ? () => getPopoverContainer(containerRef) : undefined;
};

/**
 * Parser needed for rendering HTML strings to react instead of using `dangerouslySetInnerHTML`.
 */
const htmlParser = new Parser();
/**
 * Function that parses an html string to html object that can be rendered directly.
 * @param htmlString
 */
export const parseToHTML = (htmlString: string) => {
    return htmlParser.parse(htmlString);
};

/**
 * Function that gets the date values (mostly min and max).
 * @param filterValue - value of filter
 * @param useLocalTime - boolean indicator if localtime is to be used or not
 */
export const getDateFilterValues = (
    filterValue: any,
    useLocalTime?: boolean
) => {
    const isCalendarView = getCompanyFlagValue(store.getState(), AtbViewType.CalendarView)
    const filterSelected = get(filterValue, 'value');
    const {
        THIS_MONTH,
        NEXT_MONTH,
        LAST_MONTH,
        LAST_7_DAYS,
        LAST_30_DAYS,
        NEXT_7_DAYS,
        NEXT_30_DAYS,
        CUSTOM_DATE_RANGE,
        CUSTOM_DAYS_RANGE,
        NOW,
        TODAY,
        WITHIN_THIS_WEEK,
        WITHIN_NEXT_7_DAYS,
        LAST_WEEK_UP_TO_NOW,
        CREATE_DATE_ATB_CURRENT,
        CREATE_DATE_ATB_30_DAYS,
        CREATE_DATE_ATB_60_DAYS,
        CREATE_DATE_ATB_90_PLUS_DAYS,
        CREATE_DATE_ATB_1_MONTH,
        CREATE_DATE_ATB_2_MONTHS,
        CREATE_DATE_ATB_3_PLUS_MONTHS,
        DUE_DATE_ATB_NOT_DUE,
        DUE_DATE_ATB_CURRENT,
        DUE_DATE_ATB_30_DAYS,
        DUE_DATE_ATB_60_DAYS,
        DUE_DATE_ATB_90_PLUS_DAYS,
        DUE_DATE_ATB_1_MONTH,
        DUE_DATE_ATB_2_MONTHS,
        DUE_DATE_ATB_3_PLUS_MONTHS,
        AVAILABLE_DATE_ATB_NOT_AVAILABLE,
        AVAILABLE_DATE_ATB_CURRENT,
        AVAILABLE_DATE_ATB_30_DAYS,
        AVAILABLE_DATE_ATB_60_DAYS,
        AVAILABLE_DATE_ATB_90_PLUS_DAYS,
        AVAILABLE_DATE_ATB_1_MONTH,
        AVAILABLE_DATE_ATB_2_MONTHS,
        AVAILABLE_DATE_ATB_3_PLUS_MONTHS
    } = dateSelectOptions;
    let minDate: null | string | moment.Moment = '';
    let maxDate: null | string | moment.Moment = '';

    if (filterSelected === THIS_MONTH) {
        minDate = moment().startOf('month').format(dateFormatYYYYMMDDDash);
        maxDate = moment().endOf('month').format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_MONTH) {
        const nextMonth = moment().add(1, 'month');
        minDate = moment(nextMonth)
            .startOf('month')
            .format(dateFormatYYYYMMDDDash);
        maxDate = moment(nextMonth)
            .endOf('month')
            .format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_MONTH) {
        const previousMonth = moment().subtract(1, 'month');
        minDate = moment(previousMonth)
            .startOf('month')
            .format(dateFormatYYYYMMDDDash);
        maxDate = moment(previousMonth)
            .endOf('month')
            .format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_7_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Last', 7);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_30_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Last', 30);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_7_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Next', 7);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_30_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Next', 30);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === CUSTOM_DATE_RANGE) {
        const startDate = moment(get(filterValue, 'From'));
        const endDate = moment(get(filterValue, 'To'));
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === CUSTOM_DAYS_RANGE) {
        let startDate, endDate;
        if (get(filterValue, 'From') && get(filterValue, 'To')) {
            // if (filterNameRaw === 'ActionDate') {
            const fromValue = get(filterValue, 'From');

            const rangeTypeFrom = has(fromValue, 'Last') ? 'Last' : 'Next';
            const fromStart = getDayRangeDatesFromTo(
                rangeTypeFrom,
                fromValue[rangeTypeFrom]
            );

            const toValue = get(filterValue, 'To');
            const rangeTypeTo = has(toValue, 'Last') ? 'Last' : 'Next';
            const toEnd = getDayRangeDatesFromTo(
                rangeTypeTo,
                toValue[rangeTypeTo]
            );

            startDate = fromStart;
            endDate = toEnd;
        } else {
            const lastValue = get(filterValue, 'Last');
            const nextValue = get(filterValue, 'Next');
            if (lastValue !== undefined) {
                const { startDate: startVal, endDate: endVal } =
                    getDayRangeDates('Last', lastValue);
                startDate = startVal;
                endDate = endVal;
            } else if (nextValue !== undefined) {
                const { startDate: startVal, endDate: endVal } =
                    getDayRangeDates('Next', nextValue);
                startDate = startVal;
                endDate = endVal;
            }
        }

        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NOW) {
        minDate = null;
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === TODAY) {
        minDate = moment().format(dateFormatYYYYMMDDDash);
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === WITHIN_THIS_WEEK) {
        minDate = moment().startOf('week').format(dateFormatYYYYMMDDDash);
        maxDate = moment().endOf('week').format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === WITHIN_NEXT_7_DAYS) {
        minDate = moment().format(dateFormatYYYYMMDDDash);
        const { endDate } = getDayRangeDates('Next', 7);
        maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_WEEK_UP_TO_NOW) {
        const lastWeek = moment().subtract(1, 'week');
        minDate = lastWeek.startOf('week').format(dateFormatYYYYMMDDDash);
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_CURRENT ||
        filterSelected === DUE_DATE_ATB_CURRENT ||
        filterSelected === AVAILABLE_DATE_ATB_CURRENT
    ) {
        if (isCalendarView) {
            minDate = moment().startOf('month').format(dateFormatYYYYMMDDDash);
            maxDate = moment().endOf('month').format(dateFormatYYYYMMDDDash);
        } else {
            minDate = moment().subtract(29, 'days').format(dateFormatYYYYMMDDDash);
            maxDate = moment().format(dateFormatYYYYMMDDDash);
        }
    } else if (
        filterSelected === CREATE_DATE_ATB_30_DAYS ||
        filterSelected === DUE_DATE_ATB_30_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_30_DAYS
    ) {
        minDate = moment().subtract(59, 'days').format(dateFormatYYYYMMDDDash);
        maxDate = moment().subtract(30, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_1_MONTH ||
        filterSelected === DUE_DATE_ATB_1_MONTH ||
        filterSelected === AVAILABLE_DATE_ATB_1_MONTH
    ) {
        const startMonth = moment().startOf('month').subtract(1, 'months').startOf('month');
        minDate = startMonth.format(dateFormatYYYYMMDDDash);
        maxDate = startMonth.endOf('month').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_60_DAYS ||
        filterSelected === DUE_DATE_ATB_60_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_60_DAYS
    ) {
        minDate = moment().subtract(89, 'days').format(dateFormatYYYYMMDDDash);
        maxDate = moment().subtract(60, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_2_MONTHS ||
        filterSelected === DUE_DATE_ATB_2_MONTHS ||
        filterSelected === AVAILABLE_DATE_ATB_2_MONTHS
    ) {
        const startMonth = moment().startOf('month').subtract(2, 'months').startOf('month');
        minDate = startMonth.format(dateFormatYYYYMMDDDash);
        maxDate = startMonth.endOf('month').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_90_PLUS_DAYS ||
        filterSelected === DUE_DATE_ATB_90_PLUS_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_90_PLUS_DAYS
    ) {
        minDate = null;
        maxDate = moment().subtract(90, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_3_PLUS_MONTHS ||
        filterSelected === DUE_DATE_ATB_3_PLUS_MONTHS ||
        filterSelected === AVAILABLE_DATE_ATB_3_PLUS_MONTHS
    ) {
        minDate = null;
        maxDate = moment().endOf('month').subtract(3, 'months').endOf('month').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === DUE_DATE_ATB_NOT_DUE ||
        filterSelected === AVAILABLE_DATE_ATB_NOT_AVAILABLE
    ) {
        if (isCalendarView) {
            minDate = moment().startOf('month').add(1, 'months').format(dateFormatYYYYMMDDDash);
        } else {
            minDate = moment().add(1, 'days').format(dateFormatYYYYMMDDDash);
        }
        
        maxDate = null;
    }
    const minDateWithTimeLocal = minDate && useLocalTime ? minDate + 'T00:00:00' : null;
    const maxDateWithTimeLocal = maxDate && useLocalTime ? maxDate + 'T23:59:59' : null;
    if (formatDateToDateObjectUTC) {
        if (minDateWithTimeLocal) {
            minDate = formatDateToDateObjectUTC(
                minDateWithTimeLocal,
                dateFormatYYYYMMDDTHHmmssDash,
                useLocalTime
            );
        }

        if (maxDateWithTimeLocal) {
            maxDate = formatDateToDateObjectUTC(
                maxDateWithTimeLocal,
                dateFormatYYYYMMDDTHHmmssDash,
                useLocalTime
            );
        }
    }

    return {
        minDate: !useLocalTime ? moment(minDate + 'T00:00:00.000Z', dateFormatYYYYMMDDTHHmmssSSSSSSUTCDash) : minDate,
        maxDate: !useLocalTime ? moment(maxDate + 'T23:59:59.000Z', dateFormatYYYYMMDDTHHmmssSSSSSSUTCDash) : maxDate,
    };
};

/**
 * Function that gets the dates based on range given and number of days
 * returns both start and end dates.
 * @param rangeType - Next, Last
 * @param days - number of days to add/subtract based on rangeType
 */
export const getDayRangeDates = (rangeType: 'Next' | 'Last', days: number) => {
    let startDate, endDate;
    if (rangeType === 'Next') {
        startDate = moment().add(1, 'day');
        endDate = moment(startDate).add(days - 1, 'days');
    } else if (rangeType === 'Last') {
        endDate = moment();
        startDate = moment(endDate).subtract(days, 'days');
    }

    return {
        startDate,
        endDate,
    };
};

/**
 * Function that gets the date based on rangeType and number of days
 * returns a single date (moment object).
 * @param rangeType - Next, Last
 * @param days - number of days to add/subtract based on rangeType
 */
const getDayRangeDatesFromTo = (rangeType: 'Next' | 'Last', days: number) => {
    if (rangeType === 'Next') {
        return moment(moment().add(1, 'day')).add(days - 1, 'days');
    } else if (rangeType === 'Last') {
        return moment().subtract(days, 'days');
    }
};

/**
 * Function that formats date (assumed local) to UTC date object.
 * Returns moment.Moment.
 * @param date - date in local date format
 * @param fromFormat - format of the date passed
 */
export const formatDateToDateObjectUTC = (
    date: any,
    fromFormat?: string | null,
    useLocalTime?: boolean
) => {
    const usedFromFormat = fromFormat || dateFormatYYYYMMDDTHHmmssDash;
    const localDatetime = moment(date, usedFromFormat).toDate();
    const storeState = store.getState();
    const companyTimezone = get(
        storeState,
        'companies.selectedUserCompany.Company.Timezone'
    );

    if (!useLocalTime && companyTimezone) {
        return moment
            .tz(
                moment(localDatetime).format(dateFormatYYYYMMDDTHHmmssDash),
                companyTimezone
            )
            .utc();
    } else {
        return moment.utc(localDatetime);
    }
};

/**
 * Function that gets all the urls (with http:// or https://) outside an anchor tag
 * and converts it to a link (inside anchor tag).
 * @param htmlString
 */
export const urlifyURLsOutsideAnchorTags = (htmlString: string) => {
    const re = /((https?|http?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
    const urlsOutsideAnchor = htmlString.replace(re, (url: string) => {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });

    return urlsOutsideAnchor;
};

/**
 * Function that checks if the url string is a valid one.
 * @param url
 */
export const checkIfValidURL = (url: string) => {
    var pattern = new RegExp(
        // protocol identifier
        '(?:(?:(?:https?|ftp):)?//)' +
            // user:pass authentication
            '(?:\\S+(?::\\S*)?@)?' +
            '(?:' +
            // IP address exclusion
            // private & local networks
            '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
            '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
            '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broacast addresses
            // (first & last IP address of each class)
            '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
            '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
            '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
            '|' +
            // host name
            '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
            // domain name
            '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
            // TLD identifier
            '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))' +
            // port number
            '(?::\\d{2,5})?' +
            // resource path
            '(?:[/?#]\\S*)?'
    ); // fragment locator
    return !!pattern.test(url);
};

/*
 * Function to set the last active cookie value to current timestamp.
 */
export const setCookieLastActiveCurrent = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    Cookies.set(COOKIE_LAST_ACTIVE_NAME, currentTimeStamp, {
        path: '/',
    });
};

/*
 * Function to set the token if announcements should be fetched on login (prevents fetching on refresh).
 */
export const setShouldFetchAnnouncementsOnLogin = (value: 'true' | 'false') => {
    Cookies.set(FETCH_ANNOUNCEMENTS_ON_LOGIN_TOKEN_NAME, value, {
        path: '/',
    });
};

/**
 * Function that gets the last active time stored as cookie with the ms allowance.
 */
const getCookieLastActiveTimeWithAllowance = () => {
    const cookieLastActive = Cookies.get(COOKIE_LAST_ACTIVE_NAME);
    const allowanceTimeout = TIMEOUT_MILLISECOND - TIME_ACTIVE_COOKIE_SETTING;
    const cookieLastActiveWithAllowance = moment(
        cookieLastActive,
        dateFormatTimestamp
    )
        .add(allowanceTimeout, 'ms')
        .format(dateFormatTimestamp);

    return cookieLastActiveWithAllowance;
};

/**
 * Function for getting a boolean if the user has been inactive within a period of time based on stored cookie.
 */
export const getBooleanIndicatorIfInactiveWithinPeriod = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    const cookieLastActiveWithAllowance =
        getCookieLastActiveTimeWithAllowance();

    return moment(
        cookieLastActiveWithAllowance,
        dateFormatTimestamp
    ).isSameOrBefore(moment(currentTimeStamp, dateFormatTimestamp));
};

/**
 * Function that gets the number of milliseconds between the stored cookie active time and current timestamp.
 */
export const getDiffInMsLastActiveAndCurrentTimestamp = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    const cookieLastActiveWithAllowance =
        getCookieLastActiveTimeWithAllowance();

    return moment(cookieLastActiveWithAllowance, dateFormatTimestamp).diff(
        moment(currentTimeStamp, dateFormatTimestamp),
        'ms'
    );
};

/**
 * Download to excel response handler (showing of modal response).
 */
export const downloadToExcelModalResponseHandler = (
    IsSuccess: boolean,
    Messages: string[] | undefined,
    callback?: () => void
) => {
    const modalZIndex = 2000;
    if (IsSuccess) {
        Modal.success({
            title: 'Success',
            content: `Data extraction process has started. You will be notified once it is finished together with the download link!`,
            onOk: () => {
                if (callback) callback();
            },
            zIndex: modalZIndex
        });
    } else {
        let errorMessageContent: any = `Failed to start the data extraction process. Please try again later.`;
        if (!isEmpty(Messages)) {
            errorMessageContent = map(
                Messages,
                (error: string, index: number) => <div key={index}>{error}</div>
            );
        }
        Modal.error({
            title: 'Error',
            content: errorMessageContent,
            zIndex: modalZIndex
        });
    }
};

/**
 * Function for populating the title section of change role modal.
 */
export const populateDownloadToExcelModalTitle = () => `Download as Excel`;

/**
 * Function for populating the body section of change role modal.
 */
export const populateDownloadToExcelModalDisplayMessage = () =>
    `Please wait while we start the data extraction process. . .`;

/**
 * Common function that downloads a file from the given presigned url.
 * @param presignedUrl
 * @param callback
 */
export const downloadFileFromPresignedUrl = (
    presignedUrl: string,
    callback?: () => void
) => {
    let fileName = '';

    fetch(presignedUrl)
        .then((response) => {
            const stringIndicator = '?X-Amz-Expires';
            const responseUrlArr = filter(
                response.url.split('/'),
                (stringElement: string) =>
                    includes(stringElement, stringIndicator)
            );

            const lastElement = responseUrlArr ? last(responseUrlArr) : '';
            if (lastElement) {
                fileName = get(
                    lastElement.match(`^(.+?)${stringIndicator}`),
                    1,
                    ''
                ).replace('?', '');
            }

            return response.blob();
        })
        .then((blob) => {
            if (callback) callback();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName.replace(
                '.xlsx',
                `${moment().format(dateFormatFileDownload)}.xlsx`
            );
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

/**
 * Function for getting the days overdue string/label based on the number of days provided.
 * @param date - date in utc
 * @param days - number of days
 */
export const populateDaysOverdue = (date: string, days: number | null) => {
    let overdueString = '';
    // if (days === 0) {
    //     const today = moment.utc();
    //     if (moment.utc(date).isSame(today, 'day')) {
    //         overdueString = 'Due today';
    //     } else {
    //         overdueString = 'Due tomorrow';
    //     }
    // } else if (days === 1) {
    //     overdueString = 'Due yesterday';
    // } else if (days === -1) {
    //     const tomorrow = moment.utc().add(1, 'day');

    //     if (moment.utc(date).isSame(tomorrow, 'day')) {
    //         overdueString = 'Due tomorrow';
    //     } else {
    //         overdueString = '-2 days overdue';
    //     }
    // } else {
    //     overdueString = `${days} days overdue`;
    // }
    if (days === 0) {
        overdueString = 'Due today';
    } else if (days === 1) {
        overdueString = 'Due yesterday';
    } else if (days === -1) {
        overdueString = 'Due tomorrow';
    } else {
        // if (!isNull(days) && days > 0) days++;
        overdueString = `${days} days overdue`;
    }

    return overdueString;
};

/**
 * Function that returns the sort field object based on the value selected
 * @param sortFieldValue - sort field value
 */
export const getSortFieldsWithCustomFields = (sortFieldValue: string) => {
    const sortObject: DynamicObject = {};
    if (includes(sortFieldValue, customFieldIndicator)) {
        const sortFieldValueArray = sortFieldValue
            .replace(customFieldIndicator, '')
            .split('--');
        sortObject.CustomFieldSort = JSON.stringify({
            Type: get(sortFieldValueArray, 0),
            Name: get(sortFieldValueArray, 1),
        });
    } else {
        sortObject.SortField = sortFieldValue;
    }

    return sortObject;
};

/**
 * Function that fetches the class names for each atb value.
 */
export const trialBalanceColorClassNames = (
    ATBData: any,
    isDueDateView: boolean
) => {
    /**
     * Function for getting the greatest amount label for aged trial balance.
     * @param mapValue
     */
    const getGreatestAmountLabel = (mapValue: any) => {
        let greatestAmount = 0;
        let forReturnLabel: any = '';

        forEach(mapValue, (amount, amountLabel) => {
            if (amount > 0) {
                if (amount === greatestAmount) {
                    forReturnLabel = `${forReturnLabel}+++${amountLabel}`;
                } else if (amount > greatestAmount) {
                    greatestAmount = amount;
                    forReturnLabel = amountLabel;
                }
            }
        });

        return forReturnLabel;
    };

    const greatestAmountLabel: [] =
        getGreatestAmountLabel(ATBData).split('+++');

    const overdueLabels = ['Period1', 'Period2', 'Period3Plus'];

    if (isDueDateView) {
        overdueLabels.push('Current');
    }

    let greatestHasOverdue = false;
    forEach(overdueLabels, (overdueLabel: string) => {
        if (includes(greatestAmountLabel, overdueLabel)) {
            greatestHasOverdue = true;
            return false;
        }
    });

    /**
     * Function that gets the classname/color for the aged trial balance label.
     * @param trialBalanceLabel
     */
    const getTrialBalanceColorClassname = (
        trialBalanceLabel: typeof ATBValues[number]
    ) => {
        if (
            includes(greatestAmountLabel, trialBalanceLabel) &&
            greatestHasOverdue &&
            includes(overdueLabels, trialBalanceLabel)
        ) {
            return 'red ws-nw';
        } else if (
            includes(greatestAmountLabel, trialBalanceLabel) &&
            !greatestHasOverdue &&
            !includes(overdueLabels, trialBalanceLabel)
        ) {
            return 'green ws-nw';
        } else {
            return 'ws-nw';
        }
    };

    const ATBValues = [
        'Current',
        'Period1',
        'Period2',
        'Period3Plus',
        'NotDue',
    ];

    const ATBClassNames: DynamicObject = {};
    forEach(ATBValues, (atbName: string) => {
        ATBClassNames[atbName] = getTrialBalanceColorClassname(atbName);
    });

    return ATBClassNames;
};

/**
 * Function that handles opening of URLs in new tab & focuses it, and pop-up blocker detection
 * @param url
 * @param callback
 */
export const openURLNewTab = (
    url: string,
    containerRef?: any,
    callback?: Function
) => {
    const newWin = window.open(url, '_blank');

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        Modal.warning({
            title: 'Pop-up blocker enabled',
            content:
                'Please turn off your pop-up blocker for this site and refresh this page to proceed.',
            getContainer: () => getPopoverContainer(containerRef),
        });
    } else {
        newWin.focus();
        if (callback) callback();
    }
};
/**
 * Merge 2 array of objects by key.
 * @param a1 - Original array (contains all)
 * @param a2 - Array of objects that will override the data on the original array
 * @param key - Key of object to compare
 */
export const mergeByKey = (
    a1: DynamicObject[],
    a2: DynamicObject[],
    key: string
) =>
    a1.map((itm) => ({
        ...itm,
        ...a2.find((item) => item[key] === itm[key] && item),
    }));

export const debounceEventHandler = (
    eventFunction: (...args: any) => any,
    delayInMs: number
) => {
    const debounced = debounce(eventFunction, delayInMs);

    return function (e?: any) {
        if (e) e.persist();
        return debounced(e);
    };
};

export const customFieldsTableColumnValues = (
    customFieldType: string,
    customFieldList: CustomField[],
    customFieldsDataIndex: string[]
) => {
    const customFieldsTableObject: DynamicObject = {};

    forEach(customFieldList, ({ Name, Value }: CustomField) => {
        const customFieldDataIndexName = `${customFieldIndicator}${customFieldType}--${Name}`;

        const isNameInIndexCaseInsensitive = some(
            customFieldsDataIndex,
            (invoiceDataIdxName: string) => {
                return invoiceDataIdxName === customFieldDataIndexName;
            }
        );

        if (isNameInIndexCaseInsensitive) {
            customFieldsTableObject[customFieldDataIndexName] = Value;
        }
    });

    return customFieldsTableObject;
};

export const dashboardBypassAPIFetch = (
    lastWidgetDetails: DynamicObject,
    widgetDetails: DynamicObject
) => {
    const refetchQuery = get(widgetDetails, 'refetchQuery');
    if (refetchQuery === true) {
        return false;
    } else if (!isEmpty(lastWidgetDetails)) {
        const differenceWidgetDetailKeys = reduce(
            widgetDetails,
            (result, value, key: any) => {
                return isEqual(value, lastWidgetDetails[key])
                    ? result
                    : result.concat(key);
            },
            []
        );

        const filteredKeys = difference(
            differenceWidgetDetailKeys,
            notQueryRelatedKeys
        );

        if (
            isEmpty(filteredKeys) ||
            (!isUndefined(refetchQuery) && refetchQuery === false)
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * Function that checks filetype by magic number
 */
export const checkFileTypeByMagicNumber = (
    file: any,
    allowedFileTypes: string[],
    callback: (response?: boolean) => void
) => {
    const fileName = get(file, 'name');
    // Bypass txt file as file signatures for them are very random.
    if (fileName && fileName.split('.').pop() === 'txt') {
        return callback(includes(allowedFileTypes, `.txt`));
    }

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = function (e) {
        const result = get(e, 'target.result');
        if (result) {
            const arr = new Uint8Array(result).subarray(0, 4);
            let fileSignature = '';
            for (let i = 0; i < arr.length; i++) {
                fileSignature += arr[i].toString(16);
            }
            const upperFileSignature = upperCase(fileSignature).replace(
                /\s/g,
                ''
            );

            let isValid = false;
            forEach(fileTypesSignature, (magicNumArray, fileType) => {
                const magicNumList = magicNumArray.join(',');
                if (includes(magicNumList, upperFileSignature)) {
                    if (includes(allowedFileTypes, `.${fileType}`)) {
                        isValid = true;
                        return false;
                    }
                }
            });

            callback(isValid);
        }
        // Check the file signature against known types
        //https://en.wikipedia.org/wiki/List_of_file_signatures
    };
};

/**
 * Function that gets the region config based on the list of data provided.
 */
export const getRegionConfigFromList = (
    companyRegion: string,
    keyData: DynamicObject[],
    settingsData: DynamicObject[]
) => {
    const keyConfig = get(filter(keyData, ['Region', companyRegion]), 0, {});
    const settingConfig = get(
        filter(settingsData, ['Region', companyRegion]),
        0,
        {}
    );
    return { ...keyConfig, ...settingConfig };
};

/**
 * Function that checks if the request should refetch based on error response
 */
export const checkShouldRequestRefetch = (err: any) =>
    includes(toString(err), 'Network Error') ||
    includes(get(err, 'errors.0.message'), 'Network Error') ||
    includes(get(err, 'response.data.Messages.0', ''), 'timed out') ||
    get(err, 'response.status') === 504;

const navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
];

export const setCaretPosition = (ctrl: any, pos: number) => {
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}

export const limitOnlyNumber =
    (allowDecimal = true, limitDecimal = true, allowNegative = false)
        : (event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void => {
        return (event) => {
            if (
                navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
                (event.key === 'a' && event.ctrlKey) || // Allow: Ctrl+A
                (event.key === 'c' && event.ctrlKey) || // Allow: Ctrl+C
                (event.key === 'v' && event.ctrlKey) || // Allow: Ctrl+V
                (event.key === 'x' && event.ctrlKey) || // Allow: Ctrl+X
                (event.key === 'a' && event.metaKey) || // Allow: Cmd+A (Mac)
                (event.key === 'c' && event.metaKey) || // Allow: Cmd+C (Mac)
                (event.key === 'v' && event.metaKey) || // Allow: Cmd+V (Mac)
                (event.key === 'x' && event.metaKey) || // Allow: Cmd+X (Mac)
                (event.key === '.'
                    && allowDecimal && (!limitDecimal || event.currentTarget.value && !event.currentTarget.value.includes('.'))) ||
                (event.key === '-' && allowNegative)
            ) {
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (!Number.isInteger(parseInt(event.key))) {
                event.preventDefault();
            }
        }
    };

/**
 * BYPASS CORS
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export const postBypassCORS = (
    path: string,
    params: DynamicObject,
    method = 'post'
) => {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

/**
 * Function for getting the dropdown value based on details from Company.
 */
export const getSelectedAccountingSystemBasedOnCompanyDetails = (
    selectedUserCompany: CompanyUserRole | undefined
) => {
    const companyImportType = get(
        selectedUserCompany,
        'Company.ImportType',
        companyImportTypes.EXCEL
    );
    const companyCloudImportType = get(
        selectedUserCompany,
        'Company.CloudImportType'
    );
    const companyApiImportType = get(
        selectedUserCompany,
        'Company.ApiImportType'
    );
    let selectedAccountingSystemFromCompany =
        accountingSystemOptions.IODM_CONNECT_WEB_API;
    if (companyImportType === companyImportTypes.EXCEL) {
        selectedAccountingSystemFromCompany = accountingSystemOptions.EXCEL;
    } else if (companyImportType === companyImportTypes.CLOUD) {
        if (companyCloudImportType === companyCloudImportTypes.XERO) {
            selectedAccountingSystemFromCompany = accountingSystemOptions.XERO;
        }
    } else if (companyImportType === companyImportTypes.API) {
        if (companyApiImportType === companyApiImportTypes.LEGACY) {
            selectedAccountingSystemFromCompany =
                accountingSystemOptions.LEGACY_API;
        } else if (
            companyApiImportType === companyApiImportTypes.IODM_CONNECT_WEB
        ) {
            selectedAccountingSystemFromCompany =
                accountingSystemOptions.IODM_CONNECT_WEB_API;
        }
    }

    return selectedAccountingSystemFromCompany;
};

/**
 * Function for getting sort by options if using cloud import type.
 */
export const getUsedSortByOptionsIfCloud = (
    usedSortByOptions: { label: string; value: string }[],
    isUsingCloudImportType: boolean
) => {
    return isUsingCloudImportType
        ? filter(
              usedSortByOptions,
              (option) => !includes(hiddenCloudImportFields, option.value)
          )
        : clone(usedSortByOptions);
};

/**
 * Function that checks if the date property has already passed.
 */
export const checkIfDateHasPassed = (actionDatePassed: string) => {
    const utcMoment = moment.utc();
    return utcMoment.isSameOrAfter(moment.utc(actionDatePassed));
};

/**
 * Function to check if this row item task is ready.
 * Returns boolean.
 */
export const checkIfTaskReady = (state: string, actionDate: string) => {
    return state === 'Available' && checkIfDateHasPassed(actionDate);
};

/**
 * Function for checking if valid json string
 */
export const checkIsValidJsonString = (text: string) => {
    text = typeof text !== 'string' ? JSON.stringify(text) : text;

    try {
        text = JSON.parse(text);
    } catch (e) {
        return false;
    }

    if (typeof text === 'object' && text !== null) {
        return true;
    }

    return false;
};

/**
 * Common function for checking if string is a valid email.
 */
export const checkIfEmailIsValid = (email: string | undefined | null) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return re.test(String(email).toLowerCase());
};

/**
 * Common function to round a decimal number to certain decimal places.
 */
export const roundNumberToDecimalDigits = (
    value: number,
    decimalDigits: number = 2
) => (Math.round(value * 100) / 100).toFixed(decimalDigits);

/**
* Formatting percentage number to text with percent sign.
* @param percentageNumber
*/
export const formatPercentage = (percentageNumber: number) =>
`${roundNumberToDecimalDigits(percentageNumber)}%`;

/**
 * Common function for getting the domain prefix based on environment. Used to replace [Environment] placeholder.
 */
export const getDomainPrefixBasedOnEnvironment = () => {
    const environment = getCurrentBuildEnvironment();
    let domainPrefix = '';
    if (environment === Environments.DEV) {
        domainPrefix = 'dev.';
    } else if (environment === Environments.TEST) {
        domainPrefix = 'test.';
    } else if (environment === Environments.UAT) {
        domainPrefix = 'uat.';
    } else if (environment === Environments.NONPROD) {
        domainPrefix = 'sandbox.';
    } else if (environment === Environments.PROD) {
        domainPrefix = '';
    }

    return domainPrefix;
};

/**
 * Function for formatting numbers to 1K or M or something.
 */
export const nFormatter = (num: number, digits: number = 1): string => {
        if (num < 0) {
            // If the number is negative, return the formatted number with a minus sign.
            return '-' + nFormatter(Math.abs(num), digits);
        }
    
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return num >= item.value;
            });
    
        return item
            ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
            : '0';
};
    

/**
 * Common function for fetching the compact view state of a page.
 */
export const getIsCompactViewForPage = (
    userPrefs: DynamicObject[],
    pageName: string
) => {
    const filteredUserPref = filter(userPrefs, ['PageName', pageName]);
    return get(filteredUserPref, '0.IsCompactView');
};

/**
 * Function that replaces all instances of capitalized and lowercased Customer string in a string.
 */
export const replaceInstancesOfCustomerString = (
    wholeStr: string,
    replacedStr: string,
    isOrgView?: boolean
) => {
    return isOrgView
        ? wholeStr
        : wholeStr
              .replace(/Customer/g, capitalize(replacedStr))
              .replace(/customer/g, toLower(replacedStr));
};

/**
 * Common function for replacing all instances of string in text
 */
export const replaceAllStrDynamic = (
    wholeStr: string,
    replacedStr: string,
    replacedWithStr: string
) => {
    return wholeStr.split(replacedStr).join(replacedWithStr);
};

/**
 * Function for getting query string from DocumentNode
 */
export const getGraphqlQueryString = (doc: DynamicObject) => {
    return print(doc);
};

/**
 * Function for getting current notification page type
 */
 export const getNotificationType = (location: any) => {
    const { search } = location;
    const urlQueries = QueryString.parse(search);
    const type = urlQueries['i'] as string;
    return {
        isNewInvoice: type === 'n',
        type
    };
};

/**
 * Function for checking if a comment is recent or not
 */
export const checkIsRecentComment = (createdDateTime: any, numberOfDaysBeforeRecentCommentTurnedOff: number) => {
    const diffDays = moment().diff(moment(createdDateTime), 'days');
    return diffDays <= numberOfDaysBeforeRecentCommentTurnedOff;
};

/**
 * Function for checking if a scroll event already at bottom
 */
export const isScrollToBottom = (ev: any, padding:number = 10) => {
    const { scrollTop, scrollHeight, clientHeight } = ev.currentTarget;
    return (scrollTop + clientHeight) >= (scrollHeight - padding);
}

export const roundToDecimals = (value: number, decimals: number = 2) => {
    const pow = Math.pow(10, decimals);
    return Math.round(value * pow) / pow;
}

export const parseCsv = (csvStr: string, delimiter: string | undefined = undefined) => {
    const result = Papa.parse(csvStr, {
        delimiter,
        header: false
    });
    if (result.data) return result.data as any[][];
    throw new Error("Invalid csv data");
}

export const convertCamelToSentence=(input:string) =>{
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Split camelCase
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Split PascalCase
      .toLowerCase() // Convert to lowercase
      .replace(/(^|\s)([a-z])/g, (_, space, letter) => space + letter.toUpperCase()); // Capitalize first letters
  }

export const generateGUID = (): string => {
    return uuidv4();
  };

export const computeOrganisationTabs = (selectedUserOrganisation: Organisation, userCompanies: CompanyUserRole[]) => {
    if (!selectedUserOrganisation || !userCompanies) return;
    let validCompanies = filter(userCompanies, uc => uc.Company.OrganisationId === get(selectedUserOrganisation, 'OrganisationId'));
    validCompanies = orderBy(validCompanies, c => c.Company.Region);
    const groupedCompanies = groupBy(validCompanies, c => `${c.Company.Region} (${c.Company.UsingCustomerWorkflow ? 'Customer Workflow' : 'Invoice Workflow'})`);
    const tabs: OrganisationActionTab[] = map(Object.entries(groupedCompanies), ([tabName, companies]) => ({
        tabName,
        region: companies[0].Company.Region,
        usingCustomerWorkflow: companies[0].Company.UsingCustomerWorkflow,
        companies: companies.map(c =>   {
            return {
                CompanyId:  c.Company.CompanyId, 
                Name: c.Company.Name
            }
        })
    }));
    return tabs;
}

export const useCompanyFlagValue = (flagName: keyof Company, usingCompany?: Company) => {
    const flagValue = useSelector((state: ApplicationState) => getCompanyFlagValue(state, flagName, usingCompany));
    return flagValue;
};

export const getCompanyFlagValue = (state: ApplicationState, flagName: keyof Company, usingCompany?: Company) => {
    if (usingCompany) {
        const userCompanies: CompanyUserRole[] = state.companies.userCompanies;
        const userCompany = userCompanies.find(uc => usingCompany && uc.Company.CompanyId === usingCompany.CompanyId);
        usingCompany = userCompany && userCompany.Company;
        if (usingCompany) get(usingCompany, flagName);
    }
    const currentUser = getCurrentUser(state);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    if (isOrgView) {
        const selectedUserOrganisation: Organisation | undefined = state.organisations.selectedUserOrganisation;
        const userCompanies: CompanyUserRole[] = state.companies.userCompanies;
        const orgUserCompanies = filter(userCompanies, uc => uc.Company.OrganisationId === get(selectedUserOrganisation, 'OrganisationId'));
        return some(orgUserCompanies, uc => (uc.Company as any)[flagName]);
    } else {
        const selectedUserCompany: CompanyUserRole | undefined = state.companies.selectedUserCompany;
        return get(selectedUserCompany, ['Company', flagName]);
    }
}

export const getNextMonth = (date: moment.Moment) => {
    // Get the current year and month
    var nextMonth = date.clone().add(1, 'months');
  
    // Get the number of days in the next month
    var daysInNextMonth = nextMonth.daysInMonth();
  
    // Get the current day of the month
    var currentDay = date.date();
  
    // If the current day is greater than the number of days in the next month, use the last day of the next month
    if (currentDay > daysInNextMonth) {
      currentDay = daysInNextMonth;
    }
  
    // Set the day to the adjusted day and format the date
    return nextMonth.date(currentDay);
}

export const customPostData = async (url: string, data: any, headers: any = {}) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to post data');
    }
    return response.json();
};

export const getUsingCompanyAndWorkflow = (
    isOrgView: boolean | undefined,
    selectedUserCompany: CompanyUserRole | undefined,
    selectedUserOrganisation: Organisation | undefined,
    userCompanies: CompanyUserRole[] | [],
    entityCompanyId: string | undefined
) => {
    let init: any = {
        selectedCompanyId: undefined,
        usingCustomerWorkflow: undefined,
        selectedCompanyInformation: undefined,
        selectedResourceId: undefined
    } 

    let usingCompanyAndWorkflow: any = {
        ... init,
        selectedCompanyId: get(
            selectedUserCompany, 
            'Company.CompanyId')
        ,
        usingCustomerWorkflow: get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        ),
        selectedCompanyInformation: get(
            selectedUserCompany, 
            'Company'
        ),
        selectedResourceId: get(
            selectedUserCompany, 
            'Company.CompanyId'
        )
    };

    if (isOrgView) {
        const selectedOrganisationId = get(selectedUserOrganisation, 'OrganisationId');
    
        let validCompanies = filter(
            userCompanies, 
            uc => uc.Company.OrganisationId === selectedOrganisationId
        );
        const company = validCompanies.find(com => com.Company.CompanyId === entityCompanyId);
        if (isUndefined(company))
            return init;
        
        usingCompanyAndWorkflow.selectedCompanyId = company.Company.CompanyId;
        usingCompanyAndWorkflow.selectedCompanyInformation = company.Company;
        usingCompanyAndWorkflow.usingCustomerWorkflow = company.Company.UsingCustomerWorkflow;
        usingCompanyAndWorkflow.selectedResourceId = get(selectedUserOrganisation, 'OrganisationId');
    }

    return usingCompanyAndWorkflow;
};

export const getAccessToken = async () => {
    const currentSession: DynamicObject = await Auth.currentSession();
    const accessToken = get(currentSession, 'accessToken.jwtToken');
    return accessToken || null;
};

export const getRegionallyGraphqlResponse = async (
    regionBaseApi: string | undefined,
    query: any,
    variables?: any
) => {
    if (!isUndefined(regionBaseApi) && !isEmpty(regionBaseApi)) {
        const accessToken = await getAccessToken();
        const requestInit: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: accessToken,
            },
        };
        if (!isUndefined(variables) && !isEmpty(variables)) {
            requestInit.body = JSON.stringify({
                query: getGraphqlQueryString(query),
                variables,
            });
        }

        const response: Response = await fetch(
            `${regionBaseApi}/graphql`,
            requestInit
        );

        return await response.json();
    }

    return await API.graphql(graphqlOperation(query, variables));
};

const getApiMethod: any = (method: string) => {
    switch(method) {
        case 'GET':
            return API.get;
        case 'POST':
            return API.post;
        case 'PUT':
            return API.put;
        case 'DELETE':
            return API.del;
        case 'PATCH':
            return API.patch
        default:
            return undefined;
    }
};

export const getRegionallyApiResponse = async (
    regionBaseApi: string | undefined,
    query: any,
    method: string,
    variables?: any
) => {
    if (!isUndefined(regionBaseApi) && !isEmpty(regionBaseApi)) {
        const accessToken = await getAccessToken();
        const requestInit: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: accessToken,
            },
        };
        if (!isUndefined(variables) && !isEmpty(variables)) {
            requestInit.body = JSON.stringify(variables);
        }

        const response: Response = await fetch(
            `${regionBaseApi}${query}`,
            requestInit
        );

        return await response.json();
    }
    const apiMethod = getApiMethod(method);
    return await apiMethod(API_NAME, query, {
        body: variables,
    });
};

export const getOrganisationCurrencies = (
    selectedUserOrganisation: Organisation
): any[] => {
    let result: CreditOrganisationCurrency[] = [];
    const currencies = get(selectedUserOrganisation, 'Currencies');
    if (!isUndefined(currencies) && !isEmpty(currencies)) {
        result = currencies
            .map(item => ({
                Value: item.Value,
                Rate: item.Rate
            }));
    }
    return result;
};

export const getSelectedCurrencyRate = (
    selectedUserOrganisation: Organisation,
    currencyCode: string
): number | undefined => {
    let result: number | undefined;
    const currency = find(get(selectedUserOrganisation, 'Currencies'), [
        'Value',
        currencyCode,
    ]);
    if (!isUndefined(currency) &&  !isEmpty(currency)) {
        result = get(currency, 'Rate');
    }
    return result;
};

