/**
 * File for the `Tickets` widget.
 */

import { Spin, Table } from 'antd';
import { clone, find, get, isEmpty, isNumber, isUndefined, map, forEach, includes, filter, set } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import {
    widgetDisplayTypeValues,
    populatePayloadForOrganisationRegionalWidgets,
    widgetDateRangeValues,
    tableNumberFormatter,
    displayColumnOptions
} from '../../constants/dashboards';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { ApplicationState } from '../../store';
import { getDashboardTicketsForUsersRequestAction, getDashboardTicketsForUserTypesRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    getDateFilterValues,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { defaultEndDate, defaultStartDate } from './TicketsWidgetFields';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    ticketFieldIndicator
} from '../common/FilterBar';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';
import { displayColumnIndicator } from './TopCustomersWidgetFields';
import { checkIfEmailIsValid } from '../../utils/commonFunctions';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    preview?: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}
const TicketsWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    preview,
    isOrgView,
    organisationCurrenciesAll,
    functionRefObj
}: IProps) => {
    const dispatch = useDispatch();
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );
    const widgetContainerRef = useRef<any>(null);
    const unmountedRef = useRef<any>(null);

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptions
        : filter(displayColumnOptions, ['OrgViewOnly', false]);


    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: label,
        dataIndex: value,
    }));

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        tickWidth: number;
        chartMarginLeft: number;
        columns: DynamicObject[];
        groupByUserType: boolean;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        tickWidth: 30,
        chartMarginLeft: 20,
        columns: initialTableColumns,
        groupByUserType: false
    });

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        if (unmountedRef.current) return;
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    /**
     * Function to remove Internal or External users from datasource
     * @param data - The data source
     * @param userType - "Internal" | "External"
     * @param grouped - true | false
     */
    const removeUserType = (data: DynamicObject, userType: string, grouped: boolean) => {
        if (!grouped) {
            if (userType === "Internal") {
                return data.filter(
                    (item: any) => item.User === null
                );
            }
            else {
                return data.filter(
                    (item: any) => item.AssignedEmailAddress === null
                );
            }
        }
        else {
            if (userType === "Internal") {
                return data.filter(
                    (item: any) => item.UserType !== "Company Users"
                );
            }
            else {
                return data.filter(
                    (item: any) => item.UserType !== "External Users"
                );
            }
        }
       
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const ticketsColumns: DynamicObject[] = [];
        let hasDisplayValue = false;
        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        const customFieldFilters: DynamicObject = {};
        let AssignedUserId: any = undefined;
        let AssignedEmailAddress: any = undefined;
        let Customer: string = '';
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let TicketOptionReasons: any = [];
        let createdDateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let closedDateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let CreatedDateMin: any = undefined;
        let CreatedDateMax: any = undefined;
        let ClosedDateMin: any = undefined;
        let ClosedDateMax: any = undefined;
        let DisplayInternalUsers = true;
        let DisplayExternalUsers = true;
        let GroupByUserType = false

        createdDateFilters.value = get(widgetDetails, "TicketField---CreatedDate--DateType");
        closedDateFilters.value = get(widgetDetails, "TicketField---ClosedDate--DateType");

        DisplayInternalUsers = !isUndefined(get(widgetDetails, "TicketField---InternalUsers")) ?
            get(widgetDetails, "TicketField---InternalUsers") :
            true;
        DisplayExternalUsers = !isUndefined(get(widgetDetails, "TicketField---ExternalUsers")) ?
            get(widgetDetails, "TicketField---ExternalUsers") :
            true;

        if (get(widgetDetails, "groupingOptions") === "UserType") {
            GroupByUserType = true
        }

        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_SIX_MONTHS) {
            startDate = moment()
                .subtract(5, 'months')
                .format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-MM-DDT23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    ticketsColumns.push({
                        title: columnTitle,
                        dataIndex: fieldName,
                    });
                }
            }

            if (includes(wdKey, customFieldIndicator)) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
            }

            if (includes(wdKey, ticketFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "AssignedUserId")) {
                    if (checkIfEmailIsValid(wdValue)) {
                        AssignedEmailAddress = wdValue
                    }
                    else if (includes(wdKey, "AssignedUserId--UserId")) {
                        AssignedUserId = wdValue;
                    }
                }
                else if (includes(wdKey, "ReasonType")) {
                    TicketOptionReasons = map(
                        get(widgetDetails, wdKey),
                        (options: string) => options
                    );
                }
                else if (includes(wdKey, "CreatedDate")) {
                    if (createdDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            createdDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            createdDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (createdDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            createdDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            createdDateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(createdDateFilters, true);
                    CreatedDateMin = minDate;
                    CreatedDateMax = maxDate;
                }
                else if (includes(wdKey, "ClosedDate")) {
                    if (closedDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            closedDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            closedDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (closedDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            closedDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            closedDateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(closedDateFilters, true);
                    ClosedDateMin = minDate;
                    ClosedDateMax = maxDate;
                }
            }
        });

        TicketOptionReasons = map(
            get(widgetDetails, 'ticketTypes'),
            (ticketType: string) => ticketType
        );

        let payload: DynamicObject = {
            filters: {
                DisplayName: Customer,
                CustomerCode: Customer,
                CreatedDateMin: !isEmpty(CreatedDateMin) ? CreatedDateMin : startDate,
                CreatedDateMax: !isEmpty(CreatedDateMax) ? CreatedDateMax : endDate,
                ClosedDateMin,
                ClosedDateMax,
                CustomerCountry,
                CustomerState,
                AssignedUserId,
                AssignedEmailAddress,
                TicketOptionReasons,
                ...customFieldFilters
            }
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }

        if (!GroupByUserType) {
            dispatch(
                getDashboardTicketsForUsersRequestAction(
                    payload,
                    isOrgView,
                    (ticketCounts: DynamicObject[]) => {
                        if (unmountedRef.current) return;

                        let usedColumns: any = [];
                        if (hasDisplayValue) {
                            usedColumns = ticketsColumns;
                        } else {
                            usedColumns = widgetState.columns;
                        }

                        if (!isEmpty(ticketCounts)) {
                            if (!DisplayInternalUsers) {
                                ticketCounts = removeUserType(ticketCounts, "Internal", GroupByUserType);
                            }

                            if (!DisplayExternalUsers) {
                                ticketCounts = removeUserType(ticketCounts, "External", GroupByUserType);
                            }
                        }

                        let dataSource = map(ticketCounts, (tc) => {
                            let user = !isEmpty(get(tc, 'User')) ?
                                `${get(tc, 'User.GivenName')}${" "}${get(tc, 'User.FamilyName')}` :
                                !isEmpty(get(tc, 'AssignedEmailAddress', '')) ?
                                    get(tc, 'AssignedEmailAddress', '') :
                                    'Unassigned/System'

                            const total = get(tc, 'ClosedSum', 0) + get(tc, 'OpenSum', 0);
                            return {
                                ...tc,
                                'User': user,
                                'Sum of Closed': get(tc, 'ClosedSum', 0),
                                'Sum of Open': get(tc, 'OpenSum', 0),
                                'totalTickets': total,
                            };
                        });

                        if (!(usedColumns.some((i: any) => i.dataIndex.includes("OpenSum")))) { // Remove open tickets data
                            dataSource = dataSource.map((item: any) => {
                                const { OpenSum, ['Sum of Open']: sumOfOpen, ...rest } = item;
                                set(rest, 'totalTickets', get(rest, 'ClosedSum', 0) + get(rest, 'OpenSum', 0));
                                return rest;
                            });
                        }

                        if (!(usedColumns.some((i: any) => i.dataIndex.includes("ClosedSum")))) { // Remove closed tickets data
                            dataSource = dataSource.map((item: any) => {
                                const { ClosedSum, ['Sum of Closed']: sumOfClosed, ...rest } = item;
                                set(rest, 'totalTickets', get(rest, 'ClosedSum', 0) + get(rest, 'OpenSum', 0));
                                return rest;
                            });
                        }
                        
                        dataSource.sort((a, b) => b["totalTickets"] - a["totalTickets"]);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let user = ds.User

                            if (ds.totalTickets > 0 && longestWord.length < user.length) {
                                longestWord = user; 
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateWidgetStateObject({
                            dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            columns: usedColumns,
                            groupByUserType: GroupByUserType
                        });
                    },
                    payloadCallback
                )
            );
        }
        else {
            dispatch(
                getDashboardTicketsForUserTypesRequestAction(
                    payload,
                    isOrgView,
                    (ticketCounts: DynamicObject[]) => {
                        if (unmountedRef.current) return;

                        let usedColumns: any = [];
                        if (hasDisplayValue) {
                            usedColumns = ticketsColumns;
                        } else {
                            usedColumns = widgetState.columns;
                        }

                        if (!isEmpty(ticketCounts)) {
                            if (!DisplayInternalUsers) {
                                ticketCounts = removeUserType(ticketCounts, "Internal", GroupByUserType);
                            }

                            if (!DisplayExternalUsers) {
                                ticketCounts = removeUserType(ticketCounts, "External", GroupByUserType);
                            }
                        }

                        let dataSource = map(ticketCounts, (tc) => {
                            let user = get(tc, 'UserType');

                            const total = get(tc, 'ClosedSum', 0) + get(tc, 'OpenSum', 0);
                            return {
                                ...tc,
                                'User': user,
                                'Sum of Closed': get(tc, 'ClosedSum', 0),
                                'Sum of Open': get(tc, 'OpenSum', 0),
                                'totalTickets': total,
                            };
                        });

                        if (!(usedColumns.some((i: any) => i.dataIndex.includes("OpenSum")))) { // Remove open tickets data
                            dataSource = dataSource.map((item: any) => {
                                const { OpenSum, ['Sum of Open']: sumOfOpen, ...rest } = item;
                                set(rest, 'totalTickets', get(rest, 'ClosedSum', 0) + get(rest, 'OpenSum', 0));
                                return rest;
                            });
                        }

                        if (!(usedColumns.some((i: any) => i.dataIndex.includes("ClosedSum")))) { // Remove closed tickets data
                            dataSource = dataSource.map((item: any) => {
                                const { ClosedSum, ['Sum of Closed']: sumOfClosed, ...rest } = item;
                                set(rest, 'totalTickets', get(rest, 'ClosedSum', 0) + get(rest, 'OpenSum', 0));
                                return rest;
                            });
                        }

                        dataSource.sort((a, b) => b["totalTickets"] - a["totalTickets"]);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let user = ds.User

                            if (ds.totalTickets > 0 && longestWord.length < user.length) {
                                longestWord = user;
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateWidgetStateObject({
                            dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            columns: usedColumns,
                            groupByUserType: GroupByUserType
                        });
                    },
                    payloadCallback
                )
            );
        }
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return value ? formatNumber(value, 0, localeUsed) : value;
        } else {
            return value;
        }
    };

    const customContent = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-widget-tooltip">
                    <p className="label">{payload[0].payload.User}</p>
                    {!isUndefined(payload[0].payload.OpenSum) && <p className="label">Sum of Open : {`${formatNumber(payload[0].payload.OpenSum)}`}</p>}
                    {!isUndefined(payload[0].payload.ClosedSum) && <p className="label">Sum of Closed : {`${formatNumber(payload[0].payload.ClosedSum)}`}</p>}
                </div>
            );
        }

        return null;
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function for getting if the chart grouping option is monthly or not.
     */
    const getIfIsMonthlyView = () => {
        return !isUndefined(get(widgetDetails, 'groupingOptions'))
            ? get(widgetDetails, 'groupingOptions')
            : true;
    };

    /**
     * Function for populating the label whether the chart is set to Monthly or Weekly view.
     */
    const populateChartLabel = () => {
        if (!widgetState.loading) {
            const isMonthlyView = getIfIsMonthlyView();
            const labelText = isMonthlyView ? 'Monthly view' : 'Weekly view';

            return <div className="chart-title">{labelText}</div>;
        }

        return null;
    };

    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#F44336',
        '#9C27B0',
        '#FFEB3B',
        '#795548',
        '#8BC34A',
        '#263238',
    ];

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            User,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {User}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    /**
     * Function for getting the length of text.
     * @param text
     */
    const measureText = (text: string) => {
        return text ? text.length : 0;
    };

    let orangeValueKey = 'Sum of Closed';
    let blueValueKey = 'Sum of Open';


    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');
        const {
            loading: stateLoading,
            dataSource: stateDataSource,
            tickWidth: stateTickWidth,
            chartMarginLeft: stateChartMarginLeft,
            columns: columnsData,
            groupByUserType: groupByUserType
        } = widgetState;

        if (displayView === widgetDisplayTypeValues.TABLE) {
            const ticketCountColumn = columnsData.map((item) => ({
                title: item.title,
                dataIndex: item.dataIndex,
            }));

            const initialColumn = [
                {
                    title: groupByUserType ? 'User Type' : 'User',
                    dataIndex: 'User',
                },
            ];

            const columns = [...initialColumn, ...ticketCountColumn];

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'User') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableNumberFormatter(stateDataSource, formatNumber)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="User"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            const filteredData = (stateDataSource).filter((dataPoint: any) => dataPoint["totalTickets"] > 0);
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                // legendType="circle"
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="totalTickets"
                                nameKey="User"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} content={customContent} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            const filteredData = (stateDataSource).filter((dataPoint: any) => dataPoint["totalTickets"] > 0);
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    {/* {populateChartLabel()} */}

                    <div className="tickets-widget-graph-container">
                        <ResponsiveContainer
                            // width="99%"
                            // height={
                            //     filteredData.length > 6
                            //         ? filteredData.length * 50 + 100
                            //         : '99%'
                            // }
                        >
                            <BarChart
                                data={filteredData}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    left: stateChartMarginLeft,
                                    bottom: 15,
                                }}
                                layout="vertical"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis
                                    type="category"
                                    dataKey="User"
                                    tick={{
                                        fontSize: 14,
                                        width: stateTickWidth,
                                    }}
                                />
                                <XAxis
                                    type="number"
                                    allowDecimals={false}
                                    width={stateTickWidth}
                                    orientation="bottom"
                                />
                                <Tooltip formatter={tooltipFormatter} />
                                {preview && <Legend />}
                                <Bar dataKey={blueValueKey} fill="#0088fe" />
                                <Bar dataKey={orangeValueKey} fill="#fd6a02" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div className="tickets-sticky-x-axis" />
                    </div>
                </Spin>
            );
        }


    };

    return (
        <div
            className="tickets-widget-container h-100"
            ref={widgetContainerRef}
        >
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(TicketsWidget);
