/**
 * HOC component for handling pageviews
 */

import { filter, forEach, get, includes } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updatePageViewsSelectedAction } from '../../store/common/actions';
import { parseToHTML } from '../../utils/commonFunctions';
import { appliedFilterIndicator } from './FilterBar';

export const withPageViewHandler = (WrappedComponent: any) => {
    const PageViewHandler: React.FC<any> = (props: any) => {
        const dispatch = useDispatch();

        /**
         * Common function for handling the selection of pageview option.
         * @param tableFilterValue - selected value of the dropdown
         * @param applyFiltersFunction - function to be called when applying the filters from `FilterState`
         * @param actionBarRefCurrent - ActionBar ref.current
         * @param pageName - pageName for saving current pageview filter
         */
        const handlePageViewSelection = (
            tableFilterValue: string | undefined,
            applyFiltersFunction: Function,
            actionBarRefCurrent?: any,
            pageName?: string
        ) => {
            if (actionBarRefCurrent) {
                const pageViewList = actionBarRefCurrent.getPageViewList();
                const selectedPageView = get(
                    filter(pageViewList, ['value', tableFilterValue]),
                    0
                );

                if (selectedPageView) {
                    const filterState = get(selectedPageView, 'FilterState');
                    const parsedFilterState = filterState
                        ? JSON.parse(filterState)
                        : null;

                    const htmlFilterState: any = {};
                    forEach(parsedFilterState, (stateValue, stateName) => {
                        const filterHtml =
                            includes(stateName, appliedFilterIndicator) &&
                            /<\/?[a-z][\s\S]*>/.test(stateValue)
                                ? parseToHTML(stateValue)
                                : stateValue;

                        htmlFilterState[stateName] = filterHtml;
                    });

                    if (htmlFilterState) {
                        applyFiltersFunction(htmlFilterState);
                        if (pageName) {
                            const savedValues = {
                                ...htmlFilterState,
                                tableSortState: get(
                                    parsedFilterState,
                                    'tableSortState'
                                ),
                            };
                            dispatch(
                                updatePageViewsSelectedAction(
                                    pageName,
                                    savedValues
                                )
                            );
                        }
                    }
                }
            }
        };

        return (
            <WrappedComponent
                {...props}
                handlePageViewSelection={handlePageViewSelection}
            />
        );
    };

    return PageViewHandler;
};
